import { faEnvelope, faInbox } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { Outlet } from "react-router";
import { Link } from "react-router-dom";
import { getConnectionsForLocation } from "../../actions/connections";
import { AppDispatch, RootState } from "../../app/store";
import AppInstallPanel from "../../components/AppInstallPanel";
import { SearchParams } from "../connections/routes";
import "./Conversations.scss";
import { ConversationFilters_All, ConversationFilters_Unread } from "./filters";
import { RootPath } from "./routes";

const FILTER_BUTTONS = [
  {
    label: "All",
    category: ConversationFilters_All.category,
    icon: faInbox,
  },
  {
    label: "Unread",
    category: ConversationFilters_Unread.category,
    icon: faEnvelope,
  },
];

type ConversationsPageProps = ConversationsPageConnectedProps & {
  category?: string;
  locationId?: number;
};

function ConversationsPage({ managedLocations, category, locationId }: ConversationsPageProps) {
  const navActiveKey = category ? `category-${category}` : locationId ? `location-${locationId}` : undefined;

  return (
    <div className="h-100 d-flex flex-row gap-3">
      <div
        className="d-flex flex-column h-100 justify-content-between gap-3"
        style={{ flex: "0 0 auto", maxWidth: "250px" }}
      >
        <Nav
          activeKey={navActiveKey}
          defaultActiveKey={`category-${ConversationFilters_All.category}`}
          className="d-flex flex-column side-nav w-100 conversation-filters"
          style={{ flex: "1 1", minHeight: "0px" }}
        >
          <div style={{ flex: "0 0 auto" }}>
            <div className="title">Conversations</div>
            {FILTER_BUTTONS.map((button) => (
              <Nav.Link
                as={Link}
                to={{ pathname: RootPath, search: `${SearchParams.Category}=${button.category}` }}
                eventKey={`category-${button.category}`}
              >
                <FontAwesomeIcon icon={button.icon} />
                {button.label}
              </Nav.Link>
            ))}
            <hr />
          </div>
          <div style={{ flex: "1 1", overflowY: "auto" }}>
            {managedLocations.map((location) => (
              <Nav.Link
                as={Link}
                to={{ pathname: RootPath, search: `location_id=${location.id}` }}
                eventKey={`location-${location.id}`}
              >
                <div>
                  {location.name}
                  <br />
                  <span className="fs-smaller">({location.address_1})</span>
                </div>
              </Nav.Link>
            ))}
          </div>
        </Nav>
        <AppInstallPanel>
          Want to message job seekers
          <br />
          from your phone?
        </AppInstallPanel>
      </div>
      <div className="flex-grow-1 flex-shrink-1 h-100" style={{ minWidth: "1px" }}>
        <Outlet />
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    managedLocations: state.locations.locations ?? [],
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchConnectionsForLocation: (locationId: number) => dispatch(getConnectionsForLocation(locationId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ConversationsPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(ConversationsPage);
