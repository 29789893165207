import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AdvancedMarker, Map } from "@vis.gl/react-google-maps";
import { useCallback, useMemo, useState } from "react";
import { Alert, Button, Col, Container, Modal, Row } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { createLocation, updateLocation } from "../../actions/locations";
import { AppDispatch, RootState } from "../../app/store";
import AppToast from "../../components/AppToast";
import { ErrorReasonCode } from "../../constants";
import { CompanyLocation, CompanyLocationBasic } from "../../data/companyLocation";
import { PaymentPackage } from "../../data/payments";
import { ModelValidationErrors } from "../../data/validation";
import { FailedRequestError } from "../../rest-client/errors";
import { objectsAreEqual } from "../../utils/objects";
import { isNullOrEmpty } from "../../utils/validation";
import LocationForm from "./LocationForm";

const DEFAULT_MAP_CENTER: google.maps.LatLngLiteral = {
  lat: 39.8283,
  lng: -98.5795,
};

type EditLocationModalProps = EditLocationModalConnectedProps & {
  visible?: boolean;
  locationId?: number;
  onClosed?: (result: EditLocationModalResult) => void;
};

export type EditLocationModalResult = {
  cancelled: boolean;
  locationId?: number;
};

const EditLocationModal = ({
  managedLocations,
  brands,
  paymentPackages,
  company,
  locationId,
  visible,
  onClosed,
  createLocation,
  updateLocation,
}: EditLocationModalProps) => {
  const [updating, setUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [validationErrors, setValidationErrors] = useState<ModelValidationErrors>();
  const [showConfirmDirtyClose, setShowConfirmDirtyClose] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const isFirstLocation = useMemo(() => isNullOrEmpty(managedLocations), [managedLocations]);

  const paymentPackage = useMemo<PaymentPackage | undefined>(() => {
    if (locationId) {
      return undefined;
    }
    const packageWithRemainingLocations = paymentPackages?.find(
      (paymentPackage) => paymentPackage.remaining_location_count ?? 0 > 0
    );
    if (packageWithRemainingLocations) {
      return packageWithRemainingLocations;
    }
    return paymentPackages?.find((paymentPackage) => paymentPackage.active && paymentPackage.shared);
  }, [locationId, paymentPackages]);

  const location = useMemo((): Partial<CompanyLocation> | undefined => {
    return locationId
      ? managedLocations?.find((loc) => loc.id === locationId)
      : {
          brand_id: brands?.length === 1 ? brands[0].id : undefined,
          payment_package_id: paymentPackage?.id,
        };
  }, [locationId, managedLocations, brands, paymentPackage]);

  const [locationCoords, setLocationCoords] = useState<google.maps.LatLngLiteral | undefined>(
    location?.location ? { lat: location.location.latitude, lng: location.location.longitude } : undefined
  );

  const locationFormId = useMemo(() => `location-${locationId ?? "new"}`, [locationId]);

  const copyFromLocation = useMemo<Partial<CompanyLocationBasic> | undefined>(() => {
    if (isFirstLocation) {
      return {
        address_1: company?.address_1 ?? "",
        address_2: company?.address_2 ?? "",
        address_city: company?.address_city ?? "",
        address_state: company?.address_state ?? "",
        address_zip: company?.address_zip ?? "",
      };
    } else {
      return undefined;
    }
  }, [isFirstLocation, company]);

  const handleClose = useCallback(
    ({ cancelled = false, locationId }: EditLocationModalResult) => {
      if (onClosed) {
        onClosed({ cancelled, locationId });
      }
      setUpdating(false);
      setErrorMessage(undefined);
      setValidationErrors(undefined);
    },
    [onClosed]
  );

  const handleSave = useCallback(
    (location: CompanyLocationBasic) => {
      setUpdating(true);
      setErrorMessage(undefined);
      updateLocation(locationId!, location)
        .then(() => {
          setTimeout(() => {
            handleClose({ cancelled: false, locationId });
            AppToast.show({ text: "Sucessfully saved changes", type: "success" });
          }, 500);
        })
        .catch((error) => {
          if (error instanceof FailedRequestError) {
            if (error.isUnprocessableEntity()) {
              setValidationErrors(error.responseJson);
            } else {
              setErrorMessage("An unexpected error occurred");
            }
          } else {
            setErrorMessage(error.message);
          }
        })
        .finally(() => setUpdating(false));
    },
    [handleClose, locationId, updateLocation]
  );

  const handleCreate = useCallback(
    (location: CompanyLocationBasic, confirmedCostAmount?: number) => {
      setUpdating(true);
      setErrorMessage(undefined);
      createLocation(location, confirmedCostAmount)
        .then((newLocation) => {
          handleClose({ cancelled: false, locationId: newLocation.id });
          AppToast.show({
            text: `Successfully created location`,
            type: "success",
          });
        })
        .catch((error) => {
          if (error instanceof FailedRequestError) {
            if (error.isUnprocessableEntity()) {
              if (error.responseJson.reason === ErrorReasonCode.ModelValidationFailed) {
                setValidationErrors(error.responseJson.details);
              } else {
                setErrorMessage(
                  typeof error.responseJson.details === "string"
                    ? error.responseJson.details
                    : "An unexpected error occurred"
                );
              }
            } else {
              setErrorMessage("An unexpected error occurred");
            }
          } else {
            setErrorMessage(error.message);
          }
        })
        .finally(() => setUpdating(false));
    },
    [handleClose, createLocation]
  );

  const handleHide = useCallback(
    (skipDirtyCheck: boolean = false) => {
      // check if the job has changed
      let confirmClose = false;
      if (!skipDirtyCheck) {
        if (isDirty) {
          confirmClose = true;
        }
      }
      if (confirmClose) {
        setShowConfirmDirtyClose(true);
      } else {
        handleClose({ cancelled: true });
      }
    },
    [isDirty, handleClose, setShowConfirmDirtyClose]
  );

  const changeHandler = useCallback(
    ({ attributes }: { attributes: Partial<CompanyLocationBasic> }) => {
      setIsDirty(!objectsAreEqual(location ?? {}, attributes));
      setLocationCoords(
        attributes.location ? { lat: attributes.location?.latitude, lng: attributes.location?.longitude } : undefined
      );
    },
    [setIsDirty, location, setLocationCoords]
  );

  const submitHandler = useCallback(
    (location: CompanyLocationBasic, confirmedCostAmount?: number) => {
      if (locationId) {
        handleSave(location);
      } else {
        handleCreate(location, confirmedCostAmount);
      }
    },
    [locationId, handleSave, handleCreate]
  );

  return (
    <Modal show={visible} onHide={handleHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{`${locationId ? "Edit" : "Add"} Location`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row>
            <Col>
              <LocationForm
                onSubmit={submitHandler}
                location={location}
                onChange={changeHandler}
                id={locationFormId}
                copyAddress={copyFromLocation}
                copyAddressLabel="billing address"
                paymentPackage={paymentPackage}
              />
            </Col>
            <Col>
              <div className="h-100 w-100">
                <Map
                  mapId={`edit-location-map-${location ? location.id : "new"}`}
                  center={locationCoords ?? DEFAULT_MAP_CENTER}
                  zoom={locationCoords ? 13 : 4}
                >
                  {locationCoords && (
                    <AdvancedMarker position={locationCoords}>
                      <img src="/images/map-pin.png" width={42} height={55} alt="Employer Location" />
                    </AdvancedMarker>
                  )}
                </Map>
              </div>
            </Col>
          </Row>
        </Container>
        {errorMessage && (
          <Alert variant="danger" dismissible={true} onClose={() => setErrorMessage(undefined)}>
            {errorMessage}
          </Alert>
        )}
        {showConfirmDirtyClose && (
          <div
            className="position-absolute top-0 start-0 bottom-0 end-0 d-flex flex-column align-items-center justify-content-center"
            style={{ zIndex: 10 }}
          >
            <Alert>
              <div className="d-flex flex-row gap-3 align-items-center">
                <FontAwesomeIcon icon={faTriangleExclamation} />
                You have unsaved changes
                <Button variant="outline-dark" onClick={() => handleClose({ cancelled: true })}>
                  Close without saving
                </Button>
                <Button variant="outline-dark" onClick={() => setShowConfirmDirtyClose(false)}>
                  Do not close
                </Button>
              </div>
            </Alert>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="flex-row">
        {locationId == undefined && paymentPackage == undefined ? (
          <div className="text-danger me-3">
            There is a problem with you account configuration. Please contact{" "}
            <a href="mailto:support@juvo360.com">support</a>.
          </div>
        ) : (
          <>
            {validationErrors && <div className="text-danger me-3">Fix the problems above and try again</div>}
            <Button variant="secondary" onClick={() => handleHide(showConfirmDirtyClose)}>
              {showConfirmDirtyClose ? "Confirm Close" : "Close"}
            </Button>
            {locationId ? (
              <>
                <Button variant="primary" type="submit" disabled={updating} form={locationFormId}>
                  Save
                </Button>
              </>
            ) : (
              <>
                <Button variant="primary" type="submit" disabled={updating} form={locationFormId}>
                  Create
                </Button>
              </>
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  managedLocations: state.locations.locations,
  brands: state.brands.brands,
  company: state.user.company,
  paymentPackages: state.payments.paymentPackages,
});

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    updateLocation: (locationId: number, location: CompanyLocationBasic) =>
      dispatch(updateLocation(locationId, location)),
    createLocation: (location: CompanyLocationBasic, confirmedCostAmount?: number) =>
      dispatch(createLocation(location, confirmedCostAmount)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type EditLocationModalConnectedProps = ConnectedProps<typeof connector>;

export default connector(EditLocationModal);
