import * as Api from "../rest-client/api";
import { JsonMap } from "../types/json";
import { AppAsyncAction } from "./types";

export function getAppSettings(): AppAsyncAction<JsonMap> {
  return () => Api.fetchAppSettings().then(({ responseJson }) => responseJson);
}

export function updateAppSettings(updatedSettings: JsonMap): AppAsyncAction<JsonMap> {
  return () => Api.updateAppSettings(updatedSettings).then(({ responseJson }) => responseJson);
}
