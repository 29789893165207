import { Container } from "react-bootstrap";
import { RouteObject, useLoaderData } from "react-router";
import LocationJobs from "./LocationJobs";
import JobsPage from "./JobsPage";

export const Pathname = "jobs";
export const RootPath = "/" + Pathname;

export const SearchParams = {
  Category: "category",
  LocationId: "location_id",
  JobId: "job_id",
  SelectedConnectionId: "selected_connection_id",
};

const JobsElement = () => (
  <Container className="content">
    <JobsPage />
  </Container>
);

const LocationJobsElement = () => {
  const props = useLoaderData() as any;
  return <LocationJobs {...props} />;
};

const routes: RouteObject = {
  path: Pathname,
  element: <JobsElement />,
  children: [
    {
      index: true,
      element: <LocationJobsElement />,
      loader: ({ request }) => {
        const url = new URL(request.url);
        return {
          locationId: url.searchParams.has(SearchParams.LocationId)
            ? parseInt(url.searchParams.get(SearchParams.LocationId)!)
            : null,
        };
      },
    },
  ],
};

export default routes;
