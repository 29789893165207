import { Map, Marker } from "@vis.gl/react-google-maps";

type GoogleMapProps = {
  zoom?: number,
  center?: google.maps.LatLngLiteral,
  markerLocation?: google.maps.LatLngLiteral | google.maps.LatLng | null, 
}

export default function GoogleMap({ zoom = 14, center, markerLocation }: GoogleMapProps) {
    return (
      <Map
        zoom={zoom}
        center={center}
      >
        {markerLocation && (
          <Marker
            position={markerLocation}
            icon={{
              url: "/images/map-pin.png",
              anchor: new google.maps.Point(28, 74),
              scaledSize: new google.maps.Size(56, 74)
            }}
          />
        )}
      </Map>
    );
}
