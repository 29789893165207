import React, { useRef, useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";

type ToastType = "error" | "success";
type ShowToastOptions = {
  type: ToastType;
  text: string;
  duration?: number;
  autoHide?: boolean;
  onClose?: () => void;
};

type AppToastContainerRef = {
  show: (options: ShowToastOptions) => void;
};

const AppToastContainer = React.forwardRef((_props: unknown, ref: React.Ref<AppToastContainerRef>) => {
  const [toastType, setToastType] = useState<ToastType | null>(null);
  const [toastText, setToastText] = useState<string | null>(null);
  const autoHideTimeoutId = useRef<NodeJS.Timeout | null>(null);

  // instance method to hide toast message
  const hideToast = React.useCallback(() => {
    setToastText(null);
  }, [setToastText]);

  // instance method to make toast visible
  const showToast = React.useCallback(
    ({ type, text, duration = 5000, autoHide = true, onClose }: ShowToastOptions) => {
      if (autoHide && duration) {
        autoHideTimeoutId.current = setTimeout(() => {
          hideToast();
          if (onClose) {
            onClose();
          }
          autoHideTimeoutId.current = null;
        }, duration);
      }
      setToastType(type);
      setToastText(text);
    },
    [hideToast]
  );

  // exposes instance methods
  React.useImperativeHandle(
    ref,
    React.useCallback(
      () => ({
        show: showToast,
      }),
      [showToast]
    )
  );

  return (
    <ToastContainer position="bottom-center" className="mb-4">
      {toastText && (
        <Toast bg={toastType === "error" ? "danger" : "success"}>
          <Toast.Header>{toastType === "error" ? "Error" : "Success"}</Toast.Header>
          <Toast.Body className="text-white">{toastText}</Toast.Body>
        </Toast>
      )}
    </ToastContainer>
  );
});

class AppToast extends React.Component {
  private static containerRef: AppToastContainerRef | null = null;

  public static show(options: ShowToastOptions) {
    AppToast.containerRef?.show(options);
  }

  setContainerRef(instance: AppToastContainerRef) {
    AppToast.containerRef = instance;
  }

  render() {
    // store a global ref to the container
    // note: the static methods won't work correctly if more than one <AppToast ... /> component is added to the app
    return <AppToastContainer ref={this.setContainerRef} />;
  }
}

export default AppToast;
