import { useCallback, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "../../app/store";
import { CompanyLocation } from "../../data/companyLocation";
import { AdminScope } from "../../data/user";
import EditLocationModal, { EditLocationModalResult } from "./EditLocationModal";
import LocationCard from "./LocationCard";

type LocationListProps = LocationListConnectedProps;

function LocationList({ managedLocations, canManageLocations }: LocationListProps) {
  const [locationModalVisible, setLocationModalVisible] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<CompanyLocation | null>(null);
  const [locationModalKey, setLocationModalKey] = useState("");

  const addLocation = useCallback(() => {
    setLocationModalVisible(true);
  }, [setLocationModalVisible]);

  const editLocation = useCallback(
    (location: CompanyLocation) => {
      setSelectedLocation(location);
      setLocationModalKey(`loc-modal-${new Date().getTime()}`);
      setLocationModalVisible(true);
    },
    [setLocationModalVisible, setSelectedLocation, setLocationModalKey]
  );

  const navigate = useNavigate();
  const modalClosedHandler = useCallback(
    ({ cancelled, locationId }: EditLocationModalResult) => {
      setSelectedLocation(null);
      setLocationModalKey(`loc-modal-${new Date().getTime()}`);
      setLocationModalVisible(false);
      if (!cancelled) {
        setTimeout(() => navigate(`/locations/${locationId}`), 500);
      }
    },
    [setLocationModalVisible, setSelectedLocation, setLocationModalKey, navigate]
  );

  return (
    <div className="h-100 position-relative w-100">
      <>
        {managedLocations && managedLocations.length > 0 ? (
          <>
            {canManageLocations && false && (
              <div className="d-flex flex-row justify-content-end align-items-center bg-light border-light pb-3">
                <Button variant="success" onClick={addLocation}>
                  Add a new location
                </Button>
              </div>
            )}
            <Container fluid className="g-0">
              <Row md={1} lg={1} xl={2} className="g-3 gy-3">
                {managedLocations?.map((location) => (
                  <Col key={`loc-${location.id}`}>
                    <LocationCard
                      key={`loc-${location.id}`}
                      location={location}
                      onEditClick={editLocation}
                      readOnly={!canManageLocations}
                    />
                  </Col>
                ))}
              </Row>
            </Container>
          </>
        ) : (
          <div className="mt-5 text-center d-flex flex-column gap-3">
            <h4>We're excited to welcome you<br/>to the Juvo community! 👋</h4>
            <div>Let's get your first location setup on the network.</div>
            <div>
              To get started, click <span className="fw-semibold fst-italic text-dark">'Add a new location'</span>.
            </div>
            <div className="w-auto mt-4">
              <Button variant="success" onClick={addLocation}>
                Add a new location
              </Button>
            </div>
          </div>
        )}
      </>
      <EditLocationModal
        key={locationModalKey}
        visible={locationModalVisible}
        locationId={selectedLocation?.id}
        onClosed={modalClosedHandler}
      />
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  canManageLocations: state.user.admin_scopes?.includes(AdminScope.location),
  managedLocations: state.locations.locations,
});

const connector = connect(mapStateToProps);

type LocationListConnectedProps = ConnectedProps<typeof connector>;

export default connector(LocationList);
