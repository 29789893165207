import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  ConnectionSearchResults,
  ConnectionWithFullSeekerProfile,
  ConnectionWithSeekerExperience,
} from "../data/connection";
import { ConnectionFilters } from "../data/miscellaneous";

export type ConnectionsState = {
  searchInProgress: boolean;
  searchFilters: ConnectionFilters | null;
  searchResults: ConnectionWithSeekerExperience[] | null;
  selectedConnection: ConnectionWithSeekerExperience | null;
  searchIsStale: boolean;
  searchTotalCount: number;
};

const initialState: ConnectionsState = {
  searchInProgress: false,
  searchFilters: null,
  searchResults: null,
  selectedConnection: null,
  searchIsStale: false,
  searchTotalCount: 0,
};

function applyUpdates(
  connection: ConnectionWithFullSeekerProfile | ConnectionWithSeekerExperience,
  updatedConnection: ConnectionWithFullSeekerProfile | ConnectionWithSeekerExperience
) {
  return {
    ...connection,
    status: updatedConnection.status,
    interest_level: updatedConnection.interest_level,
    reviewed: updatedConnection.reviewed,
    payment_status: updatedConnection.payment_status,
  };
}

export const connectionsSlice = createSlice({
  name: "connections",
  initialState,
  reducers: {
    setSearchInProgress: (state, action: PayloadAction<boolean>) => {
      state.searchInProgress = action.payload;
    },
    connectionSearch: (
      state,
      action: PayloadAction<{ filters: ConnectionFilters; results: ConnectionSearchResults }>
    ) => {
      state.searchResults = action.payload.results.connections;
      state.searchTotalCount = action.payload.results.total_connections;
      state.searchFilters = action.payload.filters;
      state.searchIsStale = false;
    },
    selectConnection: (state, action: PayloadAction<ConnectionWithSeekerExperience>) => {
      state.selectedConnection = action.payload;
    },
    connectionUpdated: (state, action: PayloadAction<ConnectionWithSeekerExperience>) => {
      if (state.searchResults) {
        state.searchResults = state.searchResults.map((c) =>
          c.id === action.payload.id ? applyUpdates(c, action.payload) : c
        );
      }
      state.searchIsStale = true;
    },
    searchIsStale: (state, action: PayloadAction<boolean>) => {
      state.searchIsStale = action.payload;
    },
  },
});

export const { connectionSearch, selectConnection, setSearchInProgress, connectionUpdated, searchIsStale } =
  connectionsSlice.actions;
export default connectionsSlice.reducer;
