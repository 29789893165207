import React, { useMemo } from "react";
import { faStar, faComment, faComments, faCircle } from "@fortawesome/free-solid-svg-icons";
import {
  hasEmployerChats,
  hasSeekerChats,
  conversationHasUnreadMessage,
  ParsedConversation,
} from "../../utils/conversations";
import { Badge } from "react-bootstrap";
import ExperienceList from "../common/ExperienceList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import SeekerCard from "../common/SeekerCard";
import { Link, To } from "react-router-dom";
import "./ConnectionListItem.scss";
import { ConnectionWithSeekerExperience } from "../../data/connection";
import { ParsedConnection } from "../../utils/connections";

type ItemContentProps<T> = {
  connection: T;
  selected: boolean;
  employerHasChatted: boolean;
  seekerHasChatted: boolean;
  hasUnreadChat: boolean;
  ref?: React.LegacyRef<HTMLDivElement>;
};

function ItemContent<T extends ParsedConnection<ConnectionWithSeekerExperience, ParsedConversation>>({
  connection,
  selected,
  employerHasChatted,
  seekerHasChatted,
  hasUnreadChat,
  ref,
}: ItemContentProps<T>) {
  return (
    <SeekerCard seeker={connection.seeker} className={selected ? "selected" : undefined} ref={ref}>
      <SeekerCard.Header>
        <div className="seeker-name">
          {`${connection.seeker.profile.first_name} ${connection.seeker.profile.last_name}`}
        </div>
        {connection.interest_level > 0 && <FontAwesomeIcon icon={faStar} className="favorites-icon" />}
        {employerHasChatted && (
          <div className="chat-icon">
            <FontAwesomeIcon icon={seekerHasChatted ? faComments : faComment} />
            {hasUnreadChat && <FontAwesomeIcon icon={faCircle} className="unread-chat-indicator" />}
          </div>
        )}
        {!connection.reviewed && <Badge bg="danger">New</Badge>}
      </SeekerCard.Header>
      <SeekerCard.Details>
        <div>Submitted {formatDistanceToNowStrict(connection.last_submitted_at)} ago</div>
        <ExperienceList experiences={connection.seeker.profile.experiences} maxItems={2} compact={true} />
      </SeekerCard.Details>
    </SeekerCard>
  );
}

type ConnectionListItemProps<T> = {
  connection: T;
  selected: boolean;
  linkTo?: To;
  onClick?: (connection: T) => void;
  ref?: React.LegacyRef<HTMLDivElement>;
};

function ConnectionListItem<T extends ParsedConnection<ConnectionWithSeekerExperience, ParsedConversation>>({
  connection,
  selected,
  linkTo,
  onClick,
  ref,
}: ConnectionListItemProps<T>) {
  const { conversation } = connection;
  const employerHasChatted = !!conversation && hasEmployerChats(conversation);
  const seekerHasChatted = !!conversation && hasSeekerChats(conversation);
  const hasUnreadChat = !!conversation && conversationHasUnreadMessage(conversation);
  const content = useMemo(
    () => (
      <ItemContent<T>
        ref={ref}
        connection={connection}
        selected={selected}
        employerHasChatted={employerHasChatted}
        seekerHasChatted={seekerHasChatted}
        hasUnreadChat={hasUnreadChat}
      />
    ),
    [connection, selected, employerHasChatted, seekerHasChatted, hasUnreadChat, ref]
  );

  if (linkTo) {
    return (
      <Link to={linkTo} className="no-decoration" style={{ direction: "ltr" }}>
        {content}
      </Link>
    );
  } else {
    return (
      <div
        role="button"
        onClick={() => {
          if (onClick) {
            onClick(connection);
          }
        }}
        style={{ direction: "ltr" }}
      >
        {content}
      </div>
    );
  }
}

export default ConnectionListItem;
