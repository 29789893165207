import { loadConversations } from "./conversations";
import { setActivities } from "../reducers/activities";
import { setBrands } from "../reducers/brands";
import { setConversations } from "../reducers/conversations";
import * as InitializationReducers from "../reducers/initialization";
import { setLocations } from "../reducers/locations";
import * as Api from "../rest-client/api";
import { loadActivities } from "./activities";
import { loadBrands } from "./brands";
import { loadLocationActivity, loadLocations } from "./locations";
import { AppAction, AppAsyncAction } from "./types";
import { loadPaymentPackages } from "./payments";
import { setPaymentPackages } from "../reducers/payments";

export function initData(isRefresh = false) : AppAsyncAction {
  return async (dispatch) => {
    await dispatch(initSession());
    await dispatch(loadConversations());
    if (!isRefresh) {
      await dispatch(loadLocations());
      await dispatch(loadBrands());
      await dispatch(loadPaymentPackages());
    }
    await dispatch(loadActivities());
    await dispatch(loadAppSettings());
    await dispatch(loadLocationActivity());
    dispatch(InitializationReducers.setDataLoaded(true));
  };
}

export function resetData() : AppAction {
  return async (dispatch) => {
    dispatch(InitializationReducers.setDataLoaded(false));
    dispatch(setConversations(null));
    dispatch(setLocations(null));
    dispatch(setBrands(null));
    dispatch(setPaymentPackages(null));
    dispatch(setActivities(null));
    dispatch(InitializationReducers.setAppSettings(null));
  };
}

export function initSession(): AppAsyncAction {
  return () => {
    const timeZone = ('Intl' in window) ? Intl.DateTimeFormat().resolvedOptions().timeZone : '';
    return Api.initSession({
      platform: 'browser',
      app_version: '1.0.0 (Build 1)',
      installation_id: 'n/a',
      push_notification_token: null,
      time_zone: timeZone,
    });
  };
}

export function loadAppSettings() : AppAsyncAction {
  return (dispatch) => {
    return Api.getAppSettings()
      .then(({ responseJson }) => {
        dispatch(InitializationReducers.setAppSettings(responseJson));
        return responseJson;
      });
  };
}

export function setInitialAppPath(path: string | undefined) : AppAction {
  return (dispatch) => {
    dispatch(InitializationReducers.setInitialAppPath(path));
  }
}