import { ConnectionFilters } from "../data/miscellaneous";
import BaseRestClient from "./baseClient";

export function connectionSearch(filters: ConnectionFilters) {
  const queryParams = new URLSearchParams();
  if (filters.status) {
    const statuses = Array.isArray(filters.status) ? filters.status : [filters.status];
    queryParams.append("connection_status", statuses.join(","));
  }
  if ("reviewed" in filters) {
    queryParams.append("reviewed", filters.reviewed ? "true" : "false");
  }
  if (filters.interest_level && filters.interest_level > 0) {
    queryParams.append("interest_level", filters.interest_level.toString());
  }
  if ("conversation_started" in filters) {
    queryParams.append("conversation_started", filters.conversation_started ? "true" : "false");
  }
  if (filters.job_ids) {
    queryParams.append("job_ids", filters.job_ids.join(","));
  } else if (filters.location_ids) {
    queryParams.append("location_ids", filters.location_ids.join(","));
  }
  if (filters.applicant_first_name) {
    queryParams.append("applicant_first_name", filters.applicant_first_name);
  }
  if (filters.applicant_last_name) {
    queryParams.append("applicant_last_name", filters.applicant_last_name);
  }
  if (filters.max_items) {
    queryParams.append("max_items", filters.max_items.toString());
  }
  return BaseRestClient.fetch("api/v3/employer/connections?" + queryParams.toString());
}

export function fetchConnectionsForLocation(locationId: number) {
  return BaseRestClient.fetch(`/api/v3/employer/locations/${locationId}/connections`);
}

export function fetchConnectionById(connectionId: number) {
  return BaseRestClient.fetch(`api/v3/employer/connections/${connectionId}`);
}

export function updateConnection(connectionId: number, reviewed: boolean, interestLevel: number) {
  return BaseRestClient.patch(`api/v3/employer/connections/${connectionId}`, {
    connection: {
      reviewed,
      interest_level: interestLevel,
    },
  });
}

export function acceptConnection(connectionId: number) {
  return BaseRestClient.put(`api/v3/employer/connections/${connectionId}/accept`);
}

export function declineConnection(connectionId: number) {
  return BaseRestClient.put(`api/v3/employer/connections/${connectionId}/decline`);
}
