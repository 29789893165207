import { useCallback, useRef, useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import AppToast from "../../components/AppToast";
import NotificationSettings, { NotificationSettingsRef } from "../common/NotificationSettings";

type NotificationSettingsModalProps = {
  visible: boolean;
  onClosed: () => void;
};

function NotificationSettingsModal({ visible, onClosed }: NotificationSettingsModalProps) {
  const [updating, setUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const notificationSettingsRef = useRef<NotificationSettingsRef | null>(null);

  const handleClose = useCallback(() => {
    onClosed();
  }, [onClosed]);

  const handleSave = useCallback(() => {
    setUpdating(true);
    setErrorMessage(undefined);
    notificationSettingsRef
      .current!.save()
      .then(() => {
        AppToast.show({ text: "Notification settings successfully updated!", type: "success" });
        handleClose();
      })
      .catch((error) => {
        console.error("Update failed", error);
        setErrorMessage("An unexpected error occurred");
      })
      .finally(() => setUpdating(false));
  }, [handleClose, setUpdating, setErrorMessage]);

  return (
    <Modal show={visible} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Notification Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <NotificationSettings ref={notificationSettingsRef} />
        {errorMessage && (
          <Alert variant="danger" dismissible={true}>
            {errorMessage}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={updating}>
          {updating ? "Updating..." : "Update"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NotificationSettingsModal;
