export class FailedRequestError extends Error {
  readonly response: Response;
  readonly responseJson: any | null;
  readonly responseText: string | null;
  constructor(response: Response, responseJson: any | null, responseText: string | null) {
    super(`Http request failed with status code ${response.status} [${response.statusText}]`);
    this.response = response;
    this.responseJson = responseJson;
    this.responseText = responseText;
  }

  isBadRequest(): boolean {
    return this.response.status === 400;
  }
  isConflict(): boolean {
    return this.response.status === 409;
  }
  isUnprocessableEntity(): boolean {
    return this.response.status === 422;
  }
  isNotFound(): boolean {
    return this.response.status === 404;
  }
}
