import { faInbox, faStar, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { ConnectionFilters_All, ConnectionFilters_Favorites, ConnectionFilters_Viewed } from "./filters";

export const CONNECTION_CATEGORIES = [
  {
    label: 'Total',
    icon: faInbox,
    filterKey: ConnectionFilters_All.category,
  },
  {
    label: 'Viewed',
    icon: faUserCheck,
    filterKey: ConnectionFilters_Viewed.category,
  },
  {
    label: 'Favorited',
    icon: faStar,
    filterKey: ConnectionFilters_Favorites.category,
  },
];
