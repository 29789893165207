import memoizeOne from "memoize-one";
import { EducationLevels } from "../../constants";
import { UserEducation } from "../../data/userEducation";
import { formatEducationFieldOfStudy, formatEducationSchoolName, formatExperienceTime } from "../../utils/format";
import "./ProfileItemList.scss";

const EducationLevelRanks = [
  EducationLevels.Doctorate,
  EducationLevels.MastersDegree,
  EducationLevels.BachelorsDegree,
  EducationLevels.AssociateDegree,
  EducationLevels.Professional,
  EducationLevels.Vocational,
  EducationLevels.HighSchool,
];

type EducationListProps = {
  educations: UserEducation[];
  maxItems?: number;
};

function EducationList({ educations, maxItems = 1000 }: EducationListProps) {
  const sortEducations = memoizeOne((educations) => {
    const _educations = [...educations];
    _educations.sort((ed1, ed2) => {
      if (ed1.level !== ed2.level) {
        return EducationLevelRanks.indexOf(ed1.level) < EducationLevelRanks.indexOf(ed2.level) ? -1 : 1;
      } else {
        return ed1.id > ed2.id ? -1 : 1;
      }
    });
    return _educations;
  });

  const sortedEducations = sortEducations([...educations]).slice(0, maxItems);

  return (
    <div className="profile-item-list">
      {sortedEducations.map((education, index) => (
        <div key={"edu-" + index}>
          <div className="profile-item-row">
            <div className="title">{formatEducationSchoolName(education)}</div>
            <div className="status">{education.graduated ? "Graduated" : ""}</div>
          </div>
          <div className="profile-item-row">
            <div className="details">{formatEducationFieldOfStudy(education)}</div>
            <div className="duration">{formatExperienceTime(education)}</div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default EducationList;
