import { useCallback, useEffect, useRef, useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { getVideoLocations, updateVideoLocations } from "../../actions/user";
import { AppDispatch, RootState } from "../../app/store";
import AppToast from "../../components/AppToast";
import LoadingOverlay from "../../components/LoadingOverlay";
import "./ProfilePage.scss";

type ProfileVideoLocationsModalProps = ProfileVideoLocationsModalConnectedProps & {
  visible?: boolean;
  onClosed: () => void;
};

function ProfileVideoLocationsModal({
  visible = true,
  managedLocations,
  onClosed,
  getVideoLocations,
  updateVideoLocations,
}: ProfileVideoLocationsModalProps) {
  const loading = useRef(false);
  const [updating, setUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [selectedLocationIds, setSelectedLocationIds] = useState<number[]>([]);

  const handleClose = useCallback(() => {
    onClosed();
  }, [onClosed]);

  const handleSave = useCallback(() => {
    setUpdating(true);
    setErrorMessage(undefined);
    updateVideoLocations(selectedLocationIds)
      .then(() => {
        AppToast.show({ text: "Successfully updated!", type: "success" });
        handleClose();
      })
      .catch(({ responseJson }) => {
        console.error("profile pic update failed", responseJson);
        setErrorMessage("An unexpected error occurred");
      })
      .finally(() => setUpdating(false));
  }, [selectedLocationIds, handleClose, setUpdating, updateVideoLocations, setErrorMessage]);

  useEffect(() => {
    if (!loading.current) {
      loading.current = true;
      getVideoLocations()
        .then((locationIds) => setSelectedLocationIds(locationIds))
        .finally(() => (loading.current = false));
    }
  }, [getVideoLocations, setSelectedLocationIds]);

  return (
    <Modal show={visible} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Profile Video Locations</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading.current && <LoadingOverlay />}
        <div className="mb-3">Use my profile video for the selected locations</div>
        <Form>
          {managedLocations!.map((location) => (
            <Form.Check
              label={`${location.name} (${location.address_1})`}
              value={location.id}
              checked={selectedLocationIds?.includes(location.id) ?? false}
              onChange={(event) => {
                const locationId = parseInt(event.target.value);
                if (event.target.checked) {
                  setSelectedLocationIds([...selectedLocationIds, locationId]);
                } else {
                  setSelectedLocationIds(selectedLocationIds.filter((id) => id !== locationId));
                }
              }}
            />
          ))}
        </Form>
        {errorMessage && (
          <Alert variant="danger" dismissible={true}>
            {errorMessage}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={updating}>
          {updating ? "Updating..." : "Update"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    managedLocations: state.locations.locations ?? null,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    getVideoLocations: () => dispatch(getVideoLocations()),
    updateVideoLocations: (locationIds: number[]) => dispatch(updateVideoLocations(locationIds)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ProfileVideoLocationsModalConnectedProps = ConnectedProps<typeof connector>;

export default connector(ProfileVideoLocationsModal);
