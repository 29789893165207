export function addressComponentsToAddress(components: google.maps.GeocoderAddressComponent[]): {
  address_1?: string;
  address_city?: string;
  address_state?: string;
  address_zip?: string;
} {
  const route = findComponentByType(components, 'route');
  const street_number = findComponentByType(components, 'street_number');
  const postal_code = findComponentByType(components, 'postal_code');
  const locality = findComponentByType(components, 'locality');
  const administrative_area_level_1 = findComponentByType(components, 'administrative_area_level_1');

  return {
    address_1: `${street_number?.long_name} ${route?.long_name}`.trim(),
    address_city: locality?.long_name,
    address_state: administrative_area_level_1?.short_name,
    address_zip: postal_code?.long_name
  }
}

function findComponentByType(components: google.maps.GeocoderAddressComponent[], type: string) {
  return components.find(component => component.types.includes(type));
}
