import { ActivateRegistrationParams, Business, CreateRegistrationParams, UserAccount } from "../data/registration";
import BaseRestClient from "./baseClient";

export function validateUser(user: Omit<UserAccount, "password">, registration_key?: string | null) {
  return BaseRestClient.post("api/v3/employer/registration/validate-user", { validate: { registration_key, user } });
}

export function validateBusiness(business: Business, registration_key?: string | null) {
  return BaseRestClient.post("api/v3/employer/registration/validate-business", {
    validate: { registration_key, business },
  });
}

export function fetchPricingPackage(selectorKey: string) {
  return BaseRestClient.fetch(`api/v3/employer/registration/pricing-package/${selectorKey}`);
}

export function fetchPartnerConfiguration(partnerId: string, identityToken?: string) {
  let url = `api/v3/employer/registration/partner-config/${partnerId}`;
  if (identityToken) {
    url += `?identity_token=${identityToken}`;
  }
  return BaseRestClient.fetch(url);
}

export function createRegistration(
  { user, business, pricing_package_key }: CreateRegistrationParams,
  registration_key?: string | null
) {
  return BaseRestClient.post("api/v3/employer/registration", {
    registration_key,
    user,
    business,
    pricing_package_key,
  });
}

export function activateRegistration({
  registrationKey,
  locationCount,
  paymentMethodToken,
}: ActivateRegistrationParams) {
  return BaseRestClient.post("api/v3/employer/registration/activate", {
    activation: {
      registration_key: registrationKey,
      location_count: locationCount,
      payment_method_token: paymentMethodToken,
    },
  });
}
