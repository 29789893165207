import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import { To } from "react-router";
import LoadingOverlay from "../../components/LoadingOverlay";
import { classNames } from "../../utils/classNameList";
import ConnectionPage from "./Connection";
import ConnectionList, { ConnectionListItemTypeBase } from "./ConnectionList";

type ConnectionsViewerProps<T extends ConnectionListItemTypeBase> = {
  connections: T[];
  totalConnectionCount: number;
  onMoreItemsClicked: () => void;
  selectedConnectionId?: number;
  searchInProgress: boolean;
  itemLink: (connection: T) => To;
  className?: string;
  style?: CSSProperties;
};

function ConnectionsViewer<T extends ConnectionListItemTypeBase>({
  connections,
  totalConnectionCount,
  onMoreItemsClicked,
  selectedConnectionId,
  searchInProgress,
  itemLink,
  className,
  style,
}: ConnectionsViewerProps<T>) {
  const savedScrollTop = useRef<number>();
  const savedConnectionsSize = useRef<number>();
  const listContainerRef = useRef<HTMLDivElement>(null);
  const [listVisible, setListVisible] = useState(true);
  const [connectionId, setConnectionId] = useState<number | null>(null);
  const itemClickHandler = useCallback(
    (connection: T) => {
      setConnectionId(connection.id);
    },
    [setConnectionId]
  );
  const requestMoreItems = useCallback(() => {
    savedScrollTop.current = listContainerRef.current?.scrollTop;
    savedConnectionsSize.current = connections?.length ?? 0;
    onMoreItemsClicked();
  }, [onMoreItemsClicked, connections]);

  // if refreshing the list, attempt to restore the scroll position
  useEffect(() => {
    const listContainer = listContainerRef.current;
    return () => {
      // note: the check for environment here is a work around for the strict mode behavior in React 18 
      // which triggers useEffect twice for each change
      if (
        savedScrollTop.current &&
        (process.env.NODE_ENV === "production" || savedConnectionsSize.current !== (connections?.length ?? 0))
      ) {
        listContainer?.scrollTo(0, savedScrollTop.current);
        savedScrollTop.current = 0;
        savedConnectionsSize.current = undefined;
      }
    };
  }, [connections]);

  const _selectedConnectionId = selectedConnectionId ?? connectionId ?? undefined;
  return (
    <div className={classNames("position-relative h-100 w-100", className)} style={style}>
      <div className="position-absolute h-100" style={{ width: "35%", left: "0", top: "0", direction: "rtl" }}>
        {searchInProgress && <LoadingOverlay />}
        <div style={{ height: "100%", overflowY: "auto" }} ref={listContainerRef}>
          <ConnectionList<T>
            connections={connections}
            selectedConnectionId={_selectedConnectionId}
            className="gap-3"
            itemLink={itemLink}
            onItemClick={itemLink == null ? itemClickHandler : undefined}
          />
          {totalConnectionCount > connections?.length && (
            <div className="d-flex flex-column align-items-center">
              <button className="btn btn-link" onClick={requestMoreItems}>
                <FontAwesomeIcon icon={faArrowDown} className="ms-2" />
                <span>Older ({totalConnectionCount - connections?.length})</span>
              </button>
            </div>
          )}
        </div>
      </div>
      <div
        className="position-absolute h-100"
        style={{
          ...{ top: "0", right: "0", paddingLeft: listVisible ? "1rem" : "0", zIndex: "20" },
          ...{ width: listVisible ? "65%" : "100%", transition: "width 0.5s" },
        }}
      >
        {_selectedConnectionId ? (
          <ConnectionPage
            connectionId={_selectedConnectionId}
            key={`connection-${_selectedConnectionId}`}
            showExpander={true}
            expanded={!listVisible}
            expandClick={(expanded: boolean) => setListVisible(!expanded)}
          />
        ) : (
          <div className="d-flex flex-column align-items-center justify-content-center h-100">
            <p>Select a seeker to see their details</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default ConnectionsViewer;
