import BaseRestClient from "./baseClient";

export function fetchCustomActionResults(actionId?: number, seekerId?: number) {
  const queryParams = new URLSearchParams();
  if (actionId) {
    queryParams.set('action_id', actionId.toString());
  }
  if (seekerId) {
    queryParams.set('seeker_id', seekerId.toString());
  }
  return BaseRestClient.fetch('api/v3/employer/custom_actions/results?' + queryParams.toString());
}

export function executeCustomAction(actionId: number, objectId: number) {
  return BaseRestClient.post(`api/v3/employer/custom_actions/${actionId}/execute`, {
    execute: {
      object_id: objectId
    }
  });
}