import compareDesc from "date-fns/compareDesc";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { connectionSearch } from "../../actions/connections";
import { AppDispatch, RootState } from "../../app/store";
import { ConnectionFilters } from "../../data/miscellaneous";
import { StoredConversation } from "../../reducers/conversations";
import { conversationHasUnreadMessage, parseConversations, ParsedConversation } from "../../utils/conversations";
import ConversationNavigator from "./ConversationNavigator";
import { ConversationFilters_All, conversationFiltersForCategory } from "./filters";
import { SearchParams } from "./routes";

type ConversationsFragmentProps = ConversationsFragmentConnectedProps & {
  category?: string;
  locationId?: number;
  selectedConversationId: number;
};

function ConversationsFragment({
  conversations,
  category,
  locationId,
  selectedConversationId,
}: ConversationsFragmentProps) {
  let emptyListMessage = null,
    navigatorKey = null;
  if (category) {
    const filter = conversationFiltersForCategory(category)!;
    emptyListMessage = filter.emptyListMessage;
    navigatorKey = `category-${category}`;
  } else if (locationId) {
    emptyListMessage = "No conversations exist for the selected location";
    navigatorKey = `location-${locationId}`;
  } else {
    emptyListMessage = ConversationFilters_All.emptyListMessage;
    navigatorKey = `category-${ConversationFilters_All.category}`;
  }

  const linkToSearchParams = useMemo(() => {
    const _linkToSearchParams = new URLSearchParams();
    if (category) {
      _linkToSearchParams.append(SearchParams.Category, category);
    }
    if (locationId) {
      _linkToSearchParams.append(SearchParams.LocationId, locationId.toString());
    }
    return _linkToSearchParams;
  }, [category, locationId]);

  const itemLinkTo = useCallback(
    (item: ParsedConversation) => {
      linkToSearchParams.set(SearchParams.SelectedConversationId, item.id.toString());
      return { search: linkToSearchParams.toString() };
    },
    [linkToSearchParams]
  );

  const parsedConversations = useMemo(
    () => parseConversations<StoredConversation>(conversations ?? []),
    [conversations]
  );

  const filteredAndSortedConversationIds = useMemo(() => {
    if (conversations == null) {
      return [];
    }
    let filteredConversations = parsedConversations;
    if (category) {
      const filter = conversationFiltersForCategory(category);
      if (filter) {
        filteredConversations = parsedConversations.filter((c) => filter.matcher(c));
      }
    } else if (locationId) {
      filteredConversations = parsedConversations.filter((c) => c.employer.location_id === locationId);
    }
    const sortItems = filteredConversations.map((conversation) => ({
      conversation: conversation,
      has_unread_messages: conversationHasUnreadMessage(conversation),
      last_message_sent: conversation.messages[0].created_at,
    }));
    sortItems.sort((item1, item2) => {
      if (item1.has_unread_messages === item2.has_unread_messages) {
        return compareDesc(item1.last_message_sent, item2.last_message_sent);
      } else {
        return item1.has_unread_messages ? -1 : 1;
      }
    });
    return sortItems.map((i) => i.conversation.id);
  }, [parsedConversations, category, locationId, conversations]);

  const [sortedConversations, setSortedConversations] = useState<ParsedConversation[]>([]);
  const sortedConversationIds = useRef(filteredAndSortedConversationIds);

  useEffect(() => {
    if (conversations == null) {
      setSortedConversations([]);
    } else {
      setSortedConversations(sortedConversationIds.current.map((id) => parsedConversations.find((c) => c.id === id)!));
    }
  }, [sortedConversationIds, parsedConversations, setSortedConversations, conversations]);

  return (
    <ConversationNavigator
      key={navigatorKey}
      conversations={sortedConversations}
      emptyListMessage={emptyListMessage}
      selectedConversationId={selectedConversationId}
      itemLink={itemLinkTo}
    />
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    conversations: state.conversations.conversations,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    connectionSearch: (filters: ConnectionFilters) => dispatch(connectionSearch(filters)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ConversationsFragmentConnectedProps = ConnectedProps<typeof connector>;

export default connector(ConversationsFragment);
