import { useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

type CardDeclinedModalProps = {
  visible: boolean;
  onClosed: () => void;
};
function CardDeclinedModal({ visible, onClosed }: CardDeclinedModalProps) {
  const handleClose = useCallback(() => onClosed(), [onClosed]);
  return (
    <Modal show={visible} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Payment Declined</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Payment for the connection has been declined. Click on the Payments button to update your payment information.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Link to="/profile/payments" className="btn btn-primary">
          Payments
        </Link>
      </Modal.Footer>
    </Modal>
  );
}

export default CardDeclinedModal;
