import { faPlayCircle } from "@fortawesome/free-regular-svg-icons";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { classNames } from "../utils/classNameList";
import "./Avatar.scss";

type UserType = {
  avatar_url?: string | null;
  profile?: {
    optimized_video_url?: string | null;
    optimized_video_thumb_url?: string | null;
  } | null;
};
type AvatarProps<TUser> = {
  user: TUser;
  showVideoIndicator?: boolean;
  onPlayVideo?: () => void;
  className?: string;
};

function Avatar<TUser extends UserType>({
  user,
  showVideoIndicator = true,
  onPlayVideo,
  className,
}: AvatarProps<TUser>) {
  const avatarImage =
    user.avatar_url != null ? <img src={user.avatar_url} alt="user avatar" /> : <FontAwesomeIcon icon={faUserAlt} />;
  return (
    <div className={classNames("avatar", className)}>
      {showVideoIndicator && user.profile && user.profile.optimized_video_url ? (
        <a
          href="#play-video"
          className="no-decoration"
          onClick={(event) => {
            event.preventDefault();
            if (onPlayVideo) {
              onPlayVideo();
            }
          }}
        >
          {avatarImage}
          <FontAwesomeIcon icon={faPlayCircle} className="video-overlay" />
        </a>
      ) : (
        <React.Fragment>{avatarImage}</React.Fragment>
      )}
    </div>
  );
}

export default Avatar;
