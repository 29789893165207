import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CardPaymentMethod, PaymentMethod, PaymentPackage, PricingPackageGroup } from "../data/payments";

export type PaymentsState = {
  paymentMethod: PaymentMethod | null;
  paymentPackages: PaymentPackage[] | null;
  paymentMethods: CardPaymentMethod[] | null;
  pricingPackages: PricingPackageGroup[] | null;
};

const initialState: PaymentsState = {
  paymentMethod: null,
  paymentPackages: null,
  paymentMethods: null,
  pricingPackages: null,
};

export const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    setPaymentMethod: (state, action: PayloadAction<PaymentMethod>) => {
      state.paymentMethod = action.payload;
    },
    setPaymentPackages: (state, action: PayloadAction<PaymentPackage[] | null>) => {
      state.paymentPackages = action.payload;
    },
    setPaymentMethods: (state, action: PayloadAction<CardPaymentMethod[]>) => {
      state.paymentMethods = action.payload;
    },
    setPricingPackages: (state, action: PayloadAction<PricingPackageGroup[]>) => {
      state.pricingPackages = action.payload;
    },
    paymentPackageCreated: (state, action: PayloadAction<PaymentPackage>) => {
      // if the payment packages have been loaded, add the new one to the array
      // otherwise just do nothing
      state.paymentPackages = state.paymentPackages == null ? null : [...state.paymentPackages, action.payload];
    },
    paymentPackageUpdated: (state, action: PayloadAction<PaymentPackage>) => {
      state.paymentPackages = state.paymentPackages!.map((p) => (p.id === action.payload.id ? action.payload : p));
    },
    paymentMethodAdded: (state, action: PayloadAction<CardPaymentMethod>) => {
      state.paymentMethods = [...(state.paymentMethods ?? []), action.payload];
    },
    decrementRemainingLocations: (state, action: PayloadAction<number>) => {
      const paymentPackage = state.paymentPackages!.find(p => p.id == action.payload)!;
      if (paymentPackage.remaining_location_count && paymentPackage.remaining_location_count > 0) paymentPackage.remaining_location_count--;
    },
  },
});

export const {
  setPaymentPackages,
  setPaymentMethods,
  setPricingPackages,
  paymentPackageCreated,
  paymentPackageUpdated,
  paymentMethodAdded,
  decrementRemainingLocations,
} = paymentsSlice.actions;
export default paymentsSlice.reducer;
