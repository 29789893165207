import React, { useCallback, useMemo, useState } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import Select, { MultiValue, Options } from "react-select";
import { ConnectionStatus, connectionStatusLabel } from "../../constants";
import { RadioFields, TextField } from "../../components/Forms";
import { RootState } from "../../app/store";
import { ConnectionFilters, ReactSelectOption } from "../../data/miscellaneous";
import { removeEmptyKeys } from "../../utils/objects";

const STATUSES = [
  { value: "", label: "Any" },
  {
    value: ConnectionStatus.Pending,
    label: connectionStatusLabel(ConnectionStatus.Pending),
  },
  {
    value: ConnectionStatus.Accepted,
    label: connectionStatusLabel(ConnectionStatus.Accepted),
  },
  {
    value: ConnectionStatus.Declined,
    label: connectionStatusLabel(ConnectionStatus.Declined),
  },
];

const INTEREST_LEVELS = [
  { value: "", label: "Any" },
  { value: "1", label: "Favorites" },
];

function filterValueToString(value?: string): string {
  return value == null ? "" : value + "";
}

function filterValueFromString(value?: string): string | undefined {
  if (value == null || value == undefined) {
    return undefined;
  }
  const trimmedValue = value.trim();
  return trimmedValue === "" ? undefined : trimmedValue;
}

type ConnectionFiltersSidebarProps = ConnectionFiltersSidebarConnectedProps & {
  visible: boolean;
  filters: ConnectionFilters;
  onApply: (filters: ConnectionFilters) => void;
  onClose: () => void;
};
function ConnectionFiltersSidebar({
  visible,
  filters: initialFilters,
  managedLocations,
  onApply,
  onClose,
}: ConnectionFiltersSidebarProps) {
  const [selectedLocations, setSelectedLocations] = useState<MultiValue<ReactSelectOption>>([]);
  const [selectedJobs, setSelectedJobs] = useState<MultiValue<ReactSelectOption>>([]);

  const [filters, setFilters] = useState<ConnectionFilters>({
    applicant_first_name: filterValueToString(initialFilters?.applicant_first_name),
    applicant_last_name: filterValueToString(initialFilters?.applicant_last_name),
    location_ids: initialFilters?.location_ids ?? [],
    job_ids: initialFilters?.location_ids ?? [],
    status: initialFilters?.status,
    interest_level: initialFilters?.interest_level,
  });

  const changeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilters({ ...filters, [event.target.name]: event.target.value });
    },
    [filters, setFilters]
  );

  const applyHandler = useCallback(() => {
    const searchFilters: ConnectionFilters = {
      applicant_first_name: filterValueFromString(filters.applicant_first_name),
      applicant_last_name: filterValueFromString(filters.applicant_last_name),
      location_ids: selectedLocations.map((location) => parseInt(location.value)),
      job_ids: selectedJobs.map((job) => parseInt(job.value)),
      status: filters?.status,
      interest_level: filters.interest_level,
    };
    onApply(removeEmptyKeys(searchFilters));
  }, [filters, selectedLocations, selectedJobs, onApply]);

  const locationSelectOptions = useMemo((): Options<ReactSelectOption> => {
    return (
      managedLocations?.map((location) => ({
        value: location.id + "",
        label: `${location.name} (${location.address_1})`,
        isSelected: filters.location_ids?.includes(location.id),
      })) ?? []
    );
  }, [managedLocations, filters]);

  const jobSelectOptions = useMemo((): Options<ReactSelectOption> => {
    return (
      managedLocations
        ?.filter((location) => selectedLocations?.some((loc) => parseInt(loc.value) === location.id))
        .map((location) => ({
          label: `${location.name} (${location.address_1})`,
          options: location.jobs.map((job) => ({ value: job.id, label: job.title })),
        })) ?? []
    );
  }, [managedLocations, selectedLocations]);

  return (
    <Offcanvas show={visible} onHide={onClose} className="connection-filters-sidebar">
      <Offcanvas.Header closeButton={true} className="fw-semibold text-center">
        Connection Search
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form>
          <TextField
            value={filters.applicant_first_name}
            name="applicant_first_name"
            label="First Name"
            onChange={changeHandler}
          />
          <TextField
            value={filters.applicant_last_name}
            name="applicant_last_name"
            label="Last Name"
            onChange={changeHandler}
          />
          <Form.Group className="mb-3">
            <Form.Label>Location(s)</Form.Label>
            <Select
              options={locationSelectOptions}
              isMulti={true}
              classNames={{ control: () => "react-select-control" }}
              value={selectedLocations}
              onChange={(values) => setSelectedLocations(values)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Position(s)</Form.Label>
            <Select
              options={jobSelectOptions}
              isMulti={true}
              classNames={{ control: () => "react-select-control" }}
              value={selectedJobs}
              onChange={(values) => setSelectedJobs(values)}
              placeholder={selectedLocations == null || selectedLocations.length === 0 ? "Select a location" : null}
            />
          </Form.Group>
          <RadioFields
            value={filters.status}
            attributeName="status"
            label="Connection Status"
            onChange={changeHandler}
            items={STATUSES}
          />
          <RadioFields
            value={filters.interest_level}
            attributeName="interest_level"
            label="Interest Level"
            onChange={changeHandler}
            items={INTEREST_LEVELS}
          />
          <Button variant="primary" onClick={applyHandler}>
            Apply
          </Button>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    managedLocations: state.locations.locations ?? null,
  };
};

const connector = connect(mapStateToProps);

type ConnectionFiltersSidebarConnectedProps = ConnectedProps<typeof connector>;

export default connector(ConnectionFiltersSidebar);
