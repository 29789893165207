import { Button, Modal } from "react-bootstrap";
import { formatMoney } from "../../utils/format";
import { PaymentPackageActivationCost } from "../../data/payments";

type ActivationConfirmationModalProps = {
  visible?: boolean;
  activationCost: PaymentPackageActivationCost;
  onApproved: () => void;
  onDeclined: () => void;
};

function ActivationConfirmationModal({
  visible,
  activationCost,
  onApproved,
  onDeclined,
}: ActivationConfirmationModalProps) {
  return (
    <Modal show={visible}>
      <Modal.Header>
        <Modal.Title>Location Activation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>You will be charged {activationCost?.fee_details}</div>
        {activationCost?.fee > 0 ? (
          <div>{`The total amount due to activate the location(s) is ${formatMoney(activationCost.fee)}`}</div>
        ) : (
          <div>There is no payment due to activate the location(s), but you will need to provide a credit card</div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onDeclined}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onApproved}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ActivationConfirmationModal;
