import { Container } from "react-bootstrap";
import PaymentsFragment from "../payments/PaymentsFragment";
import ContactInformationFragment from "./ContactInformationFragment";
import ProfilePage from "./ProfilePage";
import NotificationSettingsFragment from "./NotificationSettingsFragment";

export const Pathname = 'profile';
export const RootPath = '/' + Pathname;

const Profile = () => (
  <Container className="content-scrollable">
    <ProfilePage />
  </Container>
);

const routes =       {
  path: Pathname,
  element: <Profile />,
  children: [
    {
      index: true,
      element: <ContactInformationFragment />
    },
    {
      path: "contact_info",
      element: <ContactInformationFragment />
    },
    {
      path: "payments",
      element: <PaymentsFragment />,
    },
    {
      path: "notifications",
      element: <NotificationSettingsFragment />,
    },
  ]
};

export default routes;
