import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Hls from "hls.js";
import "./VideoPlayer.scss";
import { classNames } from "../../utils/classNameList";

const hlsSupported = Hls.isSupported();

type VideoPlayerProps = {
  src: string;
  poster?: string;
  autoPlay?: boolean;
  controls?: boolean;
  mode?: "popup" | "inline";
  onClose?: () => void;
  className?: string;
  isHls?: boolean;
};

function VideoPlayer({
  src,
  poster,
  autoPlay = false,
  controls = false,
  mode = "popup",
  onClose,
  className,
  isHls = true,
}: VideoPlayerProps) {
  
  const playerRef = React.useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    let hls = new Hls();

    const _initPlayer = () => {
      if (!isHls || !hlsSupported) return;
      if (hls != null) {
        hls.destroy();
      }

      const newHls = new Hls({
        enableWorker: false,
      });

      if (playerRef.current != null) {
        newHls.attachMedia(playerRef.current);
      }

      newHls.on(Hls.Events.MEDIA_ATTACHED, () => {
        newHls.loadSource(src);
        newHls.on(Hls.Events.MANIFEST_PARSED, () => {
          if (autoPlay) {
            playerRef?.current
              ?.play()
              .catch(() => console.log("Unable to autoplay prior to user interaction with the dom."));
          }
        });
      });

      newHls.on(Hls.Events.ERROR, function (_event, data) {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              newHls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              newHls.recoverMediaError();
              break;
            default:
              _initPlayer();
              break;
          }
        }
      });

      hls = newHls;
    };

    // Check for Media Source support
    if (hlsSupported) {
      _initPlayer();
    }

    return () => {
      if (hls != null) {
        hls.destroy();
      }
    };
  }, [playerRef, autoPlay, src, isHls]);

  return (
    <div className={classNames(mode === "popup" ? "video-player-popup" : "video-player-inline", className)}>
      <video
        ref={playerRef}
        controls={controls}
        autoPlay={autoPlay || !isHls}
        playsInline={true}
        poster={poster}
        src={isHls && hlsSupported ? undefined : src}
      />
      {mode === "popup" && (
        <a
          href="#close-video"
          className="no-decoration close-video-button"
          onClick={(event) => {
            event.preventDefault();
            if (onClose) {
              onClose();
            }
          }}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </a>
      )}
    </div>
  );
}

export default VideoPlayer;
