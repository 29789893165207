import { Brand } from "../data/brand";
import * as BrandsReducers from "../reducers/brands";
import * as Api from "../rest-client/api";
import { AppAction, AppAsyncAction } from "./types";

export function loadBrands() : AppAsyncAction<Brand[]> {
  return (dispatch) => {
    return Api.loadBrands().then(({ responseJson }) => {
      dispatch(BrandsReducers.setBrands(responseJson));
      return responseJson;
    });
  };
}

export function getBrandById(brandId: number) : AppAction<Brand | undefined> {
  return (_dispatch, getState) => {
    const { brands: { brands } } = getState();
    return brands?.find(l => l.id === brandId);
  };
}

export function updateBrand(brandId: number, brand: Omit<Brand, "id">) : AppAsyncAction<Brand> {
  return (dispatch) => {
    return Api.updateBrand(brandId, brand).then(({ responseJson }) => {
      dispatch(BrandsReducers.updateBrand(responseJson));
      return responseJson;
    });
  };
}

export function createBrand(brand: Brand) : AppAsyncAction<Brand> {
  return (dispatch) => {
    return Api.createBrand(brand).then(({ responseJson }) => {
      dispatch(BrandsReducers.addBrand(responseJson));
      return responseJson;
    });
  };
}
