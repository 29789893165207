import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { loadPaymentMethods, updatePaymentPackage, UpdatePaymentPackageArgs } from "../../actions/payments";
import LoadingOverlay from "../../components/LoadingOverlay";
import List, { ListItem } from "../../components/List";
import { toSentence } from "../../utils/format";
import { PaymentPackagePaymentStatus, PricingPackageUnitType } from "../../constants";
import { Badge } from "react-bootstrap";
import CreditCardDetails from "./CreditCardDetails";
import parseISO from "date-fns/parseISO";
import formatDate from "date-fns/format";
import CreditCardSelector from "./CreditCardSelector";
import AppToast from "../../components/AppToast";
import "./Payments.scss";
import AddCreditCardModal from "./AddCreditCardModal";
import ActivationConfirmationModal from "./ActivationConfirmationModal";
import NewPaymentPackageModal from "./NewPaymentPackageModal";
import { CardPaymentMethod, PaymentMethod, PaymentPackageActivationCost, PaymentPackageWithPayer } from "../../data/payments";
import { AppDispatch, RootState } from "../../app/store";
import { CompanyLocation } from "../../data/companyLocation";
import { UserState } from "../../reducers/user";

type PaymentPackageProps = {
  paymentPackage: PaymentPackageWithPayer;
  managedLocations: CompanyLocation[];
  paymentMethods: CardPaymentMethod[];
  user: UserState;
  onCardChanged: (cardId: string) => void;
  onNewCardSelected: () => void;
};

function PaymentPackage({
  paymentPackage,
  managedLocations,
  paymentMethods,
  user,
  onCardChanged,
  onNewCardSelected,
}: PaymentPackageProps) {
  const locationsForPackage = managedLocations.filter((location) => location.payment_package_id === paymentPackage.id);
  return (
    <div
      key={`package-${paymentPackage.id}`}
      className="border rounded border-primary bg-white position-relative p-3 d-flex flex-row gap-3"
    >
      <div style={{ flex: "1" }}>
        <div>
          <div className="fw-semibold">Pricing</div>
          <div>{paymentPackage.pricing.description}</div>
        </div>
        <div>
          <div className="fw-semibold">For location(s)</div>
          <div>{toSentence(locationsForPackage.map((location) => `${location.name} (${location.address_1})`))}</div>
        </div>
        <div>
          <div className="fw-semibold">Status</div>
          {paymentPackage.payment_status === PaymentPackagePaymentStatus.Paid ? (
            <span>
              {paymentPackage.pricing.unit_type === PricingPackageUnitType.Connection
                ? "Active"
                : `Paid through ${formatDate(parseISO(paymentPackage.paid_until), "P")}`}
            </span>
          ) : paymentPackage.payment_status === PaymentPackagePaymentStatus.Unpaid ? (
            <span>Hasn't yet been paid</span>
          ) : paymentPackage.payment_status === PaymentPackagePaymentStatus.Declined ? (
            <span>Last payment declined</span>
          ) : (
            <span>Payment pending</span>
          )}
        </div>
      </div>
      <div style={{ flex: "1" }}>
        <div className="d-flex flex-column align-items-start justify-content-start">
          <div>
            <div className="fw-semibold">Credit Card</div>
            <div>
              {paymentPackage.payer ? (
                <React.Fragment>
                  {paymentPackage.payer.payment_method ? (
                    <CreditCardDetails card={paymentPackage.payer.payment_method} />
                  ) : (
                    <span>Paid by {paymentPackage.payer.name}</span>
                  )}
                </React.Fragment>
              ) : (
                <span>None</span>
              )}
            </div>
            {paymentPackage.payer?.user_id === user.id && (
              <CreditCardSelector
                onCardSelected={onCardChanged}
                onNewCardSelected={onNewCardSelected}
                label={paymentPackage.payer == null ? "Select card" : "Change card"}
                cards={paymentMethods}
                className="mt-3 w-100"
              />
            )}
          </div>
        </div>
      </div>
      {[PaymentPackagePaymentStatus.Unpaid, PaymentPackagePaymentStatus.Declined].includes(
        paymentPackage.payment_status
      ) && (
        <div className="position-absolute" style={{ top: "1rem", right: "1rem" }}>
          <Badge bg="danger" pill={true}>
            !
          </Badge>
        </div>
      )}
    </div>
  );
}

type UnpaidLocationsProps = {
  locations: CompanyLocation[];
  onClicked: () => void;
};

function UnpaidLocations({ locations, onClicked }: UnpaidLocationsProps) {
  return (
    <ListItem onClick={onClicked} className="border rounded border-primary" accessoryType="details">
      <ListItem.Content className="p-3">
        {locations.length > 20 ? (
          <div>{`${locations.length} locations`}</div>
        ) : (
          <div className="d-flex flex-row flex-wrap gap-3">
            {locations.map((location) => (
              <Badge
                key={`loc-${location.id}`}
                pill={true}
                className="bg-white text-dark border border-dark fs-6 fw-normal"
              >
                {location.name} ({location.address_1})
              </Badge>
            ))}
          </div>
        )}
      </ListItem.Content>
    </ListItem>
  );
}

type PaymentsFragmentProps = PaymentsFragmentConnectedProps;

function PaymentsFragment({
  paymentPackages,
  managedLocations,
  loadPaymentMethods,
  paymentMethods,
  updatePaymentPackage,
  user,
}: PaymentsFragmentProps) {
  const loadingPaymentMethods = useRef(false);

  const [updating, setUpdating] = useState(false);
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [showActivationConfirmation, setShowActivationConfirmation] = useState(false);
  const [showCreatePaymentPackageModal, setShowCreatePaymentPackageModal] = useState(false);
  const [activationCost, setActivationCost] = useState<PaymentPackageActivationCost>();
  const targetPackageId = useRef<number | null>(null);

  type ConfirmPackageUpdateCallback = (confirmed: boolean) => void;
  const packageConfirmationCallback = useRef<ConfirmPackageUpdateCallback | null>(null);

  // used to "reset" the new payment packgae modal each time it is closed/opened
  const [newPackageModalKey, setNewPackageModalKey] = useState(0);

  const cardPaymentPackages = useMemo(
    () => paymentPackages?.filter((p) => p.payment_method === PaymentMethod.Card),
    [paymentPackages]
  );

  useEffect(() => {
    if (paymentMethods == null) {
      if (!loadingPaymentMethods.current) {
        loadingPaymentMethods.current = true;
        loadPaymentMethods().finally(() => (loadingPaymentMethods.current = false));
      }
    }
  }, [paymentMethods, loadPaymentMethods]);

  // used to get confirmation from the user on charges before activating/re-activating a package
  const confirmPackageUpdate = useCallback(
    (packageId: number, callback: ConfirmPackageUpdateCallback) => {
      const paymentPackage = cardPaymentPackages.find((p) => p.id === packageId)!;
      if (paymentPackage.activation_cost?.fee ?? 0 > 0) {
        setShowActivationConfirmation(true);
        setActivationCost(paymentPackage.activation_cost!);
        packageConfirmationCallback.current = callback;
      } else {
        callback.call(null, true);
      }
    },
    [cardPaymentPackages, setShowActivationConfirmation]
  );

  const updatePackage = useCallback(
    ({ packageId, paymentMethodId, stripeToken }: UpdatePaymentPackageArgs) => {
      return updatePaymentPackage({ packageId, paymentMethodId, stripeToken });
    },
    [updatePaymentPackage]
  );

  const updatePackageWithExistingCard = useCallback(
    (packageId: number, cardId: string) => {
      confirmPackageUpdate(packageId, (confirmed: boolean) => {
        if (confirmed) {
          setUpdating(true);
          updatePackage({ packageId, paymentMethodId: cardId })
            .then(() => AppToast.show({ text: "Successfully changed credit card", type: "success" }))
            .catch((error) => AppToast.show({ text: "Failed to change credit card: " + error.message, type: "error" }))
            .finally(() => setUpdating(false));
        }
      });
    },
    [setUpdating, updatePackage, confirmPackageUpdate]
  );

  const updatePackageWithNewCard = useCallback(
    (tokenId: string) => {
      return updatePackage({ packageId: targetPackageId.current!, stripeToken: tokenId }).then(() => {
        setShowAddCardModal(false);
        AppToast.show({ text: "Successfully added credit card", type: "success" });
      });
    },
    [updatePackage, targetPackageId]
  );

  const addCardSelected = useCallback(
    (packageId: number) => {
      confirmPackageUpdate(packageId, (confirmed: boolean) => {
        if (confirmed) {
          targetPackageId.current = packageId;
          setShowAddCardModal(true);
        }
      });
    },
    [confirmPackageUpdate, setShowAddCardModal]
  );

  const closeActivationConfirmation = useCallback(
    (approved: boolean) => {
      setShowActivationConfirmation(false);
      packageConfirmationCallback.current!.call(null, approved);
      packageConfirmationCallback.current = null;
    },
    [setShowActivationConfirmation]
  );

  if (paymentPackages == null) {
    return (
      <div className="position-relative h-100">
        <LoadingOverlay />
      </div>
    );
  }

  const locationsWithNoSubscriptions = managedLocations.filter((location) => location.payment_package_id == null);

  const groups: { type: string; items: any[] }[] = [];
  if (cardPaymentPackages.length) {
    groups.push({ type: "packages", items: cardPaymentPackages });
  }
  if (locationsWithNoSubscriptions.length > 0) {
    groups.push({ type: "unpaid_locations", items: [{ locations: locationsWithNoSubscriptions }] });
  }
  return (
    <div className="pb-3">
      <List<PaymentPackageWithPayer | { locations: CompanyLocation[] }>
        className="payment-packages-list gap-3"
        groups={groups}
        renderGroupHeader={({ group }) => {
          if (group.type === "packages") {
            return null;
          } else {
            return <div>Locations with no payment</div>;
          }
        }}
        renderGroupItem={({ group, item }) => {
          if (group.type === "packages") {
            const typedItem = item as PaymentPackageWithPayer;
            return (
              <PaymentPackage
                paymentPackage={typedItem}
                managedLocations={managedLocations}
                paymentMethods={paymentMethods}
                user={user}
                onCardChanged={(cardId: string) => updatePackageWithExistingCard(typedItem.id!, cardId)}
                onNewCardSelected={() => addCardSelected(typedItem.id!)}
              />
            );
          } else {
            const typedItem = item as { locations: CompanyLocation[] };
            return (
              <UnpaidLocations
                locations={typedItem.locations}
                onClicked={() => {
                  setNewPackageModalKey(newPackageModalKey + 1);
                  setShowCreatePaymentPackageModal(true);
                }}
              />
            );
          }
        }}
      />
      {updating && <LoadingOverlay />}
      <AddCreditCardModal
        visible={showAddCardModal}
        onClosed={() => setShowAddCardModal(false)}
        onSubmit={({ tokenId }) => updatePackageWithNewCard(tokenId)}
      />
      <ActivationConfirmationModal
        visible={showActivationConfirmation}
        activationCost={activationCost!}
        onApproved={() => closeActivationConfirmation(true)}
        onDeclined={() => closeActivationConfirmation(false)}
      />
      <NewPaymentPackageModal
        key={`NewPaymentPackageModal-${newPackageModalKey}`}
        visible={showCreatePaymentPackageModal}
        onClosed={() => setShowCreatePaymentPackageModal(false)}
      />
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    managedLocations: state.locations.locations ?? [],
    paymentPackages: state.payments.paymentPackages ?? [],
    paymentMethods: state.payments.paymentMethods ?? [],
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    loadPaymentMethods: () => dispatch(loadPaymentMethods()),
    updatePaymentPackage: (args: UpdatePaymentPackageArgs) => dispatch(updatePaymentPackage(args)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PaymentsFragmentConnectedProps = ConnectedProps<typeof connector>;

export default connector(PaymentsFragment);
