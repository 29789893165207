import React, { useCallback, useState } from "react";
import { Alert, Button, Form, Modal, ProgressBar } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { updateProfilePicture } from "../../actions/user";
import { AppDispatch, RootState } from "../../app/store";
import AppToast from "../../components/AppToast";
import Avatar from "../../components/Avatar";
import { BYTE_SIZE_1MB } from "../../constants";
import { ProgressCallback } from "../../types/miscellaneous";
import "./ProfilePage.scss";

type ProfilePicModalProps = ProfilePicModalConnectedProps & {
  visible?: boolean;
  onClosed: () => void;
};

function ProfilePicModal({ visible = true, user, onClosed, updateProfilePicture }: ProfilePicModalProps) {
  const [updating, setUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [pictureFile, setPictureFile] = useState<File>();
  const [fileValidated, setFileValidated] = useState(false);
  const [fileValidationError, setFileValidationError] = useState<string>();
  const [uploadProgress, setUploadProgress] = useState(0);

  const onFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const imageFile = event.target.files![0];
      setPictureFile(imageFile);
      setFileValidationError(undefined);
      setFileValidated(false);
      if (imageFile == null) {
        return;
      }
      if (imageFile.size > BYTE_SIZE_1MB) {
        setFileValidationError("The selected file is too large, please use an image file smaller than 1 MB");
      } else {
        const img = new Image();
        img.src = URL.createObjectURL(imageFile);
        img
          .decode()
          .catch(() => {
            setFileValidationError("The selected file is not a valid image file");
          })
          .finally(() => {
            setFileValidated(true);
          });
      }
    },
    [setPictureFile, setFileValidationError]
  );

  const handleClose = useCallback(() => {
    setPictureFile(undefined);
    onClosed();
  }, [setPictureFile, onClosed]);

  const handleSave = useCallback(() => {
    setUpdating(true);
    setErrorMessage(undefined);
    updateProfilePicture(pictureFile!, (progress: number) => setUploadProgress(progress))
      .then(() => {
        AppToast.show({ text: "Profile picture successfully uploaded!", type: "success" });
        handleClose();
      })
      .catch(({ responseJson }) => {
        console.error("profile pic update failed", responseJson);
        setErrorMessage("An unexpected error occurred");
      })
      .finally(() => setUpdating(false));
  }, [pictureFile, handleClose, setUpdating, updateProfilePicture, setErrorMessage]);

  const readyForUpload = pictureFile != null && fileValidated && fileValidationError == null;

  return (
    <Modal show={visible} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Profile Picture</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Avatar user={user} showVideoIndicator={false} className="profile-avatar" />
        <Form>
          <Form.Group className="mb-3" controlId="profile_picture_file">
            <Form.Label>Picture File</Form.Label>
            <Form.Control
              type="file"
              onChange={onFileChange}
              accept="image/png,image/jpeg"
              className={fileValidationError ? "is-invalid" : undefined}
            />
            {fileValidationError && <Form.Control.Feedback type="invalid">{fileValidationError}</Form.Control.Feedback>}
          </Form.Group>
        </Form>
        {errorMessage && (
          <Alert variant="danger" dismissible={true}>
            {errorMessage}
          </Alert>
        )}
        <ProgressBar min={0} max={1} now={uploadProgress} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={!readyForUpload || updating}>
          {updating ? "Uploading..." : "Upload"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    updateProfilePicture: (file: File, progress: ProgressCallback) => dispatch(updateProfilePicture(file, progress)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ProfilePicModalConnectedProps = ConnectedProps<typeof connector>;

export default connector(ProfilePicModal);
