import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Select from "react-select";
import { NumberInput, RadioInputs, TextInput } from "../../components/Forms";
import TinyMceEditor from "../../components/TinyMceEditor";
import { AdditionalCompensationTypes, JobCategories } from "../../constants";
import { Job } from "../../data/job";
import { ModelValidationErrors } from "../../data/validation";
import { toSentence } from "../../utils/format";
import { jobLinkUniversalUrl } from "../../utils/links";
import { EditorOptions } from "tinymce";

const DESCRIPTION_EDITOR_HEIGHT = 300;

const DESCRIPTION_EDITOR_OPTIONS: Partial<
  EditorOptions & {
    selector?: undefined;
    target?: undefined;
  }
> = {
  height: 300,
  contextmenu: [],
  entity_encoding: "raw",
  entities: "nbsp",
  plugins: ["code lists"],
  toolbar:
    "undo redo | blocks | fontsize | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat",
  skin: "bootstrap",
  menubar: "edit format",
  menu: {
    edit: { title: "Edit", items: "undo redo | cut copy paste | selectall | searchreplace" },
    format: {
      title: "Format",
      items:
        "blocks | fontsize | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify | removeformat",
    },
  },
  promotion: false,
  paste_block_drop: true,
  paste_data_images: false,
};

const CATEGORY_OPTIONS = JobCategories.map((c) => ({ value: c.id, label: c.label }));
const ADDITIONAL_COMPENSATION_OPTIONS = AdditionalCompensationTypes.map((type) => ({
  value: type.id,
  label: type.label,
}));

const HOURS_TYPE_OPTIONS = [
  { value: "part_time", label: "Part-time" },
  { value: "full_time", label: "Full-time" },
  { value: "part_and_full_time", label: "Part-time/Full-time" },
];

const CHOICES_CLASS_NAMES = {
  container: () => "react-select-container",
  control: () => "react-select-control",
  multiValue: () => "react-select-multi-value",
  multiValueLabel: () => "react-select-multi-value-label",
  multiValueRemove: () => "react-select-multi-value-remove",
};

export type JobFormRef = {
  getDescription: () => string;
};

type JobFormProps = {
  job: Partial<Job>;
  onUpdate: (attributes: Partial<Job>) => void;
  validationErrors?: ModelValidationErrors;
  jobId?: number;
  showUniversalLink?: boolean;
};

const JobForm = React.forwardRef(
  ({ job, onUpdate, validationErrors, jobId, showUniversalLink = false }: JobFormProps, ref: React.Ref<JobFormRef>) => {
    const descriptionEditorRef = useRef<any>(null);
    const [editorInitialized, setEditorInitialized] = useState(false);

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onUpdate({ [event.target.name]: event.target.value });
      },
      [onUpdate]
    );

    React.useImperativeHandle(ref, () => ({
      getDescription: () => descriptionEditorRef.current.getContent(),
    }));

    const selectedCategories = useMemo(() => {
      return job.category_ids ? CATEGORY_OPTIONS.filter((option) => job.category_ids!.includes(option.value)) : [];
    }, [job.category_ids]);

    const selectedAdditionalCompTypes = useMemo(() => {
      return ADDITIONAL_COMPENSATION_OPTIONS.filter((option) =>
        job.additional_compensation_types?.includes(option.value)
      );
    }, [job.additional_compensation_types]);

    return (
      <Form>
        <Form.Group as={Row} className="mb-3">
          <Col lg={2} md={4}>
            <Form.Label column={true}>Title</Form.Label>
          </Col>
          <Col lg={10} md={8}>
            <TextInput
              value={job.title}
              attributeName="title"
              validationErrors={validationErrors?.title}
              required={true}
              onChange={handleChange}
            />
            {validationErrors?.title && (
              <Form.Control.Feedback type="invalid">{toSentence(validationErrors?.title)}</Form.Control.Feedback>
            )}
          </Col>
        </Form.Group>
        {showUniversalLink && jobId && (
          <Form.Group as={Row} className="mb-3">
            <Col lg={2} md={4}>
              <Form.Label column={true}>Link</Form.Label>
            </Col>
            <Col lg={10} md={8}>
              <InputGroup>
                <Form.Control value={jobLinkUniversalUrl(jobId)} disabled={true} readOnly={true} />
                <CopyToClipboard text={jobLinkUniversalUrl(jobId)} onCopy={() => {}}>
                  <Button className="bg-transparent text-primary border-primary" title="Copy job link to clipboard">
                    <FontAwesomeIcon icon={faCopy} />
                  </Button>
                </CopyToClipboard>
              </InputGroup>
            </Col>
          </Form.Group>
        )}
        <Row className="mb-3">
          <Form.Label column={true} lg={2} md={4}>
            Pay Rate* ($/hr)
            {!!job.hourly_wage_min && !!job.hourly_wage_max && job.hourly_wage_min !== job.hourly_wage_max && (
              <React.Fragment>
                <div className="position-absolute">
                  <span className="text-muted fs-smaller">(* depends on experience)</span>
                </div>
              </React.Fragment>
            )}
          </Form.Label>
          <div className="col-lg-4 col-md-8 d-flex flex-row align-items-baseline">
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <NumberInput
                value={job.hourly_wage_min}
                attributeName="hourly_wage_min"
                onChange={handleChange}
                min={2.35}
                max={500}
                step={0.01}
                required={true}
                validationErrors={validationErrors?.hourly_wage_min}
              />
              {validationErrors?.hourly_wage_min && (
                <Form.Control.Feedback type="invalid">
                  {toSentence(validationErrors?.hourly_wage_min)}
                </Form.Control.Feedback>
              )}
            </InputGroup>
            <div className="flex-grow-0 mx-2">-</div>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <NumberInput
                value={job.hourly_wage_max}
                attributeName="hourly_wage_max"
                onChange={handleChange}
                min={2.35}
                max={500}
                step={0.01}
                required={true}
                validationErrors={validationErrors?.hourly_wage_max}
              />
              {validationErrors?.hourly_wage_max && (
                <Form.Control.Feedback type="invalid">
                  {toSentence(validationErrors?.hourly_wage_max)}
                </Form.Control.Feedback>
              )}
            </InputGroup>
          </div>
          <Form.Label column={true} lg={2} md={4}>
            Other Compensation?
          </Form.Label>
          <Col md={8} lg={4} className="position-relative" style={{ zIndex: 3 }}>
            <Select
              options={ADDITIONAL_COMPENSATION_OPTIONS}
              isMulti={true}
              classNames={CHOICES_CLASS_NAMES}
              value={selectedAdditionalCompTypes}
              onChange={(selectedItems) =>
                onUpdate({ additional_compensation_types: selectedItems.map((item) => item.value) })
              }
              placeholder="Select any that apply..."
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Form.Label column={true} lg={2} md={4}>
            Hours/week
          </Form.Label>
          <div className="col-lg-5 col-md-8 d-flex flex-row align-items-center">
            <RadioInputs
              attributeName="hours_type"
              value={job.hours_type}
              items={HOURS_TYPE_OPTIONS}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-5 col-md-12 d-flex flex-row align-items-center gap-3">
            <Form.Check
              checked={job.earned_wage_access_available}
              onChange={(event) => {
                onUpdate({ earned_wage_access_available: event.target.checked });
              }}
            />
            <Form.Label column={true}>Earned Wage Access Available</Form.Label>
          </div>
        </Row>
        <Form.Group as={Row} className="mb-3">
          <Col lg={2} md={4}>
            <Form.Label column={true}>Categories</Form.Label>
          </Col>
          <Col lg={10} md={8} className="position-relative" style={{ zIndex: 2 }}>
            <Select
              options={CATEGORY_OPTIONS}
              isMulti={true}
              classNames={CHOICES_CLASS_NAMES}
              placeholder="Select all that apply..."
              value={selectedCategories}
              onChange={(selectedItems) => onUpdate({ category_ids: selectedItems.map((item) => item.value) })}
            />
          </Col>
        </Form.Group>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="description">
              <div className="d-flex flex-row gap-3 align-items-baseline">
                <Form.Label>Description</Form.Label>
                {validationErrors?.description && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {toSentence(validationErrors?.description)}
                  </Form.Control.Feedback>
                )}
              </div>
              <div
                style={{
                  height: `${DESCRIPTION_EDITOR_HEIGHT}px`,
                  visibility: editorInitialized ? "visible" : "hidden",
                }}
                className={validationErrors?.description ? "tox-invalid" : undefined}
              >
                <TinyMceEditor
                  init={DESCRIPTION_EDITOR_OPTIONS}
                  onInit={(_evt: any, editor: any) => {
                    descriptionEditorRef.current = editor;
                    setEditorInitialized(true);
                  }}
                  initialValue={job.description}
                />
              </div>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    );
  }
);

export default JobForm;
