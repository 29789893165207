import React, { useCallback, useRef, useState } from "react";
import { Alert } from "react-bootstrap";
import { StripeCardError } from "../../../utils/errors";
import CreditCardForm, { CreditCardFormRef } from "../CreditCardForm";
import { ModelValidationErrors } from "../../../data/validation";

export type AddPaymentMethodRef = {
  submit: () => Promise<string | null>;
  setPaymentError: (error: Error) => void;
};

const AddPaymentMethod = React.forwardRef((_props, ref: React.Ref<AddPaymentMethodRef>) => {
  const [validationErrors, setValidationErrors] = useState<ModelValidationErrors | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const creditCardFormRef = useRef<CreditCardFormRef | null>(null);

  const submit = useCallback(() => {
    return creditCardFormRef.current!.createToken();
  }, []);

  const setPaymentError = useCallback(
    (error: Error) => {
      if (error instanceof StripeCardError) {
        if (error.param != null && error.param.length > 0) {
          setErrorMessage(null);
          setValidationErrors({ [error.param]: error.message });
        } else {
          setErrorMessage(error.message);
          setValidationErrors(null);
        }
      } else {
        setErrorMessage(error.message);
        setValidationErrors(null);
      }
    },
    [setValidationErrors, setErrorMessage]
  );

  React.useImperativeHandle(
    ref,
    React.useCallback(
      () => ({
        submit: submit,
        setPaymentError: setPaymentError,
      }),
      [submit, setPaymentError]
    )
  );

  return (
    <div>
      <CreditCardForm ref={creditCardFormRef} validationErrors={validationErrors} />
      {errorMessage && (
        <Alert variant="danger" dismissible={true}>
          {errorMessage}
        </Alert>
      )}
    </div>
  );
});

export default AddPaymentMethod;
