import { UserProfile } from "../data/userProfile";
import { setAvatarUrl, setProfile, setProfileVideo } from "../reducers/user";
import * as Api from "../rest-client/api";
import { ProgressCallback } from "../types/miscellaneous";
import { AppAsyncAction } from "./types";

export function updateProfile(updatedAttributes: Partial<UserProfile>): AppAsyncAction<UserProfile> {
  return (dispatch, getState) => {
    const { user } = getState();
    return Api.updateProfile(user.id!, updatedAttributes).then(({ responseJson }) => {
      dispatch(setProfile(responseJson));
      return responseJson;
    });
  };
}

export function updateProfilePicture(file: File, onProgress: ProgressCallback): AppAsyncAction<string> {
  return async (dispatch) => {
    const blob = await Api.uploadBlob(file, onProgress);
    const {
      responseJson: { photo_url },
    } = await Api.updateProfilePhoto(blob.signed_id);
    dispatch(setAvatarUrl(photo_url));
    return photo_url;
  };
}

export function updateProfileVideo(file: File, onProgress: (progress: number) => void): AppAsyncAction<string> {
  return async (dispatch) => {
    const blob = await Api.uploadBlob(file, onProgress);
    const {
      responseJson: { video_url },
    } = await Api.updateProfileVideo(blob.signed_id);
    dispatch(setProfileVideo({ video_url: video_url, optimized_video_thumb_url: null, optimized_video_url: null }));
    return video_url;
  };
}

export function getVideoLocations(): AppAsyncAction<number[]> {
  return () => {
    return Api.getVideoLocations().then(({ responseJson }) => responseJson);
  };
}

export function updateVideoLocations(videoLocations: number[]): AppAsyncAction<number[]> {
  return () => {
    return Api.updateVideoLocations(videoLocations).then(({ responseJson }) => responseJson);
  };
}
