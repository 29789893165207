import memoizeOne from "memoize-one";
import React from "react";
import { UserExperience } from "../../data/userExperience";
import { formatExperienceTime } from "../../utils/format";
import "./ProfileItemList.scss";

type ExperienceListProps = {
  experiences: UserExperience[];
  maxItems?: number;
  compact?: boolean;
};

function ExperienceList({ experiences, maxItems = 10000, compact = false }: ExperienceListProps) {
  const sortUserExperiences = memoizeOne((experiences) => {
    const _experiences = [...experiences];
    _experiences.sort((exp1, exp2) => {
      // work around for bug where is_current may be null
      const exp1_is_current = exp1.is_current === true,
        exp2_is_current = exp2.is_current === true;
      if (exp1_is_current === exp2_is_current) {
        return exp1.id > exp2.id ? -1 : 1;
      } else {
        return exp1_is_current ? -1 : 1;
      }
    });
    return _experiences;
  });

  const sortedExperiences = sortUserExperiences([...experiences]).slice(0, maxItems);

  return (
    <div className="profile-item-list">
      {sortedExperiences.length === 0 ? (
        <div className="profile-item-row">
          <span className="details">This would be my first job</span>
        </div>
      ) : (
        <React.Fragment>
          {sortedExperiences.map((experience, index) =>
            compact ? (
              <div className="profile-item-row" key={`exp-${index}`}>
                <span className="details">
                  {experience.title.trim()} at {experience.company_name.trim()}
                </span>
                <span className="duration">{formatExperienceTime(experience)}</span>
              </div>
            ) : (
              <div key={`exp-${index}`}>
                <div className="profile-item-row">
                  <span className="title">{experience.company_name.trim()}</span>
                  <span className="status">{experience.is_current ? "Current Job" : ""}</span>
                </div>
                <div className="profile-item-row">
                  <span className="details">{experience.title.trim()}</span>
                  <span className="duration">{formatExperienceTime(experience)}</span>
                </div>
              </div>
            )
          )}
        </React.Fragment>
      )}
    </div>
  );
}

export default ExperienceList;
