import { useCallback, useEffect, useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { connectionSearch } from "../../actions/connections";
import { AppDispatch, RootState } from "../../app/store";
import { ConnectionStatus } from "../../constants";
import { ConnectionWithSeekerExperience } from "../../data/connection";
import { ConnectionFilters } from "../../data/miscellaneous";
import { parseConnections, ParsedConnection } from "../../utils/connections";
import { parseConversations, ParsedConversation } from "../../utils/conversations";
import ConnectionsViewer from "./ConnectionsViewer";
import { ConnectionFilters_All, connectionFiltersForCategory } from "./filters";
import { SearchParams } from "./routes";

const INITIAL_MAX_ITEMS = process.env.NODE_ENV === "production" ? 100 : 10; // use smaller values in development to make paging testing easier

type ConnectionsFragmentProps = ConnectionsFragmentConnectedProps & {
  category?: string;
  filters?: string;
  selectedConnectionId?: number;
};

function ConnectionsFragment({
  connectionSearch,
  connections,
  totalConnectionCount,
  category,
  filters,
  selectedConnectionId,
  searchInProgress,
  searchFilters,
  conversations,
}: ConnectionsFragmentProps) {
  const refreshSearchResults = useCallback(
    (newFilters: ConnectionFilters) => {
      connectionSearch(newFilters).catch(() => {});
    },
    [connectionSearch]
  );

  useEffect(() => {
    let searchFilters = null;
    if (category === "custom") {
      searchFilters = JSON.parse(window.atob(filters ?? '{}'));
    } else {
      searchFilters = { ...(category ? connectionFiltersForCategory(category)!.search : ConnectionFilters_All.search) };
    }
    searchFilters["max_items"] = INITIAL_MAX_ITEMS;
    refreshSearchResults(searchFilters);
  }, [category, refreshSearchResults, filters]);

  const parsedConnections = useMemo(() => {
    const _parsedConnections = parseConnections(connections ?? [], conversations);
    const statuses = (searchFilters && "status" in searchFilters) ? searchFilters.status : undefined;
    return _parsedConnections.filter((c) => {
      if (statuses !== undefined && statuses.includes(ConnectionStatus.Declined)) {
        return true;
      } else {
        return c.status !== ConnectionStatus.Declined;
      }
    });
  }, [connections, conversations, searchFilters]);
  const filteredItemCount = connections ? connections.length - parsedConnections.length : 0;

  const linkToSearchParams = useMemo(() => {
    const _linkToSearchParams = new URLSearchParams();
    if (category) {
      _linkToSearchParams.append(SearchParams.Category, category);
    }
    if (filters) {
      _linkToSearchParams.append(SearchParams.Filters, filters);
    }
    return _linkToSearchParams;
  }, [category, filters]);

  const itemLinkTo = useCallback(
    (item: ParsedConnection<ConnectionWithSeekerExperience, ParsedConversation>) => {
      linkToSearchParams.set(SearchParams.SelectedConnectionId, item.id.toString());
      return { search: linkToSearchParams.toString() };
    },
    [linkToSearchParams]
  );

  const moreItemsClickHandler = useCallback(() => {
    const updatedFilters = { ...searchFilters };
    updatedFilters.max_items = (updatedFilters.max_items ?? 0) + INITIAL_MAX_ITEMS;
    refreshSearchResults(updatedFilters);
  }, [searchFilters, refreshSearchResults]);

  return (
    <ConnectionsViewer
      key={`connections-${category}`}
      className="h-100"
      connections={parsedConnections}
      totalConnectionCount={totalConnectionCount - filteredItemCount}
      onMoreItemsClicked={moreItemsClickHandler}
      searchInProgress={searchInProgress}
      selectedConnectionId={selectedConnectionId}
      itemLink={itemLinkTo}
    />
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    connections: state.connections.searchResults ?? null,
    totalConnectionCount: state.connections.searchTotalCount ?? 0,
    searchFilters: state.connections.searchFilters ?? {},
    searchInProgress: state.connections.searchInProgress ?? false,
    conversations: parseConversations(state.conversations.conversations ?? []),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    connectionSearch: (filters: ConnectionFilters) => dispatch(connectionSearch(filters)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ConnectionsFragmentConnectedProps = ConnectedProps<typeof connector>;

export default connector(ConnectionsFragment);
