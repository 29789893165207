import { Spinner } from "react-bootstrap";
import { Variant } from "react-bootstrap/esm/types";
import { classNames } from "../utils/classNameList";
import "./LoadingOverlay.scss";

type LoadingOverlayProps = {
  message?: string;
  variant?: Variant | undefined;
  position?: "centered" | "top" | undefined;
};
const LoadingOverlay = ({ message, variant = "primary", position = "centered" }: LoadingOverlayProps) => {
  const spinner = (
    <Spinner animation="border" variant="primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
  return (
    <div className={classNames("overlay", position === "centered" ? "overlay-centered" : "overlay-padded")}>
      {message ? (
        <div
          className={classNames(
            "d-flex flex-column p-3 gap-3 align-items-center",
            variant === "opaque" ? "rounded bg-white" : null
          )}
        >
          {spinner}
          <div>{message}</div>
        </div>
      ) : (
        spinner
      )}
    </div>
  );
};

export default LoadingOverlay;
