import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useRef, useState } from "react";
import { Alert, Button, Card, Form, FormCheck, Modal } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { batchCreateJobs, BatchCreateJobsParams, updateJob } from "../../actions/jobs";
import AppToast from "../../components/AppToast";
import Grid from "../../components/Grid";
import { ErrorReasonCode, JobStatus } from "../../constants";
import { FailedRequestError } from "../../rest-client/errors";
import { toSentence } from "../../utils/format";
import { objectsAreEqual, typedHash } from "../../utils/objects";
import { isNullOrEmpty } from "../../utils/validation";
import JobCard from "./JobCard";
import JobForm, { JobFormRef } from "./JobForm";
import "./Jobs.scss";
import { AppDispatch, RootState } from "../../app/store";
import { Job } from "../../data/job";
import { ModelValidationErrors } from "../../data/validation";
import { CompanyLocation } from "../../data/companyLocation";

type CreateStep2Props = {
  job: Job | Omit<Job, 'id'>;
  locations: CompanyLocation[];
  selectedLocationIds: number[];
  onSelectedLocationIdsChange: (ids: number[]) => void;
  validationErrors?: ModelValidationErrors;
};
function CreateStep2({
  job,
  locations,
  selectedLocationIds,
  onSelectedLocationIdsChange,
  validationErrors,
}: CreateStep2Props) {
  const locationClicked = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const locationId = parseInt(event.target.value);
      onSelectedLocationIdsChange(
        event.target.checked
          ? [...selectedLocationIds, locationId]
          : selectedLocationIds.filter((id) => id !== locationId)
      );
    },
    [selectedLocationIds, onSelectedLocationIdsChange]
  );
  const selectAllClickHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        onSelectedLocationIdsChange(locations.map((loc) => loc.id));
      } else {
        onSelectedLocationIdsChange([]);
      }
    },
    [locations, onSelectedLocationIdsChange]
  );
  return (
    <div className="d-flex flex-column gap-3">
      <JobCard job={job} readOnly={true} />
      <Card>
        <Card.Header className="bg-primary text-white d-flex flex-row justify-content-start gap-3">
          <FormCheck
            checked={selectedLocationIds.length === locations.length}
            onChange={selectAllClickHandler}
            className="form-check-light"
          />
          <div>Select Locations ({selectedLocationIds.length} chosen)</div>
        </Card.Header>
        <Card.Body style={{ overflowY: "auto", maxHeight: "200px" }}>
          <Grid
            items={locations}
            renderItem={(location) => (
              <FormCheck
                label={`${location.name} (${location.address_1})`}
                value={location.id}
                onChange={locationClicked}
                checked={selectedLocationIds.includes(location.id)}
              />
            )}
            groupSize={2}
          />
        </Card.Body>
        {!!validationErrors && validationErrors["locationIds"] && (
          <Card.Footer className="bg-white">
            <Form.Control.Feedback type="invalid" className="d-block">
              {toSentence(validationErrors["locationIds"])}
            </Form.Control.Feedback>
          </Card.Footer>
        )}
      </Card>
    </div>
  );
}

type EditJobModalProps = EditJobModalConnectedProps & {
  defaultAddLocationId?: number;
  job?: Job;
  visible?: boolean;
  onClosed: () => void;
};

function EditJobModal({
  managedLocations,
  defaultAddLocationId,
  job,
  updateJob,
  createJobs,
  visible = true,
  onClosed,
}: EditJobModalProps) {
  const jobFormRef = useRef<JobFormRef>(null);
  const [updating, setUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [validationErrors, setValidationErrors] = useState<ModelValidationErrors>();
  const [showConfirmDirtyClose, setShowConfirmDirtyClose] = useState(false);
  const [stepNumber, setStepNumber] = useState(1);
  const [locationIds, setLocationIds] = useState<number[]>(defaultAddLocationId ? [defaultAddLocationId] : []);

  const { id: jobId, ...initialAttributes } = job ?? {};
  const [jobAttributes, setJobAttributes] = useState<Partial<Job>>(initialAttributes);

  const attributesUpdated = useCallback(
    (updates: Partial<Job>) => {
      setJobAttributes({ ...jobAttributes, ...updates });
    },
    [jobAttributes, setJobAttributes]
  );

  const handleClose = useCallback(() => {
    onClosed();
    setUpdating(false);
    setErrorMessage(undefined);
    setValidationErrors(undefined);
  }, [onClosed]);

  const handleSave = useCallback(
    (activate: boolean) => {
      setUpdating(true);
      setErrorMessage(undefined);
      const description = jobFormRef.current!.getDescription();
      const updatedJobAttributes = {
        ...jobAttributes,
        description,
        status: activate ? JobStatus.Active : jobAttributes.status,
      };
      updateJob({ id: jobId!, ...updatedJobAttributes } as Job)
        .then(() => {
          setTimeout(() => {
            handleClose();
            AppToast.show({ text: "Sucessfully saved changes", type: "success" });
          }, 500);
        })
        .catch((error) => {
          if (error instanceof FailedRequestError) {
            if (error.isUnprocessableEntity()) {
              setValidationErrors(error.responseJson);
            } else {
              setErrorMessage("An unexpected error occurred");
            }
          } else {
            setErrorMessage(error.message);
          }
        })
        .finally(() => setUpdating(false));
    },
    [handleClose, jobAttributes, jobId, updateJob]
  );

  const handleCreate = useCallback(
    (activate: boolean) => {
      if (isNullOrEmpty(locationIds)) {
        setValidationErrors({ locationIds: "At least one location must be selected" });
        return;
      }
      setUpdating(true);
      setErrorMessage(undefined);
      let finalAttributes: Omit<Job, "id"> = {
        ...jobAttributes,
        status: activate ? JobStatus.Active : JobStatus.InProcess,
      } as Omit<Job, "id">;
      // flip the pay rates if the min is more than max
      if (parseFloat(finalAttributes.hourly_wage_min!) > parseFloat(finalAttributes.hourly_wage_max!)) {
        finalAttributes = {
          ...finalAttributes,
          hourly_wage_min: finalAttributes.hourly_wage_max,
          hourly_wage_max: finalAttributes.hourly_wage_min,
        };
      }
      createJobs({ attributes: finalAttributes, locationIds })
        .then((newJobs) => {
          handleClose();
          AppToast.show({
            text: `Successfully ${activate ? "created" : "saved"} ${newJobs.length} job(s)`,
            type: "success",
          });
        })
        .catch((error) => {
          if (error instanceof FailedRequestError) {
            if (error.isUnprocessableEntity()) {
              if (error.responseJson.reason === ErrorReasonCode.ModelValidationFailed) {
                setValidationErrors(error.responseJson.details);
              } else {
                setErrorMessage(
                  typeof error.responseJson.details === "string"
                    ? error.responseJson.details
                    : "An unexpected error occurred"
                );
              }
            } else {
              setErrorMessage("An unexpected error occurred");
            }
          } else {
            setErrorMessage(error.message);
          }
          setStepNumber(1);
        })
        .finally(() => setUpdating(false));
    },
    [handleClose, jobAttributes, locationIds, createJobs]
  );

  const handleHide = useCallback(
    (skipDirtyCheck = false) => {
      // check if the job has changed
      let confirmClose = false;
      if (!skipDirtyCheck) {
        if (!objectsAreEqual(initialAttributes, jobAttributes)) {
          confirmClose = true;
        } else if (stepNumber === 1) {
          const updatedDescription = jobFormRef.current!.getDescription();
          if (!("description" in initialAttributes) || updatedDescription !== initialAttributes.description) {
            confirmClose = true;
          }
        }
      }
      if (confirmClose) {
        setShowConfirmDirtyClose(true);
      } else {
        handleClose();
      }
    },
    [handleClose, initialAttributes, jobAttributes, setShowConfirmDirtyClose, stepNumber]
  );

  const handleNext = useCallback(() => {
    const errors: Partial<{ [key in keyof Job]: string }> = {};
    jobAttributes.description = jobFormRef.current!.getDescription();
    const requiredAttributes: (keyof Job)[] = [
      "title",
      "hours_type",
      "hourly_wage_min",
      "hourly_wage_max",
      "category_ids",
    ];
    const hashedJobAttributes = typedHash(jobAttributes);
    requiredAttributes.forEach((attribute) => {
      if (!(attribute in hashedJobAttributes) || isNullOrEmpty(hashedJobAttributes[attribute])) {
        errors[attribute] = "Cannot be blank";
      }
    });
    if (Object.keys(errors).length === 0) {
      setStepNumber(2);
      setValidationErrors(undefined);
    } else {
      setValidationErrors(errors);
    }
  }, [jobAttributes, setValidationErrors, setStepNumber]);

  return (
    <Modal show={visible} onHide={handleHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{jobId == null ? "Add" : "Edit"} Job</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {stepNumber === 1 ? (
          <JobForm
            ref={jobFormRef}
            job={jobAttributes}
            onUpdate={attributesUpdated}
            validationErrors={validationErrors}
            jobId={jobId}
            showUniversalLink={jobId != null}
          />
        ) : (
          <CreateStep2
            job={jobAttributes as Omit<Job, 'id'>}
            locations={managedLocations}
            selectedLocationIds={locationIds}
            onSelectedLocationIdsChange={(_locationIds) => setLocationIds(_locationIds)}
            validationErrors={validationErrors}
          />
        )}
        {errorMessage && (
          <Alert variant="danger" dismissible={true} onClose={() => setErrorMessage(undefined)}>
            {errorMessage}
          </Alert>
        )}
        {showConfirmDirtyClose && (
          <div
            className="position-absolute top-0 start-0 bottom-0 end-0 d-flex flex-column align-items-center justify-content-center"
            style={{ zIndex: 10 }}
          >
            <Alert>
              <div className="d-flex flex-row gap-3 align-items-center">
                <FontAwesomeIcon icon={faTriangleExclamation} />
                You have unsaved changes
                <Button variant="outline-dark" onClick={() => handleClose()}>
                  Close without saving
                </Button>
                <Button variant="outline-dark" onClick={() => setShowConfirmDirtyClose(false)}>
                  Do not close
                </Button>
              </div>
            </Alert>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="flex-row">
        {validationErrors ? (
          <div className="text-danger me-3">Fix the problems above and try again</div>
        ) : (
          <React.Fragment>
            {job!.status === JobStatus.Active && (
              <div className="me-3">
                <b>Note</b>: Clicking Save will publish changes immediately
              </div>
            )}
          </React.Fragment>
        )}
        <Button variant="secondary" onClick={() => handleHide(showConfirmDirtyClose)}>
          {showConfirmDirtyClose ? "Confirm Close" : "Close"}
        </Button>
        {jobId ? (
          <React.Fragment>
            <Button variant="primary" onClick={() => handleSave(false)} disabled={updating}>
              Save
            </Button>
            {job!.status !== JobStatus.Active && (
              <Button variant="primary" onClick={() => handleSave(true)} disabled={updating}>
                Save and Activate
              </Button>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {stepNumber === 1 ? (
              <Button variant="primary" onClick={handleNext}>
                Next
              </Button>
            ) : (
              <React.Fragment>
                <Button variant="primary" onClick={() => setStepNumber(1)} disabled={updating}>
                  Back
                </Button>
                <Button variant="primary" onClick={() => handleCreate(false)} disabled={updating}>
                  Save and Finish Later
                </Button>
                <Button variant="primary" onClick={() => handleCreate(true)} disabled={updating}>
                  Save and Activate
                </Button>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state: RootState) => ({
  managedLocations: state.locations.locations ?? [],
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateJob: (job: Job) => dispatch(updateJob(job)),
  createJobs: (args: BatchCreateJobsParams) => dispatch(batchCreateJobs(args)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type EditJobModalConnectedProps = ConnectedProps<typeof connector>;

export default connector(EditJobModal);
