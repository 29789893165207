import { ModelValidationErrors, ValidationErrors } from "../data/validation";

export function isNullOrEmpty(value: string | unknown[] | null | undefined): boolean {
  if (value == null || value == undefined) return true;
  if (typeof value === "string") {
    if (value.trim().length === 0) return true;
  } else if (Array.isArray(value)) {
    if (value.length === 0) return true;
  }
  return false;
}

export function isNullOrUndefined(value: unknown): boolean {
  return (value == null || value == undefined);
}

export function collectFormValidationErrors(form: HTMLFormElement): ModelValidationErrors | null {
  const validationErrors: ModelValidationErrors = {};
  const formElements = form.elements;
  for (let i = 0; i < formElements?.length; i++) {
    const formItem = formElements.item(i);
    if (formItem) {
      if (["input", "select", "textarea"].includes(formItem.tagName.toLowerCase())) {
        const formElement = formItem as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
        if (formElement.name) {
          validationErrors[formElement.name] = getElementValidationErrors(formElement);
        }
      }
    }
  }
  return validationErrors;
}

export function getElementValidationErrors(
  element: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
): ValidationErrors | null {
  let inputElement: HTMLInputElement | undefined;
  let textAreaElement: HTMLTextAreaElement | undefined;
  if (element.tagName.toLowerCase() === "input") {
    inputElement = element as HTMLInputElement;
  } else if (element.tagName.toLowerCase() === "select") {
    element = element as HTMLSelectElement;
  } else if (element.tagName.toLowerCase() === "textarea") {
    textAreaElement = element = element as HTMLTextAreaElement;
  }
  if (element) {
    if (element.validity.valid) {
      return null;
    }
    const errors: ValidationErrors = [];
    if (element.validity.valueMissing) {
      errors.push("Is required");
    } else {
      if (element.validity.badInput || element.validity.stepMismatch) {
        errors.push("Is invalid");
      }
      if (element.validity.patternMismatch) {
        errors.push("Is invalid");
      }
      if (element.validity.rangeOverflow) {
        errors.push(`Cannot be greater than ${inputElement!.max}`);
      }
      if (element.validity.rangeUnderflow) {
        errors.push(`Cannot be less than ${inputElement!.min}`);
      }
      if (element.validity.tooLong) {
        errors.push(`Is too long (cannot be more than ${(inputElement ?? textAreaElement)!.maxLength} characters)`);
      }
      if (element.validity.tooShort) {
        errors.push(`Is too short (must be at least ${(inputElement ?? textAreaElement)!.minLength} characters)`);
      }
      if (element.validity.typeMismatch) {
        errors.push(`Is not a valid ${element.type}`);
      }
      if (element.validity.customError) {
        errors.push(element.validationMessage);
      }
    }
    return errors;
  }
  return null;
}

export function mergeModelValidationErrors(
  modelErrors: ModelValidationErrors | null,
  attribute: string,
  validationErrors: ValidationErrors
): ModelValidationErrors | null {
  if (validationErrors) {
    const updatedModelErrors: ModelValidationErrors = { ...modelErrors };
    updatedModelErrors[attribute] = validationErrors;
    return updatedModelErrors;
  } else {
    if (modelErrors && attribute in modelErrors) {
      const updatedModelErrors: ModelValidationErrors = { ...modelErrors };
      delete updatedModelErrors[attribute];
      return updatedModelErrors;
    } else {
      return modelErrors ?? null;
    }
  }
}
