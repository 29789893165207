import { faInbox, faSearch, faStar, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useState } from "react";
import { Nav } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import { JuvoJobsLinks } from "../../constants";
import { ConnectionFilters } from "../../data/miscellaneous";
import "../common/SideNavBar.scss";
import ConnectionFiltersSidebar from "./ConnectionFiltersSidebar";
import { ConnectionFilters_All, ConnectionFilters_Favorites, ConnectionFilters_Viewed } from "./filters";
import { SearchParams } from "./routes";

const EVENT_KEY_SEARCH = "custom";

const FILTER_BUTTONS = [
  {
    label: "All",
    icon: faInbox,
    category: ConnectionFilters_All.category,
    filters: ConnectionFilters_All.search,
  },
  {
    label: "Viewed",
    icon: faUserCheck,
    category: ConnectionFilters_Viewed.category,
    filters: ConnectionFilters_Viewed.search,
  },
  {
    label: "Favorites",
    icon: faStar,
    category: ConnectionFilters_Favorites.category,
    filters: ConnectionFilters_Favorites.search,
  },
];

type ConnectionsPageProps = {
  category?: string;
  filters: string;
};

function ConnectionsPage({ category, filters }: ConnectionsPageProps) {
  const [showSearchSideBar, setShowSearchSideBar] = useState(false);

  const onFilterSelected = useCallback(
    (eventKey: string | null, event: React.SyntheticEvent<unknown>) => {
      if (eventKey === EVENT_KEY_SEARCH) {
        event.preventDefault();
        event.stopPropagation();
        setShowSearchSideBar(true);
      }
    },
    [setShowSearchSideBar]
  );

  const parsedFilters = filters ? JSON.parse(window.atob(filters)) : {};

  const navigate = useNavigate();
  const customSearchHandler = useCallback(
    (filters: ConnectionFilters) => {
      setShowSearchSideBar(false);
      const searchParams = new URLSearchParams();
      searchParams.append("category", EVENT_KEY_SEARCH);
      searchParams.append("filters", window.btoa(JSON.stringify(filters)));
      navigate({
        pathname: "/connections",
        search: searchParams.toString(),
      });
    },
    [setShowSearchSideBar, navigate]
  );

  return (
    <div className="h-100 d-flex flex-row gap-3">
      <div className="d-flex flex-column h-100 justify-content-between gap-3" style={{ flex: "0 0 160px" }}>
        <Nav
          activeKey={category ?? FILTER_BUTTONS[0].category}
          defaultActiveKey={ConnectionFilters_All.category}
          className="d-flex flex-column side-nav w-100"
          onSelect={onFilterSelected}
        >
          <div className="title">Connections</div>
          {FILTER_BUTTONS.map((button, index) => (
            <LinkContainer key={`button-${index}`} to={{ search: `${SearchParams.Category}=${button.category}` }}>
              <Nav.Link eventKey={button.category}>
                <FontAwesomeIcon icon={button.icon} className="side-nav-icon" />
                {button.label}
              </Nav.Link>
            </LinkContainer>
          ))}
          <Nav.Link eventKey={EVENT_KEY_SEARCH}>
            <FontAwesomeIcon icon={faSearch} className="side-nav-icon" />
            Search
          </Nav.Link>
        </Nav>
        <ConnectionFiltersSidebar
          visible={showSearchSideBar}
          filters={parsedFilters}
          onClose={() => setShowSearchSideBar(false)}
          onApply={customSearchHandler}
        />
        <div className="align-self-end flex-grow-0 flex-shrink-0 mt-2 d-flex flex-column gap-3 w-100">
          <div className="d-flex flex-column border rounded bg-white align-items-center gap-3 p-3 text-center">
            <div style={{ fontSize: "smaller " }}>
              Need a helping hand?👋
              <br />
              Watch our Juvo JumpStart Training for employers
            </div>
            <img
              src="/images/jump-start-training.png"
              alt="Training"
              style={{ width: "120px", objectFit: "contain" }}
            />
            <a href={JuvoJobsLinks.TrainingVideo} target="_blank" rel="noreferrer" className="btn btn-link btn-sm">
              Watch HERE
            </a>
          </div>
          <div className="d-flex flex-column border rounded bg-white align-items-center gap-3 p-3 text-center">
            <div style={{ fontSize: "smaller " }}>
              Have a question?
              <br />
              Visit our
            </div>
            <a href={JuvoJobsLinks.FAQs} target="_blank" rel="noreferrer" className="btn btn-link btn-sm">
              FAQ page
            </a>
          </div>
        </div>
      </div>
      <div className="flex-grow-1 flex-shrink-1 h-100">
        <Outlet />
      </div>
    </div>
  );
}

export default ConnectionsPage;
