import React, { useCallback, useState } from "react";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { connect, ConnectedProps } from "react-redux";
import { authenticate, requestPasswordReset } from "../../actions/authentication";
import { initData } from "../../actions/initialization";
import { AppDispatch, RootState } from "../../app/store";
import { FailedRequestError } from "../../rest-client/errors";
import "./LoginScreen.scss";
import { Link } from "react-router-dom";
import CenteredPage from "../../components/CenteredPage";

type ResetPasswordPopoverProps = {
  visible: boolean;
  onSubmit: (email: string) => Promise<void>;
  onClose: () => void;
};

const ResetPasswordPopover = ({ visible, onSubmit, onClose }: ResetPasswordPopoverProps) => {
  const [emailAddress, setEmailAddress] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [passwordResetSuccessful, setPasswordResetSuccessful] = useState(false);
  const [passwordResetError, setPasswordResetError] = useState<string | null>(null);

  const submitHandler = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      setSubmitted(true);
      onSubmit(emailAddress)
        .then(() => {
          setPasswordResetSuccessful(true);
        })
        .catch((error: Error) => {
          if (error instanceof FailedRequestError && error.responseJson.errors?.email) {
            setPasswordResetError("The email address was not found");
          } else {
            setPasswordResetError("An unexpected error occured");
          }
        })
        .finally(() => setSubmitted(false));
    },
    [emailAddress, onSubmit]
  );

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal show={visible} onHide={handleClose}>
      <Modal.Header>Password Reset</Modal.Header>
      <Modal.Body>
        {passwordResetSuccessful ? (
          <div>
            Your password reset request was successful, look for an email with instructions on how to complete the
            process
          </div>
        ) : (
          <div className="d-flex flex-column gap-3 align-items-center">
            <div className="text-center">To request a password reset, please provide your email address</div>
            <Form
              onSubmit={submitHandler}
              id="password-reset-form"
              className="w-100 d-flex flex-column align-items-center"
            >
              <Form.Control
                type="text"
                value={emailAddress}
                onChange={(event) => setEmailAddress(event.target.value)}
                placeholder="Enter your email address"
              />
            </Form>
            {passwordResetError && <div className="text-danger">{passwordResetError}</div>}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {!passwordResetSuccessful && (
          <Button type="submit" form="password-reset-form" disabled={submitted}>
            Submit
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

type LoginScreenProps = LoginScreenConnectedProps;

const LoginScreen = ({ authenticate, initData, requestPasswordReset }: LoginScreenProps) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const [authInProgress, setAuthInProgress] = useState(false);
  const [showPasswordResetPopup, setShowPasswordResetPopup] = useState(false);
  const [passwordResetKey, setPasswordResetKey] = useState(0);

  const resetPasswordHandler = useCallback(
    (emailAddress: string) => {
      return requestPasswordReset(emailAddress);
    },
    [requestPasswordReset]
  );

  return (
    <CenteredPage>
      <Container>
        <Row className="justify-content-center">
          <Col md={6} sm={12}>
            <div className="mb-5 text-center">
              <img alt="Juvo" src="/images/logo.png" />
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={6} sm={12}>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <Form
              className="new_user"
              id="new_user"
              onSubmit={(event) => {
                setAuthInProgress(true);
                event.preventDefault();
                authenticate(email, password)
                  .then(() => initData())
                  .catch((error) => setErrorMessage(error.message))
                  .finally(() => setAuthInProgress(false));
              }}
            >
              <Form.Group>
                <Form.Label htmlFor="user_email">Email</Form.Label>
                <Form.Control
                  autoFocus
                  autoComplete="off"
                  className="form-control"
                  type="email"
                  value={email}
                  id="user_email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="user_password">Password</Form.Label>
                <Form.Control
                  autoComplete="off"
                  className="form-control"
                  type="password"
                  id="user_password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <div className="d-grid gap-2 mt-3">
                <Button variant="primary" type="submit" disabled={!email || !password || authInProgress}>
                  {authInProgress ? "Logging in..." : "Log In"}
                </Button>
              </div>
              <div className="text-center">
                By clicking Log In, you agree to Juvo's{" "}
                <a target="_blank" rel="noreferrer" href="https://www.juvojobs.com/privacy-policy">
                  User Agreement
                </a>{" "}
                and{" "}
                <a target="_blank" rel="noreferrer" href="https://www.juvojobs.com/privacy-policy">
                  Privacy Policy
                </a>
                .
              </div>
            </Form>
            <div className="actions mt-3 text-center position-relative">
              <Button
                variant="link"
                onClick={() => {
                  setPasswordResetKey(passwordResetKey + 1);
                  setShowPasswordResetPopup(true);
                }}
              >
                Forgot your password?
              </Button>
              <ResetPasswordPopover
                key={`pw-${passwordResetKey}`}
                visible={showPasswordResetPopup}
                onClose={() => setShowPasswordResetPopup(false)}
                onSubmit={resetPasswordHandler}
              />
            </div>
            <div className="text-center">
              <Link to={import.meta.env.VITE_REGISTRATION_INFO_URL} className="btn btn-link">
                Need to create an account?
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </CenteredPage>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    isLoggedIn: state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    authenticate: (userName: string, password: string) =>
      dispatch(authenticate(userName, password)),
    initData: () => dispatch(initData()),
    requestPasswordReset: (email: string) => dispatch(requestPasswordReset(email)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type LoginScreenConnectedProps = ConnectedProps<typeof connector>;

export default connector(LoginScreen);
