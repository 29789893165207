import { PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouterProvider } from "react-router";
import { logout } from "../actions/authentication";
import { initData } from "../actions/initialization";
import LoadingOverlay from "../components/LoadingOverlay";
import { minutesAgo } from "../utils/dateTime";
import { userIsEmployer } from "../utils/user";
import appRouter from "./Router";
import { AppDispatch, RootState } from "./store";

const DATA_REFRESH_INTERVAL_MINUTES = 15; // refresh data every 15 minutes
const DATA_REFRESH_INTERVAL_MS = 1000 * 60 * DATA_REFRESH_INTERVAL_MINUTES;

const PageContainer = ({ children, fullscreen = false }: PropsWithChildren & { fullscreen?: boolean | undefined }) => {
  const classNames = ["App"];
  if (fullscreen) {
    classNames.push("fullscreen");
  }
  return <div className={classNames.join(" ")}>{children}</div>;
};

const NotCompanyAdmin = ({ onLogout }: { onLogout: () => void }) => (
  <PageContainer fullscreen={true}>
    <div className="d-flex flex-column justify-content-center align-items-center h-100 gap-3">
      <div>This application only supports employer logins.</div>
      <a
        className="btn btn-primary"
        href="#logout"
        onClick={(event) => {
          event.preventDefault();
          onLogout();
        }}
      >
        Logout
      </a>
    </div>
  </PageContainer>
);

const Initializing = () => (
  <PageContainer fullscreen={true}>
    <LoadingOverlay message="Initializing..." />
  </PageContainer>
);

type MainAppProps = MainAppConnectedProps;

function MainApp({ userIsEmployer, isDataLoaded, initData, logout }: MainAppProps) {
  const hasStartedInitialization = useRef(false);
  const lastVisibilityChange = useRef<Date | null>(null);
  const backgroundRefreshIntervalId = useRef<NodeJS.Timeout>();
  const backgroundRefreshTimeoutId = useRef<NodeJS.Timeout>();
  const lastDataRefresh = useRef<Date | null>(null);
  const [hasInitialized, setHasInitialized] = useState(false);
  const [refreshingData, setRefreshingData] = useState(false);

  const initializeApp = useCallback(() => {
    hasStartedInitialization.current = true;
    if (userIsEmployer) {
      initData().then(() => {
        setHasInitialized(true);
      });
    } else {
      setHasInitialized(true);
    }
  }, [userIsEmployer, initData]);

  const startBackgroundRefresh = useCallback(() => {
    // clear any existing interval
    if (backgroundRefreshIntervalId.current != null) {
      clearInterval(backgroundRefreshIntervalId.current);
    }
    backgroundRefreshIntervalId.current = setInterval(() => {
      initData(true);
      lastDataRefresh.current = new Date();
    }, DATA_REFRESH_INTERVAL_MS);
  }, [initData]);

  const visibilityChangeHandler = useCallback(() => {
    if (document.hidden) {
      // if document remains in background for 60 seconds, cancel the bakground refresh
      if (backgroundRefreshTimeoutId.current != null) clearTimeout(backgroundRefreshTimeoutId.current);
      backgroundRefreshTimeoutId.current = setTimeout(() => {
        if (document.hidden) {
          if (backgroundRefreshIntervalId.current != null) {
            clearInterval(backgroundRefreshIntervalId.current);
          }
        }
      }, 60 * 1000);
    } else {
      // if page has been in background for more than 5 minutes, refresh data
      if (
        lastVisibilityChange.current != null &&
        lastVisibilityChange.current < minutesAgo(DATA_REFRESH_INTERVAL_MINUTES)
      ) {
        setRefreshingData(true);
        initData(true).finally(() => {
          setRefreshingData(false);
        });
      }
      startBackgroundRefresh();
    }
    lastVisibilityChange.current = new Date();
  }, [setRefreshingData, initData, startBackgroundRefresh]);

  useEffect(() => {
    if (!hasStartedInitialization.current) {
      initializeApp();
      document.addEventListener("visibilitychange", visibilityChangeHandler);
      startBackgroundRefresh();
    }
  }, [initializeApp, visibilityChangeHandler, startBackgroundRefresh]);

  if (!userIsEmployer) {
    return <NotCompanyAdmin onLogout={() => logout()} />;
  } else if (!hasInitialized || !isDataLoaded) {
    return <Initializing />;
  } else {
    return (
      <>
        {refreshingData && <LoadingOverlay message="Refreshing data..." variant="opaque" />}
        <RouterProvider router={appRouter} />
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  isDataLoaded: state.initialization.isDataLoaded ?? false,
  userIsEmployer: userIsEmployer(state.user),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  initData: (isRefresh = false) => dispatch(initData(isRefresh)),
  logout: () => dispatch(logout()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type MainAppConnectedProps = ConnectedProps<typeof connector>;

export default connector(MainApp);
