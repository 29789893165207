import * as Sentry from "@sentry/react";
import { useRouteError } from 'react-router';

function ErrorElement() {
  const routeError = useRouteError();
  Sentry.captureException(routeError);
  return (
    <div className='position-absolute top-0 start-0 end-0 bottom-0 d-flex flex-column align-items-center justify-content-center gap-3'>
      <div>Oops! An unexpected error has occurred.  Juvo support has been notified.</div>
      <div>Click on the button below to reload the app.</div>
      <button className='btn btn-primary' onClick={() => window.location.reload()}>Reload</button>
    </div>
  );
}

export default ErrorElement;