import * as Sentry from "@sentry/react";
import store from "../app/store";
import { User } from "../data/user";
import { login as loginReducer, logout as logoutReducer } from "../reducers/auth";
import { setUser } from "../reducers/user";
import * as Api from "../rest-client/api";
import { FailedRequestError } from "../rest-client/errors";
import { deleteCookie, getCookie, setCookie } from "../utils/cookies";
import { resetData } from "./initialization";
import { AppAction, AppAsyncAction } from "./types";

const COOKIE_NAME_AUTH_HEADER = "AuthHeader";

export function authenticate(userName: string, password: string): AppAsyncAction<User> {
  return (dispatch) => {
    return Api.authenticate(userName, password).then(({ response, responseJson }) => {
      saveAuthHeaderCookie(response);
      const user = responseJson as User;
      dispatch(setUser(user));
      setSentryUser(user.id);
      return user;
    });
  };
}

export function authenticateWithIdentityToken(
  token: string,
  partnerId: string
): AppAsyncAction<{ authenticated: boolean; user?: User }> {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Api.authenticateWithIdentityToken(token, partnerId)
        .then(({ response, responseJson }) => {
          saveAuthHeaderCookie(response);
          const user = responseJson as User;
          dispatch(setUser(user));
          setSentryUser(user.id);
          resolve({ authenticated: true, user: user });
        })
        .catch((error) => {
          if (error instanceof FailedRequestError) {
            resolve({ authenticated: false });
          } else {
            reject(error);
          }
        });
    });
  };
}

function saveAuthHeaderCookie(response: Response) {
  const authHeader = response.headers.get("Authorization");
  if (authHeader) {
    const expiresIn = 14 * 86400; // token should be valid for 14 days, expressed in seconds
    if (authHeader) {
      setCookie(COOKIE_NAME_AUTH_HEADER, authHeader, { path: "/", expiresIn: expiresIn });
      store.dispatch(loginReducer({ authHeader }));
    }
  }
}

export function checkAuthentication(): AppAsyncAction<{ authenticated: boolean; user?: User }> {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const savedAuthHeader = getCookie(COOKIE_NAME_AUTH_HEADER);
      if (savedAuthHeader) {
        Api.getCurrentUser({ authHeader: savedAuthHeader })
          .then(({ responseJson }) => {
            dispatch(loginReducer({ authHeader: savedAuthHeader }));
            const user = responseJson as User;
            dispatch(setUser(user));
            setSentryUser(user.id);
            resolve({ authenticated: true, user: user });
          })
          .catch(({ response }) => {
            if (response.status === 401) {
              dispatch(logoutReducer());
              resolve({ authenticated: false });
            } else {
              console.error(response);
              reject({ authenticated: false });
            }
          });
      } else {
        resolve({ authenticated: false });
      }
    });
  };
}

export function logout(): AppAction {
  return (dispatch) => {
    deleteCookie(COOKIE_NAME_AUTH_HEADER, { path: "/" });
    dispatch(logoutReducer());
    dispatch(setUser(null));
    dispatch(resetData());
    setSentryUser(null);
  };
}

export function requestPasswordReset(email: string): AppAsyncAction<void> {
  return () => {
    return new Promise((resolve, reject) => {
      return Api.requestPasswordReset(email)
        .then(() => resolve())
        .catch((error) => reject(error));
    });
  };
}

function setSentryUser(userId: number | null) {
  Sentry.setUser({ id: userId?.toString() });
}
