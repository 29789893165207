import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactGA from "react-ga4";
import { Navigate, Outlet, createBrowserRouter, useLocation, useNavigate } from "react-router-dom";
import ErrorElement from "../components/ErrorElement";
import JuvoNavBar from "../components/NavBar";
import ActivitiesPage from "../pages/activities/ActivitiesPage";
import locationsRoutes from "../pages/locations/routes";
import connectionsRoutes from "../pages/connections/routes";
import jobsRoutes from "../pages/jobs/routes";
import conversationsRoutes from "../pages/conversations/routes";
import profileRoutes from "../pages/profile/routes";
import { AppDispatch, RootState } from "./store";
import { setInitialAppPath } from "../actions/initialization";
import { connect, ConnectedProps } from "react-redux";

type RootProps = RootConnectedProps;

function Root(props: RootProps) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  const { initialAppPath, clearInitialAppPath } = props;
  useEffect(() => {
    if (initialAppPath) {
      clearInitialAppPath();
      navigate(initialAppPath);
    }
  }, [initialAppPath, clearInitialAppPath, navigate]);

  return (
    <React.Fragment>
      <div className="banner fixed-top">
        <Container>
          <Row>
            <Col>
              <JuvoNavBar />
            </Col>
          </Row>
        </Container>
      </div>
      <Outlet />
    </React.Fragment>
  );
}

const mapStateToProps = (state: RootState) => ({
  initialAppPath: state.initialization.initialAppPath,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  clearInitialAppPath: () => dispatch(setInitialAppPath(undefined)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type RootConnectedProps = ConnectedProps<typeof connector>;
const ConnectedRoot = connector(Root);

const Activities = () => (
  <Container className="content">
    <ActivitiesPage />
  </Container>
);

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <ConnectedRoot />,
    errorElement: <ErrorElement />,
    children: [
      // make /connections the default landing screen
      {
        index: true,
        element: <Navigate replace to="/connections" />,
      },
      connectionsRoutes,
      locationsRoutes,
      jobsRoutes,
      conversationsRoutes,
      {
        path: "activity",
        element: <Activities />,
      },
      profileRoutes,
      { path: "*", element: <ConnectedRoot /> },
    ],
  },
]);

export default appRouter;
