export class StripeCardError extends Error {
  private _param: string;
  constructor(message: string, param: string) {
    super(message);
    this.name = 'StripeCardError';
    this._param = param;
  }
  get param() {
    return this._param;
  }
}

export class AcceptConnectionError extends Error {
  static ReasonCodes = {
    AlreadyApproved: 'AlreadyApproved',
    PaymentMethodMissing: 'PaymentMethodMissing',
    PaymentDeclined: 'PaymentDeclined',
  };
  private _reason: string;
  constructor(message: string, reason: string) {
    super(message);
    this.name = 'AcceptConnectionError';
    this._reason = reason;
  }
  get reason() {
    return this._reason;
  }
}
