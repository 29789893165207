import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CompanyLocation, LocationActivity } from "../data/companyLocation";
import { Job } from "../data/job";
import { PaymentPackage } from "../data/payments";

export type LocationsState = {
  locations: CompanyLocation[] | null;
  locationActivity: LocationActivity[] | null;
};

const initialState: LocationsState = {
  locations: null,
  locationActivity: null,
};

export const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    setLocations: (state, action) => {
      state.locations = action.payload;
    },
    paymentPackageCreated: (
      state,
      action: PayloadAction<{ affectedLocationIds: number[]; package: PaymentPackage }>
    ) => {
      state.locations = state.locations!.map((location) => {
        return action.payload.affectedLocationIds.includes(location.id)
          ? {
              ...location,
              payment_package_id: action.payload.package.id,
            }
          : location;
      });
    },
    jobsAdded: (state, action: PayloadAction<Job[]>) => {
      state.locations!.forEach((location) => {
        const jobsForLocation = action.payload.filter((job) => job.company_location_id === location.id);
        if (jobsForLocation.length > 0) {
          location.jobs = [...location.jobs ?? [], ...jobsForLocation.map((job) => ({ id: job.id, title: job.title }))];
        }
      });
    },
    setLocationActivity: (state, action: PayloadAction<LocationActivity[]>) => {
      state.locationActivity = action.payload;
    },
    addLocation: (state, action: PayloadAction<CompanyLocation>) => {
      state.locations?.push(action.payload);
    },
    updateLocation: (state, action: PayloadAction<CompanyLocation>) => {
      if (state.locations) {
        const index = state.locations?.findIndex((location) => location.id === action.payload.id);
        if (index >= 0) {
          state.locations[index] = { ...state.locations[index], ...action.payload };
        }
      }
    },
  },
});

export const { setLocations, paymentPackageCreated, jobsAdded, setLocationActivity, addLocation, updateLocation } =
  locationsSlice.actions;
export default locationsSlice.reducer;
