import BaseRestClient from "./baseClient";

export function loadConversations() {
  return BaseRestClient.fetch("api/v2/conversations");
}

export function loadConversationById(conversationId: number) {
  return BaseRestClient.fetch(`api/v2/conversations/${conversationId}`);
}

export function addConversationMessage(conversationId: number, message: string) {
  return BaseRestClient.post(`api/v2/conversations/${conversationId}/add_message?update_last_read=true`, {
    message: message,
  });
}

export function updateConversationLastRead(conversationId: number, dateTime: Date) {
  return BaseRestClient.post(`api/v2/conversations/${conversationId}/set_last_read`, {
    last_read: dateTime,
  });
}

export function startConversation(connectionId: number, message: string) {
  return BaseRestClient.post("api/v2/conversations", {
    conversation: {
      connection_id: connectionId,
      message: message,
    },
  });
}
