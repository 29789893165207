import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { faCircleExclamation, faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { executeCustomAction, fetchCustomActionResults } from "../../actions/customActions";
import { AppDispatch, RootState } from "../../app/store";
import { CustomAction, CustomActionResult, CustomActionScope, CustomActionStatus } from "../../data/customActions";
import { classNames } from "../../utils/classNameList";
import { isNullOrEmpty } from "../../utils/validation";
import { CustomActionModal } from "./CustomActionModal";

type CustomActionWithResult = CustomAction & {
  result?: CustomActionResult;
};

type CustomActionDropdownProps = CustomActionDropdownConnectedProps & {
  seekerId: number;
};

function CustomActionDropdown({
  seekerId,
  customActions,
  fetchCustomActionResults,
  executeCustomAction,
}: CustomActionDropdownProps) {
  const refreshResultsTimeout = useRef<NodeJS.Timeout>();
  const [refreshingResults, setRefreshingResults] = useState(false);
  const [customActionResults, setCustomActionResults] = useState<CustomActionResult[]>();
  const [selectedCustomAction, setSelectedCustomAction] = useState<CustomActionWithResult>();

  const customActionAndResults = useMemo<CustomActionWithResult[] | undefined>(
    () =>
      customActions?.map((action) => ({
        ...action,
        result: customActionResults?.find((result) => result.action_id === action.id),
      })),
    [customActions, customActionResults]
  );

  const refreshActionResults = useCallback(() => {
    return fetchCustomActionResults(seekerId).then((results) => {
      setCustomActionResults(results);
      return results;
    });
  }, [seekerId, fetchCustomActionResults, setCustomActionResults]);

  const customActionButtonToggle = useCallback(() => {
    if (customActionResults == undefined) {
      refreshActionResults();
    }
  }, [customActionResults, refreshActionResults]);

  const customActionSelected = useCallback(
    (key: string | null) => {
      setSelectedCustomAction(customActions?.find((action) => `action-${action.id}` === key));
    },
    [customActions]
  );

  const startResultsRefresh = useCallback(() => {
    const startedAt = new Date().getTime();
    setRefreshingResults(true);
    const callback = () => {
      console.debug(`Refreshing results (${new Date().toTimeString()})`);
      refreshResultsTimeout.current = undefined;
      refreshActionResults().then((updatedResults) => {
        const itemResult = updatedResults?.find((item) => item.object_id === seekerId);
        console.debug(`Item status = ${itemResult?.status}`);
        if (itemResult?.status === CustomActionStatus.Started) {
          const totalTime = new Date().getTime() - startedAt;
          if (totalTime < 15000) {
            refreshResultsTimeout.current = setTimeout(callback, totalTime * 2);
          } else {
            setRefreshingResults(false);
          }
        } else {
          setRefreshingResults(false);
        }
      });
    };
    refreshResultsTimeout.current = setTimeout(callback, 2000);
  }, [seekerId, setRefreshingResults, refreshActionResults]);

  const executeCustomActionClicked = useCallback(
    (action: CustomAction) => {
      executeCustomAction(action!.id, seekerId).then((result) => {
        setCustomActionResults([...customActionResults!.filter((item) => item.object_id !== seekerId), result]);
        startResultsRefresh();
      });
    },
    [seekerId, executeCustomAction, customActionResults, setCustomActionResults, startResultsRefresh]
  );

  useEffect(
    () => () => {
      if (refreshResultsTimeout.current) {
        clearTimeout(refreshResultsTimeout.current);
        refreshResultsTimeout.current = undefined;
      }
    },
    []
  );

  const customActionModalClosed = useCallback(() => {
    setSelectedCustomAction(undefined);
  }, [setSelectedCustomAction]);

  const customActionStatusLabelAndIcon = (action: CustomActionWithResult) => {
    switch (action.result?.status) {
      case undefined:
      case CustomActionStatus.NotStarted:
        return {
          statusLabel: "Not started",
        };
      case CustomActionStatus.Completed:
        return {
          statusLabel: "Completed",
          statusIcon: faCircleCheck,
          statusIconClassName: "text-success",
        };
      case CustomActionStatus.Started:
        return {
          statusLabel: "Started",
          statusIcon: faClockRotateLeft,
          statusIconClassName: "text-primary",
        };
      case CustomActionStatus.Failed:
        return {
          statusLabel: "Failed",
          statusIcon: faCircleExclamation,
          statusIconClassName: "text-danger",
        };
      default:
        return {
          statusLabel: "Unknown status",
        };
    }
  };

  if (isNullOrEmpty(customActionAndResults)) {
    return <></>;
  } else {
    return (
      <>
        <DropdownButton
          variant="primary"
          drop="down"
          title="Other Actions"
          onSelect={customActionSelected}
          onToggle={customActionButtonToggle}
        >
          {customActionAndResults!.map((action) => {
            const { statusLabel, statusIcon, statusIconClassName } = customActionStatusLabelAndIcon(action);
            return (
              <Dropdown.Item
                key={`action-${action.id}`}
                eventKey={`action-${action.id}`}
                disabled={customActionResults == undefined}
              >
                <>
                  {action.label}
                  <span className="custom-action-status-label">{statusLabel}</span>
                  {statusIcon && (
                    <FontAwesomeIcon
                      icon={statusIcon}
                      className={classNames("custom-action-status-icon", statusIconClassName)}
                    />
                  )}
                </>
              </Dropdown.Item>
            );
          })}
        </DropdownButton>
        {selectedCustomAction && (
          <CustomActionModal
            visible={true}
            refreshing={refreshingResults}
            customAction={selectedCustomAction}
            actionResult={customActionResults?.find((result) => result.action_id === selectedCustomAction.id)}
            onClosed={customActionModalClosed}
            executeActionClicked={executeCustomActionClicked}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    customActions: state.initialization.customActions?.filter((action) => action.scope == CustomActionScope.Seeker),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchCustomActionResults: (seekerId: number) => dispatch(fetchCustomActionResults({ seekerId })),
    executeCustomAction: (actionId: number, seekerId: number) => dispatch(executeCustomAction(actionId, seekerId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type CustomActionDropdownConnectedProps = ConnectedProps<typeof connector>;

export default connector(CustomActionDropdown);
