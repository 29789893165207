import store from "../app/store";
import { FailedRequestError } from "./errors";

let HOST = import.meta.env.VITE_API_SERVER_ROOT_URL;
if (!HOST?.endsWith("/")) {
  HOST += "/";
}
//const HOST = 'http://mike-laptop:3000/';

export type RequestOptions = { authHeader?: string };

export type RequestResponse = { response: Response; responseJson: any; dateTime?: Date };

class BaseRestClient {
  static getRequestHeaders(method: string, options?: RequestOptions) {
    const headers: { [key: string]: string } = {
      Accept: "application/json",
    };
    if (method !== "GET") {
      headers["Content-Type"] = "application/json";
    }
    // if isAutenticated was not provided or is true, add authorization header
    if (!(options && "isAuthenticated" in options) || options.isAuthenticated) {
      let authHeader = null;
      if (options?.authHeader) {
        authHeader = options.authHeader;
      } else {
        const {
          auth: { authHeader: stateAuthHeader },
        } = store.getState();
        authHeader = stateAuthHeader;
      }
      if (authHeader) {
        headers["Authorization"] = authHeader;
      }
    }
    return headers;
  }

  static getHost() {
    return HOST;
  }

  static fetch(url: string, options?: RequestOptions) {
    return BaseRestClient.execute(url, "GET", null, options);
  }

  static post(url: string, body?: any, options?: RequestOptions) {
    return BaseRestClient.execute(url, "POST", body, options);
  }

  static put(url: string, body?: any, options?: RequestOptions) {
    return BaseRestClient.execute(url, "PUT", body, options);
  }

  static patch(url: string, body?: any, options?: RequestOptions) {
    return BaseRestClient.execute(url, "PATCH", body, options);
  }

  static execute(
    url: string,
    method: string,
    body: any,
    options?: RequestOptions
  ): Promise<RequestResponse> {
    let requestBody: BodyInit | null | undefined;
    if (body) {
      if (typeof body === "string") {
        requestBody = body;
      } else {
        requestBody = JSON.stringify(body);
      }
    }
    return new Promise((resolve, reject) => {
      try {
        window
          .fetch(HOST + url, {
            method: method,
            mode: "cors",
            headers: BaseRestClient.getRequestHeaders(method, options),
            body: requestBody,
          })
          .then((response) => {
            BaseRestClient.processResponse(response, resolve, reject);
          })
          .catch((error) => {
            console.error(error);
            return reject(error);
          });
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }

  static processResponse(
    response: Response,
    resolve: (value: RequestResponse) => void,
    reject: (reason?: any) => void
  ) {
    if (response.ok) {
      const dateTimeString = response.headers.get("date");
      const dateTime = dateTimeString ? new Date(dateTimeString) : undefined;
      if (BaseRestClient.isJsonResponse(response)) {
        response.json().then((body) => {
          resolve({ response, responseJson: body, dateTime });
        });
      } else {
        resolve({ response, responseJson: null, dateTime });
      }
    } else {
      if (BaseRestClient.isJsonResponse(response)) {
        response.json().then((json) => reject(new FailedRequestError(response, json, null)));
      } else {
        response.text().then((body) => reject(new FailedRequestError(response, null, body)));
      }
    }
  }

  static isJsonResponse(response: Response) {
    const contentType = response.headers.get("content-type");
    return contentType && contentType.indexOf("application/json") !== -1;
  }
}

export default BaseRestClient;
