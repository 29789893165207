import { faClose } from "@fortawesome/free-solid-svg-icons";
import { CSSProperties, PropsWithChildren, useState } from "react";
import { Button } from "react-bootstrap";
import { classNames } from "../utils/classNameList";
import { AppStoreBadge, GooglePlayStoreBadge } from "./AppInstallBadges";
import IconButton from "./IconButton";

type AppInstallPanelProps = PropsWithChildren & {
  message?: string;
  style?: CSSProperties;
  className?: string;
};

function AppInstallPanel({ message, children, style, className }: AppInstallPanelProps) {
  const [showInstallBadges, setShowInstallBadges] = useState(false);
  return (
    <div
      className={classNames(
        "d-flex flex-column border rounded bg-white align-items-center gap-3 p-3 text-center",
        className
      )}
      style={style}
    >
      {showInstallBadges ? (
        <div className="d-flex flex-column gap-3 align-items-center">
          <div className="d-flex flex-row align-items-center" style={{ fontSize: "smaller" }}>
            Get the app
            <IconButton icon={faClose} onClick={() => setShowInstallBadges(false)} />
          </div>
          <AppStoreBadge />
          <GooglePlayStoreBadge />
        </div>
      ) : (
        <div style={{ fontSize: "smaller" }}>
          <div>
            {message}
            {children}
          </div>
          <Button variant="link" size="sm" onClick={() => setShowInstallBadges(true)}>
            Download our app
          </Button>
        </div>
      )}
    </div>
  );
}

export default AppInstallPanel;
