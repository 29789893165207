import BaseRestClient from "./baseClient";

export function fetchAppSettings() {
  return BaseRestClient.fetch('api/v1/app/app-settings');
}

export function updateAppSettings(newSettings: { [key: string] : any }) {
  return BaseRestClient.patch('api/v1/app/app-settings', { settings: newSettings });
}

