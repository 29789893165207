import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { CheckInput, SelectField, TextField } from "../../components/Forms";
import { ADDRESS_STATE_NAMES } from "../../constants";
import { ModelValidationErrors } from "../../data/validation";
import { toSentence } from "../../utils/format";

const CARD_VALIDATION_ERROR_CODES = [
  "incomplete_number",
  "incomplete_cvc",
  "incomplete_expiry",
  "incorrect_number",
  "expired_card",
  "incorrect_cvc",
  "invalid_cvc",
  "invalid_expiry_month",
  "invalid_expiry_year",
  "invalid_number",
];

const CARD_VALIDATION_ERROR_KEYS = ["number", "cvc", "exp_month", "exp_year"];

const stripeOptions = {
  style: {
    base: {
      fontSize: "16px",
      fontFamily:
        'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      color: "#212529",
      backgroundColor: "#fff",
    },
  },
};

const OTHER_DATA_DEFAULT: CreditCardOtherData = {
  name: "",
  address_line1: "",
  address_line2: "",
  address_city: "",
  address_state: "",
  address_zip: "",
  address_country: "US",
};

export type CreditCardOtherData = {
  name: string;
  address_line1: string;
  address_line2?: string;
  address_city: string;
  address_state: string;
  address_zip: string;
  address_country: "US";
};

export type CreditCardAddress = Omit<CreditCardOtherData, "name">;

type CreditCardFormProps = {
  validationErrors?: ModelValidationErrors | null;
  copyAddress?: CreditCardAddress;
  copyAddressLabel?: string;
};

export type CreditCardFormRef = {
  createToken: () => Promise<string | null>;
  reset: () => void;
};

const parentValidationErrorsToCardValidationError = (errors: ModelValidationErrors | null | undefined) => {
  const cardErrors: string[] = [];
  if (errors) {
    for (const [key, value] of Object.entries(errors)) {
      if (CARD_VALIDATION_ERROR_KEYS.includes(key)) {
        const combinedErrors = toSentence(value);
        if (combinedErrors) {
          cardErrors.push(combinedErrors);
        }
      }
    }
  }
  return toSentence(cardErrors);
};

const CreditCardForm = React.forwardRef(
  (
    { validationErrors: parentValidationErrors, copyAddress, copyAddressLabel }: CreditCardFormProps,
    ref: React.Ref<CreditCardFormRef>
  ) => {
    const [otherData, setOtherData] = useState<CreditCardOtherData>(OTHER_DATA_DEFAULT);
    const [copyAddressChecked, setCopyAddressChecked] = useState(false);
    const [validationErrors, setValidationErrors] = useState<ModelValidationErrors | null | undefined>(
      parentValidationErrors
    );
    const [cardValidationError, setCardValidationError] = useState<string | null>(
      parentValidationErrorsToCardValidationError(parentValidationErrors)
    );

    useEffect(() => {
      setValidationErrors(parentValidationErrors);
      setCardValidationError(parentValidationErrorsToCardValidationError(parentValidationErrors));
    }, [parentValidationErrors]);

    const stripe = useStripe();
    const elements = useElements();

    const otherDataChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setOtherData({ ...otherData, [event.target.name]: event.target.value });
        if (event.target.name !== "name") {
          setCopyAddressChecked(false);
        }
      },
      [otherData, setOtherData, setCopyAddressChecked]
    );

    const onCopyAddressClicked = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
          setCopyAddressChecked(true);
          setOtherData({ ...otherData, ...copyAddress });
        }
      },
      [otherData, setOtherData, copyAddress]
    );

    const createToken = useCallback((): Promise<string | null> => {
      return new Promise((resolve, reject) => {
        const cardElement = elements!.getElement(CardElement);
        stripe!.createToken(cardElement!, otherData).then(({ error, token }) => {
          if (error) {
            const stripeError = error as StripeError;
            if (stripeError.type === "validation_error" || stripeError.type === "card_error") {
              if (stripeError.code && CARD_VALIDATION_ERROR_CODES.includes(stripeError.code)) {
                setValidationErrors(null);
                setCardValidationError(stripeError.message ?? "The card information is not valid");
                resolve(null);
                return;
              }
              if (stripeError.param && stripeError.message) {
                setCardValidationError(null);
                setValidationErrors({ [stripeError.param]: stripeError.message });
                resolve(null);
                return;
              }
            }
            setValidationErrors(null);
            setCardValidationError(null);
            reject(new Error(stripeError.message ?? "An expected error occurred"));
          } else {
            setCardValidationError(null);
            setValidationErrors(null);
            resolve(token.id);
          }
        });
      });
    }, [stripe, elements, otherData]);

    const reset = useCallback(() => {
      setOtherData(OTHER_DATA_DEFAULT);
      const creditCardElement = elements!.getElement(CardElement);
      creditCardElement?.clear();
    }, [setOtherData, elements]);

    React.useImperativeHandle(
      ref,
      React.useCallback(
        () => ({
          createToken: createToken,
          reset: reset,
        }),
        [createToken, reset]
      )
    );

    return (
      <Form>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="credit-card">
              <Form.FloatingLabel label="Credit Card">
                <div className="form-control">
                  <CardElement options={stripeOptions} onChange={() => {
                    setCardValidationError(null);
                  }} />
                </div>
              </Form.FloatingLabel>
              {cardValidationError && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {cardValidationError}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              floatingLabel
              value={otherData.name}
              attributeName="name"
              label="Name"
              validationErrors={validationErrors?.name}
              onChange={otherDataChange}
            />
          </Col>
        </Row>
        {copyAddress && (
          <Row>
            <Col>
              <CheckInput
                id="copy-from-address"
                label={`Same as ${copyAddressLabel}`}
                checked={copyAddressChecked}
                onChange={onCopyAddressClicked}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <TextField
              floatingLabel
              value={otherData.address_line1}
              attributeName="address_line1"
              label="Address"
              validationErrors={validationErrors?.address_line1}
              onChange={otherDataChange}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              floatingLabel
              value={otherData.address_line2 ?? ""}
              attributeName="address_line2"
              label="Address #2"
              validationErrors={validationErrors?.address_line2}
              onChange={otherDataChange}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              floatingLabel
              value={otherData.address_city}
              attributeName="address_city"
              label="City"
              validationErrors={validationErrors?.address_city}
              onChange={otherDataChange}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={6} md={12}>
            <SelectField
              floatingLabel
              value={otherData.address_state}
              attributeName="address_state"
              label="State"
              validationErrors={validationErrors?.address_state}
              onChange={otherDataChange}
              items={["", ...ADDRESS_STATE_NAMES]}
            />
          </Col>
          <Col xl={6} md={12}>
            <TextField
              floatingLabel
              value={otherData.address_zip}
              attributeName="address_zip"
              label="Zip Code"
              validationErrors={validationErrors?.address_zip}
              onChange={otherDataChange}
            />
          </Col>
        </Row>
      </Form>
    );
  }
);

export default CreditCardForm;
