import { parseISO } from "date-fns";
import { Connection } from "../data/connection";
import { Conversation } from "../data/conversation";

export type ParsedConnection<
  T extends Connection = Connection,
  C extends Pick<Conversation, "connection_id"> = Conversation
> = Omit<T, "last_submitted_at"> & {
  last_submitted_at: Date;
  conversation: C | undefined;
};

export function parseConnections<
  T extends Connection = Connection,
  C extends Pick<Conversation, "connection_id"> = Conversation
>(connections: T[], conversations: C[]): ParsedConnection<T, C>[] {
  return connections.map((connection) => {
    const conversation = conversations?.find((conv) => conv.connection_id === connection.id);
    return {
      ...connection,
      last_submitted_at: parseISO(connection.last_submitted_at),
      conversation: conversation,
    };
  });
}
