import { faEdit, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useState } from "react";
import { Card, Dropdown, Nav, NavItem, Navbar } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect, ConnectedProps } from "react-redux";
import sanitizeHtml from "sanitize-html";
import { updateJob } from "../../actions/jobs";
import AppToast from "../../components/AppToast";
import LoadingOverlay from "../../components/LoadingOverlay";
import { JobCategories, JobStatus, getJobStatusLabel } from "../../constants";
import { formatHours, formatRate } from "../../utils/format";
import { jobLinkUniversalUrl } from "../../utils/links";
import { AppDispatch } from "../../app/store";
import { Job } from "../../data/job";

type JobCardProps = JobCardConnectedProps & {
  job: Job | Omit<Job, "id">;
  readOnly?: boolean;
  onEditClick?: (job: Job) => void;
};

function JobCard({ job, onEditClick, updateJob, readOnly = false }: JobCardProps) {
  const [waiting, setWaiting] = useState(false);
  const jobDescriptionHtml = sanitizeHtml(job.description);
  const changeActiveHandler = useCallback(
    (active: boolean) => {
      setWaiting(true);
      updateJob({ ...(job as Job), status: active ? JobStatus.Active : JobStatus.Inactive })
        .catch(() => AppToast.show({ text: "Failed to update job", type: "error" }))
        .finally(() => setWaiting(false));
    },
    [updateJob, setWaiting, job]
  );
  return (
    <Card className="job-card">
      <Card.Header className="bg-primary text-white">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div>{job.title}</div>
          {!readOnly && (
            <Nav className="ms-auto">
              <Navbar.Text>Status:</Navbar.Text>
              <Dropdown as={NavItem} onSelect={(key) => changeActiveHandler(key === "activate")}>
                <Dropdown.Toggle variant="primary">{getJobStatusLabel(job.status)}</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="activate" disabled={job.status === JobStatus.Active}>
                    Activate
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="deactivate" disabled={job.status !== JobStatus.Active}>
                    Deactivate
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Navbar.Text>|</Navbar.Text>
              <Nav.Link
                onClick={(event) => {
                  event.preventDefault();
                  if (onEditClick) {
                    onEditClick(job as Job);
                  }
                }}
                className="text-white"
              >
                <FontAwesomeIcon icon={faEdit} />
                &nbsp;Edit
              </Nav.Link>
              <Navbar.Text>|</Navbar.Text>
              {"id" in job && (
                <CopyToClipboard
                  text={jobLinkUniversalUrl(job.id)}
                  onCopy={() => {
                    AppToast.show({
                      type: "success",
                      text: "Copied job link to the clipboard",
                    });
                  }}
                >
                  <Nav.Link className="text-white" title="Copy job link to clipboard">
                    <FontAwesomeIcon icon={faShare} />
                    &nbsp;Share
                  </Nav.Link>
                </CopyToClipboard>
              )}
            </Nav>
          )}
        </div>
      </Card.Header>
      <Card.Body>
        {jobDescriptionHtml ? (
          <div dangerouslySetInnerHTML={{ __html: jobDescriptionHtml }} className="job-description" />
        ) : (
          <div className="text-muted">No description provided</div>
        )}
        <hr />
        <div className="job-details">
          <div className="pay-range">
            <label>Pay Rate</label>
            {formatRate(job)}
          </div>
          <div className="hours-range">
            <label>Hours</label>
            {formatHours(job)}
          </div>
          <div className="categories">
            <label>Categories</label>
            {JobCategories.filter((cat) => job.category_ids.includes(cat.id))
              .map((cat) => cat.label)
              .join(" | ")}
          </div>
        </div>
      </Card.Body>
      {waiting && <LoadingOverlay />}
    </Card>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    updateJob: (job: Job) => dispatch(updateJob(job)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type JobCardConnectedProps = ConnectedProps<typeof connector>;

export default connector(JobCard);
