import { PricingPackageUnitType, pricingPackageTermDescription } from "../../../constants";
import { PricingPackage, PricingPackageGroup } from "../../../data/payments";
import { formatMoney } from "../../../utils/format";

type PricingPackageChooserProps = {
  packages: PricingPackageGroup[];
  selectedPackage?: PricingPackage;
  onSelect: (pricingPackage: PricingPackage) => void;
};

function PricingPackageChooser({ packages, selectedPackage, onSelect }: PricingPackageChooserProps) {
  const packagesByUnitType: { [key in PricingPackageUnitType]?: PricingPackageGroup[] } = {};

  [PricingPackageUnitType.Connection, PricingPackageUnitType.Location, PricingPackageUnitType.Company].forEach(
    (unitType) => {
      const packagesForType = packages?.filter((p) => p.unit_type === unitType);
      if (packagesForType.length > 0) {
        packagesByUnitType[unitType] = packagesForType;
      }
    }
  );

  return (
    <div className="d-flex flex-column gap-3">
      {packages.map((packageGroup) => (
        <PricingPackageGroupElement
          group={packageGroup}
          selectedPackage={selectedPackage}
          onPackageSelected={(_package: PricingPackage) => onSelect(_package)}
          key={`unit-type-${packageGroup.unit_type}`}
        />
      ))}
    </div>
  );
}

type PricingPackageGroupProps = {
  group: PricingPackageGroup;
  selectedPackage?: PricingPackage;
  onPackageSelected: (pricingPackage: PricingPackage) => void;
};
function PricingPackageGroupElement({ group: packageGroup, selectedPackage, onPackageSelected }: PricingPackageGroupProps) {
  return (
    <div className="border rounded p-3 d-flex flex-column gap-3">
      {(packageGroup.title != null || packageGroup.description != null) && (
        <div className="align-self-center text-center">
          {packageGroup.title != null && (
            <div className="fw-bold fs-5 text-primary align-self-center">{packageGroup.title}</div>
          )}
          {packageGroup.description != null && <div>{packageGroup.description}</div>}
        </div>
      )}
      {packageGroup.packages.map((_package) => {
        const containerClasses = ["border", "rounded", "p-3"];
        if (_package.id === selectedPackage?.id) {
          containerClasses.push("border-primary");
        }
        return (
          <div
            key={`package-${_package.id}`}
            className={containerClasses.join(" ")}
            onClick={() => onPackageSelected(_package)}
            role="button"
          >
            <div className="d-flex flex-row justify-content-between">
              {_package.title != null && <div className="fw-semibold text-primary">{_package.title}</div>}
              <div className="fw-semibold">
                {_package.setup_fee != null &&
                  _package.setup_fee > 0 &&
                  `${formatMoney(_package.setup_fee, 0)} setup fee +\n`}
                {formatMoney(
                  _package.unit_price!,
                  packageGroup.unit_type === PricingPackageUnitType.Connection ? 2 : 0
                )}
                /{pricingPackageTermDescription(_package.term)}
              </div>
            </div>
            <div>
              {_package.description != null && <div>{_package.description}</div>}
              {_package.notes != null && <div>{_package.notes}</div>}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default PricingPackageChooser;
