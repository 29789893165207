export function objectsAreEqual(o1: any, o2: any): boolean {
  const entries1 = Object.entries(o1);
  const entries2 = Object.entries(o2);
  if (entries1.length !== entries2.length) {
    return false;
  }
  for (let i = 0; i < entries1.length; ++i) {
    if (entries1[i][0] !== entries2[i][0]) {
      return false;
    }
    if (entries1[i][1] !== entries2[i][1]) {
      return false;
    }
  }
  return true;
}

export function removeEmptyKeys<T>(object: any): T {
  for (const key in object) {
    if (object[key] == null) {
      delete object[key];
    } else if (Array.isArray(object[key]) && object[key].length === 0) {
      delete object[key];
    } else if (object[key] === "") {
      delete object[key];
    }
  }
  return object;
}

export function typedHash<T>(source: T) : { [key in keyof T]: any } {
  return source as { [key in keyof T]: any };
}