import React, { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import { Alert, Card } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import {
  addConversationMessage,
  selectConversationByConnectionId,
  startConversation,
  updateConversationLastRead,
} from "../../actions/conversations";
import AppToast from "../../components/AppToast";
import { ConnectionPaymentStatus } from "../../constants";
import { classNames } from "../../utils/classNameList";
import { hasEmployerChats, hasSystemUserChats, parseConversation, ParsedConversation } from "../../utils/conversations";
import ConversationMessages from "../conversations/ConversationMessages";
import ConversationToolbar from "../conversations/ConversationToolbar";
import { AppDispatch, RootState } from "../../app/store";
import {
  ConnectionWithFullSeekerProfile,
  ConnectionWithSeekerExperience,
  ConnectionWithSeekerExperienceAndJobs,
} from "../../data/connection";
import { Conversation } from "../../data/conversation";

type ConversationPanelProps = ConversationPanelConnectedProps & {
  connection: ConnectionWithFullSeekerProfile | ConnectionWithSeekerExperience | ConnectionWithSeekerExperienceAndJobs;
  className?: string;
  style?: CSSProperties;
};

function ConversationPanel({
  currentUser,
  connection,
  updateConversationLastRead,
  selectConversationByConnectionId,
  className,
  style,
}: ConversationPanelProps) {
  const updatingLastReadId = useRef<number | null>(null);
  const [conversation, setConversation] = useState<ParsedConversation | null>(null);

  const connectionRequired = connection ? connection.payment_status === ConnectionPaymentStatus.Unpaid : false;
  const existingChatsAreAutoChats = conversation && !hasEmployerChats(conversation) && hasSystemUserChats(conversation);

  const _setConversation = useCallback(
    (newConversation: Conversation) => {
      if (newConversation == null) {
        setConversation(null);
      } else {
        const parsedConversation = parseConversation(newConversation);
        setConversation(parsedConversation);
      }
    },
    [setConversation]
  );

  const updateLastRead = useCallback(
    (conversationId: number) => {
      if (updatingLastReadId.current === conversationId) return;
      updatingLastReadId.current = conversationId;
      updateConversationLastRead(conversationId)
        .then((updatedConversation) => {
          _setConversation(updatedConversation);
          updatingLastReadId.current = null;
        })
        .catch((error) => {
          console.error(error);
          AppToast.show({ text: "An unexpected error occurred", type: "error" });
        });
    },
    [updateConversationLastRead, _setConversation]
  );

  useEffect(() => {
    const conversation = selectConversationByConnectionId(connection.id);
    _setConversation(conversation!);
    if (conversation) {
      updateLastRead(conversation.id);
    }
  }, [connection, _setConversation, selectConversationByConnectionId, updateLastRead]);

  return (
    <Card className={classNames("border-primary connection-conversation", className)} style={style}>
      <Card.Header className="bg-primary text-white">Conversation</Card.Header>
      <Card.Body className="p-0">
        {conversation ? (
          <ConversationMessages currentUser={currentUser} conversation={conversation} />
        ) : (
          <div className="p-3">
            {connectionRequired
              ? "To start a conversation, first connect with the seeker above"
              : "Start a conversation by sending a message below!"}
          </div>
        )}
      </Card.Body>
      <Card.Footer className="bg-white">
        {conversation && (
          <React.Fragment>
            {connectionRequired ? (
              <Alert variant="warning" className="alert-sm">
                To continue the conversation, first connect with the seeker above
              </Alert>
            ) : (
              <React.Fragment>
                {existingChatsAreAutoChats && (
                  <Alert variant="warning" className="alert-sm">
                    Juvo has previously chatted with this seeker to give them a positive experience with your business.
                  </Alert>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {conversation ? (
          <ConversationToolbar
            conversation={conversation}
            enabled={!connectionRequired}
            onConversationUpdated={(updatedConversation) => _setConversation(updatedConversation)}
          />
        ) : (
          <ConversationToolbar
            connection={connection}
            enabled={!connectionRequired}
            onConversationUpdated={(updatedConversation) => _setConversation(updatedConversation)}
          />
        )}
      </Card.Footer>
    </Card>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    currentUser: state.user,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    selectConversationByConnectionId: (connectionId: number) =>
      dispatch(selectConversationByConnectionId(connectionId)),
    startConversation: (connectionId: number, message: string) => dispatch(startConversation(connectionId, message)),
    addConversationMessage: (conversationId: number, message: string) =>
      dispatch(addConversationMessage(conversationId, message)),
    updateConversationLastRead: (conversationId: number) => dispatch(updateConversationLastRead(conversationId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ConversationPanelConnectedProps = ConnectedProps<typeof connector>;

export default connector(ConversationPanel);
