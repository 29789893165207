import { combineReducers } from "redux";
import { AnyAction } from "@reduxjs/toolkit";
import authReducer from "./auth";
import userReducer from "./user";
import initalizationReducer from "./initialization";
import connectionsReducer from "./connections";
import conversationsReducer from "./conversations";
import locationsReducer from "./locations";
import activitiesReducer from "./activities";
import paymentsReducer from "./payments";
import jobsReducer from "./jobs";
import registrationReducer from "./registration";
import brandsReducer from "./brands";

// approach taken from https://medium.com/@asher.cassetto.cohen/resetting-redux-state-with-a-root-reducer-bonus-how-to-reset-state-selectively-e2a008d0de61
const appReducers = combineReducers({
  auth: authReducer,
  user: userReducer,
  connections: connectionsReducer,
  initialization: initalizationReducer,
  conversations: conversationsReducer,
  locations: locationsReducer,
  activities: activitiesReducer,
  payments: paymentsReducer,
  jobs: jobsReducer,
  registration: registrationReducer,
  brands: brandsReducer,
});

function rootReducer(state: any, action: AnyAction) {
  // on logout, set state to undefined so it is reset to initial state in the app reducer
  return appReducers(action.type === "auth/logout" ? undefined : state, action);
}
export default rootReducer;
