import { faCheckCircle, faCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";
import { Stack } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { Navigate } from "react-router";
import { RootState } from "../../app/store";
import { PaymentMethod } from "../../data/payments";
import BusinessForm from "./BusinessForm";
import InitPage from "./InitPage";
import PaymentForm from "./PaymentForm";
import "./Registration.scss";
import UserAccountForm from "./UserAccountForm";

const STEPS = [
  {
    label: "Contact Details",
    bottom: <img className="banner-left-image" src="/images/registration-step-1.png" alt="Create an account" />,
  },
  {
    label: "Business Information",
    bottom: (
      <div className="marketing-content">
        <img
          className="banner-left-image banner-left-image-rounded"
          src="/images/registration-step-2.png"
          alt="Let's market your business"
        />
        <h5 className="text-white text-uppercase mt-3">Let's market your business</h5>
        <div className="text-white">
          Your Juvo 'pin' acts as a <i>billboard</i> on our map. You're on your way to getting in front of <i>local</i>{" "}
          job seekers like never before!
        </div>
      </div>
    ),
  },
  {
    label: "Payment Details",
    bottom: (
      <div className="marketing-content">
        <img
          className="banner-left-image banner-left-image-rounded"
          src="/images/registration-step-3.jpg"
          alt="More talent in your future"
        />
        <h5 className="text-white text-uppercase mt-3">More talent in your future</h5>
        <div className="text-white">
          You'll have interested seekers connecting with you in no time! Finish creating your profile to see who's in
          your area.
        </div>
      </div>
    ),
  },
];

function StepsIndicator({ currentStep }: { currentStep: number }) {
  return (
    <Stack direction="vertical">
      {STEPS.map((step, stepIndex) => {
        const status = currentStep === stepIndex ? "active" : currentStep > stepIndex ? "completed" : "pending";
        const completed = currentStep > stepIndex;
        return (
          <div className="registration-step-label" data-status={status} key={`step-${currentStep}-${stepIndex}`}>
            <FontAwesomeIcon icon={completed ? faCheckCircle : faCircle} />
            <label>{step.label}</label>
          </div>
        );
      })}
    </Stack>
  );
}

type RegistrationPageProps = RegistrationPageConnectedProps & {
  step?: string;
};

function RegistrationPage({ step, pricingPackage, paymentMethod, user, business }: RegistrationPageProps) {
  let pageContent: ReactNode | undefined;
  let currentStep = 0;
  if (step) {
    if (step === "init") {
      pageContent = <InitPage />;
    } else if (step === "user") {
      if (paymentMethod == PaymentMethod.Card && pricingPackage == null) {
        pageContent = <Navigate to="/registration/init" />;
      } else {
        pageContent = <UserAccountForm />;
        currentStep = 0;
      }
    } else if (step === "business") {
      if (user == null) {
        pageContent = <Navigate to="/registration/user" />;
      } else {
        currentStep = 1;
        pageContent = <BusinessForm />;
      }
    } else if (step === "payment") {
      if (business == null) {
        pageContent = <Navigate to="/registration/business" />;
      } else {
        currentStep = 2;
        pageContent = <PaymentForm />;
      }
    } else {
      console.error(`Unsupported step in registration page {$step}`);
      pageContent = <Navigate to="/registration/init" />;
    }
  } else {
    pageContent = <Navigate to="/registration/init" />;
  }

  return (
    <div className="registration-page d-flex flex-row align-items-stretch">
      <div className="banner-left">
        <div className="w-auto flex-resize">
          <div className="mt-5 mb-3">
            <img alt="Juvo" src="/images/logo.png" />
          </div>
          <div className="d-flex flex-column align-items-center">
            <StepsIndicator currentStep={currentStep} />
          </div>
        </div>
        <div className="w-auto flex-grow-0 flex-shrink-1" style={{ overflow: "clip" }}>
          {STEPS[currentStep].bottom}
        </div>
      </div>
      <div className="mt-3 registration-page-content">{pageContent}</div>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  pricingPackage: state.registration.pricing_package,
  paymentMethod: state.registration.payment_method,
  user: state.registration.user,
  business: state.registration.business,
});

const connector = connect(mapStateToProps);

type RegistrationPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(RegistrationPage);
