import React, { useCallback, useRef, useState } from "react";
import FormControl from "react-bootstrap/FormControl";
import "./AutoComplete.scss";

export type Suggestion<T = number> = {
  id: T;
  name: string;
};

type AutoCompleteProps<T extends Suggestion<V>, V = number> = {
  selectedItem?: T;
  placeholder?: string;
  suggestions: T[] | ((input: string | undefined) => Promise<T[]>);
  onChange: (value: T) => void;
};

function AutoComplete<T extends Suggestion<V>, V = number>({
  selectedItem,
  placeholder,
  suggestions,
  onChange,
}: AutoCompleteProps<T, V>) {
  const updateSuggestionsTimeout = useRef<NodeJS.Timeout>();
  const suggestionsRef = useRef<HTMLUListElement>(null);

  const [activeSuggestion, setActiveSuggestion] = useState<number | undefined>();
  const [filteredSuggestions, setFilteredSuggestions] = useState<T[]>();
  const [suggestionsVisible, setSuggestionsVisible] = useState(false);
  const [userInput, setUserInput] = useState(selectedItem?.name ?? "");

  const showSuggestions = useCallback(
    (suggestionsToShow: T[]) => {
      setActiveSuggestion(0);
      setFilteredSuggestions(suggestionsToShow);
      setSuggestionsVisible(true);
    },
    [setActiveSuggestion, setFilteredSuggestions, setSuggestionsVisible]
  );

  const suggestionSelected = useCallback(
    (item: T) => {
      if (onChange) {
        onChange(item);
      }
    },
    [onChange]
  );

  const updateSuggestions = useCallback(
    (filter: string) => {
      if (suggestions instanceof Function) {
        // add a 1 second delay after an edit from the user before refreshing the suggestions
        // this helps with responsiveness if the user makes several edits to the search term quickly
        if (updateSuggestionsTimeout.current != null) {
          clearTimeout(updateSuggestionsTimeout.current);
        }
        updateSuggestionsTimeout.current = setTimeout(() => {
          suggestions(filter).then((updatedSuggestions) => {
            showSuggestions(updatedSuggestions);
          });
        }, 1000);
      } else if (filter.length > 0) {
        const filteredSuggestions = suggestions.filter(
          (suggestion) => suggestion.name.toLowerCase().indexOf(filter.toLowerCase()) > -1
        );
        showSuggestions(filteredSuggestions);
      } else {
        showSuggestions(suggestions);
      }
    },
    [suggestions, showSuggestions]
  );

  const onInputChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setUserInput(e.currentTarget.value);
      updateSuggestions(e.currentTarget.value);
    },
    [setUserInput, updateSuggestions]
  );

  const focusHandler = useCallback(() => {
    updateSuggestions(userInput);
  }, [updateSuggestions, userInput]);

  const blurHandler = useCallback(
    (event: React.FocusEvent<HTMLElement>) => {
      if (!suggestionsRef.current?.contains(event.relatedTarget)) {
        setSuggestionsVisible(false);
      }
    },
    [setSuggestionsVisible]
  );

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const dataSuggestionId = e.currentTarget.getAttribute("data-id");
      if (dataSuggestionId) {
        const suggestionId = parseInt(dataSuggestionId);
        const suggestion = filteredSuggestions?.find((suggestion) => suggestion.id === suggestionId);
        if (suggestion) {
          setActiveSuggestion(0);
          setFilteredSuggestions([]);
          setSuggestionsVisible(false);
          setUserInput(suggestion.name);
          suggestionSelected(suggestion);
        }
      }
    },
    [
      filteredSuggestions,
      setActiveSuggestion,
      setFilteredSuggestions,
      setSuggestionsVisible,
      setUserInput,
      suggestionSelected,
    ]
  );

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (filteredSuggestions) {
        if (e.code === "Enter") {
          if (filteredSuggestions && activeSuggestion != null) {
            const suggestion = filteredSuggestions[activeSuggestion];
            setActiveSuggestion(0);
            setSuggestionsVisible(false);
            setUserInput(suggestion.name);
            suggestionSelected(suggestion);
          }
          e.preventDefault();
        } else if (e.code === "ArrowUp") {
          if (activeSuggestion != null) {
            if (activeSuggestion === 0) {
              setActiveSuggestion(filteredSuggestions.length - 1);
            } else {
              setActiveSuggestion(activeSuggestion - 1);
            }
          } else {
            setActiveSuggestion(filteredSuggestions.length - 1);
          }
          e.preventDefault();
        } else if (e.code === "ArrowDown") {
          if (activeSuggestion != null) {
            if (activeSuggestion < filteredSuggestions.length - 1) {
              setActiveSuggestion(activeSuggestion + 1);
            } else {
              setActiveSuggestion(0);
            }
          } else {
            setActiveSuggestion(0);
          }
          e.preventDefault();
        }
      }
    },
    [
      filteredSuggestions,
      activeSuggestion,
      setActiveSuggestion,
      setSuggestionsVisible,
      setUserInput,
      suggestionSelected,
    ]
  );

  return (
    <div className="position-relative">
      <FormControl
        type="text"
        placeholder={placeholder}
        onChange={onInputChanged}
        onKeyDown={onKeyDown}
        value={userInput}
        onFocus={focusHandler}
        onBlur={blurHandler}
      />
      {suggestionsVisible &&
        (filteredSuggestions?.length ? (
          <ul className="suggestions" ref={suggestionsRef} tabIndex={1}>
            {filteredSuggestions.map((suggestion, index) => {
              const className = index === activeSuggestion ? "suggestion-active" : undefined;
              return (
                <li className={className} key={`sug-${suggestion.id}`} data-id={suggestion.id} onClick={onClick}>
                  {suggestion.name}
                </li>
              );
            })}
          </ul>
        ) : (
          <div className="no-suggestions">
            <em>No suggestions available.</em>
          </div>
        ))}
    </div>
  );
}

export default AutoComplete;
