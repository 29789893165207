import { Container } from "react-bootstrap";
import { RouteObject, useLoaderData } from "react-router";
import AuthGuard from "../auth/AuthGuard";
import Connection from "./Connection";
import ConnectionsFragment from "./ConnectionsFragment";
import ConnectionsPage from "./ConnectionsPage";

export const Pathname = 'connections';
export const RootPath = '/' + Pathname;

export const SearchParams = {
  Category: 'category',
  Filters: 'filters',
  SelectedConnectionId: 'selected_connection_id'
}

const ConnectionsElement = () => {
  const props = useLoaderData() as any;
  return (
    <AuthGuard>
      <Container className="content-scrollable">
        <ConnectionsPage {...props} />
      </Container>
    </AuthGuard>
  );
};

const ConnectionsFragmentElement = () => {
  const props = useLoaderData() as any;
  return <ConnectionsFragment {...props} />;
};

const ConnectionPageElement = () => {
  const connectionId = useLoaderData() as number;
  return <Connection connectionId={connectionId} showExpander={false} />;
};

const routes : RouteObject = {
  path: Pathname,
  loader: ({ request }) => {
    const url = new URL(request.url);
    return {
      category: url.searchParams.get(SearchParams.Category),
      filters: url.searchParams.get(SearchParams.Filters)
    }
  },
  element: <ConnectionsElement />,
  children: [
    {
      index: true,
      loader: ({ request }) => {
        const url = new URL(request.url);
        return {
          category: url.searchParams.get(SearchParams.Category),
          filters: url.searchParams.get(SearchParams.Filters),
          selectedConnectionId: url.searchParams.has(SearchParams.SelectedConnectionId) ?
            parseInt(url.searchParams.get(SearchParams.SelectedConnectionId)!) :
            null,
        }
      },
      element: <ConnectionsFragmentElement />,
    },
    {
      path: ":connectionId",
      loader: ({ params }) => {
        return parseInt(params.connectionId!);
      },
      element: <ConnectionPageElement />,
    },
  ]
};

export default routes;