import { useEffect, useMemo, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { fetchPartnerConfiguration, fetchPricingPackage } from "../../actions/registration";
import { AppDispatch } from "../../app/store";
import AppToast from "../../components/AppToast";
import CenteredPage from "../../components/CenteredPage";
import LoadingOverlay from "../../components/LoadingOverlay";

type InitPageProps = InitPageConnectedProps;

function InitPage({ fetchPricingPackage, fetchPartnerConfiguration }: InitPageProps) {
  const initializing = useRef<boolean>(false);

  const [showRedirectToMarketing, setShowRedirectToMarketing] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  useEffect(() => {
    if (!initializing.current) {
      initializing.current = true;
      if (urlParams.has("pricing_selector")) {
        const pricingSelectorId = urlParams.get("pricing_selector")!;
        fetchPricingPackage(pricingSelectorId)
          .then(() => {
            navigate("/registration/user");
          })
          .catch((error) => {
            console.error(error);
            AppToast.show({ type: "error", text: "An unexpected error occurred" });
          });
        return;
      } else if (urlParams.has("partner_id")) {
        fetchPartnerConfiguration(urlParams.get("partner_id")!, urlParams.get("identity_token") ?? undefined)
          .then(() => {
            navigate("/registration/user");
          })
          .catch((error) => {
            console.error(error);
            AppToast.show({ type: "error", text: "An unexpected error occurred" });
          });
        return;
      }
      initializing.current = false;
      setShowRedirectToMarketing(true);
    }
  }, [urlParams, fetchPricingPackage, fetchPartnerConfiguration, navigate, setShowRedirectToMarketing]);

  return (
    <>
      {showRedirectToMarketing && (
        <CenteredPage>
          <div>
            Click <a href={import.meta.env.VITE_REGISTRATION_INFO_URL}>here</a> to learn more about joining the Juvo
            Job network
          </div>
        </CenteredPage>
      )}
      {initializing.current && <LoadingOverlay message="Initializing..." />}
    </>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchPricingPackage: (selectorKey: string) => dispatch(fetchPricingPackage(selectorKey)),
  fetchPartnerConfiguration: (partnerId: string, identityToken?: string) =>
    dispatch(fetchPartnerConfiguration(partnerId, identityToken)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type InitPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(InitPage);
