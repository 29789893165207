import { AdditionalCompensationTypes, getEducationLevelLabel } from "../constants";
import { CompanyLocationBasic } from "../data/companyLocation";
import { Job } from "../data/job";
import { UserEducation } from "../data/userEducation";
import { UserExperience } from "../data/userExperience";
import { isNullOrEmpty } from "./validation";

export function formatHours(item: Job | Omit<Job, 'id'>, suffix = true) {
  switch (item.hours_type) {
    case "part_time":
      return "Part-time";
    case "full_time":
      return "Full-time";
    case "part_and_full_time":
      return "Part-time/Full-time";
    default: {
      const suffixStr = suffix ? "hours" : "";
      const hours_min = +item.hours_min || "0";
      const hours_max = +item.hours_max || "0";
      const hoursLabel =
        item.hours_min === item.hours_max
          ? hours_min === null
            ? ""
            : `${hours_min} ${suffixStr}`
          : `${hours_min} - ${hours_max} ${suffixStr}`;
      return hoursLabel;
    }
  }
}

export function formatRate(item: Job | Omit<Job, 'id'>, suffix = true) {
  const suffixStr = suffix ? "/hr" : "";
  let wage_min, wage_max;
  let rateDescription = "";
  if (item.hourly_wage_min == null && item.hourly_wage_max == null) {
    return "Depends on experience";
  } else if (item.hourly_wage_min == null) {
    wage_max = formateRate(parseFloat(item.hourly_wage_max));
    rateDescription = `$${wage_max}${suffixStr}`;
  } else if (item.hourly_wage_max == null) {
    wage_min = formateRate(parseFloat(item.hourly_wage_min));
    rateDescription = `$${wage_min}${suffixStr}`;
  } else {
    wage_min = formateRate(parseFloat(item.hourly_wage_min));
    wage_max = formateRate(parseFloat(item.hourly_wage_max));

    rateDescription =
      wage_min === wage_max
        ? wage_min === "0.00"
          ? ""
          : `$${wage_min}${suffixStr}`
        : `$${wage_min} - ${wage_max}${suffixStr}`;
  }
  if (isNullOrEmpty(item.additional_compensation_types)) {
    return rateDescription;
  } else {
    const additionalCompTypes = item.additional_compensation_types!.map((typeId) => {
      return AdditionalCompensationTypes.find((type) => type.id === typeId)?.label;
    });
    return rateDescription + " + " + toSentence(additionalCompTypes?.map(compType => compType ?? ''));
  }
  function formateRate(rate: number) {
    const rateVal = Number(rate);
    if (Number.isSafeInteger(rateVal)) {
      return rateVal.toFixed(0);
    } else {
      return rateVal.toFixed(2);
    }
  }
}

export function formatExperienceTime(item: UserExperience) {
  const yearLabel = item.years ? `${item.years}YR` : "";
  const monthLabel = item.months ? ` ${item.months}MO` : "";
  return `${yearLabel}${monthLabel}`.trim();
}

export function formatEducationFieldOfStudy(item: UserEducation) {
  return item.level == null
    ? item.field_of_study
    : getEducationLevelLabel(item.level) + (item.field_of_study == null ? "" : ` (${item.field_of_study})`);
}

export function formatEducationSchoolName(item: UserEducation) {
  return item.school_name ?? "Unspecified school";
}

export function formatMoney(value: number, precision = 2) {
  return `$${value.toFixed(precision)}`;
}

export function formatDistance(value: number) {
  const distInMiles = value / 1609.34;
  return `${distInMiles.toFixed(2)} MI`;
}

export function toSentence(
  items: string | string[] | null,
  options: { separator?: string; lastSeparator?: string } = { separator: ", ", lastSeparator: "and" }
) {
  if (items == null) {
    return null;
  }
  if (!Array.isArray(items)) {
    return items;
  }
  if (items.length === 0) {
    return null;
  }
  if (items.length === 1) {
    return items[0];
  }
  return `${items.slice(0, items.length - 1).join(options.separator)} ${options.lastSeparator} ${
    items[items.length - 1]
  }`;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function locationAddress(location: CompanyLocationBasic) {
  let address = location.address_1;
  if (location.address_2) {
    address += `, ${location.address_2}`
  }
  return `${address}, ${location.address_city}, ${location.address_state} ${location.address_zip}`;
}