import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Conversation } from "../data/conversation";

export type StoredConversation = Conversation & { _read_at: string };

export type ConversationState = {
  conversations: StoredConversation[] | null;
};

const initialState: ConversationState = {
  conversations: null,
};

export const conversationsSlice = createSlice({
  name: "conversations",
  initialState,
  reducers: {
    setConversations: (state, action: PayloadAction<StoredConversation[] | null>) => {
      state.conversations = action.payload;
    },
    conversationUpdated: (state, action: PayloadAction<Conversation>) => {
      if (state.conversations != null) {
        state.conversations = state.conversations.map((c) =>
          c.id === action.payload.id ? { ...c, ...action.payload } : c
        );
      }
    },
    conversationCreated: (state, action: PayloadAction<StoredConversation>) => {
      state.conversations = [...(state.conversations ?? []), action.payload];
    },
  },
});

export const { setConversations, conversationUpdated, conversationCreated } = conversationsSlice.actions;
export default conversationsSlice.reducer;
