import React, { ChangeEventHandler, PropsWithChildren, ReactElement, ReactNode } from "react";
import { Form, FormCheckProps, FormControlProps, FormLabel, FormSelectProps, Row } from "react-bootstrap";
import { ValidationErrors } from "../data/validation";
import { toSentence } from "../utils/format";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import FormCheckLabel from "react-bootstrap/esm/FormCheckLabel";

type InputCommonProps = {
  attributeName?: string;
  validationErrors?: ValidationErrors;
};

type FloatingLabelProps = {
  floatingLabel?: boolean;
};

type FieldCommonProps = InputCommonProps & {
  label: string;
  layout?: "vertical" | "horizontal";
};

type TextInputProps = InputCommonProps &
  FormControlProps &
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

type TextFieldProps = FieldCommonProps & TextInputProps & FloatingLabelProps;

type NumberInputProps = InputCommonProps &
  FormControlProps &
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

type NumberFieldProps = FieldCommonProps & NumberInputProps & FloatingLabelProps;

type InputChoice = {
  value: string;
  label: string;
};

type CombinedCheckInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
  FormCheckProps;
type CheckInputProps = InputCommonProps &
  Omit<CombinedCheckInputProps, "type"> & {
    inline?: boolean;
    label?: string;
  };

type ChoiceInputProps = InputCommonProps &
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> &
  FormSelectProps & {
    items: InputChoice[] | string[];
  };

type RadioInputProps = Omit<ChoiceInputProps, "onChange"> & {
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

type ChoiceFieldProps = FieldCommonProps & ChoiceInputProps & FloatingLabelProps;

type RadioFieldProps = FieldCommonProps & RadioInputProps;

type FieldProps = PropsWithChildren & Partial<FieldCommonProps> & FloatingLabelProps;

export function Field({ attributeName, label, layout, validationErrors, floatingLabel, children }: FieldProps) {
  let labelElement: typeof FormLabel | null = null;
  const otherElements: ReactElement[] = [];
  React.Children.forEach<ReactNode>(children, (child) => {
    if (child != null) {
      if (React.isValidElement(child)) {
        if (child.type === FormLabel) {
          labelElement = child as unknown as typeof FormLabel;
        } else {
          otherElements.push(child);
        }
      }
    }
  });
  return floatingLabel ? (
    <Form.Group className="mb-3" controlId={attributeName} as={layout === "horizontal" ? Row : undefined}>
      <Form.FloatingLabel label={label}>{otherElements}</Form.FloatingLabel>
      {validationErrors && <Form.Control.Feedback type="invalid">{toSentence(validationErrors)}</Form.Control.Feedback>}
    </Form.Group>
  ) : (
    <Form.Group className="mb-3" controlId={attributeName} as={layout === "horizontal" ? Row : undefined}>
      {labelElement ? labelElement : <Form.Label>{label}</Form.Label>}
      {otherElements}
      {validationErrors && <Form.Control.Feedback type="invalid">{toSentence(validationErrors)}</Form.Control.Feedback>}
    </Form.Group>
  );
}

export const TextInput = React.forwardRef((props: TextInputProps, ref: React.Ref<HTMLInputElement>) => {
  const { attributeName, validationErrors, ...otherProps } = props;
  return <Form.Control name={attributeName} type="text" isInvalid={!!validationErrors} ref={ref} {...otherProps} />;
});

export const TextField = React.forwardRef((props: TextFieldProps, ref: React.Ref<HTMLInputElement>) => {
  const { attributeName, label, validationErrors, layout = "vertical", floatingLabel, ...otherProps } = props;
  return (
    <Field
      attributeName={attributeName}
      label={label}
      layout={layout}
      validationErrors={validationErrors}
      floatingLabel={floatingLabel}
    >
      <TextInput attributeName={attributeName} validationErrors={validationErrors} {...otherProps} ref={ref} />
    </Field>
  );
});

export const NumberInput = ({ attributeName, validationErrors, ...otherProps }: NumberInputProps): JSX.Element => {
  return <Form.Control name={attributeName} type="number" isInvalid={!!validationErrors} {...otherProps} />;
};

export const NumberField = ({
  attributeName,
  label,
  validationErrors,
  layout = "vertical",
  floatingLabel,
  ...otherProps
}: NumberFieldProps): JSX.Element => {
  return (
    <Field
      attributeName={attributeName}
      label={label}
      layout={layout}
      validationErrors={validationErrors}
      floatingLabel={floatingLabel}
    >
      <NumberInput attributeName={attributeName} validationErrors={validationErrors} {...otherProps} />
    </Field>
  );
};

export const CheckInput = React.forwardRef(
  (
    { attributeName, validationErrors, label, id, inline = false, ...otherProps }: CheckInputProps,
    ref: React.Ref<HTMLInputElement>
  ): JSX.Element => {
    return (
      <Form.Check>
        <FormCheckInput name={attributeName} inline={inline} isInvalid={!!validationErrors} ref={ref} id={id} {...otherProps} />
        <FormCheckLabel htmlFor={id}>{label}</FormCheckLabel>
        {validationErrors && (
          <Form.Control.Feedback type="invalid">{toSentence(validationErrors)}</Form.Control.Feedback>
        )}
      </Form.Check>
    );
  }
);

export const SelectInput = React.forwardRef((props: ChoiceInputProps, ref: React.Ref<HTMLSelectElement>) => {
  const { attributeName, validationErrors, items, ...otherProps } = props;
  return (
    <Form.Select name={attributeName} isInvalid={!!validationErrors} {...otherProps} ref={ref}>
      {items.map((item: string | InputChoice) => {
        if (typeof item == "string") {
          return <option key={item}>{item}</option>;
        } else {
          return <option key={item.value}>{item.label}</option>;
        }
      })}
    </Form.Select>
  );
});

export const SelectField = React.forwardRef((props: ChoiceFieldProps, ref: React.Ref<HTMLSelectElement>) => {
  const { attributeName, label, validationErrors, layout = "vertical", items, floatingLabel, ...otherProps } = props;
  return (
    <Field
      attributeName={attributeName}
      label={label}
      layout={layout}
      validationErrors={validationErrors}
      floatingLabel={floatingLabel}
    >
      <SelectInput
        attributeName={attributeName}
        validationErrors={validationErrors}
        items={items}
        {...otherProps}
        ref={ref}
      />
    </Field>
  );
});

export const RadioInputs = ({ value, attributeName, items, onChange }: RadioInputProps) => {
  return (
    <div>
      {items.map((item, index) => {
        let itemValue: string, itemLabel: string;
        if (typeof item == "string") {
          itemValue = itemLabel = item;
        } else {
          itemValue = item.value;
          itemLabel = item.label;
        }
        return (
          <Form.Check
            key={`check-${index}`}
            checked={value === itemValue}
            inline={true}
            label={itemLabel}
            name={attributeName}
            type="radio"
            value={itemValue}
            id={`${attributeName}-${index}`}
            onChange={onChange}
          />
        );
      })}
    </div>
  );
};

export const RadioFields = ({
  attributeName,
  label,
  items,
  validationErrors,
  onChange,
  layout = "vertical",
}: RadioFieldProps) => {
  return (
    <Field attributeName={attributeName} label={label} layout={layout} validationErrors={validationErrors}>
      <RadioInputs
        attributeName={attributeName}
        items={items}
        validationErrors={validationErrors}
        onChange={onChange}
      />
    </Field>
  );
};
