import { faAddressCard, faBell, faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav } from "react-bootstrap";
import { Outlet, useLocation } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import AppInstallPanel from "../../components/AppInstallPanel";
import "./ProfilePage.scss";
import { RootState } from "../../app/store";
import { connect, ConnectedProps } from "react-redux";
import { PaymentMethod } from "../../data/payments";
import { useMemo } from "react";
import { isNullOrEmpty } from "../../utils/validation";

type ProfilePageProps = ProfilePageConnectedProps;

function ProfilePage({ paymentPackages }: ProfilePageProps) {
  const navLocation = useLocation();
  const match = navLocation.pathname.match(/\/profile\/(.*)$/i);
  let activeKey = "contact_info";
  if (match) {
    activeKey = match[1];
  }

  const cardPaymentPackages = useMemo(
    () => paymentPackages?.filter((p) => p.payment_method === PaymentMethod.Card),
    [paymentPackages]
  );

  return (
    <div className="h-100 d-flex flex-row gap-3">
      <div className="d-flex flex-column h-100 justify-content-between gap-3" style={{ flex: "0 0 250px" }}>
        <Nav defaultActiveKey="contact_info" activeKey={activeKey} className="d-flex flex-column side-nav w-100">
          <LinkContainer to="contact_info">
            <Nav.Link eventKey={"contact_info"}>
              <FontAwesomeIcon icon={faAddressCard} className="side-nav-icon" />
              Contact Information
            </Nav.Link>
          </LinkContainer>
          {!isNullOrEmpty(cardPaymentPackages) && (
            <LinkContainer to="payments">
              <Nav.Link eventKey={"payments"}>
                <FontAwesomeIcon icon={faCreditCard} className="side-nav-icon" />
                Payments
              </Nav.Link>
            </LinkContainer>
          )}
          <LinkContainer to="notifications">
            <Nav.Link eventKey={"notifications"}>
              <FontAwesomeIcon icon={faBell} className="side-nav-icon" />
              Notifications
            </Nav.Link>
          </LinkContainer>
        </Nav>
        <AppInstallPanel>
          Need to view job seekers
          <br /> on the go?
        </AppInstallPanel>
      </div>
      <div className="flex-grow-1 flex-shrink-1 h-100">
        <Outlet />
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    paymentPackages: state.payments.paymentPackages,
  };
};

const connector = connect(mapStateToProps);

type ProfilePageConnectedProps = ConnectedProps<typeof connector>;

export default connector(ProfilePage);
