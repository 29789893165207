import { JuvoJobsLinks } from "../constants";

export function AppStoreBadge() {
  return (
    <a href={JuvoJobsLinks.AppStore} target="_blank" rel="noreferrer">
      <img src="/images/app-store-badge.png" alt="App Store"></img>
    </a>
  );
}

export function GooglePlayStoreBadge() {
  return (
    <a href={JuvoJobsLinks.PlayStore} target="_blank" rel="noreferrer">
      <img src="/images/google-play-badge.png" alt="Google Play"></img>
    </a>
  );
}
