import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { PropsWithChildren } from "react";

type JobDropdownToggleProps = PropsWithChildren & {
  onClick: (e: React.MouseEvent) => void;
};

const JobDropdownToggle = React.forwardRef(
  ({ children, onClick }: JobDropdownToggleProps, ref: React.LegacyRef<HTMLButtonElement>) => (
    <button
      ref={ref}
      className="btn btn-outline-dark"
      onClick={(e) => {
        onClick(e);
      }}
    >
      {children}
      <FontAwesomeIcon className="ms-2" icon={faCaretDown} />
    </button>
  )
);

export default JobDropdownToggle;
