import { format as formatDate, parseISO } from "date-fns";
import { UserCertification } from "../../data/userCertification";
import "./ProfileItemList.scss";

type CertificatesListProps = {
  certificates: UserCertification[];
  maxItems?: number;
};

function CertificatesList({ certificates, maxItems = 1000 }: CertificatesListProps) {
  return (
    <div className="profile-item-list">
      {certificates.slice(0, maxItems).map((certificate, index) => (
        <div key={"cert-" + index}>
          <div className="profile-item-row">
            <div className="title">{certificate.name}</div>
            <div className="status">
              {certificate.expiration_date && formatDate(parseISO(certificate.expiration_date), "P")}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default CertificatesList;
