import { Container } from "react-bootstrap";
import { RouteObject, useLoaderData } from "react-router";
import Location from "./Location";
import LocationConnections from "./LocationConnections";
import LocationsPage from "./LocationsPage";
import LocationsList from "./LocationsList";

export const Pathname = "locations";
export const RootPath = "/" + Pathname;

export const SearchParams = {
  Category: "category",
  JobId: "job_id",
  SelectedConnectionId: "selected_connection_id",
};

const LocationsElement = () => (
  <Container className="content">
    <LocationsPage />
  </Container>
);

const LocationListElement = () => <LocationsList />;

const LocationElement = () => {
  const { locationId } = useLoaderData() as { locationId: number };
  return <Location locationId={locationId} />;
};

const LocationConnectionsElement = () => {
  const props = useLoaderData() as any;
  return <LocationConnections {...props} />;
};

const routes: RouteObject = {
  path: Pathname,
  element: <LocationsElement />,
  children: [
    {
      index: true,
      element: <LocationListElement />,
    },
    {
      path: ":locationId",
      loader: ({ params }: { params: any }) => {
        return {
          locationId: parseInt(params.locationId),
        };
      },
      element: <LocationElement />,
    },
    {
      path: ":locationId/connections",
      loader: ({ request, params }) => {
        const url = new URL(request.url);
        return {
          locationId: params.locationId ? parseInt(params.locationId) : undefined,
          category: url.searchParams.get(SearchParams.Category),
          jobId: url.searchParams.has(SearchParams.JobId) ? parseInt(url.searchParams.get(SearchParams.JobId)!) : null,
          selectedConnectionId: url.searchParams.has(SearchParams.SelectedConnectionId)
            ? parseInt(url.searchParams.get(SearchParams.SelectedConnectionId)!)
            : null,
        };
      },
      element: <LocationConnectionsElement />,
    },
  ],
};

export default routes;
