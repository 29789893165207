import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardPaymentMethod } from "../../data/payments";
import { classNames } from "../../utils/classNameList";
import { cardIcon } from "../../utils/creditCard";
import "./CreditCardDetails.scss";

type CreditCardDetailsProps = {
  card: CardPaymentMethod;
  className?: string;
};

function CreditCardDetails({ card, className }: CreditCardDetailsProps) {
  return (
    <div className={classNames("credit-card", className)}>
      <div className="icon-container">
        <FontAwesomeIcon icon={cardIcon(card.brand)} className="icon" />
      </div>
      <div className="details-container details-text">
        {card.brand}
        <br />
        {`Expires ${card.expiration_month}/${card.expiration_year}`}
        <br />
        Last 4 digits - {card.last4}
      </div>
    </div>
  );
}

export default CreditCardDetails;
