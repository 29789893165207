import { UserProfile } from "./userProfile";

export enum AdminScope {
  location = "location",
  job = "job",
  user = "user",
}

export type Company = {
  id: number;
  name: string;
  address_1: string;
  address_2: null,
  address_city: string;
  address_state: string;
  address_zip: string;
  phone: string;
}

export type User<ProfileType = UserProfile> = {
  id: number;
  email: string;
  role_id: number;
  avatar_url: string | null;
  profile: ProfileType;
  admin_scopes: AdminScope[];
  company: Company;
};
