import { useCallback, useState } from "react";
import { Form, ListGroup } from "react-bootstrap";
import { CompanyLocation } from "../../../data/companyLocation";

type LocationChooserProps = {
  locations: CompanyLocation[];
  selectedIds: number[];
  onChange: (locationIds: number[]) => void;
};

function LocationsChooser({ locations, selectedIds, onChange }: LocationChooserProps) {
  const [locationIds, setLocationIds] = useState<number[]>(selectedIds ?? []);
  const updateLocationIds = useCallback(
    (newLocationIds: number[]) => {
      setLocationIds(newLocationIds);
      onChange(newLocationIds);
    },
    [setLocationIds, onChange]
  );

  return (
    <div className="d-flex flex-column gap-3">
      <ListGroup variant="flush">
        {locations.map((location) => (
          <ListGroup.Item key={`location-${location.id}`}>
            <div className="d-flex flex-row gap-3">
              <Form.Check
                id={`location-${location.id}`}
                value={location.id}
                checked={locationIds.includes(location.id)}
                onChange={(event) => {
                  const _locationId = parseInt(event.currentTarget.value);
                  if (event.currentTarget.checked) {
                    updateLocationIds([...locationIds, _locationId]);
                  } else {
                    updateLocationIds(locationIds.filter((id) => id !== _locationId));
                  }
                }}
              />
              {`${location.name} (${location.address_1})`}
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
}

export default LocationsChooser;
