import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PaymentMethod } from "../data/payments";
import { Business, RegistrationPricingPackage, UserAccount } from "../data/registration";

export interface RegistrationState {
  registration_key: string | null;
  pending_registration_key: string | null;
  partner_id?: string | null;
  payment_method?: PaymentMethod | null;
  payment_description?: string | null;
  pricing_package?: RegistrationPricingPackage | null;
  user: UserAccount | null;
  business: Business | null;
  location_count: number | null;
  identity_token?: string | null;
}

const initialState: RegistrationState = {
  registration_key: null,
  pending_registration_key: null,
  partner_id: null,
  pricing_package: null,
  user: null,
  business: null,
  location_count: null,
};

export const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setPendingRegistrationKey: (state, action: PayloadAction<string | null>) => {
      state.pending_registration_key = action.payload;
    },
    setRegistrationKey: (state, action: PayloadAction<string | null>) => {
      state.registration_key = action.payload;
    },
    setPartnerId: (state, action: PayloadAction<string | null>) => {
      state.partner_id = action.payload;
    },
    setPaymentMethod: (state, action: PayloadAction<PaymentMethod | null>) => {
      state.payment_method = action.payload;
    },
    setPaymentDescription: (state, action: PayloadAction<string | null | undefined>) => {
      state.payment_description = action.payload;
    },
    setPricingPackage: (state, action: PayloadAction<RegistrationPricingPackage | null | undefined>) => {
      state.pricing_package = action.payload;
    },
    setUser: (state, action: PayloadAction<UserAccount | null>) => {
      state.user = action.payload;
    },
    setLocationCount: (state, action: PayloadAction<number | null>) => {
      state.location_count = action.payload;
    },
    setBusiness: (state, action: PayloadAction<Business | null>) => {
      state.business = action.payload;
    },
    setIdentityToken: (state, action: PayloadAction<string | null>) => {
      state.identity_token = action.payload;
    },
  },
});

export const {
  setPendingRegistrationKey,
  setRegistrationKey,
  setPartnerId,
  setPaymentMethod,
  setPaymentDescription,
  setPricingPackage,
  setUser,
  setLocationCount,
  setBusiness,
  setIdentityToken,
} = registrationSlice.actions;
export default registrationSlice.reducer;
