import { PricingPackageUnitType } from "../../../constants";
import { PricingPackage } from "../../../data/payments";
import { formatMoney, toSentence } from "../../../utils/format";

type PaymentConfirmationProps = {
  pricingPackage: PricingPackage;
  locationCount: number;
};

function PaymentConfirmation({ pricingPackage, locationCount }: PaymentConfirmationProps) {
  if (pricingPackage == null) {
    return <div />;
  }
  let setupFeeAmount = 0;
  let initialPaymentAmount = 0;
  let perConnectionPaymentAmount = 0;
  switch (pricingPackage.unit_type) {
    case PricingPackageUnitType.Connection:
      setupFeeAmount = pricingPackage.setup_fee ?? 0;
      perConnectionPaymentAmount = pricingPackage.unit_price!;
      break;
    case PricingPackageUnitType.Location:
      setupFeeAmount = pricingPackage.setup_fee ?? 0 * locationCount;
      initialPaymentAmount = pricingPackage.unit_price! * locationCount;
      break;
    case PricingPackageUnitType.Company:
      setupFeeAmount = pricingPackage.setup_fee ?? 0;
      initialPaymentAmount = pricingPackage.unit_price!;
      break;
    default:
  }
  const totalAmount = setupFeeAmount + initialPaymentAmount;
  const paymentItems = [];
  if (setupFeeAmount > 0) {
    paymentItems.push(`a one-time setup fee of ${formatMoney(setupFeeAmount)}`);
  }
  if (initialPaymentAmount > 0) {
    paymentItems.push(
      `an initial payment of ${formatMoney(initialPaymentAmount)} for the first ${pricingPackage.term} days`
    );
  }
  if (perConnectionPaymentAmount > 0) {
    paymentItems.push(
      `${formatMoney(perConnectionPaymentAmount)} for each seeker connection that is accepted in the future`
    );
  }

  return (
    <div className="text-center">
      <div>You will be charged {toSentence(paymentItems)}</div>
      {totalAmount > 0 ? (
        <div>{`The total amount due to activate the location(s) is ${formatMoney(totalAmount)}`}</div>
      ) : (
        <div>There is no payment due to activate the location(s), but you will need to provide a credit card</div>
      )}
    </div>
  );
}

export default PaymentConfirmation;
