import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface AuthState {
  loggedIn: boolean;
  authHeader: string | null;
}

const initialState: AuthState = {
  loggedIn: false,
  authHeader: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<{ authHeader: string }>) => {
      state.loggedIn = true;
      state.authHeader = action.payload.authHeader;
    },
    logout: (state) => {
      state.loggedIn = false;
      state.authHeader = null;
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
