import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, PropsWithChildren, ReactNode, useEffect, useRef } from "react";
import { Link, To } from "react-router-dom";
import { classNames } from "../utils/classNameList";
import { scrollIntoView } from "../utils/scroll";
import "./List.scss";

// TODO - refactor this to separate List and GroupList components?

type ListGroupBase<TItem> = {
  items: TItem[];
};

type ListPropsWithItems<TItem, TSelectedItemRef> = BaseListProps & {
  items: TItem[];
  selectedIndex?: number;
  renderItem: (args: {
    item: TItem;
    index: number;
    selected: boolean;
    ref?: React.LegacyRef<TSelectedItemRef>;
  }) => ReactNode;
};

type ListPropsWithGroups<TItem, TGroup> = BaseListProps & {
  groups: TGroup[];
  renderGroupHeader: (params: { group: TGroup; index: number }) => ReactNode;
  renderGroupItem: (args: { item: TItem; index: number; group: TGroup }) => ReactNode;
};

type BaseListProps = {
  className?: string;
  style?: CSSProperties;
};

type ListProps<TItem, TGroup extends ListGroupBase<TItem>, TSelectedItemRef extends HTMLElement> =
  | ListPropsWithItems<TItem, TSelectedItemRef>
  | ListPropsWithGroups<TItem, TGroup>;

function List<
  TItem = unknown,
  TGroup extends ListGroupBase<TItem> = any,
  TSelectedItemRef extends HTMLElement = HTMLDivElement
>(props: ListProps<TItem, TGroup, TSelectedItemRef>) {
  const { className, style } = props;
  const selectedItemRef = useRef<TSelectedItemRef>(null);
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if ("items" in props && "selectedIndex" in props) {
      if (selectedItemRef.current && listRef.current) {
        scrollIntoView(selectedItemRef.current, listRef.current!.parentElement!);
      }
    }
  }, [props]);

  return (
    <div className={classNames("list", className)} style={style} ref={listRef}>
      {"groups" in props ? (
        <React.Fragment>
          {props.groups.map((group: TGroup, index: number) => (
            <React.Fragment key={`group-${index}`}>
              {props.renderGroupHeader({ group, index })}
              {group.items.map((item, index) => (
                <React.Fragment key={`item-${index}`}>{props.renderGroupItem({ group, item, index })}</React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {props.items.map((item: TItem, index: number) => {
            const selected = index === props.selectedIndex;
            return (
              <React.Fragment key={`item-${index}`}>
                {props.renderItem({ item, index, selected, ref: selected ? selectedItemRef : undefined })}
              </React.Fragment>
            );
          })}
        </React.Fragment>
      )}
    </div>
  );
}

type ListItemProps = PropsWithChildren & {
  className?: string;
  accessoryType?: "details";
  onClick?: () => void;
  linkTo?: To;
};

export function ListItem({ children, className, accessoryType, onClick, linkTo }: ListItemProps) {
  const item = (
    <div className={classNames("list-item", className)} onClick={onClick} role={onClick && "button"}>
      {children}
      {accessoryType === "details" && (
        <div className="list-item-decorator">
          <FontAwesomeIcon icon={faCaretRight} />
        </div>
      )}
    </div>
  );
  if (linkTo) {
    return (
      <Link to={linkTo} className="no-decoration">
        {item}
      </Link>
    );
  } else {
    return item;
  }
}

type ListItemPartProps = PropsWithChildren & {
  className?: string;
};

function ListItemLeft({ children, className }: ListItemPartProps) {
  return <div className={classNames("list-item-left", className)}>{children}</div>;
}

function ListItemContent({ children, className }: ListItemPartProps) {
  return <div className={classNames("list-item-content", className)}>{children}</div>;
}

function ListItemRight({ children, className }: ListItemPartProps) {
  return <div className={classNames("list-item-right", className)}>{children}</div>;
}

ListItem.Left = ListItemLeft;
ListItem.Content = ListItemContent;
ListItem.Right = ListItemRight;

export function ListGroupHeader({ children, className }: ListItemPartProps) {
  return <div className={classNames("list-group-header", className)}>{children}</div>;
}

export default List;
