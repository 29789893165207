import { PaymentMethod } from "@stripe/stripe-js";
import { ErrorReasonCode } from "../constants";
import { PaymentPackage, PricingPackage } from "../data/payments";
import * as LocationsState from "../reducers/locations";
import * as PaymentsState from "../reducers/payments";
import * as Api from "../rest-client/api";
import { FailedRequestError } from "../rest-client/errors";
import { StripeCardError } from "../utils/errors";
import { AppAsyncAction } from "./types";

export function loadPaymentPackages(): AppAsyncAction<PaymentPackage[]> {
  return (dispatch) => {
    return Api.loadPaymentPackages().then(({ responseJson }) => {
      dispatch(PaymentsState.setPaymentPackages(responseJson));
      return responseJson;
    });
  };
}

export function loadPaymentMethods(): AppAsyncAction<PaymentMethod[]> {
  return (dispatch) => {
    return Api.loadPaymentMethods().then(({ responseJson }) => {
      dispatch(PaymentsState.setPaymentMethods(responseJson));
      return responseJson;
    });
  };
}

export function loadPricingPackages(): AppAsyncAction<PricingPackage[]> {
  return (dispatch) => {
    return Api.loadPricingPackages().then(({ responseJson }) => {
      dispatch(PaymentsState.setPricingPackages(responseJson));
      return responseJson;
    });
  };
}

function paymentErrorFromResponse(error: FailedRequestError) {
  if (error.responseJson) {
    if (error.responseJson.reason === ErrorReasonCode.StripeCardError) {
      return new StripeCardError(error.responseJson.details, error.responseJson.param_name);
    }
  }
  return error;
}

export type CreatePaymentPackageArgs = {
  locationIds: number[];
  pricingPackageId: number;
} & (
  | {
      stripeToken?: string;
    }
  | {
      paymentMethodId?: string;
    }
);

export function createPaymentPackage(args: CreatePaymentPackageArgs): AppAsyncAction<PaymentPackage> {
  return (dispatch) => {
    const { locationIds, pricingPackageId } = args;
    const stripeToken = 'stripeToken' in args ? args.stripeToken : undefined;
    const paymentMethodId = 'paymentMethodId' in args ? args.paymentMethodId : undefined;
  
    return Api.createPaymentPackage({ locationIds, pricingPackageId, stripeToken, paymentMethodId })
      .then(({ responseJson }) => {
        dispatch(PaymentsState.paymentPackageCreated(responseJson));
        if (stripeToken != null) {
          dispatch(PaymentsState.paymentMethodAdded(responseJson.payer.payment_method));
        }
        dispatch(
          LocationsState.paymentPackageCreated({
            affectedLocationIds: responseJson.location_ids ?? locationIds,
            package: responseJson,
          })
        );
        return responseJson;
      })
      .catch((error) => {
        if (error instanceof FailedRequestError && error.isUnprocessableEntity()) {
          throw paymentErrorFromResponse(error);
        }
        throw error;
      });
  };
}

export type UpdatePaymentPackageArgs = {
  packageId: number;
  stripeToken?: string;
  paymentMethodId?: string;
};

export function updatePaymentPackage({
  packageId,
  paymentMethodId,
  stripeToken,
}: UpdatePaymentPackageArgs): AppAsyncAction<PaymentPackage> {
  return (dispatch) => {
    return Api.updatePaymentPackage({ packageId, paymentMethodId, stripeToken })
      .then(({ responseJson }) => {
        dispatch(PaymentsState.paymentPackageUpdated(responseJson));
        if (stripeToken != null) {
          dispatch(PaymentsState.paymentMethodAdded(responseJson.payer.payment_method));
        }
        return responseJson;
      })
      .catch((error) => {
        throw paymentErrorFromResponse(error);
      });
  };
}
