import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Activity } from "../data/activity";

export interface ActivityState {
  activities: Activity[] | null;
}

const initialState: ActivityState = {
  activities: null,
}

export const activitiesSlice = createSlice({
  name: "activities",
  initialState,
  reducers: {
    setActivities: (state, action: PayloadAction<Activity[] | null>) => {
      state.activities = action.payload;
    },
    markActivitiesRead: (state) => {
      if (state.activities) {
        state.activities = state.activities.map(a => ({ ...a, read: true }));
      }
    }
  },
});

export const { setActivities, markActivitiesRead } = activitiesSlice.actions;
export default activitiesSlice.reducer;
