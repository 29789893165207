import { CompanyLocationBasic } from "../data/companyLocation";
import BaseRestClient from "./baseClient";

export function loadLocations() {
  return BaseRestClient.fetch('api/v3/employer/locations');
}

export function updateLocation(locationId: number, attributes: CompanyLocationBasic) {
  return BaseRestClient.patch(
    `api/v3/employer/locations/${locationId}`,
    {
      location: attributes
    }
  );
}

export function createLocation(attributes: CompanyLocationBasic, confirmedCostAmount?: number) {
  return BaseRestClient.post(
    'api/v3/employer/locations',
    {
      location: {
        ...attributes,
        confirmed_cost_amount: confirmedCostAmount
      }
    }
  );
}

export function fetchLocationActivityStats() {
  return BaseRestClient.fetch('api/v3/employer/locations/activity_stats');
}
