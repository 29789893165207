import { ErrorReasonCode } from "../constants";
import { ConnectionSearchResults, ConnectionWithFullSeekerProfile, ConnectionWithSeekerExperienceAndJobs } from "../data/connection";
import { ConnectionFilters } from "../data/miscellaneous";
import {
  connectionSearch as connectionSearchReducer,
  connectionUpdated,
  searchIsStale,
  setSearchInProgress,
} from "../reducers/connections";
import * as Api from "../rest-client/api";
import { JsonMap } from "../types/json";
import { AcceptConnectionError } from "../utils/errors";
import { AppAction, AppAsyncAction } from "./types";

export function connectionSearch(filters: ConnectionFilters): AppAsyncAction<ConnectionSearchResults> {
  return (dispatch) => {
    dispatch(setSearchInProgress(true));
    return Api.connectionSearch(filters)
      .then(({ responseJson }) => {
        dispatch(connectionSearchReducer({ filters, results: responseJson }));
        return responseJson;
      })
      .finally(() => {
        dispatch(setSearchInProgress(false));
      });
  };
}

export function getConnectionsForLocation(locationId: number): AppAsyncAction<ConnectionWithSeekerExperienceAndJobs[]> {
  return () => {
    return Api.fetchConnectionsForLocation(locationId).then(({ responseJson }) => responseJson);
  };
}

export function getConnectionById(connectionId: number): AppAsyncAction<ConnectionWithFullSeekerProfile> {
  return () => {
    return Api.fetchConnectionById(connectionId).then(({ responseJson }) => responseJson);
  };
}

export function updateConnectionById(connectionId: number, reviewed: boolean, interestLevel: number): AppAsyncAction<ConnectionWithFullSeekerProfile> {
  return (dispatch) => {
    return Api.updateConnection(connectionId, reviewed, interestLevel).then(({ responseJson }) => {
      dispatch(connectionUpdated(responseJson));
      return responseJson;
    });
  };
}

export function setConnectionSearchIsStale(isStale: boolean): AppAction<void> {
  return (dispatch) => {
    dispatch(searchIsStale(isStale));
  };
}

export function acceptConnection(connectionId: number): AppAsyncAction<ConnectionWithFullSeekerProfile> {
  return (dispatch) => {
    return Api.acceptConnection(connectionId)
      .then(({ responseJson }) => {
        dispatch(connectionUpdated(responseJson));
        return responseJson;
      })
      .catch(({ error, responseJson }) => {
        throw errorFromAcceptResponse({ error, responseJson });
      });
  };
}

export function declineConnection(connectionId: number): AppAsyncAction<ConnectionWithFullSeekerProfile> {
  return (dispatch) => {
    return Api.declineConnection(connectionId).then(({ responseJson }) => {
      dispatch(connectionUpdated(responseJson));
      return responseJson;
    });
  };
}

function errorFromAcceptResponse({ error, responseJson }: { error: Error; responseJson: JsonMap }): Error {
  if (responseJson) {
    if ("reason" in responseJson) {
      switch (responseJson.reason) {
        case ErrorReasonCode.ModelNotUnique:
        case ErrorReasonCode.ModelInvalidState:
          return new AcceptConnectionError(
            "Connection is already approved",
            AcceptConnectionError.ReasonCodes.AlreadyApproved
          );
        case ErrorReasonCode.PaymentMethodMissing:
          return new AcceptConnectionError(
            "A payment method does not exist",
            AcceptConnectionError.ReasonCodes.PaymentMethodMissing
          );
        case ErrorReasonCode.StripeCardError:
          return new AcceptConnectionError(
            "The existing credit card was declined",
            AcceptConnectionError.ReasonCodes.PaymentDeclined
          );
        default:
          return new Error(`An unexpected error occurred: [${responseJson.reason}] ${responseJson.details}`);
      }
    } else if ("error" in responseJson) {
      return new Error("An unexpected error occurred: " + responseJson.error);
    } else if ("message" in error) {
      return new Error("An unexpected error occurred: " + error.message);
    }
  }
  return new Error("An unexpected error occurred");
}
