import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties } from "react";

type IconButtonProps = { 
  icon: IconProp; 
  size?: number; 
  onClick: () => void; 
  className?: string; 
  style?: CSSProperties 
};

function IconButton({ icon, size = 1, onClick, className, style }: IconButtonProps) {
  const radius = Math.fround(size * 0.75);
  return (
    <div
      className={className}
      style={{
        ...(style ?? {}),
        fontSize: `${size}rem`,
        borderRadius: `${radius}rem`,
        textAlign: "center",
        height: `${size * 1.5}rem`,
        width: `${size * 1.5}rem`,
        lineHeight: `${size * 1.5}rem`,
        cursor: "pointer",
      }}
      role="button"
      onClick={(event) => {
        event.preventDefault();
        onClick();
      }}
    >
      <FontAwesomeIcon icon={icon} />
    </div>
  );
}

export default IconButton;
