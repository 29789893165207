import { Button, Modal } from "react-bootstrap";
import {
  CustomAction,
  CustomActionFailureReason,
  CustomActionResult,
  CustomActionStatus,
} from "../../data/customActions";
import { useCallback, useMemo } from "react";
import { ButtonSpinnerOverlay } from "../../components/ButtonSpinnerOverlay";

type CustomActionModalProps = {
  visible?: boolean;
  onClosed: () => void;
  executeActionClicked: (action: CustomAction) => void;
  customAction: CustomAction;
  actionResult?: CustomActionResult;
  refreshing: boolean;
};

export function CustomActionModal({
  visible,
  onClosed,
  executeActionClicked,
  customAction,
  actionResult,
  refreshing,
}: CustomActionModalProps) {
  const onExecuteActionClicked = useCallback(() => {
    executeActionClicked(customAction);
  }, [customAction, executeActionClicked]);
  const body = useMemo(() => {
    switch (actionResult?.status) {
      case CustomActionStatus.Completed:
        return (
          <>
            <p>{actionResult.details?.message}</p>
            {actionResult.details?.external_link && (
              <>
                <p>{actionResult.details?.link_message}</p>
                <a href={actionResult.details.external_link} target="_blank" rel="noreferrer">
                  {actionResult.details.link_title}
                </a>
              </>
            )}
          </>
        );
      case CustomActionStatus.Started:
        return <p>This action has been started, awaiting results.</p>;
      case undefined:
      case CustomActionStatus.NotStarted:
        return (
          <>
            <p>This action has not yet been started.</p>
            <p>{customAction.description}</p>
          </>
        );
      case CustomActionStatus.Failed: {
        let failureReason = "An unexpected error occurred";
        switch (actionResult.details?.failure_reason) {
          case CustomActionFailureReason.Authorization:
            failureReason = "An authorization error occurred";
            break;
          case CustomActionFailureReason.Configuration:
            failureReason = "A configuration error occurred";
            break;
          default:
        }
        return (
          <>
            <p>This action failed:</p>
            <p>{failureReason}</p>
          </>
        );
      }
    }
  }, [customAction, actionResult]);

  return (
    <Modal show={visible} onHide={onClosed}>
      <Modal.Header closeButton>
        <Modal.Title>{customAction.label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <div className="position-relative">
          <Button onClick={onExecuteActionClicked} disabled={actionResult && !actionResult.can_resubmit}>
            {actionResult?.status === CustomActionStatus.Failed && actionResult.can_resubmit ? "Retry" : "Start"}
          </Button>
          {refreshing && <ButtonSpinnerOverlay />}
        </div>
        <Button variant="secondary" onClick={onClosed}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
