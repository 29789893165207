import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import {
  createPaymentPackage,
  CreatePaymentPackageArgs,
  loadPaymentMethods,
  loadPricingPackages,
} from "../../actions/payments";
import { AppDispatch, RootState } from "../../app/store";
import AppToast from "../../components/AppToast";
import LoadingOverlay from "../../components/LoadingOverlay";
import { CardPaymentMethod, PricingPackage } from "../../data/payments";
import AddPaymentMethod, { AddPaymentMethodRef } from "./fragments/AddPaymentMethod";
import LocationsChooser from "./fragments/LocationsChooser";
import PaymentConfirmation from "./fragments/PaymentConfirmation";
import PaymentMethodsChooser from "./fragments/PaymentMethodChooser";
import PricingPackageChooser from "./fragments/PricingPackageChooser";

enum Steps {
  Pricing = "pricing",
  Locations = "locations",
  Confirmation = "confirmation",
  PaymentMethod = "payment-method",
  AddPaymentMethod = "add-payment-method",
}

type ModalBodyProps = PropsWithChildren & {
  title: string;
  subtitle?: string;
};

const ModalBody = ({ title, subtitle, children }: ModalBodyProps) => (
  <div className="d-flex flex-column">
    <div className="bg-primary bg-gradient text-white text-center p-3">
      <div className="fs-5 fw-semibold">{title}</div>
      {subtitle && <div style={{ whiteSpace: "pre-line" }}>{subtitle}</div>}
    </div>
    <div className="p-3">{children}</div>
  </div>
);

type NewPaymentPackageModalProps = NewPaymentPackageModalConnectedProps & {
  visible?: boolean;
  locationId?: number;
  onClosed: (success: boolean) => void;
};

type StepDetails = {
  nextEnabled: boolean | (() => boolean);
  finishEnabled?: boolean | (() => boolean);
  showFinish: boolean;
  component: React.ReactNode;
  finish?: () => void;
};

const NewPaymentPackageModal = ({
  visible = true,
  createPaymentPackage,
  locationId,
  unpaidLocations,
  paymentMethods,
  pricingPackages,
  loadPricingPackages,
  loadPaymentMethods,
  onClosed,
}: NewPaymentPackageModalProps) => {
  const loadingPricingPackages = useRef(false);
  const loadingPaymentMethods = useRef(false);

  const [updating, setUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [locationIds, setLocationIds] = useState(locationId ? [locationId] : unpaidLocations.map((l) => l.id));
  const [pricingPackage, setPricingPackage] = useState<PricingPackage | undefined>(
    pricingPackages && pricingPackages.length === 1 ? pricingPackages[0].packages[0] : undefined
  );
  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null);
  const [currentStep, setCurrentStep] = useState<Steps | null>(null);
  const addPaymentMethodFragmentRef = useRef<AddPaymentMethodRef | null>(null);

  // determine the available steps based on the props
  const allSteps = useMemo(() => {
    if (pricingPackages == null || paymentMethods == null) return [];
    const _steps = [];
    if (locationId == null) {
      _steps.push(Steps.Locations);
    }
    _steps.push(Steps.Pricing);
    _steps.push(Steps.Confirmation);
    if (paymentMethods.length > 0) {
      _steps.push(Steps.PaymentMethod);
    }
    _steps.push(Steps.AddPaymentMethod);
    return _steps;
  }, [pricingPackages, paymentMethods, locationId]);

  const handleClose = useCallback(
    (success: boolean) => {
      onClosed(success);
    },
    [onClosed]
  );

  useEffect(() => {
    if (allSteps != null) {
      setCurrentStep(allSteps[0]);
    }
  }, [pricingPackages, paymentMethods, locationId, allSteps]);

  const createPackageHandler = useCallback(
    (tokenId?: string, errorHandler?: (error: Error) => void) => {
      setUpdating(true);
      const createPackagePromise = paymentMethodId
        ? createPaymentPackage({
            locationIds,
            pricingPackageId: pricingPackage!.id,
            paymentMethodId,
          })
        : createPaymentPackage({
            locationIds,
            pricingPackageId: pricingPackage!.id,
            stripeToken: tokenId,
          });
      createPackagePromise
        .then(() => {
          AppToast.show({ text: "Thanks for your payment!", type: "success" });
          handleClose(true);
        })
        .catch((error) => {
          if (errorHandler) {
            errorHandler.call(null, error);
          } else {
            setErrorMessage(error.message);
          }
        })
        .finally(() => setUpdating(false));
      return createPackagePromise;
    },
    [pricingPackage, locationIds, paymentMethodId, createPaymentPackage, handleClose]
  );

  const setNextStep = useCallback(() => {
    const currentStepIndex = allSteps.indexOf(currentStep!);
    if (currentStepIndex < allSteps.length - 1) {
      setCurrentStep(allSteps[currentStepIndex + 1]);
    }
  }, [allSteps, currentStep, setCurrentStep]);

  const setPreviousStep = useCallback(() => {
    const currentStepIndex = allSteps.indexOf(currentStep!);
    if (currentStepIndex > 0) {
      setCurrentStep(allSteps[currentStepIndex - 1]);
    }
  }, [allSteps, currentStep, setCurrentStep]);

  const getCurrentStep = useCallback((): StepDetails | null => {
    if (pricingPackages == null || paymentMethods == null) return null;
    switch (currentStep) {
      case Steps.Pricing:
        return {
          nextEnabled: pricingPackage != null,
          showFinish: false,
          component: (
            <ModalBody
              title="Choose Your Plan"
              subtitle={
                locationId
                  ? "This location doesn't have an active payment method.\nPlease choose a pricing package to connect with seekers."
                  : undefined
              }
            >
              <PricingPackageChooser
                packages={pricingPackages}
                selectedPackage={pricingPackage}
                onSelect={(pricingPackage: PricingPackage) => {
                  setPricingPackage(pricingPackage);
                  setNextStep();
                }}
              />
            </ModalBody>
          ),
        };
      case Steps.Locations:
        return {
          nextEnabled: () => locationIds != null && locationIds.length > 0,
          showFinish: false,
          component: (
            <ModalBody title="Choose the location(s) to activate">
              <LocationsChooser
                locations={unpaidLocations}
                selectedIds={locationIds}
                onChange={(locationIds: number[]) => setLocationIds(locationIds)}
              />
            </ModalBody>
          ),
        };
      case Steps.Confirmation:
        return {
          nextEnabled: true,
          showFinish: false,
          component: (
            <ModalBody title="Confirm Payment">
              <PaymentConfirmation pricingPackage={pricingPackage!} locationCount={locationIds.length} />
            </ModalBody>
          ),
        };
      case Steps.PaymentMethod:
        return {
          nextEnabled: false,
          showFinish: true,
          finishEnabled: () => paymentMethodId != null,
          finish: () => {
            createPackageHandler();
          },
          component: (
            <ModalBody title="Select a Credit Card">
              <PaymentMethodsChooser
                paymentMethods={paymentMethods}
                onSelect={(paymentMethod: CardPaymentMethod) => {
                  setPaymentMethodId(paymentMethod.id);
                }}
                onAddCardClick={() => setNextStep()}
              />
            </ModalBody>
          ),
        };
      case Steps.AddPaymentMethod:
        return {
          nextEnabled: false,
          showFinish: true,
          finishEnabled: true,
          finish: () => {
            addPaymentMethodFragmentRef.current!.submit().then((tokenId) => {
              if (tokenId) {
                createPackageHandler(tokenId, (error) => {
                  addPaymentMethodFragmentRef.current!.setPaymentError(error);
                });
              }
            });
          },
          component: (
            <ModalBody title="Add a Credit Card">
              <AddPaymentMethod ref={addPaymentMethodFragmentRef} />
            </ModalBody>
          ),
        };
      default:
        return null;
    }
  }, [
    currentStep,
    pricingPackages,
    paymentMethods,
    setPaymentMethodId,
    paymentMethodId,
    pricingPackage,
    setPricingPackage,
    locationIds,
    setLocationIds,
    createPackageHandler,
    locationId,
    setNextStep,
    unpaidLocations,
  ]);
  useEffect(() => {
    if (pricingPackages == null) {
      if (!loadingPricingPackages.current) {
        loadingPricingPackages.current = true;
        loadPricingPackages().finally(() => (loadingPricingPackages.current = false));
      }
    }
  }, [pricingPackages, loadPricingPackages]);

  useEffect(() => {
    if (paymentMethods == null) {
      if (!loadingPaymentMethods.current) {
        loadingPaymentMethods.current = true;
        loadPaymentMethods().finally(() => (loadingPaymentMethods.current = false));
      }
    }
  }, [paymentMethods, loadPaymentMethods]);

  const step = getCurrentStep();
  let nextEnabled = false,
    showFinish = false,
    finishEnabled = false;
  const nextClick: () => void = setNextStep;
  if (step) {
    if (step.nextEnabled instanceof Function) {
      nextEnabled = step.nextEnabled.call(this);
    } else {
      nextEnabled = step.nextEnabled;
    }
    showFinish = step.showFinish;
    if (showFinish) {
      if (step.finishEnabled instanceof Function) {
        finishEnabled = step.finishEnabled.call(this);
      } else {
        finishEnabled = step.finishEnabled!;
      }
    }
  }

  return (
    <Modal show={visible} onHide={() => handleClose(false)} scrollable={true} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Activate Location(s)</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        {step?.component ?? (
          <div style={{ height: "200px" }}>
            <LoadingOverlay />
          </div>
        )}
        {errorMessage && (
          <Alert variant="danger" dismissible={true}>
            {errorMessage}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClosed(false)}>
          Cancel
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            if (allSteps.indexOf(currentStep!) === 0) {
              handleClose(false);
            } else {
              setPreviousStep();
            }
          }}
        >
          Back
        </Button>
        {showFinish ? (
          <Button
            variant="primary"
            disabled={!finishEnabled || updating}
            onClick={() => {
              step!.finish!.call(this);
            }}
          >
            {updating ? "Activating..." : "Activate"}
          </Button>
        ) : (
          <Button variant="secondary" onClick={nextClick} disabled={!nextEnabled}>
            Next
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    pricingPackages: state.payments.pricingPackages,
    paymentMethods: state.payments.paymentMethods,
    unpaidLocations: state.locations.locations?.filter((l) => l.payment_package_id == null) ?? [],
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    loadPricingPackages: () => dispatch(loadPricingPackages()),
    loadPaymentMethods: () => dispatch(loadPaymentMethods()),
    createPaymentPackage: (args: CreatePaymentPackageArgs) => dispatch(createPaymentPackage(args)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type NewPaymentPackageModalConnectedProps = ConnectedProps<typeof connector>;

export default connector(NewPaymentPackageModal);
