import React, { useCallback } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { SelectField, TextField } from "../../components/Forms";
import { ADDRESS_STATE_NAMES } from "../../constants";

import "./ProfilePage.scss";
import { ModelValidationErrors } from "../../data/validation";

type ContactInformationFormProps = {
  contactInfo: any;
  onUpdate: (contact: any) => void;
  validationErrors?: ModelValidationErrors;
};
function ContactInformationForm({ contactInfo, onUpdate, validationErrors }: ContactInformationFormProps) {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      onUpdate({ [event.target.name]: event.target.value });
    },
    [onUpdate]
  );

  return (
    <Form>
      <Row>
        <Col lg={6} md={12}>
          <TextField
            value={contactInfo.first_name}
            attributeName="first_name"
            label="First Name"
            validationErrors={validationErrors?.first_name}
            onChange={handleChange}
          />
        </Col>
        <Col lg={6} md={12}>
          <TextField
            value={contactInfo.last_name}
            attributeName="last_name"
            label="Last Name"
            validationErrors={validationErrors?.last_name}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            value={contactInfo.phone}
            attributeName="phone"
            label="Phone Number"
            validationErrors={validationErrors?.phone}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            value={contactInfo.address_1}
            attributeName="address_1"
            label="Address"
            validationErrors={validationErrors?.address_1}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            value={contactInfo.address_2}
            attributeName="address_2"
            label="Address Line 2"
            validationErrors={validationErrors?.address_2}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            value={contactInfo.address_city}
            attributeName="address_city"
            label="City"
            validationErrors={validationErrors?.address_city}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={8} md={12}>
          <SelectField
            value={contactInfo.address_state}
            attributeName="address_state"
            label="State"
            validationErrors={validationErrors?.address_state}
            onChange={handleChange}
            items={["", ...ADDRESS_STATE_NAMES]}
          />
        </Col>
        <Col lg={4} md={12}>
          <TextField
            value={contactInfo.address_zip}
            attributeName="address_zip"
            label="Zip Code"
            validationErrors={validationErrors?.address_zip}
            onChange={handleChange}
          />
        </Col>
      </Row>
    </Form>
  );
}

export default ContactInformationForm;
