import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SelectCallback } from "@restart/ui/types";
import { Dropdown } from "react-bootstrap";
import { CardPaymentMethod } from "../../data/payments";
import { cardIcon } from "../../utils/creditCard";

type CreditCardSelectorProps = {
  cards: CardPaymentMethod[];
  label?: string;
  onCardSelected: (cardId: string) => void;
  onNewCardSelected: () => void;
  className?: string;
};

function CreditCardSelector({
  cards,
  label = "Select Card",
  onCardSelected,
  onNewCardSelected,
  className,
}: CreditCardSelectorProps) {
  const handleSelect : SelectCallback = (eventKey: string | null) => {
    if (eventKey === "new-card") {
      onNewCardSelected();
    } else {
      onCardSelected(eventKey!);
    }
  };
  return (
    <Dropdown onSelect={handleSelect} className={className}>
      <Dropdown.Toggle variant="primary">{label}</Dropdown.Toggle>
      <Dropdown.Menu>
        {cards &&
          cards.map((card) => (
            <Dropdown.Item eventKey={card.id} key={`card-${card.id}`}>
              <FontAwesomeIcon icon={cardIcon(card.brand)} className="fs-4 me-3" />
              {`${card.brand} ending with ${card.last4}, expires ${card.expiration_month}/${card.expiration_year}`}
            </Dropdown.Item>
          ))}
        {cards != null && cards.length > 0 && <Dropdown.Divider />}
        <Dropdown.Item eventKey="new-card">Add a new card...</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default CreditCardSelector;
