import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import memoizeOne from "memoize-one";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import { getConnectionsForLocation } from "../../actions/connections";
import { AppDispatch, RootState } from "../../app/store";
import { ListItem } from "../../components/List";
import LoadingOverlay from "../../components/LoadingOverlay";
import Map from "../../components/Map";
import { CompanyLocationJob } from "../../data/companyLocation";
import { ConnectionWithSeekerExperienceAndJobs } from "../../data/connection";
import { AdminScope } from "../../data/user";
import { locationAddress } from "../../utils/format";
import { FollowOnLinkedInSidePanel } from "../common/FollowOnLinkedSidePanel";
import { CONNECTION_CATEGORIES } from "../connections/constants";
import { connectionFiltersForCategory } from "../connections/filters";
import { SearchParams } from "../connections/routes";
import AddJobModal from "./AddJobModal";
import EditLocationModal from "./EditLocationModal";
import "./Locations.scss";

type ConnectionsByJobAndCategoryCounts = { [key: number]: { [key: number]: number } };

const connectionCountsByJobAndCategory = memoizeOne(
  (jobs: CompanyLocationJob[], connections: ConnectionWithSeekerExperienceAndJobs[]) => {
    const jobsMap: ConnectionsByJobAndCategoryCounts = {};
    jobs?.forEach((job) => {
      const jobConnections = connections.filter((connection) => connection.job_ids.includes(job.id));
      const categoriesCount: { [key: number]: number } = {};
      jobsMap[job.id] = categoriesCount;
      CONNECTION_CATEGORIES.forEach((category, index) => {
        const matcher = connectionFiltersForCategory(category.filterKey)!.matcher;
        categoriesCount[index] = jobConnections.filter((connection) => matcher.call(null, connection)).length;
      });
    });
    return jobsMap;
  }
);

type LocationProps = LocationConnectedProps & {
  locationId: number;
};

const Location = ({ locationId, managedLocations, fetchConnectionsForLocation, canManageLocations }: LocationProps) => {
  const [loading, setLoading] = useState(false);
  const [connectionsForLocation, setConnectionsForLocation] = useState<ConnectionWithSeekerExperienceAndJobs[] | null>(
    null
  );
  const locationDataLoading = useRef<boolean>();
  const [locationModalVisible, setLocationModalVisible] = useState(false);
  const [addJobModalVisible, setAddJobModalVisible] = useState(false);
  const [addJobModalKey, setAddJobModalKey] = useState<string | undefined>();

  const location = useMemo(
    () => (locationId ? managedLocations.find((l) => l.id === locationId)! : managedLocations[0]),
    [managedLocations, locationId]
  );

  const fetchLocationData = useCallback(
    (locationId: number) => {
      setConnectionsForLocation(null);
      setLoading(true);
      fetchConnectionsForLocation(locationId)
        .then((connections) => setConnectionsForLocation(connections))
        .finally(() => {
          setLoading(false);
          locationDataLoading.current = false;
        });
    },
    [setLoading, fetchConnectionsForLocation, setConnectionsForLocation]
  );

  useEffect(() => {
    if (!locationDataLoading.current) {
      locationDataLoading.current = true;
      fetchLocationData(locationId);
    }
  }, [locationId, fetchLocationData]);

  const editLocation = useCallback(() => setLocationModalVisible(true), [setLocationModalVisible]);

  const addJobClicked = useCallback(() => {
    setAddJobModalKey(`add-job-${new Date().getTime}`);
    setAddJobModalVisible(true);
  }, [setAddJobModalKey, setAddJobModalVisible]);

  const addJobModalClosed = useCallback(() => {
    setAddJobModalVisible(false);
  }, [setAddJobModalVisible]);

  const connectionCounts = location
    ? connectionCountsByJobAndCategory(location.jobs, connectionsForLocation ?? [])
    : {};

  const locationHasJobs = useMemo(() => location.jobs && location.jobs.length > 0, [location?.jobs]);

  return (
    <div className="h-100 position-relative">
      {loading ? (
        <div className="content-full-height">
          <LoadingOverlay />
        </div>
      ) : (
        <>
          <div className="d-flex flex-row align-items-center bg-light border-bottom border-primary-subtle pb-3 mb-3">
            <div className="d-flex flex-row align-items-baseline flex-grow-1 gap-3">
              <div className="fs-5 text-primary fw-bold">{location.name}</div>
              <div>({locationAddress(location)})</div>
            </div>
            <div className="d-flex flex-row align-items-center gap-2">
              {locationHasJobs && (
                <Button variant="success" onClick={addJobClicked}>
                  Add job
                </Button>
              )}
              {canManageLocations && (
                <Button variant="success" onClick={editLocation}>
                  Edit
                </Button>
              )}
            </div>
          </div>
          <div className="d-flex flex-row gap-3 justify-content-around">
            <div className="d-flex flex-column gap-3" style={{ flex: '1 1' }}>
              {locationHasJobs ? (
                <>
                  {location.jobs?.map((job) => (
                    <Card key={`job-${job.id}`}>
                      <Card.Header className="bg-primary text-white">{job.title}</Card.Header>
                      <Card.Body>
                        <div className="d-flex flex-column gap-2">
                          {CONNECTION_CATEGORIES.map((category, index) => (
                            <Link
                              key={`connections-${job.id}-${index}`}
                              to={{
                                pathname: `/locations/${location.id}/connections`,
                                search: `${SearchParams.Category}=${category.filterKey}&job_id=${job.id}`,
                              }}
                            >
                              <ListItem className="job-submissions-list-item" accessoryType="details">
                                <ListItem.Left>
                                  <FontAwesomeIcon icon={category.icon} />
                                </ListItem.Left>
                                <ListItem.Content>
                                  {connectionCounts[job.id][index]} {category.label} Submissions
                                </ListItem.Content>
                              </ListItem>
                            </Link>
                          ))}
                        </div>
                      </Card.Body>
                    </Card>
                  ))}
                </>
              ) : (
                <div className="text-center d-flex flex-column gap-3">
                  <div>
                    Our seekers can't find you on the network until your add your jobs. Click{" "}
                    <span className="fw-semibold text-dark">'Add job'</span> to start marketing your open positions!
                  </div>
                  <div className="w-auto">
                    <Button onClick={addJobClicked} variant="success">
                      Add job
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <div className="location-map">
              <Map
                center={{ lat: location.location.latitude, lng: location.location.longitude }}
                markerLocation={{ lat: location.location.latitude, lng: location.location.longitude }}
              />
            </div>
            <div className="d-flex flex-column h-100 gap-3 d-md-none d-lg-none d-xl-block" style={{ flex: "0 0 160px" }}>
              <div className="align-self-end flex-grow-0 flex-shrink-0 d-flex flex-column gap-3 w-100">
                <FollowOnLinkedInSidePanel />
              </div>
            </div>
          </div>
          <EditLocationModal
            key={`location-edit-modal-${locationId}`}
            visible={locationModalVisible}
            locationId={locationId}
            onClosed={() => setLocationModalVisible(false)}
          />
          <AddJobModal
            key={addJobModalKey}
            visible={addJobModalVisible}
            locationId={location.id}
            onClosed={addJobModalClosed}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    canManageLocations: state.user.admin_scopes?.includes(AdminScope.location),
    managedLocations: state.locations.locations ?? [],
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchConnectionsForLocation: (locationId: number) => dispatch(getConnectionsForLocation(locationId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type LocationConnectedProps = ConnectedProps<typeof connector>;

export default connect(mapStateToProps, mapDispatchToProps)(Location);
