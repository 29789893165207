import { Activity } from "../data/activity";
import { markActivitiesRead as markActivitiesReadReducer, setActivities } from "../reducers/activities";
import * as Api from "../rest-client/api";
import { AppAsyncAction } from "./types";

export function loadActivities(): AppAsyncAction<Activity[]> {
  return (dispatch) => {
    return Api.fetchActivities().then(({ responseJson }) => {
      dispatch(setActivities(responseJson));
      return responseJson;
    });
  };
}

export function markActivitiesRead() : AppAsyncAction {
  return (dispatch) => {
    return Api.markActivitiesRead().then(() => {
      dispatch(markActivitiesReadReducer());
    })
  };
}