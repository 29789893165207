import { PropsWithChildren, ReactNode, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { classNames } from "../utils/classNameList";
import "./Grid.scss";

type GridProps<T> = {
  items: T[];
  renderItem: (item: T) => ReactNode;
  groupSize?: number;
  className?: string;
};

function Grid<T>({ items, renderItem, groupSize = 1, className }: GridProps<T>) {
  const groupedItems = useMemo(() => {
    if (items.length === 0) {
      return [[]];
    }
    const groupedItems = [];
    let index = 0;
    do {
      const start = index * groupSize;
      const end = start + groupSize;
      if (end > items.length) {
        const group: (T | null)[] = Array.from(items.slice(start, items.length));
        while (group.length < groupSize) {
          group.push(null);
        }
        groupedItems.push(group);
      } else {
        groupedItems.push(items.slice(start, end));
      }
      index++;
    } while (index * groupSize < items.length);
    return groupedItems;
  }, [items, groupSize]);

  return (
    <div className={className}>
      {groupedItems.map((group, groupIndex) => (
        <Row key={`group-${groupIndex}`}>
          {group.map((item, index) => (
            <Col key={`connection-${index}`}>{item ? renderItem(item) : <div />}</Col>
          ))}
        </Row>
      ))}
    </div>
  );
}

type GridItemProps = PropsWithChildren & {
  className?: string;
};

function GridItem({ children, className }: GridItemProps) {
  return <div className={classNames("grid-item", className)}>{children}</div>;
}

function GridItemItemLeft({ children }: PropsWithChildren) {
  return <div className="grid-item-left">{children}</div>;
}

function GridItemItemContent({ children }: PropsWithChildren) {
  return <div className="grid-item-content">{children}</div>;
}

function GridItemItemRight({ children }: PropsWithChildren) {
  return <div className="grid-item-right">{children}</div>;
}

GridItem.Left = GridItemItemLeft;
GridItem.Content = GridItemItemContent;
GridItem.Right = GridItemItemRight;

export default Grid;
