import { DeviceInfo } from "../data/miscellaneous";
import BaseRestClient from "./baseClient";

export function authenticate(userName: string, password: string) {
  return BaseRestClient.post("users/sign_in", {
    user: {
      email: userName,
      password: password,
    },
  }).catch(({ response, responseJson }) => {
    if (response.status === 401) {
      throw new Error("The username and/or password is not correct");
    } else {
      console.error("Login failed", response, responseJson);
      throw new Error("An unexpected error occurred");
    }
  });
}

export function authenticateWithIdentityToken(token: string, partnerId: string) {
  return BaseRestClient.post("users/sign_in_token", {
    identity_token: token,
    identity_provider: partnerId
  });
}

export function getCurrentUser(options = {}) {
  return BaseRestClient.fetch("api/v1/users/current", options);
}

export function initSession(deviceInfo: Omit<DeviceInfo, "last_session_start"> & { time_zone: string }) {
  return BaseRestClient.put("api/v1/app/device-init-session", { device: deviceInfo });
}

export function requestPasswordReset(email: string) {
  return BaseRestClient.post("users/password", { user: { email } });
}
