import { faCcAmex, faCcDiscover, faCcMastercard, faCcVisa } from "@fortawesome/free-brands-svg-icons";
import { faCreditCard, IconDefinition } from "@fortawesome/free-solid-svg-icons";

export function cardIcon(brand: string) : IconDefinition {
  switch (brand.toLowerCase()) {
    case 'visa':
      return faCcVisa;
    case 'mastercard':
      return faCcMastercard;
    case 'discover':
      return faCcDiscover;
    case 'american express':
      return faCcAmex;
    default:
      return faCreditCard;
  }
}