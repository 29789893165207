import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { UserExperience } from "../../data/userExperience";
import ExperienceList from "./ExperienceList";
import "./ProfileItemList.scss";

const EXPANDABLE_INITIAL_ITEM_COUNT = 2;

type ExperienceCardProps = {
  experiences: UserExperience[];
};

function ExperienceCard({ experiences }: ExperienceCardProps) {
  const [expanded, setExpanded] = useState(false);

  const showMoreItems = experiences.length > EXPANDABLE_INITIAL_ITEM_COUNT;

  return (
    <Card className="border-primary">
      <Card.Header className="bg-primary text-white">Experience</Card.Header>
      <Card.Body>
        <ExperienceList experiences={experiences} maxItems={expanded ? 1000 : EXPANDABLE_INITIAL_ITEM_COUNT} />
      </Card.Body>
      {showMoreItems && (
        <Card.Footer className="bg-white align-items-center d-flex flex-column p-1">
          {expanded ? (
            <Button variant="link" onClick={() => setExpanded(false)}>
              <FontAwesomeIcon icon={faCaretUp} className="me-2" />
              Most recent
            </Button>
          ) : (
            <Button variant="link" onClick={() => setExpanded(true)}>
              <FontAwesomeIcon icon={faCaretDown} className="me-2" />
              {experiences.length - EXPANDABLE_INITIAL_ITEM_COUNT} more
            </Button>
          )}
        </Card.Footer>
      )}
    </Card>
  );
}

export default ExperienceCard;
