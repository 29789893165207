import { useCallback, useRef, useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { createJob, newJob } from "../../actions/jobs";
import { AppDispatch } from "../../app/store";
import AppToast from "../../components/AppToast";
import { ButtonSpinnerOverlay } from "../../components/ButtonSpinnerOverlay";
import LoadingOverlay from "../../components/LoadingOverlay";
import { Job } from "../../data/job";
import { ModelValidationErrors } from "../../data/validation";
import { FailedRequestError } from "../../rest-client/errors";
import JobChooserDropdown from "../jobs/JobChooserDropdown";
import JobForm, { JobFormRef } from "../jobs/JobForm";

type AddJobModalProps = EditLocationModalConnectedProps & {
  visible?: boolean;
  locationId: number;
  onClosed?: () => void;
};

const AddJobModal = ({ locationId, visible, onClosed, newJob, createJob }: AddJobModalProps) => {
  const jobFormRef = useRef<JobFormRef | null>(null);
  const [job, setJob] = useState<Partial<Job>>({ company_location_id: locationId });
  const [errorMessage, setErrorMessage] = useState<string>();
  const [submitting, setSubmitting] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ModelValidationErrors | undefined>();

  const onJobUpdated = useCallback(
    (attributes: Partial<Job>) => {
      setJob({ ...job, ...attributes });
    },
    [job, setJob]
  );

  const handleHide = useCallback(() => {
    if (onClosed) {
      onClosed();
    }
  }, [onClosed]);

  const onSubmit = useCallback(() => {
    setSubmitting(true);
    createJob(job as unknown as Omit<Job, "id">)
      .then(() => {
        handleHide();
      })
      .catch((error) => {
        if (error instanceof FailedRequestError && error.isUnprocessableEntity()) {
          setValidationErrors(error.responseJson);
        } else {
          setErrorMessage("An unexpected error occurred");
        }
      })
      .finally(() => setSubmitting(false));
  }, [job, createJob, handleHide]);

  const copyFromJobSelected = useCallback(
    (jobId: number) => {
      setWaiting(true);
      newJob(jobId)
        .then((job: Job) => {
          setJob({ ...job, company_location_id: locationId });
        })
        .catch(() => {
          AppToast.show({
            type: "error",
            text: "An unexpected error occurred when copying the selected job",
          });
        })
        .finally(() => setWaiting(false));
    },
    [newJob, setWaiting, locationId]
  );

  return (
    <Modal show={visible} onHide={handleHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Add Job</Modal.Title>
        <JobChooserDropdown onItemSelected={copyFromJobSelected} className="ms-3" />
      </Modal.Header>
      <Modal.Body>
        <JobForm job={job} onUpdate={onJobUpdated} ref={jobFormRef} />
        {errorMessage && (
          <Alert variant="danger" dismissible={true} onClose={() => setErrorMessage(undefined)}>
            {errorMessage}
          </Alert>
        )}
        {waiting && <LoadingOverlay position="centered" />}
      </Modal.Body>
      <Modal.Footer className="flex-row">
        {validationErrors && <div className="text-danger me-3">Fix the problems above and try again</div>}
        <Button variant="secondary" onClick={handleHide} disabled={submitting}>
          Close
        </Button>
        <div className="position-relative">
          <Button variant="primary" onClick={onSubmit} disabled={submitting}>
            Create
          </Button>
          {submitting && <ButtonSpinnerOverlay />}
        </div>
      </Modal.Footer>
    </Modal>
  );
};
const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    createJob: (job: Omit<Job, "id">) => dispatch(createJob(job)),
    newJob: (templateJobId: number) => dispatch(newJob({ templateJobId: templateJobId })),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type EditLocationModalConnectedProps = ConnectedProps<typeof connector>;

export default connector(AddJobModal);
