import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import { CSSProperties, useCallback } from "react";
import { Link, To } from "react-router-dom";
import List from "../../components/List";
import { conversationHasUnreadMessage, ParsedConversation } from "../../utils/conversations";
import SeekerCard from "../common/SeekerCard";

type ConversationItemProps = {
  conversation: ParsedConversation;
  selected: boolean;
  linkTo: To;
};

function ConversationItem({ conversation, selected, linkTo }: ConversationItemProps) {
  const hasUnreadChats = conversationHasUnreadMessage(conversation);
  const lastMessage = conversation.messages[0];
  const classNames = [];
  if (hasUnreadChats) {
    classNames.push("unread");
  }
  if (selected) {
    classNames.push("selected");
  }
  return (
    <Link to={linkTo} className="no-decoration">
      <SeekerCard seeker={conversation.seeker} className={classNames.join(" ")}>
        <SeekerCard.Header>
          <div className="seeker-name">
            {conversation.seeker.first_name} {conversation.seeker.last_name}
          </div>
        </SeekerCard.Header>
        <SeekerCard.Details>
          <div className="message-content">{lastMessage.message}</div>
          <div className="message-time">{formatDistanceToNowStrict(lastMessage.created_at)} ago</div>
        </SeekerCard.Details>
      </SeekerCard>
    </Link>
  );
}

type ConversationListProps = {
  conversations: ParsedConversation[];
  selectedConversationId: number;
  itemLink: (conversation: ParsedConversation) => To;
  style?: CSSProperties;
};
function ConversationList({ conversations, selectedConversationId, itemLink, style }: ConversationListProps) {
  const renderItem = useCallback(
    ({ item }: { item: ParsedConversation }) => (
      <ConversationItem conversation={item} selected={item.id === selectedConversationId} linkTo={itemLink(item)} />
    ),
    [selectedConversationId, itemLink]
  );

  return (
    <List<ParsedConversation>
      className="conversation-list"
      style={style}
      items={conversations}
      renderItem={renderItem}
    />
  );
}

export default ConversationList;
