import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { ConnectedProps, connect } from "react-redux";
import { getJobTemplates, getJobsForLocation, newJob } from "../../actions/jobs";
import { AppDispatch, RootState } from "../../app/store";
import AppToast from "../../components/AppToast";
import LoadingOverlay from "../../components/LoadingOverlay";
import ToggleButtons from "../../components/ToggleButtons";
import { JobStatus, getJobStatusLabel } from "../../constants";
import { Job } from "../../data/job";
import EditJobModal from "./EditJobModal";
import JobCard from "./JobCard";
import { isNullOrEmpty } from "../../utils/validation";

type LocationJobsProps = LocationJobsConnectedProps & {
  locationId?: number;
};

const LocationJobs = ({
  locationId,
  jobs,
  managedLocations,
  fetchJobsForLocation,
  newJob,
  jobTemplates,
  loadJobTemplates,
}: LocationJobsProps) => {
  const [loading, setLoading] = useState(false);
  const jobToEdit = useRef<Job | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [activeStatus, setActiveStatus] = useState<JobStatus>(JobStatus.Active);
  const locationDataLoading = useRef<boolean | null>(null);

  const location = useMemo(() => {
    if (!isNullOrEmpty(managedLocations)) {
      if (locationId) {
        return managedLocations!.find((l) => l.id === locationId)!;
      } else {
        return managedLocations![0];
      }
    }
    return null;
  }, [locationId, managedLocations]); 

  const editJobHandler = useCallback(
    (job: Job) => {
      jobToEdit.current = job;
      setShowEditModal(true);
    },
    [setShowEditModal]
  );

  const addJobHandler = useCallback(
    (templateId?: number) => {
      newJob({ templateId })
        .then((newJob) => {
          jobToEdit.current = {
            ...newJob,
            company_location_id: locationId!,
          };
          setShowEditModal(true);
        })
        .catch(() => AppToast.show({ text: "An unexpected error occurred", type: "error" }));
    },
    [setShowEditModal, newJob, locationId]
  );

  const addJobSelectHandler = useCallback(
    (eventKey: string | null) => {
      if (eventKey === "blank") {
        addJobHandler();
      } else {
        addJobHandler(parseInt(eventKey!));
      }
    },
    [addJobHandler]
  );

  const addJobDropdownToggleHandler = useCallback(() => {
    if (jobTemplates == null) {
      loadJobTemplates();
    }
  }, [jobTemplates, loadJobTemplates]);

  const fetchLocationData = useCallback(
    (locationId: number) => {
      setLoading(true);
      fetchJobsForLocation(locationId).finally(() => {
        setLoading(false);
        locationDataLoading.current = false;
      });
    },
    [setLoading, fetchJobsForLocation]
  );

  useEffect(() => {
    setActiveStatus(JobStatus.Active);
  }, [locationId]);

  useEffect(() => {
    if (!locationDataLoading.current) {
      locationDataLoading.current = true;
      fetchLocationData(location!.id);
    }
  }, [location, fetchLocationData]);

  const filteredAndSortedJobs = useMemo(() => {
    if (jobs == null) {
      return [];
    }
    const filteredJobs = jobs.filter((job) => job.status === activeStatus);
    filteredJobs.sort((l, r) => (l.id > r.id ? 1 : -1));
    return filteredJobs;
  }, [jobs, activeStatus]);

  return (
    <div className="h-100 position-relative w-100">
      {loading ? (
        <LoadingOverlay position="centered" />
      ) : (
        <React.Fragment>
          <div className="d-flex flex-row justify-content-between align-items-center bg-light border-light pb-3">
            <ToggleButtons
              items={[
                { value: JobStatus.Active, label: getJobStatusLabel(JobStatus.Active) },
                { value: JobStatus.Inactive, label: getJobStatusLabel(JobStatus.Inactive) },
                { value: JobStatus.InProcess, label: getJobStatusLabel(JobStatus.InProcess) },
              ]}
              selectedItem={activeStatus}
              onItemClick={(item) => setActiveStatus(item.value as JobStatus)}
            />
            {false && (
              <DropdownButton
                variant="dark"
                title={getJobStatusLabel(activeStatus)}
                onSelect={(key) => setActiveStatus(key as JobStatus)}
              >
                <Dropdown.Item eventKey={JobStatus.Active}>Active</Dropdown.Item>
                <Dropdown.Item eventKey={JobStatus.Inactive}>Inactive</Dropdown.Item>
                <Dropdown.Item eventKey={JobStatus.InProcess}>In Process</Dropdown.Item>
              </DropdownButton>
            )}
            <DropdownButton
              variant="success"
              drop="down"
              title="Add a new job"
              onToggle={addJobDropdownToggleHandler}
              onSelect={addJobSelectHandler}
            >
              <Dropdown.Item eventKey={"blank"}>Start with a blank job</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Header>Use a template</Dropdown.Header>
              {jobTemplates == null ? (
                <Dropdown.Item disabled={true} eventKey="loading">
                  Fetching templates....
                </Dropdown.Item>
              ) : (
                <React.Fragment>
                  {jobTemplates.map((template) => (
                    <Dropdown.Item key={`template-${template.id}`} eventKey={template.id}>
                      {template.name}
                    </Dropdown.Item>
                  ))}
                </React.Fragment>
              )}
            </DropdownButton>
          </div>
          <div className="d-flex flex-column gap-3">
            {filteredAndSortedJobs.map((job) => (
              <JobCard key={`job-${job.id}`} job={job} onEditClick={editJobHandler} />
            ))}
          </div>
          {showEditModal && (
            <EditJobModal
              key={`edit-job-${jobToEdit.current!.id}`}
              visible={true}
              job={jobToEdit.current ?? undefined}
              defaultAddLocationId={location!.id}
              onClosed={() => setShowEditModal(false)}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    managedLocations: state.locations.locations ?? null,
    jobs: state.jobs.jobsForLocation?.jobs,
    jobTemplates: state.jobs.templates,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchJobsForLocation: (locationId: number) => dispatch(getJobsForLocation(locationId)),
    newJob: (args: { templateId?: number }) => dispatch(newJob(args)),
    loadJobTemplates: () => dispatch(getJobTemplates()),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type LocationJobsConnectedProps = ConnectedProps<typeof connector>;

export default connector(LocationJobs);
