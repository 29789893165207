import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Brand } from "../data/brand";

export type BrandsState = {
  brands: Brand[] | null;
};

const initialState: BrandsState = {
  brands: null,
};

export const brandsSlice = createSlice({
  name: "brands",
  initialState,
  reducers: {
    setBrands: (state, action: PayloadAction<Brand[] | null>) => {
      state.brands = action.payload;
    },
    addBrand: (
      state,
      action: PayloadAction<Brand>
    ) => {
      state.brands = [ ...state.brands ?? [], action.payload ];
    },
    updateBrand: (state, action: PayloadAction<Partial<Brand>>) => {
      if (state.brands) {
        const index = state.brands?.findIndex((brand) => brand.id === action.payload.id);
        if (index >= 0) {
          state.brands[index] = { ...state.brands[index], ...action.payload };
        }
      }
    },
  },
});

export const { setBrands, addBrand, updateBrand } =
brandsSlice.actions;
export default brandsSlice.reducer;
