export function isScrolledIntoView(element: HTMLElement, parent: HTMLElement)
{
  const parentBounds = parent.getBoundingClientRect();
  const elementBounds = element.getBoundingClientRect();

  // if parent doesn't find element, it is considered in view
  if ((elementBounds.bottom > parentBounds.bottom) && (elementBounds.top < parentBounds.top)) {
    return true;
  } else if ((elementBounds.bottom < parentBounds.bottom) && (elementBounds.top > parentBounds.top)) {
    return true;
  } else {
    return false;
  }
}

export function scrollIntoView(element: HTMLElement, parent: HTMLElement, smooth = true)
{
  const parentBounds = parent.getBoundingClientRect();
  const elementBounds = element.getBoundingClientRect();

  // element doesn't fit in poarent, leave it where it is
  if ((elementBounds.bottom > parentBounds.bottom) && (elementBounds.top < parentBounds.top)) {
    return;
  } 
  // element is fully visible, leave it where it is
  if ((elementBounds.bottom < parentBounds.bottom) && (elementBounds.top > parentBounds.top)) {
    return;
  }

  const behavior = smooth ? 'smooth' : 'instant';
  if (elementBounds.top < parentBounds.top) {
    element.scrollIntoView({ block: "start", inline: "nearest", behavior })
  } else {
    element.scrollIntoView({ block: "end", inline: "nearest", behavior })
  }
}