import BaseRestClient from "./baseClient";

export function createPaymentPackage({
  locationIds,
  pricingPackageId,
  stripeToken,
  paymentMethodId,
}: {
  locationIds: number[];
  pricingPackageId: number;
  stripeToken?: string;
  paymentMethodId?: string;
}) {
  return BaseRestClient.post("api/v3/employer/payment_packages", {
    payment_package: {
      company_location_ids: locationIds,
      pricing_package_id: pricingPackageId,
      payment_method_token: stripeToken,
      payment_method_id: paymentMethodId,
    },
  });
}

export function loadPaymentPackages() {
  return BaseRestClient.fetch("api/v3/employer/payment_packages?include_payer_info=true");
}

export function loadPricingPackages() {
  return BaseRestClient.fetch("api/v3/employer/payment_packages/pricing_packages");
}

export function loadPaymentMethods() {
  return BaseRestClient.fetch("api/v3/employer/payment_packages/payment_methods");
}

export function getPaymentPackageById(packageId: number) {
  return BaseRestClient.fetch(`api/v3/employer/payment_packages/${packageId}`);
}

export function updatePaymentPackage({
  packageId,
  paymentMethodId,
  stripeToken,
}: {
  packageId: number;
  paymentMethodId?: string;
  stripeToken?: string;
}) {
  return BaseRestClient.patch(`api/v3/employer/payment_packages/${packageId}`, {
    payment_package: {
      payment_method_token: stripeToken,
      payment_method_id: paymentMethodId,
    },
  });
}
