import { ErrorReasonCode } from "../constants";
import { PartnerConfiguration } from "../data/partner_configuration";
import {
  ActivateRegistrationParams,
  Business,
  CreateRegistrationParams,
  RegistrationPricingPackage,
  UserAccount,
} from "../data/registration";
import * as RegistrationReducers from "../reducers/registration";
import * as Api from "../rest-client/api";
import { FailedRequestError } from "../rest-client/errors";
import { StripeCardError } from "../utils/errors";
import { AppAction, AppAsyncAction } from "./types";
import authenticatedRouter from "../app/Router";
import { authenticate } from "./authentication";
import { setInitialAppPath } from "./initialization";

export function validateUser(user: Omit<UserAccount, "password">): AppAsyncAction<void> {
  return (dispatch, getState) => {
    const {
      registration: { pending_registration_key },
    } = getState();
    return Api.validateUser(user, pending_registration_key).then(({ responseJson }) => {
      const { registration_key } = responseJson;
      dispatch(RegistrationReducers.setPendingRegistrationKey(registration_key));
    });
  };
}

export function validateBusiness(business: Business): AppAsyncAction<void> {
  return (dispatch, getState) => {
    const {
      registration: { pending_registration_key },
    } = getState();
    return Api.validateBusiness(business, pending_registration_key).then(({ responseJson }) => {
      const { registration_key } = responseJson;
      dispatch(RegistrationReducers.setPendingRegistrationKey(registration_key));
    });
  };
}

export function fetchPartnerConfiguration(partnerId: string, identityToken?: string): AppAsyncAction {
  return (dispatch) => {
    return Api.fetchPartnerConfiguration(partnerId, identityToken).then(({ responseJson }) => {
      const config: PartnerConfiguration = responseJson;
      dispatch(RegistrationReducers.setPaymentMethod(config.payment_method));
      dispatch(RegistrationReducers.setPaymentDescription(config.payment_description));
      dispatch(RegistrationReducers.setPricingPackage(config.pricing_package));
      dispatch(RegistrationReducers.setUser(config.user ?? null));
      dispatch(RegistrationReducers.setBusiness(config.business ?? null));
      dispatch(RegistrationReducers.setPendingRegistrationKey(config.registration_key ?? null));
    });
  };
}
export function fetchPricingPackage(selectorKey: string): AppAsyncAction<RegistrationPricingPackage> {
  return (dispatch) =>
    Api.fetchPricingPackage(selectorKey).then(({ responseJson }) => {
      const pricingPackage = responseJson as RegistrationPricingPackage;
      dispatch(RegistrationReducers.setPricingPackage(pricingPackage));
      return pricingPackage;
    });
}

export function createRegistration(registration: CreateRegistrationParams): AppAsyncAction<string> {
  return (dispatch, getState) => {
    const {
      registration: { pending_registration_key },
    } = getState();
    return Api.createRegistration(registration, pending_registration_key).then(({ responseJson }) => {
      const registrationKey = responseJson.registration_key;
      dispatch(RegistrationReducers.setRegistrationKey(registrationKey));
      return registrationKey;
    });
  };
}

export function clearRegistrationData(): AppAction {
  return (dispatch) => {
    dispatch(RegistrationReducers.setUser(null));
    dispatch(RegistrationReducers.setBusiness(null));
    dispatch(RegistrationReducers.setPaymentMethod(null));
    dispatch(RegistrationReducers.setPaymentDescription(null));
    dispatch(RegistrationReducers.setPricingPackage(null));
    dispatch(RegistrationReducers.setPendingRegistrationKey(null));
    dispatch(RegistrationReducers.setRegistrationKey(null));
  };
}

export function activateRegistration(params: ActivateRegistrationParams): AppAsyncAction<void> {
  return () => {
    return Api.activateRegistration(params)
      .then(() => {})
      .catch((error) => {
        if (error instanceof FailedRequestError) {
          if (error.responseJson.reason === ErrorReasonCode.StripeCardError) {
            throw new StripeCardError(error.responseJson.details, error.responseJson.param_name);
          }
        }
        throw error;
      });
  };
}

export function authenticateAndClearRegistration(): AppAction {
  return (dispatch, getState) => {
    const {
      registration: { user },
    } = getState();
    if (user) {
      dispatch(authenticate(user.email, user.password)).then(() => {
        dispatch(clearRegistrationData());
        dispatch(setInitialAppPath("/locations"));
        authenticatedRouter.navigate("/");
      });
    }
  };
}
