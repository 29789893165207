import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Grid from "../../../components/Grid";
import CreditCardDetails from "../CreditCardDetails";
import { CardPaymentMethod } from "../../../data/payments";

type PaymentMethodsChooserProps = {
  paymentMethods: CardPaymentMethod[];
  selectedMethodId?: string;
  onSelect: (paymentMethod: CardPaymentMethod) => void;
  onAddCardClick: () => void;
};
function PaymentMethodsChooser({
  paymentMethods,
  selectedMethodId,
  onSelect,
  onAddCardClick,
}: PaymentMethodsChooserProps) {
  const [methodId, setMethodId] = useState(selectedMethodId);
  return (
    <React.Fragment>
      <Grid<CardPaymentMethod>
        groupSize={2}
        items={paymentMethods}
        renderItem={(paymentMethod) => {
          const classNames = ["border", "rounded", "p-3"];
          if (methodId && paymentMethod.id === methodId) {
            classNames.push("border-primary");
          }
          return (
            <div
              className={classNames.join(" ")}
              role="button"
              onClick={() => {
                setMethodId(paymentMethod.id);
                onSelect(paymentMethod);
              }}
            >
              <CreditCardDetails card={paymentMethod} />
            </div>
          );
        }}
      />
      <div className="mt-3 d-flex flex-column align-items-center">
        <Button onClick={onAddCardClick}>Add a new card</Button>
      </div>
    </React.Fragment>
  );
}

export default PaymentMethodsChooser;
