export const ADDRESS_STATES = [
  { name: 'Alabama', code: 'AL' },
  { name: 'Alaska', code: 'AK' },
  { name: 'Arizona', code: 'AZ' },
  { name: 'Arkansas', code: 'AR' },
  { name: 'California', code: 'CA' },
  { name: 'Colorado', code: 'CO' },
  { name: 'Connecticut', code: 'CT' },
  { name: 'Delaware', code: 'DE' },
  { name: 'District of Columbia', code: 'DC' },
  { name: 'Florida', code: 'FL' },
  { name: 'Georgia', code: 'GA' },
  { name: 'Hawaii', code: 'HI' },
  { name: 'Idaho', code: 'ID' },
  { name: 'Illinois', code: 'IL' },
  { name: 'Indiana', code: 'IN' },
  { name: 'Iowa', code: 'IA' },
  { name: 'Kansas', code: 'KS' },
  { name: 'Kentucky', code: 'KY' },
  { name: 'Louisiana', code: 'LA' },
  { name: 'Maine', code: 'ME' },
  { name: 'Maryland', code: 'MD' },
  { name: 'Massachusetts', code: 'MA' },
  { name: 'Michigan', code: 'MI' },
  { name: 'Minnesota', code: 'MN' },
  { name: 'Mississippi', code: 'MS' },
  { name: 'Missouri', code: 'MO' },
  { name: 'Montana', code: 'MT' },
  { name: 'Nebraska', code: 'NE' },
  { name: 'Nevada', code: 'NV' },
  { name: 'New Hampshire', code: 'NH' },
  { name: 'New Jersey', code: 'NJ' },
  { name: 'New Mexico', code: 'NM' },
  { name: 'New York', code: 'NY' },
  { name: 'North Carolina', code: 'NC' },
  { name: 'North Dakota', code: 'ND' },
  { name: 'Ohio', code: 'OH' },
  { name: 'Oklahoma', code: 'OK' },
  { name: 'Oregon', code: 'OR' },
  { name: 'Pennsylvania', code: 'PA' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Rhode Island', code: 'RI' },
  { name: 'South Carolina', code: 'SC' },
  { name: 'South Dakota', code: 'SD' },
  { name: 'Tennessee', code: 'TN' },
  { name: 'Texas', code: 'TX' },
  { name: 'Utah', code: 'UT' },
  { name: 'Vermont', code: 'VT' },
  { name: 'Virginia', code: 'VA' },
  { name: 'Washington', code: 'WA' },
  { name: 'West Virginia', code: 'WV' },
  { name: 'Wisconsin', code: 'WI' },
  { name: 'Wyoming', code: 'WY' }
];

export const ADDRESS_STATE_NAMES = ADDRESS_STATES.map(item => item.name);
export const ADDRESS_STATE_CODES = ADDRESS_STATES.map(item => item.code);

export function addressStateCodeToName(code: string) {
  return ADDRESS_STATES.find(item => item.code === code)?.name;
}

export function addressStateNameToCode(name: string) {
  return ADDRESS_STATES.find(item => item.name === name)?.code;
}

export enum ErrorReasonCode {
  PaymentMethodMissing = "PaymentMethodMissing",
  PaymentMethodInvalid = "PaymentMethodInvalid",
  StripeCardError = "StripeCardError",
  MissingParameter = "MissingParameter",
  InvalidParameter = "InvalidParameter",
  ModelValidationFailed = "ModelValidationFailed",
  ModelNotUnique = "ModelNotUnique",
  ModelInvalidState = "ModelInvalidState",
  ReconnectToSoon = "ReconnectToSoon",
}

export enum EducationLevels {
  HighSchool = 0,
  Certification = 1,
  Vocational = 2,
  AssociateDegree = 3,
  BachelorsDegree = 4,
  MastersDegree = 5,
  Doctorate = 6,
  Professional = 7,
}

export function getEducationLevelLabel(level: EducationLevels) {
  switch (level) {
    case EducationLevels.HighSchool:
      return "High School/GED";
    case EducationLevels.Certification:
      return "Certification";
    case EducationLevels.AssociateDegree:
      return "Associate Degree";
    case EducationLevels.Professional:
      return "Professional Degree";
    case EducationLevels.BachelorsDegree:
      return "Bachelor's Degree";
    case EducationLevels.MastersDegree:
      return "Master's Degree";
    case EducationLevels.Doctorate:
      return "Doctorate";
    default:
      return "";
  }
}

export function getEducationLevelDescription(level: EducationLevels) {
  switch (level) {
    case EducationLevels.HighSchool:
      return "a High School Diploma/GED";
    case EducationLevels.Certification:
      return "Certification(s)";
    case EducationLevels.AssociateDegree:
      return "an Associate Degree";
    case EducationLevels.Professional:
      return "a Professional Degree";
    case EducationLevels.BachelorsDegree:
      return "a Bachelor's Degree";
    case EducationLevels.MastersDegree:
      return "a Master's Degree";
    case EducationLevels.Doctorate:
      return "a Doctorate";
    default:
      return "";
  }
}

export enum ConnectionStatus {
  Pending = "pending",
  Accepted = "accepted",
  Declined = "declined",
  Revoked = "revoked",
}

export function connectionStatusLabel(status: ConnectionStatus) {
  switch (status) {
    case ConnectionStatus.Pending:
      return "Pending";
    case ConnectionStatus.Accepted:
      return "Connected";
    case ConnectionStatus.Declined:
      return "Declined";
    case ConnectionStatus.Revoked:
      return "Cancelled";
  }
}

export enum ConnectionPaymentStatus {
  Paid = "paid",
  Unpaid = "unpaid",
  Scheduled = "scheduled",
}

export enum PaymentPackagePaymentStatus {
  Paid = "paid",
  Unpaid = "unpaid",
  Scheduled = "scheduled",
  Declined = "declined",
}

export enum PricingPackageUnitType {
  Connection = "connection",
  Location = "location",
  Company = "company",
}

export enum PricingPackageTerm {
  Month = 30,
  Quarter = 90,
  Year = 365,
}

export function pricingPackageTermDescription(term: PricingPackageTerm) {
  switch (term) {
    case PricingPackageTerm.Month:
      return "month";
    case PricingPackageTerm.Quarter:
      return "quarter";
    case PricingPackageTerm.Year:
      return "year";
    case null:
      return "connection";
    default:
      return "";
  }
}

export enum ActivityType {
  MessageReceived = "message_received",
  JobUpdated = "job_update",
  DailyDigest = "daily_digest",
  SignUp = "new_user_signup",
  WeeklyDigest = "weekly_digest",
  StaleJob = "stale_job",
  EmployerJobSubmissions = "employer_job_submissions",
  Reminder = "reminder",
  UserReferralUpdate = "user_referral_update",
  FeaturedJobs = "featured_jobs_notification",
  NewSeekerConnection = "new_seeker_connection",
  ConnectionUpdate = "connection_update",
}

export enum ReminderActivityObjectId {
  Home = 0,
  JobsMap = 1,
  JobsList = 2,
  UserProfileVideo = 3,
  UserProfile = 4,
}

export const BYTE_SIZE_1MB = 1024 * 1024;

export enum JuvoJobsLinks {
  FAQs = "https://www.juvojobs.com/faq-for-employers",
  TrainingVideo = "https://vimeo.com/showcase/9708662",
  AppStore = "https://apps.apple.com/us/app/juvo-jobs/id1049621538",
  PlayStore = "https://play.google.com/store/apps/details?id=com.juvo360.app&hl=en_US",
}

export const JobCategories = [
  { id: 1, label: "Customer Service/Sales" },
  { id: 2, label: "Food Services" },
  { id: 3, label: "General Labor" },
  { id: 4, label: "Hospitality" },
  { id: 5, label: "General Business" },
  { id: 6, label: "Retail" },
  { id: 7, label: "Skilled Trade" },
  { id: 8, label: "Other" },
  { id: 9, label: "Volunteer" },
  { id: 10, label: "Healthcare" },
];

export const AdditionalCompensationTypes = [
  { id: 1, label: "Tips" },
  { id: 2, label: "Bonus" },
  { id: 3, label: "Commission" },
];

const jobStatuses = [
  {
    value: "in_process",
    label: "In Process",
  },
  {
    value: "active",
    label: "Active",
  },
  {
    value: "inactive",
    label: "Inactive",
  },
];
export enum JobStatus {
  InProcess = "in_process",
  Active = "active",
  Inactive = "inactive",
}
export function getJobStatusLabel(status: JobStatus) {
  const jobStatus = jobStatuses.find((item) => item.value === status);
  return jobStatus ? jobStatus.label : "Unknown";
}

export enum ConnectionInitiator {
  Seeker = "seeker",
  Employer = "employer",
}

export enum JobHoursType {
  PartTime = 'part_time',
  FullTime = 'full_time',
  PartAndFullTime = 'part_and_full_time',
}

export enum AdditionalCompensationType {
  Tips = 1,
  Bonus = 2,
  Commission = 3,
}

export const DBA_TOOLTIP_TEXT = "'Doing Business As' - This will be the company name displayed on the Juvo Jobs Network";