import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserProfile } from "../data/userProfile";
import { AdminScope, Company, User } from "../data/user";

export interface UserState {
  id?: number;
  email?: string;
  role_id?: number;
  admin_scopes?: AdminScope[];
  avatar_url?: string | null;
  profile?: UserProfile;
  company?: Company;
}

const initialState: UserState = {};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.id = action.payload?.id;
      state.email = action.payload?.email;
      state.role_id = action.payload?.role_id;
      state.admin_scopes = action.payload?.admin_scopes;
      state.avatar_url = action.payload?.avatar_url;
      state.profile = action.payload?.profile;
      state.company = action.payload?.company;
    },
    setProfile: (state, action: PayloadAction<UserProfile>) => {
      state.profile = action.payload;
    },
    setAvatarUrl: (state, action: PayloadAction<string>) => {
      state.avatar_url = action.payload;
    },
    setProfileVideo: (
      state,
      action: PayloadAction<Partial<{
        video_url: string | null;
        optimized_video_url: string | null;
        optimized_video_thumb_url: string | null;
      }>>
    ) => {
      state.profile! = { ...state.profile!, ...action.payload };
    },
  },
});

export const { setUser, setProfile, setAvatarUrl, setProfileVideo } = userSlice.actions;
export default userSlice.reducer;
