import { PropsWithChildren } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../app/store";
import LoginScreen from "./LoginScreen";

type AuthGuardProps = AuthGuardConnectedProps & PropsWithChildren;

function AuthGuard({ isLoggedIn, children }: AuthGuardProps) {
  if (!isLoggedIn) {
    return <LoginScreen />;
  } else {
    return <>{children}</>;
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    isLoggedIn: state.auth.loggedIn,
  };
};

const connector = connect(mapStateToProps);

type AuthGuardConnectedProps = ConnectedProps<typeof connector>;

export default connector(AuthGuard);
