import {
  faBell,
  faCarSide,
  faCommentDots,
  faFileImport,
  faInbox,
  faLowVision,
  faStar,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import parseISO from "date-fns/parseISO";
import { useCallback, useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { markActivitiesRead } from "../../actions/activities";
import { getLocationByJobId } from "../../actions/locations";
import { AppDispatch, RootState } from "../../app/store";
import List, { ListItem } from "../../components/List";
import { ActivityType, ReminderActivityObjectId } from "../../constants";
import { Activity } from "../../data/activity";
import "./ActivitiesPage.scss";
import NotificationSettingsModal from "./NotificationSettingsModal";

type ActivitiesPageProps = ActivitiesPageConnectedProps;

function ActivitiesPage({ activities, markActivitiesRead, getLocationByJobId }: ActivitiesPageProps) {
  const markingAsRead = useRef<boolean>();
  const [showNotificationSettingsModal, setShowNotificationSettingsModal] = useState(false);

  const activityLink = useCallback((item: Activity) => {
    switch (item.activity_type) {
      case ActivityType.JobUpdated:
      case ActivityType.EmployerJobSubmissions: {
        const location = getLocationByJobId(parseInt(item.object_id!));
        if (location) {
          return `/locations/${location.id}/connections?category=All&job_id=${item.object_id}`;
        }
        break;
      }
      case ActivityType.Reminder: {
        switch (parseInt(item.object_id!)) {
          case ReminderActivityObjectId.Home:
            return "/";
          case ReminderActivityObjectId.UserProfile:
          case ReminderActivityObjectId.UserProfileVideo:
            return "/profile";
          default:
        }
        break;
      }
      case ActivityType.NewSeekerConnection:
        break;
      default:
    }
    return undefined;
  }, [getLocationByJobId]);

  const itemIcon = useCallback((item: Activity) => {
    let icon;

    switch (item.activity_type) {
      case ActivityType.MessageReceived:
        icon = faCommentDots;
        break;
      case ActivityType.JobUpdated:
        icon = faFileImport;
        break;
      case ActivityType.DailyDigest:
      case ActivityType.WeeklyDigest:
        icon = faCarSide;
        break;
      case ActivityType.StaleJob:
        icon = faLowVision;
        break;
      case ActivityType.FeaturedJobs:
        icon = faStar;
        break;
      case ActivityType.EmployerJobSubmissions:
      case ActivityType.NewSeekerConnection:
        icon = faInbox;
        break;
      case ActivityType.Reminder:
        icon = faBell;
        break;
      default:
        icon = faUserAlt;
    }
    return <FontAwesomeIcon icon={icon} />;
  }, []);

  const renderActivity = ({ item }: { item: Activity }) => {
    return (
      <ListItem linkTo={activityLink(item)} className={item.read ? undefined : "unread"}>
        <ListItem.Left>{itemIcon(item)}</ListItem.Left>
        <ListItem.Content>{item.message}</ListItem.Content>
        <ListItem.Right className="text-muted">{formatDistanceToNowStrict(parseISO(item.created_at))}</ListItem.Right>
      </ListItem>
    );
  };

  useEffect(() => {
    return () => {
      if (!markingAsRead.current) {
        markingAsRead.current = true;
        markActivitiesRead().then(() => (markingAsRead.current = false));
      }
    };
  });

  return (
    <div className="d-flex flex-row gap-3">
      <div
        className="d-flex flex-column h-100 justify-content-between gap-3 sticky-left-panel"
        style={{ flex: "0 0 200px" }}
      >
        <div className="d-flex flex-column border rounded bg-white align-items-center gap-3 p-3 text-center">
          <div style={{ fontSize: "smaller" }}>Manage your notifications</div>
          <button className="btn btn-primary btn-sm" onClick={() => setShowNotificationSettingsModal(true)}>
            View Settings
          </button>
        </div>
      </div>
      <div className="h-100" style={{ flex: "1 1", overflowY: "auto" }}>
        <List<Activity> items={activities} renderItem={renderActivity} className="activities-list" />
      </div>
      <div className="d-flex flex-column h-100 gap-3 sticky-right-panel" style={{ flex: "0 0 240px" }}>
        <div className="d-flex flex-column border rounded bg-white align-items-center gap-3 p-3 text-center">
          <div style={{ fontSize: "smaller" }}>
            <span className="fw-bold">Contact the Juvo Team!</span>
            <br />
            Reach out to our team if you have any questions with your account
          </div>
          <img src="/images/contact-sales.png" alt="Contact Sales" style={{ width: "200px", objectFit: "contain" }} />
          <a href="mailto:sales@juvo360.com" className="btn btn-link btn-sm">
            Contact Sales
          </a>
        </div>
      </div>
      <NotificationSettingsModal
        visible={showNotificationSettingsModal}
        onClosed={() => setShowNotificationSettingsModal(false)}
      />
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    activities: state.activities.activities ?? [],
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    markActivitiesRead: () => dispatch(markActivitiesRead()),
    getLocationByJobId: (jobId: number) => dispatch(getLocationByJobId(jobId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ActivitiesPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(ActivitiesPage);
