import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { Accordion, Dropdown, ListGroup } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../app/store";
import { sortLocations } from "../../utils/locations";
import { isNullOrEmpty } from "../../utils/validation";

type JobChooserDropdownProps = JobChooserDropdownConnectedProps & {
  onItemSelected: (jobId: number) => void;
  className?: string;
};

function JobChooserDropdown({ managedLocations, onItemSelected, className }: JobChooserDropdownProps) {
  const sortedLocations = useMemo(() => {
    if (isNullOrEmpty(managedLocations)) {
      return [];
    }
     else {
      const locationsWithJobs = managedLocations!.filter(location => (!isNullOrEmpty(location.jobs)));
      return sortLocations(locationsWithJobs);
    }
  }, 
  [managedLocations]);

  return (
    <Dropdown className={className}>
      <DropdownToggle variant="outline-primary">Copy from...</DropdownToggle>
      <Dropdown.Menu style={{ maxHeight: "400px", overflowY: "auto" }}>
        <Accordion flush>
          {sortedLocations?.map((location) => (
            <Accordion.Item key={`location-${location.id}`} eventKey={`location-${location.id}`}>
              <Accordion.Header className="fs-1 text-nowrap">{`${location.name} (${location.address_1})`}</Accordion.Header>
              <Accordion.Body>
                <ListGroup>
                  {location.jobs.map((job) => (
                    <ListGroup.Item
                      action
                      active={false}
                      href="#"
                      key={`job-${location.id}-${job.id}`}
                      onClick={(e) => {
                        e.preventDefault();
                        onItemSelected(job.id);
                      }}
                    >
                      <div className="d-flex flex-row gap-2 align-items-center">
                        <div>{job.title}</div>
                        <FontAwesomeIcon icon={faCopy} />
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Dropdown.Menu>
    </Dropdown>
  );
}

const mapStateToProps = (state: RootState) => ({
  managedLocations: state.locations.locations,
});

const connector = connect(mapStateToProps);

type JobChooserDropdownConnectedProps = ConnectedProps<typeof connector>;

export default connector(JobChooserDropdown);
