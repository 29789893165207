import { Alert, Button } from "react-bootstrap";
import NotificationSettings, { NotificationSettingsRef } from "../common/NotificationSettings";
import { useCallback, useRef, useState } from "react";
import AppToast from "../../components/AppToast";

function NotificationSettingsFragment() {
  const [updating, setUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const notificationSettingsRef = useRef<NotificationSettingsRef>(null);

  const saveHandler = useCallback(() => {
    setUpdating(true);
    setErrorMessage(undefined);
    notificationSettingsRef
      .current!.save()
      .then(() => {
        AppToast.show({ text: "Notification settings successfully updated!", type: "success" });
      })
      .catch((error) => {
        console.error("Update failed", error);
        setErrorMessage("An unexpected error occurred");
      })
      .finally(() => setUpdating(false));
  }, [setUpdating, setErrorMessage]);

  return (
    <div>
      <div className="p-3 border border-primary rounded bg-white mb-3">
        <NotificationSettings ref={notificationSettingsRef} />
        {errorMessage && (
          <Alert variant="danger" dismissible={true}>
            {errorMessage}
          </Alert>
        )}
      </div>
      <Button onClick={saveHandler} disabled={updating}>
        Save
      </Button>
    </div>
  );
}

export default NotificationSettingsFragment;
