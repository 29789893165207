import { Conversation } from "../data/conversation";
import {
  conversationCreated,
  conversationUpdated,
  setConversations,
  StoredConversation,
} from "../reducers/conversations";
import * as Api from "../rest-client/api";
import { AppAction, AppAsyncAction } from "./types";

function applyReadAt(conversation: Conversation, readAt: Date): StoredConversation {
  return {
    ...conversation,
    _read_at: readAt.toJSON(),
  };
}

export function loadConversations(): AppAsyncAction<Conversation[]> {
  return (dispatch) => {
    return Api.loadConversations().then(({ responseJson, dateTime }) => {
      const conversations = (responseJson as Conversation[]).map((c) => applyReadAt(c, dateTime!));
      dispatch(setConversations(conversations));
      return conversations;
    });
  };
}

export function loadConversationById(conversationId: number): AppAsyncAction<Conversation> {
  return (dispatch) =>
    Api.loadConversationById(conversationId).then(({ responseJson, dateTime }) => {
      const conversation = applyReadAt(responseJson as Conversation, dateTime!);
      dispatch(conversationUpdated(conversation));
      return conversation;
    });
}

export function selectConversationByConnectionId(connectionId: number): AppAction<Conversation | undefined> {
  return (_dispatch, getState) => {
    const {
      conversations: { conversations },
    } = getState();
    return conversations?.find((c) => c.connection_id === connectionId);
  };
}

export function updateConversationLastRead(conversationId: number): AppAsyncAction<Conversation> {
  return (dispatch, getState) => {
    const {
      conversations: { conversations },
    } = getState();
    const conversation = conversations!.find((c) => c.id === conversationId)!;
    return Api.updateConversationLastRead(conversationId, new Date(conversation._read_at)).then(
      ({ responseJson, dateTime }) => {
        const conversation = applyReadAt(responseJson, dateTime!);
        dispatch(conversationUpdated(conversation));
        return conversation;
      }
    );
  };
}

export function addConversationMessage(conversationId: number, message: string): AppAsyncAction<Conversation> {
  return (dispatch) => {
    return Api.addConversationMessage(conversationId, message).then(({ responseJson, dateTime }) => {
      const conversation: StoredConversation = applyReadAt(responseJson, dateTime!);
      dispatch(conversationUpdated(conversation));
      return conversation;
    });
  };
}

export function startConversation(connectionId: number, message: string): AppAsyncAction<Conversation> {
  return (dispatch) => {
    return Api.startConversation(connectionId, message).then(({ responseJson, dateTime }) => {
      const conversation: StoredConversation = applyReadAt(responseJson, dateTime!);
      dispatch(conversationCreated(conversation));
      return conversation;
    });
  };
}
