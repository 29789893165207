import React, { useCallback, useState } from "react";
import { Alert, Button, Form, Modal, ProgressBar } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { updateProfileVideo } from "../../actions/user";
import AppToast from "../../components/AppToast";
import { BYTE_SIZE_1MB } from "../../constants";
import VideoPlayer from "../common/VideoPlayer";

import "./ProfilePage.scss";
import { AppDispatch, RootState } from "../../app/store";
import { ProgressCallback } from "../../types/miscellaneous";

type ProfileVideoModalProps = ProfileVideoModalConnectedProps & {
  visible?: boolean;
  onClosed: () => void;
};

function ProfileVideoModal({ visible, user, onClosed, updateProfileVideo }: ProfileVideoModalProps) {
  const [updating, setUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [videoFile, setVideoFile] = useState<File>();
  const [fileValidated, setFileValidated] = useState(false);
  const [fileValidationError, setFileValidationError] = useState<string>();
  const [uploadProgress, setUploadProgress] = useState(0);

  const onFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const videoFile = event.target.files![0];
      setVideoFile(videoFile);
      setFileValidationError(undefined);
      setFileValidated(false);
      if (videoFile == null) {
        return;
      }
      if (videoFile.size > BYTE_SIZE_1MB * 100) {
        setFileValidationError("The selected file is too large, please use an image file smaller than 100 MB");
      } else {
        const video = document.createElement("video");
        video.src = URL.createObjectURL(videoFile);
        video.addEventListener("error", () => {
          setFileValidationError("The selected file is not a valid video file");
          setFileValidated(true);
        });
        video.addEventListener("loadedmetadata", (event: any) => {
          const isPortrait = event.target.videoHeight >= event.target.videoWidth;
          if (!isPortrait) {
            setFileValidationError(
              "The video file appears to have been recorded in landscape (height is less than width).  Juvo requires video are recorded in portrait."
            );
          }
          setFileValidated(true);
        });
      }
    },
    [setVideoFile, setFileValidationError]
  );

  const handleClose = useCallback(() => {
    setVideoFile(undefined);
    onClosed();
  }, [setVideoFile, onClosed]);

  const handleSave = useCallback(() => {
    setUpdating(true);
    setErrorMessage(undefined);
    updateProfileVideo(videoFile!, (progress) => setUploadProgress(progress))
      .then(() => {
        AppToast.show({ text: "Profile picture successfully uploaded!", type: "success" });
        handleClose();
      })
      .catch(({ responseJson }) => {
        console.error("profile pic update failed", responseJson);
        setErrorMessage("An unexpected error occurred");
      })
      .finally(() => setUpdating(false));
  }, [videoFile, handleClose, setUpdating, updateProfileVideo, setErrorMessage]);

  const readyForUpload = videoFile != null && fileValidated && fileValidationError == null;

  return (
    <Modal show={visible} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Profile Video</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column align-items-center justify-content-center position-relative profile-video">
          {user.profile?.optimized_video_url && user.profile?.optimized_video_thumb_url ? (
            <VideoPlayer
              src={user.profile.optimized_video_url ?? user.profile.video_url}
              poster={user.profile.optimized_video_thumb_url}
              mode="inline"
              autoPlay={false}
              controls={true}
              isHls={!!user.profile.optimized_video_url}
            />
          ) : (
            <div>Upload a profile video below</div>
          )}
        </div>
        <Form>
          <Form.Group className="my-3" controlId="profile_picture_file">
            <Form.Control
              placeholder="Video file"
              type="file"
              onChange={onFileChange}
              className={fileValidationError && "is-invalid"}
              accept="video/mp4"
            />
            {fileValidationError && <Form.Control.Feedback type="invalid">{fileValidationError}</Form.Control.Feedback>}
          </Form.Group>
        </Form>
        {errorMessage && (
          <Alert variant="danger" dismissible={true}>
            {errorMessage}
          </Alert>
        )}
        <ProgressBar min={0} max={1} now={uploadProgress} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={!readyForUpload || updating}>
          {updating ? "Uploading..." : "Upload"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    updateProfileVideo: (file: File, progress: ProgressCallback) => dispatch(updateProfileVideo(file, progress)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ProfileVideoModalConnectedProps = ConnectedProps<typeof connector>;

export default connector(ProfileVideoModal);
