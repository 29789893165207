import { Job } from "../data/job";
import { JobTemplate } from "../data/jobTemplate";
import * as JobsReducers from "../reducers/jobs";
import * as LocationsReducers from "../reducers/locations";
import * as Api from "../rest-client/api";
import { AppAsyncAction } from "./types";

export function getJobsForLocation(locationId: number): AppAsyncAction<Job[]> {
  return (dispatch) =>
    Api.fetchJobs({ locationId }).then(({ responseJson }) => {
      dispatch(JobsReducers.setJobsForLocation({ locationId, jobs: responseJson }));
      return responseJson;
    });
}

export function updateJob(job: Job): AppAsyncAction<Job> {
  return (dispatch) => {
    const { id, ...attributes } = job;
    return Api.updateJob(id, attributes).then(({ responseJson }) => {
      dispatch(JobsReducers.jobUpdated(responseJson));
      return responseJson;
    });
  };
}

export function createJob(attributes: Omit<Job, "id">): AppAsyncAction<Job> {
  return (dispatch) =>
    Api.createJob(attributes).then(({ responseJson }) => {
      dispatch(JobsReducers.jobCreated(responseJson));
      dispatch(LocationsReducers.jobsAdded([responseJson]));
      return responseJson;
    });
}

export type BatchCreateJobsParams = {
  attributes: Omit<Job, "id">;
  locationIds: number[];
};

export function batchCreateJobs({ attributes, locationIds }: BatchCreateJobsParams): AppAsyncAction<Job[]> {
  return (dispatch) =>
    Api.batchCreateJobs({ attributes, locationIds }).then(({ responseJson }) => {
      dispatch(JobsReducers.jobsCreated(responseJson));
      dispatch(LocationsReducers.jobsAdded(responseJson));
      return responseJson;
    });
}

export function newJob(args: { templateId?: number; templateJobId?: number }): AppAsyncAction<Job> {
  return () =>
    Api.newJob(args).then(({ responseJson }) => {
      return responseJson;
    });
}

export function getJobTemplates(): AppAsyncAction<JobTemplate> {
  return (dispatch) =>
    Api.fetchJobTemplates().then(({ responseJson }) => {
      dispatch(JobsReducers.setTemplates(responseJson));
      return responseJson;
    });
}
