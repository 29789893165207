import { useCallback, useMemo, useState } from "react";
import { Button, Nav } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { getConnectionsForLocation } from "../../actions/connections";
import { AppDispatch, RootState } from "../../app/store";
import { AdminScope } from "../../data/user";
import { sortLocations } from "../../utils/locations";
import EditLocationModal, { EditLocationModalResult } from "./EditLocationModal";
import "./Locations.scss";

type LocationPageProps = LocationsPageConnectedProps;

function LocationsPage({ managedLocations, canManageLocations }: LocationPageProps) {
  const location = useLocation();
  const [locationModalKey, setLocationModalKey] = useState<string>();
  const [showAddLocationModal, setShowAddLocationModal] = useState(false);

  const match = location.pathname.match(/\/locations\/(\d+)/i);
  let locationId: number | null = null;
  if (match) {
    locationId = parseInt(match[1]);
  }

  const sortedLocations = useMemo(() => sortLocations(managedLocations), [managedLocations]);

  const addLocationClick = useCallback(() => {
    setLocationModalKey(`add-location-modal-${new Date().getTime()}`);
    setShowAddLocationModal(true);
  }, [setLocationModalKey, setShowAddLocationModal]);

  const navigate = useNavigate();
  const closeLocationModal = useCallback(
    ({ cancelled, locationId }: EditLocationModalResult) => {
      setShowAddLocationModal(false);
      if (!cancelled) {
        setTimeout(() => navigate(`/locations/${locationId}`), 500);
      }
    },
    [setShowAddLocationModal, navigate]
  );

  return (
    <div className="position-relative h-100 d-flex flex-row gap-3">
      <div
        className="d-flex flex-column justify-content-between sticky-left-panel sticky-left-panel-full-height"
        style={{ flex: "0 0 auto", minWidth: "200px", maxWidth: "250px" }}
      >
        <div style={{ flex: "1 1 10px" }} className="pb-3">
          <Nav
            className="d-flex flex-column side-nav h-100"
            activeKey={locationId ? `location-${locationId}` : undefined}
          >
            <div className="title" style={{ flex: "0 0 auto" }}>
              Locations
            </div>
            <div style={{ flex: "1 1 10px", overflowY: "auto" }}>
              {sortedLocations?.map((location) => (
                <Nav.Link
                  key={`loc-${location.id}`}
                  as={Link}
                  to={`/locations/${location.id}`}
                  eventKey={`location-${location.id}`}
                  active={locationId === null ? false : undefined}
                >
                  <div>
                    {location.name}
                    <br />
                    <span className="fs-smaller">({location.address_1})</span>
                  </div>
                </Nav.Link>
              ))}
            </div>
          </Nav>
        </div>
        {canManageLocations && (
          <div className="d-flex flex-column align-items-center mb-3" style={{ flex: "0 0 auto" }}>
            <Button variant="success" onClick={addLocationClick}>
              Add a new location
            </Button>
          </div>
        )}
      </div>
      <div className="flex-grow-1 flex-shrink-1 h-100">
        <Outlet />
      </div>
      <EditLocationModal key={locationModalKey} visible={showAddLocationModal} onClosed={closeLocationModal} />
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    canManageLocations: state.user.admin_scopes?.includes(AdminScope.location),
    managedLocations: state.locations.locations ?? null,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchConnectionsForLocation: (locationId: number) => dispatch(getConnectionsForLocation(locationId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type LocationsPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(LocationsPage);
