import React, { HTMLAttributes, useId } from "react";
import { classNames } from "../utils/classNameList";

type ToggleButtonItem = {
  value?: string;
  label: string;
};

interface ToggleButtonProps extends HTMLAttributes<HTMLDivElement> {
  items: ToggleButtonItem[];
  selectedItem?: ToggleButtonItem | string | null;
  onItemClick: (item: ToggleButtonItem) => void;
}

const itemValue = (item: ToggleButtonItem): string => {
  return item.value ?? item.label;
};

const ToggleButtons = ({ items, selectedItem, onItemClick, className, ...otherProps }: ToggleButtonProps) => {
  const groupId = useId();
  const selectedValue =
    selectedItem ? typeof selectedItem === "string" ? selectedItem : itemValue(selectedItem) : null;
  return (
    <div className={classNames('btn-group', className)} role="group" {...otherProps}>
      {items.map((item: ToggleButtonItem, index: number) => {
        const itemId = `${groupId}-${index}`;
        const isSelected = selectedValue === itemValue(item);
        return (
          <React.Fragment key={`{item-${index}}`}>
            <input
              type="radio"
              className="btn-check"
              name="options"
              id={itemId}
              defaultChecked={isSelected}
              onClick={() => onItemClick(item)}
            />
            <label className={`btn ${isSelected ? 'btn-dark' : 'btn-outline-dark'}`} htmlFor={itemId}>
              {item.label}
            </label>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default ToggleButtons;
