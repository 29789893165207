import * as Sentry from "@sentry/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { APIProvider } from "@vis.gl/react-google-maps";
import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import { checkAuthentication } from "./actions/authentication";
import "./App.scss";
import MainApp from "./app/MainApp";
import { AppDispatch, RootState } from "./app/store";
import UnauthenticatedAppContent from "./app/UnauthenticatedApp";
import LoadingOverlay from "./components/LoadingOverlay";

const stripeLoader = import.meta.env.VITE_STRIPE_KEY ? loadStripe(import.meta.env.VITE_STRIPE_KEY) : null;

const PageContainer = ({ children, fullscreen = false }: PropsWithChildren & { fullscreen?: boolean | undefined }) => {
  const classNames = ["App"];
  if (fullscreen) {
    classNames.push("fullscreen");
  }
  return <div className={classNames.join(" ")}>{children}</div>;
};

const Initializing = () => (
  <PageContainer fullscreen={true}>
    <LoadingOverlay message="Initializing..." />
  </PageContainer>
);

const MobileDeviceMessage = () => (
  <div className="d-flex flex-column align-items-center justify-content-center gap-3 vh-100 vw-100">
    <div className="text-center fs-4 mb-5">
      This web app is not designed for smaller screens such as mobile devices. Please a screen with higher resolution
      such as a desktop or tablet.
    </div>
  </div>
);

const isMobile = () => {
  return window.innerWidth < 768;
};

type AppProps = AppConnectedProps;

const App = ({ isLoggedIn, checkAuthentication }: AppProps) => {
  const [mobile, setMobile] = useState(isMobile());
  const handleWindowSizeChange = useCallback(() => setMobile(isMobile()), [setMobile]);

  const hasStartedInitialization = useRef(false);
  const [hasInitialized, setHasInitialized] = useState(false);

  const initializeApp = useCallback(() => {
    hasStartedInitialization.current = true;
    checkAuthentication().then(() => {
      setHasInitialized(true);
    });
  }, [checkAuthentication]);

  useEffect(() => {
    if (!hasStartedInitialization.current) {
      window.addEventListener("resize", handleWindowSizeChange);
      initializeApp();
    }
  }, [initializeApp, handleWindowSizeChange]);

  const mainContent = useMemo(() => {
    if (mobile) {
      return <MobileDeviceMessage />;
    } else if (!hasInitialized) {
      return <Initializing />;
    } else if (isLoggedIn) {
      return <MainApp />;
    } else {
      return <UnauthenticatedAppContent />;
    }
  }, [hasInitialized, isLoggedIn, mobile]);

  return (
    <Sentry.ErrorBoundary fallback={<div>An unexpected error occurred</div>}>
      <Elements stripe={stripeLoader}>
        <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY!}>{mainContent}</APIProvider>
      </Elements>
    </Sentry.ErrorBoundary>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoggedIn: state.auth.loggedIn ?? false,
  user: state.user,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  checkAuthentication: () => dispatch(checkAuthentication()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type AppConnectedProps = ConnectedProps<typeof connector>;

const connectedApp = connector(App);
const exportedApp = import.meta.env.MODE === "development" ? connectedApp : Sentry.withProfiler(connectedApp);

export default exportedApp;
