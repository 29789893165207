import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Job } from "../data/job";
import { JobTemplate } from "../data/jobTemplate";

type JobsForLocation = {
  locationId: number;
  jobs: Job[];
};

export interface JobsState {
  jobsForLocation: JobsForLocation | null;
  templates: JobTemplate[] | null;
}

const initialState: JobsState = {
  jobsForLocation: null,
  templates: null,
};

export const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    setJobsForLocation: (state, action: PayloadAction<JobsForLocation>) => {
      state.jobsForLocation = action.payload;
    },
    jobUpdated: (state, action: PayloadAction<Job>) => {
      if (state.jobsForLocation) {
        state.jobsForLocation = {
          ...state.jobsForLocation,
          jobs: state.jobsForLocation.jobs.map((job) => {
            if (job.id === action.payload.id) {
              return action.payload;
            } else {
              return job;
            }
          }),
        };
      }
    },
    jobCreated: (state, action: PayloadAction<Job>) => {
      if (state.jobsForLocation && state.jobsForLocation.locationId === action.payload.company_location_id) {
        state.jobsForLocation.jobs = [...(state.jobsForLocation.jobs ?? []), action.payload];
      }
    },
    jobsCreated: (state, action: PayloadAction<Job[]>) => {
      if (state.jobsForLocation != null) {
        if (action.payload.some((job) => job.company_location_id === state.jobsForLocation!.locationId)) {
          state.jobsForLocation.jobs = [
            ...state.jobsForLocation.jobs,
            ...action.payload.filter((job) => job.company_location_id === state.jobsForLocation!.locationId),
          ];
        }
      }
    },
    setTemplates: (state, action: PayloadAction<JobTemplate[]>) => {
      state.templates = action.payload;
    },
  },
});

export const { setJobsForLocation, jobUpdated, jobCreated, jobsCreated, setTemplates } = jobsSlice.actions;
export default jobsSlice.reducer;
