import { Job } from "../data/job";
import BaseRestClient from "./baseClient";

export function fetchJobs({ locationId }: { locationId?: number }) {
  const queryParams = new URLSearchParams();
  if (locationId) {
    queryParams.append("location_id", locationId.toString());
  }
  return BaseRestClient.fetch("api/v3/employer/jobs?" + queryParams.toString());
}

export function updateJob(jobId: number, attributes: Partial<Job>) {
  return BaseRestClient.patch(`api/v3/employer/jobs/${jobId}`, {
    job: attributes,
  });
}

export function createJob(attributes: Omit<Job, "id">) {
  return BaseRestClient.post("api/v3/employer/jobs", {
    job: attributes,
  });
}

export function batchCreateJobs({ attributes, locationIds }: { attributes: Omit<Job, "id">; locationIds: number[] }) {
  return BaseRestClient.post("api/v3/employer/jobs/batch_create", {
    job: attributes,
    location_ids: locationIds,
  });
}

export function newJob({ templateId, templateJobId }: { templateId?: number; templateJobId?: number }) {
  const queryParams = new URLSearchParams();
  if (templateId) {
    queryParams.append("template_id", templateId.toString());
  }
  if (templateJobId) {
    queryParams.append("template_job_id", templateJobId.toString());
  }
  return BaseRestClient.fetch("api/v3/employer/jobs/new?" + queryParams.toString());
}

export function fetchJobTemplates() {
  return BaseRestClient.fetch("api/v3/employer/job_templates");
}
