import { Outlet, RouterProvider, createBrowserRouter, useLoaderData } from "react-router-dom";
import LoginScreen from "../pages/auth/LoginScreen";
import PartnerLandingPage from "../pages/partner/Landing";
import RegistrationPage from "../pages/registration/RegistrationPage";

function RegistrationPageElement() {
  const loaderData = useLoaderData();
  const { step } = loaderData as { step: string | undefined };
  return <RegistrationPage step={step} />;
}

function PartnerLandingPageElement() {
  const { partnerId, idToken } = useLoaderData() as { partnerId?: string | null; idToken?: string | null };
  return <PartnerLandingPage partnerId={partnerId} idToken={idToken} />;
}

const unauthenticatedAppRouter = createBrowserRouter([
  {
    path: "/",
    element: <UnauthenticatedApp />,
    children: [
      { index: true, element: <LoginScreen /> },
      { path: "/", element: <LoginScreen /> },
      {
        path: "/partner",
        loader: ({ request }) => {
          const url = new URL(request.url);
          return {
            idToken: url.searchParams.get("id_token"),
            partnerId: url.searchParams.get("partner_id"),
          };
        },
        element: <PartnerLandingPageElement />,
      },
      {
        path: "/partner/:partnerId",
        loader: ({ params, request }) => {
          const url = new URL(request.url);
          return {
            idToken: url.searchParams.get("id_token") ?? url.searchParams.get("token"),
            partnerId: params.partnerId,
          };
        },
        element: <PartnerLandingPageElement />,
      },
      {
        path: "/registration/:step",
        loader: ({ params }) => {
          return {
            step: params.step,
          };
        },
        element: <RegistrationPageElement />,
      },
      { path: "*", element: <LoginScreen /> },
    ],
  },
]);

function UnauthenticatedApp() {
  return <Outlet />;
}

function UnauthenticatedAppContent() {
  return <RouterProvider router={unauthenticatedAppRouter}></RouterProvider>;
}

export default UnauthenticatedAppContent;
