import { useCallback, useRef, useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { ModelValidationErrors } from "../../data/validation";
import { StripeCardError } from "../../utils/errors";
import CreditCardForm, { CreditCardFormRef } from "./CreditCardForm";
import "./Payments.scss";

type AddCreditCardModalProps = {
  visible?: boolean;
  onClosed: () => void;
  onSubmit: (args: { tokenId: string }) => Promise<void>;
};

function AddCreditCardModal({ visible, onClosed, onSubmit }: AddCreditCardModalProps) {
  const [updating, setUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [validationErrors, setValidationErrors] = useState<ModelValidationErrors>();
  const creditCardFormRef = useRef<CreditCardFormRef | null>(null);

  const handleClose = useCallback(() => {
    onClosed();
    setUpdating(false);
    setErrorMessage(undefined);
    setValidationErrors(undefined);
    creditCardFormRef.current!.reset();
  }, [onClosed]);

  const handleSave = useCallback(() => {
    setUpdating(true);
    setErrorMessage(undefined);
    creditCardFormRef
      .current!.createToken()
      .then((tokenId) => {
        if (tokenId) {
          onSubmit({ tokenId })
            .then(() => {
              handleClose();
            })
            .catch((error) => {
              if (error instanceof StripeCardError) {
                if (error.param != null && error.param.length > 0) {
                  setErrorMessage(undefined);
                  setValidationErrors({ [error.param]: error.message });
                } else {
                  setErrorMessage(error.message);
                  setValidationErrors(undefined);
                }
              } else {
                setErrorMessage(error.message);
                setValidationErrors(undefined);
              }
            });
        }
      })
      .catch((error: Error) => {
        setErrorMessage(error.message);
      })
      .finally(() => setUpdating(false));
  }, [handleClose, onSubmit]);

  return (
    <Modal show={visible} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Credit Card</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CreditCardForm ref={creditCardFormRef} validationErrors={validationErrors} />
        {errorMessage && (
          <Alert variant="danger" dismissible={true} onClose={() => setErrorMessage(undefined)}>
            {errorMessage}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={updating}>
          {updating ? "Processing..." : "Submit"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddCreditCardModal;
