import { conversationHasUnreadMessage, ParsedConversation } from "../../utils/conversations";

export const ConversationFilters: {
  category: string;
  matcher: (conversation: ParsedConversation) => boolean;
  emptyListMessage: string;
}[] = [
  {
    category: "all",
    matcher: () => true,
    emptyListMessage: "No conversations started",
  },
  {
    category: "unread",
    matcher: (conversation) => conversationHasUnreadMessage(conversation),
    emptyListMessage: "No unread conversations found",
  },
];

export function conversationFiltersForCategory(category: string) {
  return ConversationFilters.find((f) => f.category === category);
}
export const ConversationFilters_All = conversationFiltersForCategory("all")!;
export const ConversationFilters_Unread = conversationFiltersForCategory("unread")!;
