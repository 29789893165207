import { useMemo } from "react";
import { Container } from "react-bootstrap";
import { LoaderFunctionArgs, RouteObject, useLoaderData } from "react-router";
import store from "../../app/store";
import { parseConversation } from "../../utils/conversations";
import ItemNotFoundPage from "../common/ItemNotFoundPage";
import Conversation from "./Conversation";
import ConversationsFragment from "./ConversationsFragment";
import ConversationsPage from "./ConversationsPage";

export const Pathname = "conversations";
export const RootPath = "/" + Pathname;

export const SearchParams = {
  Category: "category",
  LocationId: "location_id",
  SelectedConversationId: "selected_conversation_id",
};

const ConversationsElement = () => {
  const props = useLoaderData() as any;
  return (
    <Container className="content-scrollable">
      <ConversationsPage {...props} />
    </Container>
  );
};

const ConversationsFragmentElement = () => {
  const props = useLoaderData() as any;
  let key = "category-all";
  if (props.locationId) {
    key = `location-${props.locationId}`;
  } else if (props.category) {
    key = `category-${props.category}`;
  }
  return <ConversationsFragment key={key} {...props} />;
};

const ConversationPageElement = () => {
  const { conversationId } = useLoaderData() as { conversationId: number };
  const parsedConversation = useMemo(() => {
    const {
      conversations: { conversations },
    } = store.getState();
    const conversation = conversations?.find((c) => c.id === conversationId);
    if (conversation) {
      return parseConversation(conversation);
    } else {
      return undefined;
    }
  }, [conversationId]); 

  if (parsedConversation) {
    return <Conversation conversation={parsedConversation} />;
  } else {
    return <ItemNotFoundPage itemDescription="conversation" />
  }
};

const commonLoader = ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  return {
    category: url.searchParams.get(SearchParams.Category),
    locationId: url.searchParams.has(SearchParams.LocationId)
      ? parseInt(url.searchParams.get(SearchParams.LocationId)!)
      : null,
    selectedConversationId: url.searchParams.has(SearchParams.SelectedConversationId)
      ? parseInt(url.searchParams.get(SearchParams.SelectedConversationId)!)
      : null,
  };
};

const routes: RouteObject = {
  path: Pathname,
  loader: commonLoader,
  element: <ConversationsElement />,
  children: [
    {
      index: true,
      loader: commonLoader,
      element: <ConversationsFragmentElement />,
    },
    {
      path: ":conversationId",
      loader: ({ params }) => {
        return {
          conversationId: parseInt(params.conversationId!),
        };
      },
      element: <ConversationPageElement />,
    },
  ],
};

export default routes;
