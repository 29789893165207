export function enterKeyPressPatch(inputField: HTMLInputElement) {
  inputField.addEventListener("keydown", (event: KeyboardEvent) => {
    if (event.code === "Enter" || event.code === "Escape") {
      let choicesVisible = false;
      document.querySelectorAll(".pac-container").forEach((element) => {
        if (element.checkVisibility()) {
          choicesVisible = true;
        }
      });
      if (choicesVisible) {
        event.preventDefault();
      }
    }
  });
}