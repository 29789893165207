export function FollowOnLinkedInSidePanel() {
  return (
    <div className="d-flex flex-column border rounded bg-white align-items-center gap-3 p-3 text-center">
      <div style={{ fontSize: "smaller" }}>
        <span className="fw-bold">Want to learn how you can be the best business on the block?</span>
        <br />
        Follow our LinkedIn to stay up to date with latest hiring trends &amp; tips for hourly employers like you!
      </div>
      <a href="https://link.juvoapp.com/quY" className="btn btn-link btn-sm">
        <img
          src="/images/follow-linkedin.png"
          alt="Add Location"
          className="mb-2"
          style={{ width: "150px", objectFit: "contain" }}
        />
        Visit our LinkedIn
      </a>
    </div>
  );
}
