import { UserProfile } from "../data/userProfile";
import { ProgressCallback } from "../types/miscellaneous";
import BaseRestClient from "./baseClient";
import { Blob, DirectUpload } from "@rails/activestorage";

export function updateProfile(userId: number, updatedAttributes: Partial<UserProfile>) {
  return BaseRestClient.patch(`api/v1/users/${userId}/profile/undefined`, {
    profile: updatedAttributes,
  });
}

export function updateProfilePhoto(blobId: string) {
  return BaseRestClient.post("api/v2/users/update_photo", {
    blob_id: blobId,
  });
}

export function uploadBlob(file: File, onProgress: ProgressCallback) {
  return new Promise<Blob>((resolve, reject) => {
    new DirectUpload(file, BaseRestClient.getHost() + "api/v1/direct_uploads", {
      directUploadWillStoreFileWithXHR: (xhr) => {
        xhr.upload.addEventListener("progress", (event) => {
          if (onProgress) {
            onProgress(event.loaded / event.total);
          }
        });
      },
    }).create((error, blob) => {
      if (error) {
        reject(error);
      } else {
        resolve(blob);
      }
    });
  });
}

export function updateProfileVideo(blobId: string) {
  return BaseRestClient.post("api/v2/users/update_video", {
    blob_id: blobId,
  });
}

export function getVideoLocations() {
  return BaseRestClient.fetch("/api/v1/employer/video_locations");
}

export function updateVideoLocations(videoLocations: number[]) {
  return BaseRestClient.patch("/api/v1/employer/video_locations", {
    location_ids: videoLocations,
  });
}
