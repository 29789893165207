import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CustomAction } from "../data/customActions";
import { JsonMap } from "../types/json";

export interface InitializationState {
  isDataLoaded: boolean;
  initialAppPath?: string;
  appSettings: JsonMap | null;
  customActions?: CustomAction[];
}

const initialState: InitializationState = {
  isDataLoaded: false,
  appSettings: null,
};

export const initializationSlice = createSlice({
  name: "initialization",
  initialState,
  reducers: {
    setDataLoaded: (state, action: PayloadAction<boolean>) => {
      state.isDataLoaded = action.payload;
    },
    setAppSettings: (state, action: PayloadAction<JsonMap | null>) => {
      if (action.payload == null) {
        state.appSettings = null;
        state.customActions = undefined;
      } else {
        const { custom_actions, ...otherSettings } = action.payload;
        state.appSettings = otherSettings;
        state.customActions = custom_actions as CustomAction[];
      }
    },
    setInitialAppPath: (state, action: PayloadAction<string | undefined>) => {
      state.initialAppPath = action.payload;
    },
  },
});

export const { setDataLoaded, setAppSettings, setInitialAppPath } = initializationSlice.actions;
export default initializationSlice.reducer;
