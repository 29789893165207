import { useMapsLibrary } from "@vis.gl/react-google-maps";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Container, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router";
import { AppDispatch, RootState } from "../../app/store";
import { Field, TextField, TextInput } from "../../components/Forms";
import { Business } from "../../data/registration";
import { ModelValidationErrors } from "../../data/validation";
import { setBusiness } from "../../reducers/registration";
import {
  collectFormValidationErrors,
  getElementValidationErrors,
  isNullOrEmpty,
  isNullOrUndefined,
  mergeModelValidationErrors,
} from "../../utils/validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { authenticateAndClearRegistration, validateBusiness } from "../../actions/registration";
import { ButtonSpinnerOverlay } from "../../components/ButtonSpinnerOverlay";
import { FailedRequestError } from "../../rest-client/errors";
import { enterKeyPressPatch } from "../../utils/placesAutocomplete";
import { addressComponentsToAddress } from "../../utils/googleAddress";
import { PaymentMethod } from "../../data/payments";
import { DBA_TOOLTIP_TEXT } from "../../constants";

type BusinessFormProps = BusinessFormConnectedProps;

function BusinessForm({
  validateBusiness,
  business: savedBusiness,
  saveBusiness,
  paymentMethod,
  paymentDescription,
  pricingPackage,
  authenticateAndClearRegistration,
}: BusinessFormProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const streetAddressInputRef = useRef<HTMLInputElement>(null);

  const [business, setBusiness] = useState<Partial<Business>>(savedBusiness ?? {});
  const [validating, setValidating] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ModelValidationErrors | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [validationVisibile, setValidationVisibile] = useState(false);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setBusiness({ ...business, [event.target.name]: event.target.value });
      if (validationErrors && event.target.name in validationErrors) {
        const updatedValidationErrors = { ...validationErrors };
        delete updatedValidationErrors[event.target.name];
        setValidationErrors(updatedValidationErrors);
      }
    },
    [business, setBusiness, validationErrors, setValidationErrors]
  );

  const navigate = useNavigate();

  const showValidationErrors = useCallback(
    (errors: ModelValidationErrors | null, isServerSide: boolean) => {
      setValidationErrors(errors);
      if (isServerSide) {
        formRef.current!.classList.remove("was-validated");
      } else {
        formRef.current!.classList.add("was-validated");
      }
      setValidationVisibile(true);
    },
    [setValidationErrors, setValidationVisibile]
  );

  const submitHandler = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      const isValid = formRef.current!.checkValidity();
      if (isValid) {
        const completedBusiness = business as Business;
        validateBusiness(completedBusiness)
          .then(() => {
            saveBusiness(business as Business);
            if (
              paymentMethod === PaymentMethod.Manual &&
              isNullOrUndefined(pricingPackage) &&
              isNullOrEmpty(paymentDescription)
            ) {
              authenticateAndClearRegistration();
            } else {
              navigate("/registration/payment");
            }
          })
          .catch((error) => {
            setValidating(false);
            if (error instanceof FailedRequestError) {
              if (error.isConflict()) {
                showValidationErrors({ email: "An account with this name already exists" }, true);
                return;
              } else if (error.isUnprocessableEntity()) {
                showValidationErrors(error.responseJson, true);
                return;
              }
            }
            setErrorMessage("An unexpected error occurred");
          });
      } else {
        const formValidationErrors = collectFormValidationErrors(formRef.current!);
        showValidationErrors(formValidationErrors, false);
      }
    },
    [
      business,
      validateBusiness,
      saveBusiness,
      showValidationErrors,
      navigate,
      authenticateAndClearRegistration,
      paymentDescription,
      paymentMethod,
      pricingPackage,
    ]
  );

  const blurHandler = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
      if (!validationVisibile) return;
      const newValidationError = getElementValidationErrors(event.target);
      showValidationErrors(mergeModelValidationErrors(validationErrors, event.target.name, newValidationError), false);
    },
    [validationErrors, showValidationErrors, validationVisibile]
  );

  const [placeAutocomplete, setPlaceAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const places = useMapsLibrary("places");

  useEffect(() => {
    if (!places || !streetAddressInputRef.current) return;

    const _placeAutocomplete = new places.Autocomplete(streetAddressInputRef.current, {
      componentRestrictions: { country: ["us"] },
      fields: ["address_components", "geometry"],
      types: ["address"],
    });
    setPlaceAutocomplete(_placeAutocomplete);
    enterKeyPressPatch(streetAddressInputRef.current);
  }, [places]);

  const updateAddress = useCallback(
    (place: google.maps.places.PlaceResult) => {
      if (place.address_components) {
        setBusiness({
          ...business,
          ...addressComponentsToAddress(place.address_components),
        });
      }
    },
    [business, setBusiness]
  );

  useEffect(() => {
    if (!placeAutocomplete) return;
    placeAutocomplete.addListener("place_changed", () => {
      const place = placeAutocomplete.getPlace();
      updateAddress(place);
    });
  }, [placeAutocomplete, updateAddress]);

  return (
    <Form onSubmit={submitHandler} ref={formRef} noValidate>
      <Container fluid>
        <Row>
          <Col className="mt-3 mb-3">
            <h4 className="text-primary">Share your business info!</h4>
            Let's make sure your pin looks perfect on our network
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <TextField
              value={business?.name ?? ""}
              name="name"
              label="Company Legal Name"
              maxLength={100}
              minLength={5}
              validationErrors={validationErrors?.name}
              required={true}
              onChange={handleChange}
              onBlur={blurHandler}
            />
          </Col>
          <Col sm={6}>
            <Field>
              <Form.Label>
                <>
                  <span>DBA</span>&nbsp;
                  <OverlayTrigger overlay={<Tooltip>{DBA_TOOLTIP_TEXT}</Tooltip>}>
                    <FontAwesomeIcon icon={faCircleInfo} />
                  </OverlayTrigger>
                </>
              </Form.Label>
              <TextInput
                value={business?.brand_name ?? ""}
                name="brand_name"
                maxLength={100}
                minLength={2}
                validationErrors={validationErrors?.brand_name}
                required={true}
                onChange={handleChange}
                onBlur={blurHandler}
              />
            </Field>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <TextField
              value={business?.address_1 ?? ""}
              name="address_1"
              label="Street Address"
              autoComplete="false"
              maxLength={100}
              minLength={5}
              validationErrors={validationErrors?.address_1}
              required={true}
              onChange={handleChange}
              onBlur={blurHandler}
              ref={streetAddressInputRef}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6} lg={6} xl={6}>
            <TextField
              value={business?.address_city ?? ""}
              name="address_city"
              label="City"
              maxLength={100}
              minLength={2}
              validationErrors={validationErrors?.address_city}
              required={true}
              onChange={handleChange}
              onBlur={blurHandler}
            />
          </Col>
          <Col sm={3} md={3} lg={3} xl={3}>
            <TextField
              value={business?.address_state ?? ""}
              name="address_state"
              label="State"
              maxLength={100}
              minLength={2}
              validationErrors={validationErrors?.address_state}
              required={true}
              onChange={handleChange}
              onBlur={blurHandler}
            />
          </Col>
          <Col sm={3} md={3} lg={3} xl={3}>
            <TextField
              value={business?.address_zip ?? ""}
              name="address_zip"
              label="Zipcode"
              maxLength={5}
              minLength={5}
              pattern="^\d{5}$"
              validationErrors={validationErrors?.address_zip}
              required={true}
              onChange={handleChange}
              onBlur={blurHandler}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {errorMessage && (
              <Alert variant="danger" dismissible onClose={() => setErrorMessage(null)}>
                {errorMessage}
              </Alert>
            )}
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="col-md-auto mt-3 position-relative">
            <Button variant="primary" type="submit" disabled={validating}>
              Continue
            </Button>
            {validating && <ButtonSpinnerOverlay />}
          </Col>
        </Row>
      </Container>
    </Form>
  );
}

const mapStateToProps = (state: RootState) => ({
  business: state.registration.business,
  paymentMethod: state.registration.payment_method,
  paymentDescription: state.registration.payment_description,
  pricingPackage: state.registration.pricing_package,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  validateBusiness: (business: Business) => dispatch(validateBusiness(business)),
  saveBusiness: (business: Business) => dispatch(setBusiness(business)),
  authenticateAndClearRegistration: () => dispatch(authenticateAndClearRegistration()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type BusinessFormConnectedProps = ConnectedProps<typeof connector>;

export default connector(BusinessForm);
