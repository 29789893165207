import React, { ReactNode, useState } from "react";
import { To } from "react-router";
import { ParsedConversation } from "../../utils/conversations";
import Connection from "../connections/Connection";
import Conversation from "./Conversation";
import ConversationList from "./ConversationList";

type ConversationNavigatorProps = {
  conversations: ParsedConversation[];
  emptyListMessage: ReactNode;
  selectedConversationId: number;
  itemLink: (item: ParsedConversation) => To;
};

function ConversationNavigator({
  conversations,
  emptyListMessage,
  selectedConversationId,
  itemLink,
}: ConversationNavigatorProps) {
  const [connectionVisible, setConnectionVisible] = useState(false);
  const selectedConversation = conversations.find((c) => c.id === selectedConversationId)!;

  return (
    <div className="d-flex flex-row h-100 gap-3 conversation-navigator">
      {conversations.length === 0 ? (
        <div>{emptyListMessage}</div>
      ) : (
        <React.Fragment>
          {connectionVisible && selectedConversation ? (
            <Connection
              connectionId={selectedConversation.connection.id}
              key={`connection-${selectedConversation.connection.id}`}
              showExpander={true}
              expanded={true}
              expandClick={(expanded) => setConnectionVisible(expanded)}
              style={{ flex: "1 1" }}
            />
          ) : (
            <React.Fragment>
              <ConversationList
                conversations={conversations}
                selectedConversationId={selectedConversationId}
                style={{ flex: "0.4 0.4 40%", overflowY: "auto" }}
                itemLink={itemLink}
              />
              <Conversation
                conversation={selectedConversation}
                className="h-100"
                expanded={false}
                expandClick={(expanded) => setConnectionVisible(expanded)}
                style={{ flex: "0.6 0.6 60%" }}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

export default ConversationNavigator;
