export enum CustomActionStatus {
  NotStarted = 'not_started',
  Started = 'started',
  Completed = 'completed',
  Failed = 'failed'
}

export enum CustomActionFailureReason {
  Authorization = 'authorization', 
  BadRequest = 'bad_request', 
  Configuration = 'configuration', 
  Other = 'other',  
}

export type CustomActionResult = {
  action_id: number;
  object_id: number;
  status: CustomActionStatus;
  can_resubmit: boolean;
  details?: {
    message: string;
    external_link?: string;
    link_title?: string;
    link_message?: string;
    failure_reason?: CustomActionFailureReason;
  };
};

export enum CustomActionScope {
  Seeker = 'seeker'
}
export type CustomAction = {
  id: number,
  scope: CustomActionScope,
  label: string,
  description: string
}