import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import LoadingOverlay from "../../components/LoadingOverlay";
import { AppDispatch, RootState } from "../../app/store";
import { getAppSettings, updateAppSettings } from "../../actions/appSettings";

export type NotificationSettingsRef = {
  save: () => Promise<{ [key: string]: any }>;
};

type NotificationSettingsProps = NotificationSettingsConnectedProps;

const NotificationSettings = React.forwardRef(
  (props: NotificationSettingsProps, ref: React.Ref<NotificationSettingsRef>) => {
    const { getAppSettings, updateAppSettings } = props;
    const [appSettings, setAppSettings] = useState<{ [key: string]: any }>();
    const appSettingsLoaded = useRef(false);

    useEffect(() => {
      if (!appSettingsLoaded.current) {
        getAppSettings().then((appSettings) => {
          setAppSettings(appSettings);
          appSettingsLoaded.current = true;
        });
      }
    });

    const handleSave = useCallback(() => {
      return updateAppSettings(appSettings!);
    }, [updateAppSettings, appSettings]);

    React.useImperativeHandle(
      ref,
      React.useCallback(
        () => ({
          save: handleSave,
        }),
        [handleSave]
      )
    );

    return (
      <div className="position-relative">
        <h5>How to notify me</h5>
        <Form.Check
          checked={appSettings?.push_notifications_enabled ?? true}
          onChange={(event) =>
            setAppSettings({ ...appSettings, push_notifications_enabled: event.currentTarget.checked })
          }
          type="switch"
          label="In-app (requires Juvo Jobs app)"
        />
        <Form.Check
          checked={appSettings?.email_notifications_enabled ?? true}
          onChange={(event) =>
            setAppSettings({ ...appSettings, email_notifications_enabled: event.currentTarget.checked })
          }
          type="switch"
          label="Email"
        />
        <h5 className="mt-3">When to notify me</h5>
        <Form.Check
          checked={appSettings?.notify_on_each_submission ?? true}
          onChange={(event) =>
            setAppSettings({ ...appSettings, notify_on_each_submission: event.currentTarget.checked })
          }
          type="switch"
          label="Each time a seeker connects with me"
        />
        <Form.Check
          defaultChecked={true}
          disabled={true}
          type="switch"
          label="Daily reminder for connections that haven't been reviewed"
        />
        <Form.Check
          checked={appSettings?.notify_on_each_chat_message ?? true}
          onChange={(event) =>
            setAppSettings({ ...appSettings, notify_on_each_chat_message: event.currentTarget.checked })
          }
          type="switch"
          label="Each time a seeker sends a chat message"
        />
        <Form.Check
          defaultChecked={true}
          disabled={true}
          type="switch"
          label="Daily reminder for chat messages that haven't been read"
        />
        {!appSettingsLoaded.current && <LoadingOverlay />}
      </div>
    );
  }
);

const mapStateToProps = (state: RootState) => {
  return {
    userProfile: state.user.profile,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    getAppSettings: () => dispatch(getAppSettings()),
    updateAppSettings: (updatedSettings: { [key: string]: any }) => dispatch(updateAppSettings(updatedSettings)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type NotificationSettingsConnectedProps = ConnectedProps<typeof connector>;

export default connector(NotificationSettings);
