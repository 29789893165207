import compareAsc from "date-fns/compareAsc";
import memoizeOne from "memoize-one";
import React, { CSSProperties } from "react";
import { ConversationMessage } from "../../data/conversation";
import { ParsedConversation } from "../../utils/conversations";
import "./Conversations.scss";
import { UserState } from "../../reducers/user";

type MessageGroup = {
  participant_id: number;
  messages: ConversationMessage<Date>[];
};

const groupMessages = memoizeOne((conversation: ParsedConversation) => {
  const dayGroups: {
    day: string;
    messages: ConversationMessage<Date>[];
    messageGroups: MessageGroup[];
  }[] = [];

  let lastMessageDate = null;
  const { messages } = conversation;

  // group messages by day
  for (let index = 0; index < messages.length; index++) {
    const currentMessage = messages[index];
    const currentMessageDay = currentMessage.created_at.toDateString();
    let messageDayGroup = null;
    if (lastMessageDate !== currentMessageDay) {
      messageDayGroup = {
        day: currentMessageDay,
        messages: [],
        messageGroups: [],
      };
      dayGroups.push(messageDayGroup);
      lastMessageDate = currentMessageDay;
    } else {
      messageDayGroup = dayGroups[dayGroups.length - 1];
    }
    messageDayGroup.messages.push(currentMessage);
  }

  // group messages chronologically by sender/receiver
  dayGroups.forEach((dayGroup) => {
    let lastMessageParticipantId = null,
      lastMessageGroup: MessageGroup | null = null;
    for (let index = 0; index < dayGroup.messages.length; index++) {
      const message = dayGroup.messages[index];
      if (index === 0 || message.participant_id !== lastMessageParticipantId) {
        lastMessageGroup = {
          participant_id: message.participant_id,
          messages: [message],
        };
        dayGroup.messageGroups.push(lastMessageGroup);
        lastMessageParticipantId = message.participant_id;
      } else {
        lastMessageGroup!.messages.push(message);
      }
    }
  });

  // sort messages within each group chronologically
  dayGroups.forEach((dayGroup) => {
    dayGroup.messageGroups.forEach((messageGroup) => {
      messageGroup.messages.sort((a, b) => compareAsc(a.created_at, b.created_at));
    });
  });

  return dayGroups;
});

type MessageBubbleProps = {
  message: string;
  isFirstInGroup?: boolean;
  isLastInGroup?: boolean;
  isFromCurrentUser?: boolean;
  isFromSystemUser?: boolean;
  time: string;
};
function MessageBubble({
  message,
  isFirstInGroup = false,
  isLastInGroup = false,
  isFromCurrentUser = true,
  isFromSystemUser,
  time,
}: MessageBubbleProps) {
  const bubbleClass = isFromCurrentUser
    ? "bubble bg-primary text-white"
    : isFromSystemUser
    ? "bubble bg-white border border-info"
    : "bubble bg-secondary";
  const bubbleStyle: CSSProperties = {
    alignSelf: isFromCurrentUser ? "flex-end" : "flex-start",
    alignItems: isFromCurrentUser ? "flex-end" : "flex-start",
  };
  if (!(isFirstInGroup && isLastInGroup)) {
    if (isLastInGroup) {
      if (isFromCurrentUser) {
        bubbleStyle.borderTopRightRadius = 0;
      } else {
        bubbleStyle.borderTopLeftRadius = 0;
      }
    } else if (isFirstInGroup) {
      if (isFromCurrentUser) {
        bubbleStyle.borderBottomRightRadius = 0;
      } else {
        bubbleStyle.borderBottomLeftRadius = 0;
      }
    } else {
      if (isFromCurrentUser) {
        bubbleStyle.borderTopRightRadius = 0;
        bubbleStyle.borderBottomRightRadius = 0;
      } else {
        bubbleStyle.borderTopLeftRadius = 0;
        bubbleStyle.borderBottomLeftRadius = 0;
      }
    }
  }

  const textClass = "message-text";
  const textStyle = {
    fontSize: "80%",
  };
  const timeStyle = {
    ...textStyle,
    fontSize: "60%",
  };
  return (
    <div className={bubbleClass} style={bubbleStyle}>
      <div className={textClass} style={textStyle}>
        {message}
      </div>
      <div style={timeStyle}>{time}</div>
    </div>
  );
}

type ConversationMessagesProps = {
  currentUser: UserState;
  conversation: ParsedConversation;
};

function ConversationMessages({ currentUser, conversation }: ConversationMessagesProps) {
  const groupedMessages = groupMessages(conversation);
  const currentUserParticipantId = conversation.participants.find((p) => p.user_id === currentUser.id)?.id;

  return (
    <div className="chat-messages">
      {groupedMessages.map((group, groupIndex) => {
        return (
          <React.Fragment key={`daygroup-${groupIndex}`}>
            {group.messageGroups.map((messageGroup, messageGroupIndex) => {
              const participant = conversation.participants.find((p) => p.id === messageGroup.participant_id)!;
              return (
                <div className="bubble-group" key={`message-group-${group.day}-${messageGroupIndex}`}>
                  {participant.id !== currentUserParticipantId && (
                    <div className="participant">
                      {participant.is_system_user ? (
                        <React.Fragment>
                          <img alt="User Avatar" src="/images/map-pin.png" className="participant-icon" />
                          <span className="participant-label">Juvo</span>
                        </React.Fragment>
                      ) : (
                        <span className="participant-label">
                          {participant.user_id === conversation.seeker.id
                            ? "Seeker"
                            : conversation.employer.company_name}
                        </span>
                      )}
                    </div>
                  )}
                  <React.Fragment>
                    {messageGroup.messages.map((message, index) => {
                      return (
                        <MessageBubble
                          message={message.message}
                          time={message.created_at.toLocaleTimeString("en-US", {
                            hour12: true,
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                          isFirstInGroup={index === 0}
                          isLastInGroup={index === messageGroup.messages.length - 1}
                          isFromCurrentUser={messageGroup.participant_id === currentUserParticipantId}
                          isFromSystemUser={participant.is_system_user}
                          key={message.id}
                        />
                      );
                    })}
                  </React.Fragment>
                </div>
              );
            })}
            <div className="message-day-separator">{group.day}</div>
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default React.memo(ConversationMessages);
