import { useMemo } from "react";
import { Nav } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { Outlet, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { RootState } from "../../app/store";
import { FollowOnLinkedInSidePanel } from "../common/FollowOnLinkedSidePanel";
import "./Jobs.scss";
import { RootPath, SearchParams } from "./routes";

type JobsPageProps = JobsPageConnectedProps;

function JobsPage({ managedLocations }: JobsPageProps) {
  const navLocation = useLocation();

  const locationId = useMemo(() => {
    const searchParams = new URLSearchParams(navLocation.search);
    return searchParams.has(SearchParams.LocationId)
      ? parseInt(searchParams.get(SearchParams.LocationId)!)
      : managedLocations![0].id;
  }, [managedLocations, navLocation.search]);

  return (
    <div className="h-100 d-flex flex-row gap-3">
      <div
        className="sticky-left-panel sticky-left-panel-full-height pb-3"
        style={{ flex: "0 0 auto", maxWidth: "250px" }}
      >
        <Nav
          defaultActiveKey={`location-${managedLocations![0].id}`}
          activeKey={`location-${locationId}`}
          className="d-flex flex-column side-nav h-100 w-100"
        >
          <div className="title">Jobs</div>
          <div style={{ flex: "1 1", overflowY: "auto" }}>
            {managedLocations!.map((location) => (
              <Nav.Link
                as={Link}
                to={{
                  pathname: RootPath,
                  search: `${SearchParams.LocationId}=${location.id}`,
                }}
                eventKey={`location-${location.id}`}
              >
                <div>
                  {location.name}
                  <br />
                  <span className="fs-smaller">({location.address_1})</span>
                </div>
              </Nav.Link>
            ))}
          </div>
        </Nav>
      </div>
      <div className="flex-grow-1 flex-shrink-1 h-100">
        <Outlet />
      </div>
      <div className="d-flex flex-column h-100 gap-3 sticky-right-panel" style={{ flex: "0 0 160px" }}>
        <div className="align-self-end flex-grow-0 flex-shrink-0 d-flex flex-column gap-3 w-100">
          <FollowOnLinkedInSidePanel />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    managedLocations: state.locations.locations ?? null,
  };
};

const connector = connect(mapStateToProps);

type JobsPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(JobsPage);
