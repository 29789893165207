import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import { faBell, faClipboard, faListDots, faUserCircle, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import memoizeOne from "memoize-one";
import { Badge, Dropdown, Nav, Navbar } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import { logout } from "../actions/authentication";
import { AppDispatch, RootState } from "../app/store";
import { parseConversations, unreadConversationCount } from "../utils/conversations";
import "./NavBar.scss";
import { isNullOrEmpty } from "../utils/validation";

const unreadChatCount = memoizeOne((conversations) => {
  if (conversations == null) {
    return 0;
  } else {
    return unreadConversationCount(parseConversations(conversations));
  }
});

type NavItemProps = {
  activeKey: string;
  to: any;
  className?: string;
  icon: IconProp;
  label?: string;
  badge?: string | number;
};

function NavItem({ activeKey, to, className, icon, label, badge }: NavItemProps) {
  return (
    <Nav className={className} activeKey={activeKey}>
      <LinkContainer to={to}>
        <Nav.Link active={activeKey.startsWith(to)}>
          <div className="navbar-button position-relative">
            {badge && (
              <Badge bg="danger" pill={true}>
                {badge}
              </Badge>
            )}
            <FontAwesomeIcon icon={icon} />
            {label}
          </div>
        </Nav.Link>
      </LinkContainer>
    </Nav>
  );
}

type JuvoNavBarProps = JuvoNavConnectedProps;

function JuvoNavBar({ user, logout, unreadActivityCount, unreadChatCount, managedLocations }: JuvoNavBarProps) {
  const location = useLocation();
  const activeKey = location.pathname === "/" ? "/connections" : location.pathname;
  const navigate = useNavigate();
  const profileDropdownSelect = (eventKey: string | null, event: React.SyntheticEvent<unknown>) => {
    if (eventKey === "/logout") {
      logout();
      navigate("/");
      event.preventDefault();
      event.stopPropagation();
    }
  };
  return (
    <Navbar expand="lg" className="main-navbar">
      <LinkContainer to="/">
        <Navbar.Brand>
          <img alt="Juvo" src="/images/logo.png" />
        </Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <NavItem className="ms-auto" activeKey={activeKey} to="/connections" icon={faUserGroup} label="Connections" />
        <NavItem
          activeKey={activeKey}
          to="/locations"
          icon={faListDots}
          label="Locations"
          badge={isNullOrEmpty(managedLocations) ? "!" : undefined}
        />
        <NavItem activeKey={activeKey} to="/jobs" icon={faClipboard} label="Jobs" />
        <NavItem
          activeKey={activeKey}
          to="/activity"
          icon={faBell}
          label="Activity"
          badge={unreadActivityCount > 0 ? unreadActivityCount : undefined}
        />
        <NavItem
          activeKey={activeKey}
          to="/conversations"
          icon={faCommentAlt}
          label="Conversations"
          badge={unreadChatCount > 0 ? unreadChatCount : undefined}
        />
      </Navbar.Collapse>
      <Dropdown align="end" onSelect={profileDropdownSelect}>
        <Dropdown.Toggle variant="link">
          <FontAwesomeIcon icon={faUserCircle} size="2x" className="nav-link-color" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <p className="user-info">{user.email}</p>
          <LinkContainer to="/profile" key="profile">
            <Dropdown.Item eventKey="profile">Profile</Dropdown.Item>
          </LinkContainer>
          <LinkContainer to="/logout" key="logout">
            <Dropdown.Item>Logout</Dropdown.Item>
          </LinkContainer>
        </Dropdown.Menu>
      </Dropdown>
    </Navbar>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    isLoggedIn: state.auth.loggedIn,
    user: state.user,
    unreadActivityCount: state.activities.activities
      ? state.activities.activities.filter((item) => !item.read).length
      : 0,
    unreadChatCount: unreadChatCount(state.conversations.conversations),
    managedLocations: state.locations.locations,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type JuvoNavConnectedProps = ConnectedProps<typeof connector>;

export default connector(JuvoNavBar);
