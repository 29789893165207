import { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router";
import { authenticateWithIdentityToken } from "../../actions/authentication";
import { AppDispatch, RootState } from "../../app/store";
import LoadingOverlay from "../../components/LoadingOverlay";
import { setInitialAppPath } from "../../actions/initialization";
import { Link } from "react-router-dom";

type LandingPageProps = LandingPageConnectedProps & {
  partnerId?: string | null;
  idToken?: string | null;
};

function LandingPage({ partnerId, idToken, authenticateWithIdentityToken, setInitialAppPath }: LandingPageProps) {
  const initializing = useRef<boolean>(false);
  const navigate = useNavigate();
  const [hasInitialized, setHasInitialized] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    if (!initializing.current) {
      initializing.current = true;
      if (partnerId && idToken) {
        authenticateWithIdentityToken(idToken, partnerId)
          .then(({ authenticated }) => {
            if (authenticated) {
              setInitialAppPath("/connections");
              navigate("/");
            }
          })
          .catch((error) => {
            setErrorMessage(error.message);
          })
          .finally(() => {
            setHasInitialized(true);
          });
      } else {
        setErrorMessage("You have arrived at this page from an incomplete link.");
        setHasInitialized(true);
      }
      initializing.current = false;
    }
  }, [partnerId, idToken, navigate, setHasInitialized, authenticateWithIdentityToken, setInitialAppPath]);

  if (!hasInitialized) {
    return (
      <div className="App fullscreen">
        <LoadingOverlay message="Initializing..." />
      </div>
    );
  } else if (errorMessage) {
    return (
      <div className="fullscreen d-flex flex-column align-items-center justify-content-around">
        <div className="text-center">
          <div>There was an unexpected error initializing your session with Juvo.</div>
          <div className="text-danger">{errorMessage}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="fullscreen d-flex flex-column align-items-center justify-content-center gap-3">
        <h4>We're excited to welcome you to the Juvo community! 👋</h4>
        <div>
          It appears that you have not yet setup an account with Juvo. Click the button below to get started.
        </div>
        <Link to={`/registration/init?partner_id=${partnerId}&identity_token=${idToken}`} className="btn btn-success mt-3">Setup Juvo account</Link>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  loggedIn: state.auth.loggedIn,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  authenticateWithIdentityToken: (token: string, partnerId: string) =>
    dispatch(authenticateWithIdentityToken(token, partnerId)),
  setInitialAppPath: (path: string) => dispatch(setInitialAppPath(path)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type LandingPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(LandingPage);
