import {
  PaymentPackagePaymentStatus,
  PricingPackageTerm,
  PricingPackageUnitType,
} from '../constants';

export type PricingPackageGroup = {
  unit_type: PricingPackageUnitType;
  title?: string | undefined;
  description?: string | undefined;
  packages: PricingPackage[];
};

export type PricingPackage = {
  id: 1;
  description: string;
  notes: string | null;
  sort_order: number | null;
  setup_fee: number | null;
  unit_type: PricingPackageUnitType;
  unit_price: number | null;
  term: PricingPackageTerm;
  title: string;
};

export type PaymentPackage = {
  id?: number | undefined;
  name: string;
  created_at: string;
  active: boolean;
  shared: boolean;
  payment_method: PaymentMethod;
  payment_status: PaymentPackagePaymentStatus;
  paid_until: string;
  remaining_location_count?: number;
  pricing: {
    unit_type: PricingPackageUnitType;
    unit_price: number;
    setup_fee: number | null;
    term: PricingPackageTerm | null;
    description: string;
  };
  activation_cost?: PaymentPackageActivationCost;
};

export type PaymentPackageActivationCost = {
  fee: number;
  fee_details: string;
  new_paid_until: string;
}

export type PaymentPackageWithPayer = PaymentPackage & {
  payer?: Payer | undefined
}

type Payer = {
  user_id: number
  name: string;
  payment_method: CardPaymentMethod
};

export type CardPaymentMethod = {
  id: string;
  brand: string;
  expiration_month: number;
  expiration_year: number;
  last4: string;
  name: string;
};

export enum PaymentMethod {
  Card = 'card', Manual = 'manual'
}