import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo } from "react";
import { Card, Nav } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { updateLocation } from "../../actions/locations";
import { AppDispatch, RootState } from "../../app/store";
import { CompanyLocation, CompanyLocationBasic } from "../../data/companyLocation";
import { locationAddress } from "../../utils/format";

type LocationCardProps = LocationCardConnectedProps & {
  location: CompanyLocation;
  readOnly?: boolean;
  onEditClick?: (location: CompanyLocation) => void;
};

function LocationCard({ brands, location, onEditClick, readOnly = false }: LocationCardProps) {
  const brand = useMemo(() => brands?.find((brand) => brand.id === location.brand_id), [brands, location.brand_id]);

  const editClickHandler = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      if (onEditClick) {
        onEditClick(location);
      }
    },
    [location, onEditClick]
  );

  return (
    <Card className="location-card">
      <Card.Header className="bg-primary text-white">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <h5>{location.name}</h5>
          <Nav className="ms-auto">
            {!readOnly && (
              <Nav.Link className="text-white" onClick={editClickHandler}>
                <FontAwesomeIcon icon={faEdit} />
                &nbsp;Edit
              </Nav.Link>
            )}
          </Nav>
        </div>
      </Card.Header>
      <Card.Body>
        <div>{brand?.name}</div>
        <div>{location.name}</div>
        <div>{locationAddress(location)}</div>
      </Card.Body>
    </Card>
  );
}

const mapStateToProps = (state: RootState) => ({
  brands: state.brands.brands,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateLocation: (id: number, location: CompanyLocationBasic) => dispatch(updateLocation(id, location)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type LocationCardConnectedProps = ConnectedProps<typeof connector>;

export default connector(LocationCard);
