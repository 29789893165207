import { faPlayCircle } from "@fortawesome/free-regular-svg-icons";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { PropsWithChildren } from "react";
import { SeekerWithExperience } from "../../data/seeker";
import { classNames } from "../../utils/classNameList";
import "./SeekerCard.scss";

function SeekerCardHeader({ children }: PropsWithChildren) {
  return <div className="header">{children}</div>;
}

function SeekerCardDetails({ children }: PropsWithChildren) {
  return <div className="body">{children}</div>;
}

type UserType = {
  avatar_url?: string | null;
  profile?: {
    optimized_video_url?: string | null;
    optimized_video_thumb_url?: string | null;
  } | null;
};

type SeekerCardProps<T> = PropsWithChildren & {
  seeker: T;
  className?: string;
  ref?: React.LegacyRef<HTMLDivElement>
};

function SeekerCard<T extends UserType = SeekerWithExperience>({ seeker, children, className, ref }: SeekerCardProps<T>) {
  let header,
    details = null;
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child?.type) {
        if (child.type === SeekerCardHeader) {
          header = child;
        } else if (child.type === SeekerCardDetails) {
          details = child;
        }
      }
    }
  });

  return (
    <div className={classNames("seeker-card", className)} ref={ref}>
      {header}
      {details}
      <div className="avatar">
        {seeker.avatar_url != null ? (
          <img src={seeker.avatar_url} className="user-avatar" alt="User Avatar" />
        ) : (
          <FontAwesomeIcon icon={faUserAlt} className="user-avatar" />
        )}
        {seeker.profile?.optimized_video_url && (
          <div className="has-video-indicator">
            <FontAwesomeIcon icon={faPlayCircle} />
          </div>
        )}
      </div>
    </div>
  );
}

SeekerCard.Header = SeekerCardHeader;
SeekerCard.Details = SeekerCardDetails;

export default SeekerCard;
