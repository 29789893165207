import { faCamera, faEdit, faLocationDot, faVideoCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../app/store";
import Avatar from "../../components/Avatar";
import IconButton from "../../components/IconButton";
import { UserState } from "../../reducers/user";
import VideoPlayer from "../common/VideoPlayer";
import ContactInformationModal from "./ContactInformationModal";
import "./ProfilePage.scss";
import ProfilePicModal from "./ProfilePicModal";
import ProfileVideoLocationsModal from "./ProfileVideoLocationsModal";
import ProfileVideoModal from "./ProfileVideoModal";

type ContactInformationFragmentProps = ContactInformationFragmentConnectedProps;

function ContactInformationFragment({ user }: ContactInformationFragmentProps) {
  const [editContactInfoVisible, setEditContactInfoVisible] = useState(false);
  const [editProfilePicVisible, setEditProfilePicVisible] = useState(false);
  const [editProfileVideoVisible, setEditProfileVideoVisible] = useState(false);
  const [editProfileVideoLocationsVisible, setEditProfileVideoLocationsVisible] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  return (
    <div className="contact-info-header">
      <Avatar<UserState> user={user} onPlayVideo={() => setShowVideo(true)} />
      <div className="top">
        <div className="edit-buttons">
          <IconButton
            size={1.5}
            onClick={() => setEditProfilePicVisible(true)}
            className="bg-white text-primary"
            icon={faCamera}
          />
          <IconButton
            size={1.5}
            onClick={() => setEditProfileVideoVisible(true)}
            className="bg-white text-primary"
            icon={faVideoCamera}
          />
          <IconButton
            size={1.5}
            onClick={() => setEditProfileVideoLocationsVisible(true)}
            className="bg-white text-primary"
            icon={faLocationDot}
          />
        </div>
      </div>
      <div className="bottom">
        <div className="d-flex flex-row">
          <div>
            <div className="name">
              {user.profile?.first_name} {user.profile?.last_name}
            </div>
            <div className="address">
              <label className="me-2">Address: </label>
              {user.profile?.address_1}
              {user.profile?.address_2 ? `, ${user.profile?.address_2}` : null}
              {user.profile?.address_city ? ` ${user.profile?.address_city}` : null}
              {user.profile?.address_state ? `, ${user.profile?.address_state}` : null}
              {user.profile?.address_zip ? ` ${user.profile?.address_zip}` : null}
            </div>
            <div className="phone">
              <label className="me-2">Phone Number: </label>
              {user.profile?.phone}
            </div>
          </div>
          <div className="align-self-start">
            <Button variant="link" onClick={() => setEditContactInfoVisible(true)}>
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </div>
        </div>
      </div>
      <ContactInformationModal visible={editContactInfoVisible} onClosed={() => setEditContactInfoVisible(false)} />
      <ProfilePicModal visible={editProfilePicVisible} onClosed={() => setEditProfilePicVisible(false)} />
      <ProfileVideoModal visible={editProfileVideoVisible} onClosed={() => setEditProfileVideoVisible(false)} />
      <ProfileVideoLocationsModal
        visible={editProfileVideoLocationsVisible}
        onClosed={() => setEditProfileVideoLocationsVisible(false)}
      />
      {showVideo && user.profile?.optimized_video_url && user.profile?.optimized_video_thumb_url && (
        <div className="video-container">
          <VideoPlayer
            src={user.profile.optimized_video_url}
            poster={user.profile.optimized_video_thumb_url}
            autoPlay={true}
            onClose={() => setShowVideo(false)}
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
  };
};

const connector = connect(mapStateToProps);

type ContactInformationFragmentConnectedProps = ConnectedProps<typeof connector>;

export default connector(ContactInformationFragment);
