import { faDownLeftAndUpRightToCenter, faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons";
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Alert, Badge, Button, Card } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { acceptConnection } from "../../actions/connections";
import { loadConversationById, updateConversationLastRead } from "../../actions/conversations";
import { AppDispatch, RootState } from "../../app/store";
import AppToast from "../../components/AppToast";
import Avatar from "../../components/Avatar";
import IconButton from "../../components/IconButton";
import LoadingOverlay from "../../components/LoadingOverlay";
import { ConnectionPaymentStatus } from "../../constants";
import { CompanyLocation } from "../../data/companyLocation";
import { classNames } from "../../utils/classNameList";
import { hasEmployerChats, hasSystemUserChats, ParsedConversation } from "../../utils/conversations";
import { AcceptConnectionError } from "../../utils/errors";
import { formatMoney } from "../../utils/format";
import CardDeclinedModal from "../payments/CardDeclinedModal";
import NewPaymentPackageModal from "../payments/NewPaymentPackageModal";
import ConversationMessages from "./ConversationMessages";
import ConversationToolbar from "./ConversationToolbar";
import { ConversationSeeker } from "../../data/conversation";

type HeaderProps<T> = {
  conversation: ParsedConversation;
  location: T;
  job_ids: number[];
};
function Header<T extends CompanyLocation = CompanyLocation>({ conversation, location, job_ids }: HeaderProps<T>) {
  const appliedJobTitles = location.jobs.filter((job) => job_ids.includes(job.id)).map((job) => job.title);
  return (
    <div className="d-flex flex-row gap-3 text-white">
      <Avatar<ConversationSeeker> user={conversation.seeker} onPlayVideo={() => {}} />
      <div className="d-flex flex-column">
        <div className="fw-semibold fs-larger">
          {conversation.seeker.first_name} {conversation.seeker.last_name}
        </div>
        <div>
          <span>Location: </span>
          {conversation.employer.location_description} ({conversation.employer.location_details})
        </div>
        <div>
          <span>Interested in: </span>
          {appliedJobTitles.map((jobTitle, index) => (
            <Badge key={`pos-${index}`} pill={true} className="bg-primary text-white border border-white">
              {jobTitle}
            </Badge>
          ))}
        </div>
      </div>
    </div>
  );
}

type ConversationProps = ConversationConnectedProps & {
  conversation: ParsedConversation;
  expandClick?: (expanded: boolean) => void;
  expanded?: boolean;
  className?: string;
  style?: CSSProperties;
};

const Conversation = ({
  user,
  conversation,
  managedLocations,
  updateConversationLastRead,
  loadConversationById,
  acceptConnection,
  expandClick,
  expanded,
  className,
  style,
}: ConversationProps) => {
  const [waiting, setWaiting] = useState(false);
  const [connectionFeeConfirmed, setConnectionFeeConfirmed] = useState(false);
  const [showCreatePaymentPackageModal, setShowCreatePaymentPackageModal] = useState(false);
  const [showCardDeclinedModal, setShowCardDeclinedModal] = useState(false);
  const connectionRequired = conversation?.connection
    ? conversation.connection.payment_status === ConnectionPaymentStatus.Unpaid
    : false;
  const existingChatsAreAutoChats = conversation && !hasEmployerChats(conversation) && hasSystemUserChats(conversation);
  const updatingLastReadId = useRef<number | null>(null);
  const location = useMemo(
    () => managedLocations!.find((l) => l.id === conversation?.employer.location_id)!,
    [managedLocations, conversation]
  );

  const acceptConnectionHandler = useCallback(() => {
    setWaiting(true);
    acceptConnection(conversation.connection_id)
      .then(() => {
        // refresh the conversation
        loadConversationById(conversation.id).finally(() => setWaiting(false));
      })
      .catch((error) => {
        setWaiting(false);
        if (error instanceof AcceptConnectionError) {
          switch (error.reason) {
            case AcceptConnectionError.ReasonCodes.AlreadyApproved: {
              // refresh conversation
              loadConversationById(conversation.id);
              break;
            }
            case AcceptConnectionError.ReasonCodes.PaymentDeclined: {
              setShowCardDeclinedModal(true);
              break;
            }
            case AcceptConnectionError.ReasonCodes.PaymentMethodMissing: {
              setShowCreatePaymentPackageModal(true);
              break;
            }
            default:
          }
        } else {
          AppToast.show({ text: error?.message ?? "An unxpected error occurred", type: "error" });
        }
      });
  }, [conversation, acceptConnection, loadConversationById]);

  const updateLastRead = useCallback(
    (conversationId: number) => {
      if (updatingLastReadId.current === conversationId) return;
      updatingLastReadId.current = conversationId;
      updateConversationLastRead(conversationId)
        .then(() => {
          updatingLastReadId.current = null;
        })
        .catch((error) => {
          console.error(error);
          AppToast.show({ text: "An unexpected error occurred", type: "error" });
        });
    },
    [updateConversationLastRead]
  );

  useEffect(() => {
    if (conversation?.id) {
      updateLastRead(conversation.id);
    }
  }, [conversation?.id, updateLastRead]);

  const expandClickHandler = useCallback(() => {
    if (expandClick) {
      expandClick(!expanded);
    }
  }, [expanded, expandClick]);

  const connectButton = useMemo(() => {
    if (conversation?.connection == null) return null;
    const style = { flex: "0 0 auto" };
    return conversation.connection.accept_fee_cents == null ? (
      <Button onClick={acceptConnectionHandler} style={style}>
        Connect
      </Button>
    ) : connectionFeeConfirmed || conversation.connection.accept_fee_cents === 0 ? (
      <Button onClick={acceptConnectionHandler} style={style}>
        {connectionFeeConfirmed ? "Confirm" : "Connect"}
      </Button>
    ) : (
      <Button onClick={() => setConnectionFeeConfirmed(true)} style={style}>
        {`Connect (${formatMoney(conversation.connection.accept_fee_cents / 100)})`}
      </Button>
    );
  }, [conversation, acceptConnectionHandler, connectionFeeConfirmed, setConnectionFeeConfirmed]);

  const alertMessages = [];
  if (existingChatsAreAutoChats) {
    alertMessages.push(
      "Juvo has previously chatted with this seeker to give them a positive experience with your business."
    );
  }
  if (connectionRequired) {
    alertMessages.push("To continue the conversation, first connect with the seeker.");
  }

  return (
    <div className={classNames("d-flex flex-column conversation-details position-relative", className)} style={style}>
      {conversation ? (
        <Card className="border-primary" style={{ maxHeight: "100%" }}>
          <Card.Header className="bg-primary" style={{ flex: "0 0 auto" }}>
            <Header conversation={conversation} location={location} job_ids={conversation.connection.job_ids} />
            <IconButton
              icon={expanded ? faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter}
              onClick={expandClickHandler}
              className="text-white"
              style={{ position: "absolute", right: "0.5rem", top: "0.5rem" }}
            />
          </Card.Header>
          <Card.Body className="p-0" style={{ flex: "0 1 auto", minHeight: "1px" }}>
            <ConversationMessages currentUser={user} conversation={conversation} />
          </Card.Body>
          <Card.Footer className="bg-white" style={{ flex: "0 0 auto" }}>
            <React.Fragment>
              {alertMessages.length > 0 && (
                <Alert
                  variant="warning"
                  className="d-flex flow-row gap-3 align-items-center justify-content-between alert-sm"
                >
                  {alertMessages.join(" ")}
                  {connectionRequired && connectButton}
                </Alert>
              )}
            </React.Fragment>
            <ConversationToolbar conversation={conversation} enabled={!connectionRequired} />
          </Card.Footer>
        </Card>
      ) : (
        <span>Select a conversation</span>
      )}
      {waiting && <LoadingOverlay />}
      {showCreatePaymentPackageModal && (
        <NewPaymentPackageModal
          visible={true}
          locationId={conversation.employer.location_id}
          onClosed={(success) => {
            setShowCreatePaymentPackageModal(false);
            if (success) {
              acceptConnectionHandler();
            }
          }}
        />
      )}
      {showCardDeclinedModal && <CardDeclinedModal visible={true} onClosed={() => setShowCardDeclinedModal(false)} />}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    managedLocations: state.locations.locations,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    updateConversationLastRead: (conversationId: number) => dispatch(updateConversationLastRead(conversationId)),
    acceptConnection: (connectionId: number) => dispatch(acceptConnection(connectionId)),
    loadConversationById: (conversationId: number) => dispatch(loadConversationById(conversationId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ConversationConnectedProps = ConnectedProps<typeof connector>;

export default connector(Conversation);
