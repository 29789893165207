// these helper functions were taken from sample code at https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
export type CookieOptions = {
  expiresIn?: number;
  path?: string;
  domain?: string;
  secure?: boolean;
};

export function getCookie(cookieName: string) {
  const regex = new RegExp("(?:(?:^|.*;\\s*)" + cookieName + "\\s*\\=\\s*([^;]*).*$)|^.*$");
  return document.cookie.replace(regex, "$1");
}

export function setCookie(cookieName: string, cookieValue: string, options: CookieOptions) {
  document.cookie = `${cookieName}=${cookieValue};${buildCookieOptions(options)}`;
}

export function deleteCookie(cookieName: string, options: CookieOptions) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { expiresIn, ...passThruOptions } = options ?? {};
  const cookieOptions = buildCookieOptions(passThruOptions);
  document.cookie = `${cookieName}=;max-age=0;expires=Thu, 01 Jan 1970 00:00:01 GMT;${cookieOptions}`;
}

function buildCookieOptions(options: CookieOptions) {
  let cookieOptions = "samesite=strict";
  if (options) {
    if (options.expiresIn) {
      cookieOptions += ";expires=" + new Date(Date.now() + options.expiresIn * 1000).toUTCString();
      cookieOptions += `;max-age=${options.expiresIn}`;
    }
    if (options.path) {
      cookieOptions += ";path=" + options.path;
    }
    if (options.domain) {
      cookieOptions += ";domain=" + options.domain;
    }
    if (options.secure) {
      if (window.location.protocol === "https:") {
        cookieOptions += ";secure";
      }
    }
  } else {
    if (window.location.protocol === "https:") {
      cookieOptions += ";secure";
    }
  }
  return cookieOptions;
}

export function cookieExists(cookieName: string) {
  const allCookies = document.cookie.split(";");
  return allCookies.some(function (item) {
    return item.trim().indexOf(cookieName + "=") === 0;
  });
}
