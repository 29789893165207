import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useRef, useState } from "react";
import {
  Alert,
  Button,
  Col,
  ColProps,
  Container,
  Form,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router";
import { validateUser } from "../../actions/registration";
import { AppDispatch, RootState } from "../../app/store";
import { ButtonSpinnerOverlay } from "../../components/ButtonSpinnerOverlay";
import { CheckInput, Field, SelectInput, TextField, TextInput } from "../../components/Forms";
import { UserAccount } from "../../data/registration";
import { ModelValidationErrors } from "../../data/validation";
import { setLocationCount, setUser } from "../../reducers/registration";
import { FailedRequestError } from "../../rest-client/errors";
import { range } from "../../utils/range";
import {
  collectFormValidationErrors,
  getElementValidationErrors,
  mergeModelValidationErrors,
} from "../../utils/validation";
import { Link } from "react-router-dom";
import { toSentence } from "../../utils/format";

type UserAccountProps = UserAccountFormConnectedProps;

const LOCATION_COUNT_CHOICES = range(1, 100).map((r) => r.toString());

type UserAccountEdit = Partial<UserAccount> & {
  phone_ext?: string;
};

function UserAccountForm({
  validateUser,
  user: savedUser,
  saveUser,
  locationCount: savedLocationCount,
  saveLocationCount,
}: UserAccountProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const [user, setUser] = useState<UserAccountEdit>(savedUser ?? {});
  const [locationCount, setLocationCount] = useState<number>(savedLocationCount ?? 1);
  const [validating, setValidating] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ModelValidationErrors | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [validationVisibile, setValidationVisibile] = useState(false);
  const [certifiedEmployer, setCertifiedEmployer] = useState(false);
  const [certifiedEmployerMessage, setCertifiedEmployerMessage] = useState<string | null>(null);

  const updateUser = useCallback(
    (attributes: UserAccountEdit) => {
      setUser({ ...user, ...attributes });
      if (validationErrors) {
        const updatedValidationErrors = { ...validationErrors };
        for (const key of Object.keys(validationErrors)) {
          if (key in attributes) {
            delete updatedValidationErrors[key];
          }
        }
        setValidationErrors(updatedValidationErrors);
      }
    },
    [user, setUser, validationErrors, setValidationErrors]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => updateUser({ [event.target.name]: event.target.value }),
    [updateUser]
  );

  const showValidationErrors = useCallback(
    (errors: ModelValidationErrors | null, isServerSide: boolean) => {
      setValidationErrors(errors);
      if (isServerSide) {
        formRef.current!.classList.remove("was-validated");
      } else {
        formRef.current!.classList.add("was-validated");
      }
      setValidationVisibile(true);
    },
    [setValidationErrors, setValidationVisibile]
  );

  const navigate = useNavigate();
  const submitHandler = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      if (!certifiedEmployer) {
        setCertifiedEmployerMessage("Please check this box to continue");
        return;
      }
      const isValid = formRef.current!.checkValidity();
      if (isValid) {
        // extract phone and extension for further processing,
        // discard password from validation request
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { phone, phone_ext, password: _password, ...passThru } = user;
        const validationUserAccount = passThru as UserAccount;
        if (phone) {
          let combinedPhone = phone;
          if (combinedPhone.length > 10 && combinedPhone.startsWith("1")) {
            combinedPhone = combinedPhone.slice(1);
          }
          if (phone_ext) {
            combinedPhone = `${combinedPhone}x${phone_ext}`;
          }
          validationUserAccount.phone = combinedPhone;
        }
        validateUser(validationUserAccount)
          .then(() => {
            saveUser(user as UserAccount);
            saveLocationCount(locationCount);
            navigate("/registration/business");
          })
          .catch((error) => {
            setValidating(false);
            if (error instanceof FailedRequestError) {
              if (error.isConflict()) {
                showValidationErrors({ email: "An account with this email already exists" }, true);
                return;
              } else if (error.isUnprocessableEntity()) {
                showValidationErrors(error.responseJson, true);
                return;
              }
            }
            setErrorMessage("An unexpected error occurred");
          });
      } else {
        const formValidationErrors = collectFormValidationErrors(formRef.current!);
        showValidationErrors(formValidationErrors, false);
      }
    },
    [
      user,
      saveUser,
      navigate,
      locationCount,
      saveLocationCount,
      showValidationErrors,
      validateUser,
      certifiedEmployer,
      setCertifiedEmployerMessage,
    ]
  );

  const blurHandler = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
      if (!validationVisibile) return;
      const newValidationError = getElementValidationErrors(event.target);
      setValidationErrors(mergeModelValidationErrors(validationErrors, event.target.name, newValidationError));
    },
    [validationErrors, setValidationErrors, validationVisibile]
  );

  const onShowPasswordClick = useCallback(() => {
    const passwordInput = passwordInputRef?.current;
    if (passwordInput) {
      passwordInput.type = passwordInput.type === "text" ? "password" : "text";
    }
  }, []);

  const onEmployerCertifiedChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCertifiedEmployer(event.target.checked);
      setCertifiedEmployerMessage(null);
    },
    [setCertifiedEmployer, setCertifiedEmployerMessage]
  );

  const columnSizes: ColProps = {
    sm: 6,
    md: 6,
    lg: 6,
    xl: 6,
    xxl: 6,
  };

  return (
    <Form onSubmit={submitHandler} ref={formRef} noValidate>
      <Container fluid>
        <Row>
          <Col className="mt-3 mb-3">
            <h4 className="text-primary">Create your account</h4>
            For the employers who just want to hire <i>local</i> talent.
            <br />
            Get noticed in the neighborhood by joining the Juvo Jobs network!
          </Col>
        </Row>
        <Row>
          <Col {...columnSizes}>
            <TextField
              value={user?.first_name ?? ""}
              autoComplete="given-name"
              name="first_name"
              label="First Name"
              validationErrors={validationErrors?.first_name}
              required
              onChange={handleChange}
              onBlur={blurHandler}
            />
          </Col>
          <Col {...columnSizes}>
            <TextField
              value={user?.last_name ?? ""}
              autoComplete="family-name"
              name="last_name"
              label="Last Name"
              validationErrors={validationErrors?.last_name}
              required
              onChange={handleChange}
              onBlur={blurHandler}
            />
          </Col>
        </Row>
        <Row>
          <Col {...columnSizes}>
            <Field label="Phone Number">
              <InputGroup>
                <TextInput
                  type="tel"
                  pattern="(1-?)?\(?([0-9]{3})\)?[\-. ]?([0-9]{3})[\-. ]?([0-9]{4})"
                  autoComplete="tel"
                  value={user?.phone ?? ""}
                  name="phone"
                  placeholder="###-###-####"
                  validationErrors={validationErrors?.phone}
                  required
                  onChange={handleChange}
                  onBlur={blurHandler}
                  className="flex-grow-1 flex-shrink-1"
                />
                <InputGroup.Text>x</InputGroup.Text>
                <TextInput
                  type="tel"
                  htmlSize={1}
                  autoComplete="tel-extension"
                  inputMode="numeric"
                  value={user?.phone_ext ?? ""}
                  name="phone_ext"
                  validationErrors={validationErrors?.phone_ext}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    updateUser({ phone_ext: event.target.value.replace(/[^0-9]/g, "") })
                  }
                  onBlur={blurHandler}
                  className="flex-grow-0 flex-shrink-0 w-auto"
                  style={{ flexBasis: "auto" }}
                />
                {validationErrors?.phone && (
                  <Form.Control.Feedback type="invalid">{toSentence(validationErrors?.phone)}</Form.Control.Feedback>
                )}
              </InputGroup>
            </Field>
          </Col>
          <Col {...columnSizes}>
            <TextField
              type="email"
              autoComplete="email"
              value={user?.email ?? ""}
              name="email"
              label="Email"
              validationErrors={validationErrors?.email}
              required
              onChange={handleChange}
              onBlur={blurHandler}
            />
          </Col>
        </Row>
        <Row>
          <Col {...columnSizes}>
            <Field attributeName="password">
              <Form.Label>Password</Form.Label>
              <InputGroup hasValidation>
                <TextInput
                  type="password"
                  autoComplete="new-password"
                  value={user?.password ?? ""}
                  name="password"
                  minLength={8}
                  validationErrors={validationErrors?.password}
                  required
                  onChange={handleChange}
                  onBlur={blurHandler}
                  ref={passwordInputRef}
                />
                <Button variant="outline-primary" onClick={onShowPasswordClick}>
                  <span className="fs-smaller fw-bold">SHOW</span>
                </Button>
                {validationErrors?.password && (
                  <Form.Control.Feedback type="invalid">{toSentence(validationErrors?.password)}</Form.Control.Feedback>
                )}
              </InputGroup>
            </Field>
          </Col>
          <Col {...columnSizes}>
            <Field>
              <Form.Label>
                <>
                  <span>Number of locations?</span>&nbsp;
                  <OverlayTrigger overlay={<Tooltip>The number of locations you’d like to add to our network</Tooltip>}>
                    <FontAwesomeIcon icon={faCircleInfo} />
                  </OverlayTrigger>
                </>
              </Form.Label>
              <SelectInput
                value={locationCount.toString()}
                name="location_count"
                required
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setLocationCount(parseInt(event.target.value))}
                items={LOCATION_COUNT_CHOICES}
              />
            </Field>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <CheckInput
                id="employer-certify"
                label="I certify that I am an employer interested in learning more about Juvo Jobs"
                checked={certifiedEmployer}
                onChange={onEmployerCertifiedChange}
              />
              {certifiedEmployerMessage && <div className="text-danger">{certifiedEmployerMessage}</div>}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            {errorMessage && (
              <Alert variant="danger" dismissible onClose={() => setErrorMessage(null)}>
                {errorMessage}
              </Alert>
            )}
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="col-md-auto mt-3 position-relative">
            <Button variant="primary" type="submit" disabled={validating}>
              Continue
            </Button>
            {validating && <ButtonSpinnerOverlay />}
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="col-md-auto mt-3">
            <Link to={"/login"} className="btn btn-link">
              Already have an account? Click to login
            </Link>
          </Col>
        </Row>
      </Container>
    </Form>
  );
}

const mapStateToProps = (state: RootState) => ({
  user: state.registration.user,
  locationCount: state.registration.location_count,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  validateUser: (user: UserAccount) => dispatch(validateUser(user)),
  saveUser: (user: UserAccount) => dispatch(setUser(user)),
  saveLocationCount: (count: number) => dispatch(setLocationCount(count)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type UserAccountFormConnectedProps = ConnectedProps<typeof connector>;

export default connector(UserAccountForm);
