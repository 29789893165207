import React, { PropsWithChildren, useCallback, useMemo, useRef, useState } from "react";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router";
import { authenticate } from "../../actions/authentication";
import { setInitialAppPath } from "../../actions/initialization";
import { activateRegistration, clearRegistrationData, createRegistration } from "../../actions/registration";
import { AppDispatch, RootState } from "../../app/store";
import { ButtonSpinnerOverlay } from "../../components/ButtonSpinnerOverlay";
import { NumberInput } from "../../components/Forms";
import { addressStateCodeToName, pricingPackageTermDescription, PricingPackageUnitType } from "../../constants";
import { PaymentMethod } from "../../data/payments";
import { ActivateRegistrationParams, CreateRegistrationParams } from "../../data/registration";
import { ModelValidationErrors } from "../../data/validation";
import { FailedRequestError } from "../../rest-client/errors";
import { StripeCardError } from "../../utils/errors";
import { isNullOrUndefined } from "../../utils/validation";
import CreditCardForm, { CreditCardAddress, CreditCardFormRef } from "../payments/CreditCardForm";

type SummaryRowProps = PropsWithChildren & {
  label: string;
  item?: string;
};

function SummaryRow({ label, item, children }: SummaryRowProps) {
  return (
    <div className="order-summary-row">
      <div className="order-summary-row-label">
        <label className="col-form-label">{label}</label>
      </div>
      <div className="order-summary-row-item">{item ?? children}</div>
    </div>
  );
}

function BottomElements({
  buttonText,
  errorMessage,
  setErrorMessage,
  submitHandler,
  submitting,
}: {
  buttonText: string;
  errorMessage: string | null;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
  submitHandler: (e: React.MouseEvent) => void;
  submitting: boolean;
}) {
  return (
    <>
      <Row>
        <Col>
          {errorMessage && (
            <Alert variant="danger" dismissible onClose={() => setErrorMessage(null)}>
              {errorMessage}
            </Alert>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="text-center mt-3 position-relative">
          <Button variant="primary" type="submit" onClick={submitHandler} disabled={submitting}>
            {buttonText}
          </Button>
          {submitting && <ButtonSpinnerOverlay />}
        </Col>
      </Row>
      <Row>
        <Col className="text-center mt-3">
          {`By clicking ${buttonText}, you agree to Juvo's `}
          <a target="_blank" rel="noreferrer" href="https://www.juvojobs.com/privacy-policy">
            User Agreement
          </a>{" "}
          and{" "}
          <a target="_blank" rel="noreferrer" href="https://www.juvojobs.com/privacy-policy">
            Privacy Policy
          </a>
        </Col>
      </Row>
    </>
  );
}

type PaymentFormProps = PaymentFormConnectedProps;

function PaymentForm({
  user,
  business,
  locationCount: savedLocationCount,
  paymentMethod,
  paymentDescription,
  pricingPackage,
  createRegistration,
  activateRegistration,
  registrationKey,
  clearRegistrationData,
  authenticate,
  setInitialAppPath,
}: PaymentFormProps) {
  const formRef = useRef<CreditCardFormRef>(null);

  const [locationCount, setLocationCount] = useState(savedLocationCount ?? 1);
  const [validationErrors, setValidationErrors] = useState<ModelValidationErrors | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const copyFromAddress = useMemo<CreditCardAddress>(
    () => ({
      address_line1: business.address_1,
      address_line2: business.address_2,
      address_city: business.address_city,
      address_state: addressStateCodeToName(business.address_state) ?? "",
      address_zip: business.address_zip,
      address_country: "US",
    }),
    [business]
  );

  const submitRegistration = useCallback(async () => {
    try {
      const registrationKey = await createRegistration({
        user: user!,
        business: business!,
        pricing_package_key: pricingPackage?.selector_key,
      });
      return registrationKey;
    } catch (error) {
      if (error instanceof FailedRequestError && error.isUnprocessableEntity()) {
        // these validation errors shouldn't happen, but is possible
        // if necessary the code could navigate back to the appropriate screens for the user to fix
        /*
        const validationErrors = error.responseJson as ModelValidationErrors;
        if ("user" in validationErrors) {
          return null;
        } else if ("business" in validationErrors) {
          return null;
        }
        */
      }
      setErrorMessage("An unxepected error occurred");
      return null;
    }
  }, [user, business, setErrorMessage, createRegistration, pricingPackage?.selector_key]);

  const submitActivation = useCallback(
    async (registrationKey: string, paymentMethodToken?: string) => {
      try {
        await activateRegistration({
          registrationKey: registrationKey!,
          locationCount: locationCount!,
          paymentMethodToken,
        });
        return true;
      } catch (error) {
        if (error instanceof StripeCardError) {
          if (error.param != null && error.param.length > 0) {
            setErrorMessage(null);
            setValidationErrors({ [error.param]: error.message });
          } else {
            setErrorMessage(error.message);
            setValidationErrors(null);
          }
        } else if (error instanceof Error) {
          setErrorMessage(error.message);
          setValidationErrors(null);
        } else {
          setErrorMessage("An unexpected error occurred");
          setValidationErrors(null);
        }
        return false;
      }
    },
    [activateRegistration, locationCount, setErrorMessage, setValidationErrors]
  );

  const navigate = useNavigate();
  const submitHandler = useCallback(
    async (event: React.MouseEvent) => {
      event.preventDefault();
      setSubmitting(true);
      let paymentSuccessful = false;
      if (paymentMethod === PaymentMethod.Manual) {
        let _registrationKey = registrationKey;
        if (_registrationKey == null) {
          _registrationKey = await submitRegistration();
        }
        if (_registrationKey) {
          paymentSuccessful = await submitActivation(_registrationKey);
        }
      } else {
        let paymentMethodToken: string | null = null;
        try {
          paymentMethodToken = await formRef.current!.createToken();
        } catch (error) {
          if (error instanceof Error) {
            setErrorMessage(error.message);
          } else {
            setErrorMessage("An unexpected error occurred");
          }
        }
        if (paymentMethodToken) {
          let _registrationKey = registrationKey;
          if (_registrationKey == null) {
            _registrationKey = await submitRegistration();
          }
          if (_registrationKey) {
            paymentSuccessful = await submitActivation(_registrationKey, paymentMethodToken);
          }
        }
      }
      if (paymentSuccessful) {
        authenticate(user.email, user.password).then(() => {
          clearRegistrationData();
          setInitialAppPath("/locations");
          navigate("/");
        });
      }
      setSubmitting(false);
    },
    [
      paymentMethod,
      authenticate,
      clearRegistrationData,
      setInitialAppPath,
      submitActivation,
      submitRegistration,
      user,
      setErrorMessage,
      navigate,
      registrationKey,
    ]
  );

  if (paymentMethod == PaymentMethod.Manual && isNullOrUndefined(pricingPackage)) {
    return (
      <Container fluid>
        <Row>
          <Col className="d-flex justify-content-center mt-5 mb-3">
            {paymentDescription ? (
              <div dangerouslySetInnerHTML={{ __html: paymentDescription }} />
            ) : (
              <div>You're all set, click Complete Setup to finish registration</div>
            )}
          </Col>
        </Row>
        <BottomElements
          buttonText="Complete Setup"
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          submitHandler={submitHandler}
          submitting={submitting}
        />
      </Container>
    );
  } else {
    const unitPrice = (pricingPackage.setup_fee ?? 0) + (pricingPackage.unit_price ?? 0);
    const totalPrice =
      pricingPackage.unit_type === PricingPackageUnitType.Company ? unitPrice : unitPrice * locationCount;

    const isCreditCardPayment = (isNullOrUndefined(paymentMethod) || paymentMethod == PaymentMethod.Card);

    return (
      <Container fluid>
        {isCreditCardPayment && (
          <Row>
            <Col className="mt-3 mb-3">
              <h4 className="text-primary">Payment Details</h4>
              Provide your credit card info
            </Col>
          </Row>
        )}
        <Row>
          {isCreditCardPayment && (
            <Col>
              <CreditCardForm
                validationErrors={validationErrors}
                ref={formRef}
                copyAddress={copyFromAddress}
                copyAddressLabel="business address"
              />
            </Col>
          )}
          <Col className="d-flex justify-content-center">
            <div className="flex-grow-1" style={{ maxWidth: "500px" }}>
              <h5>Order Summary</h5>
              <SummaryRow label={pricingPackage.title} item={`$ ${unitPrice.toFixed(2)}/${pricingPackageTermDescription(pricingPackage.term)}`} />
              <SummaryRow label="Number of Locations">
                <NumberInput
                  value={locationCount}
                  required
                  min={1}
                  max={999}
                  step={1}
                  className="text-end"
                  dir="rtl"
                  onChange={(event) => setLocationCount(parseInt(event.target.value))}
                  onBlur={(event) => {
                    if (event.target.value.length === 0 || event.target.value === "0") {
                      setLocationCount(1);
                    }
                  }}
                  style={{ width: "5rem" }}
                />
              </SummaryRow>
              <hr />
              <div className="fw-bold">{isCreditCardPayment ? 'Total due now' : 'Total'}</div>
              <SummaryRow
                label={`(${locationCount}) Location(s) @ (${`$${unitPrice.toFixed(2)}`}) ${pricingPackage.title}`}
                item={`$ ${totalPrice.toFixed(2)}`}
              />
            </div>
          </Col>
        </Row>
        {paymentDescription && (
          <Row>
            <Col className="d-flex justify-content-center mt-3 mb-3">
              <div dangerouslySetInnerHTML={{ __html: paymentDescription }} />
            </Col>
          </Row>
        )}
        <BottomElements
          buttonText="Complete Your Order"
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          submitHandler={submitHandler}
          submitting={submitting}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.registration.user!,
  business: state.registration.business!,
  locationCount: state.registration.location_count!,
  pricingPackage: state.registration.pricing_package!,
  registrationKey: state.registration.registration_key,
  paymentMethod: state.registration.payment_method,
  paymentDescription: state.registration.payment_description,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createRegistration: (registration: CreateRegistrationParams) => dispatch(createRegistration(registration)),
  activateRegistration: (params: ActivateRegistrationParams) => dispatch(activateRegistration(params)),
  clearRegistrationData: () => dispatch(clearRegistrationData()),
  authenticate: (userName: string, password: string) => dispatch(authenticate(userName, password)),
  setInitialAppPath: (path: string) => dispatch(setInitialAppPath(path)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PaymentFormConnectedProps = ConnectedProps<typeof connector>;

export default connector(PaymentForm);
