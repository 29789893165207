import React from "react";
import { To } from "react-router";
import List from "../../components/List";
import { ConnectionWithSeekerExperience } from "../../data/connection";
import { ParsedConnection } from "../../utils/connections";
import { ParsedConversation } from "../../utils/conversations";
import ConnectionListItem from "./ConnectionListItem";

export type ConnectionListItemTypeBase = ParsedConnection<ConnectionWithSeekerExperience, ParsedConversation>;

type ConnectionListProps<T extends ConnectionListItemTypeBase> = {
  connections: T[];
  className?: string;
  selectedConnectionId?: number;
  itemLink?: (item: T) => To;
  onItemClick?: (item: T) => void;
};

function ConnectionList<T extends ConnectionListItemTypeBase>({
  connections,
  className,
  selectedConnectionId,
  itemLink,
  onItemClick,
}: ConnectionListProps<T>) {
  const selectedIndex =
    selectedConnectionId && connections ? connections.findIndex((c) => c.id === selectedConnectionId) : undefined;
  return (
    <React.Fragment>
      <List<T, any, HTMLDivElement>
        items={connections ?? []}
        className={className}
        selectedIndex={selectedIndex}
        renderItem={({
          item: connection,
          selected,
          ref,
        }) => (
          <ConnectionListItem
            ref={ref}
            connection={connection}
            selected={selected}
            linkTo={itemLink ? itemLink(connection) : undefined}
            onClick={onItemClick}
          />
        )}
      />
    </React.Fragment>
  );
}

export default ConnectionList;
