import compareDesc from "date-fns/compareDesc";
import isBefore from "date-fns/isBefore";
import parseISO from "date-fns/parseISO";
import { Conversation, ConversationMessage } from "../data/conversation";

export type ParsedConversation = Conversation<Date | null, ConversationMessage<Date>>;

export function parseConversation<T extends Conversation = Conversation>(
  conversation: T
): ParsedConversation {
  const _conversation : ParsedConversation = {
    ...conversation,
    last_read: conversation.last_read ? parseISO(conversation.last_read) : null,
    messages: conversation.messages.map((message) => ({
      ...message,
      created_at: parseISO(message.created_at),
    })),
  };
  _conversation.messages.sort((l, r) => compareDesc(l.created_at, r.created_at));
  return _conversation;
}

export function parseConversations<T extends Conversation = Conversation>(conversations: T[]) : ParsedConversation[] {
  return conversations?.map(parseConversation);
}

function seekerParticipant(conversation: ParsedConversation) {
  return conversation.participants.find((p) => p.user_id === conversation.seeker.id);
}

function employerParticipants(conversation: ParsedConversation) {
  return conversation.participants.filter((p) => p.user_id !== conversation.seeker.id && !p.is_system_user);
}

function systemUserParticipants(conversation: ParsedConversation) {
  return conversation.participants.filter((p) => p.is_system_user);
}

export function hasSeekerChats(conversation: ParsedConversation) {
  const seekerParticipantId = seekerParticipant(conversation)?.id;
  return seekerParticipantId != null && conversation.messages.some((m) => m.participant_id === seekerParticipantId);
}

export function hasEmployerChats(conversation: ParsedConversation) {
  const employerParticipantsIds = employerParticipants(conversation).map((p) => p.id);
  return (
    employerParticipantsIds.length > 0 &&
    conversation.messages.some((m) => employerParticipantsIds.includes(m.participant_id))
  );
}

export function hasSystemUserChats(conversation: ParsedConversation) {
  const systemUserParticipantsIds = systemUserParticipants(conversation).map((p) => p.id);
  return (
    systemUserParticipantsIds.length > 0 &&
    conversation.messages.some((m) => systemUserParticipantsIds.includes(m.participant_id))
  );
}

export function conversationHasUnreadMessage(conversation: ParsedConversation) {
  const seekerParticipantId = seekerParticipant(conversation)?.id;
  if (seekerParticipantId == null) {
    return false;
  }
  return (
    conversation.messages.filter(
      (m) =>
        m.participant_id === seekerParticipantId &&
        (conversation.last_read == null || isBefore(conversation.last_read, m.created_at))
    ).length > 0
  );
}

export function unreadConversationCount(conversations: ParsedConversation[]) {
  let count = 0;
  conversations.forEach((c) => {
    if (conversationHasUnreadMessage(c)) {
      count++;
    }
  });
  return count;
}
