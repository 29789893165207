import { CompanyLocationBasic } from "../data/companyLocation";

export function sortLocations<T extends CompanyLocationBasic>(locations: T[] | null) {
  if (locations == null) {
    return null;
  }
  const sortedLocations = Array.from(locations ?? []);
  sortedLocations?.sort((left, right) => {
    const nameCompare = left.name.localeCompare(right.name);
    return nameCompare === 0 ? left.address_1.localeCompare(right.address_1) : nameCompare;
  });
  return sortedLocations;
}
