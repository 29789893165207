import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Col, Container, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../app/store";
import AppToast from "../../components/AppToast";
import AutoComplete from "../../components/AutoComplete";
import { CheckInput, Field, TextField } from "../../components/Forms";
import { DBA_TOOLTIP_TEXT, pricingPackageTermDescription, PricingPackageUnitType } from "../../constants";
import { Brand } from "../../data/brand";
import { CompanyLocationBasic } from "../../data/companyLocation";
import { PaymentPackage } from "../../data/payments";
import { ModelValidationErrors } from "../../data/validation";
import { formatMoney, toSentence } from "../../utils/format";
import { addressComponentsToAddress } from "../../utils/googleAddress";
import { enterKeyPressPatch } from "../../utils/placesAutocomplete";
import {
  collectFormValidationErrors,
  getElementValidationErrors,
  isNullOrEmpty,
  mergeModelValidationErrors,
} from "../../utils/validation";

export type LocationCost = {
  initialAmount: number;
  initialDescription: string;
  periodicAmount: number;
  periodDescription: string;
  periodStartDate: Date;
};

type LocationFormProps = LocationFormConnectedProps & {
  id?: string;
  location?: Partial<CompanyLocationBasic>;
  onSubmit: (location: CompanyLocationBasic, confirmedCostAmount?: number) => void;
  onChange?: (event: { valid: boolean; attributes: Partial<CompanyLocationBasic> }) => void;
  validationErrors?: ModelValidationErrors;
  copyAddress?: Partial<CompanyLocationBasic>;
  copyAddressLabel?: string;
  paymentPackage?: PaymentPackage;
};

function LocationForm({
  location: initialLocation,
  onSubmit,
  onChange,
  brands,
  validationErrors: parentValidationErrors,
  id,
  copyAddress,
  copyAddressLabel,
  paymentPackage,
}: LocationFormProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const confirmChargeRef = useRef<HTMLInputElement | null>(null);
  const streetAddressInputRef = useRef<HTMLInputElement>(null);

  const [location, setLocation] = useState<Partial<CompanyLocationBasic>>(initialLocation ?? {});

  const { confirmCharge, initialChargeAmount, shortChargeDescription, detailedChargeDescription } = useMemo<{
    confirmCharge: boolean;
    initialChargeAmount?: number;
    shortChargeDescription?: string;
    detailedChargeDescription?: string;
  }>(() => {
    if (
      paymentPackage &&
      (paymentPackage?.remaining_location_count ?? 0) === 0 &&
      ((paymentPackage.pricing.setup_fee ?? 0) > 0 ||
        (paymentPackage.pricing.unit_type === PricingPackageUnitType.Location &&
          (paymentPackage.pricing.unit_price ?? 0) > 0))
    ) {
      const shortChargeItems: string[] = [];
      const detailsChargeItems: string[] = [];
      const detailsInitialChargeItems: string[] = [];
      const { unit_price, setup_fee, term } = paymentPackage.pricing;
      const paidUntil = new Date(paymentPackage.paid_until);
      const daysRemainingInBillingCycle = Math.floor((paidUntil.getTime() - Date.now()) / (86400 * 1000));

      const initialAmount = (setup_fee ?? 0) + (daysRemainingInBillingCycle / term!) * unit_price;

      if (initialAmount > 0) {
        shortChargeItems.push(`${formatMoney(initialAmount)} now`);
      }
      if (setup_fee ?? 0 > 0) {
        detailsInitialChargeItems.push(`a one-time setup fee of ${formatMoney(initialAmount)}`);
      }
      if (unit_price > 0 && daysRemainingInBillingCycle > 0) {
        detailsInitialChargeItems.push(
          `a charge of ${formatMoney(
            (daysRemainingInBillingCycle / term!) * unit_price
          )} for the remaining days in the current billing cycle ending ${Intl.DateTimeFormat("en-US").format(
            paidUntil
          )}`
        );
      }
      if (detailsInitialChargeItems.length > 0) {
        detailsChargeItems.push(toSentence(detailsInitialChargeItems)!);
      }
      if (unit_price > 0) {
        const periodicChargeDescription = `${formatMoney(unit_price)}/${pricingPackageTermDescription(term!)}`;
        const billingPeriodStart = Intl.DateTimeFormat("en-US").format(paidUntil);
        shortChargeItems.push(`${periodicChargeDescription} starting ${billingPeriodStart}`);
        detailsChargeItems.push(`${periodicChargeDescription} thereafter`);
      }
      return {
        confirmCharge: true,
        initialChargeAmount: initialAmount,
        shortChargeDescription: toSentence(shortChargeItems)!,
        detailedChargeDescription: toSentence(detailsChargeItems, { lastSeparator: ", then " })!,
      };
    } else {
      return { confirmCharge: false };
    }
  }, [paymentPackage]);

  const [chargeConfirmed, setChargeConfirmed] = useState(!confirmCharge);

  const [validationErrors, setValidationErrors] = useState<ModelValidationErrors | null>(
    parentValidationErrors ?? null
  );

  const [validationVisibile, setValidationVisibile] = useState(false);
  const [copyAddressChecked, setCopyAddressChecked] = useState(false);

  const applyLocationUpdates = useCallback(
    (attributes: Partial<CompanyLocationBasic>) => {
      setLocation({ ...location, ...attributes });
      if (validationErrors) {
        const updatedValidationErrors = { ...validationErrors };
        for (const key of Object.keys(validationErrors)) {
          if (key in attributes) {
            delete updatedValidationErrors[key];
          }
        }
        setValidationErrors(updatedValidationErrors);
      }
    },
    [location, setLocation, validationErrors, setValidationErrors]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      applyLocationUpdates({ [event.target.name]: event.target.value });
      if (event.target.name !== "name") {
        setCopyAddressChecked(false);
      }
    },
    [applyLocationUpdates, setCopyAddressChecked]
  );

  const brandChanged = useCallback(
    (item: Brand) => {
      applyLocationUpdates({ brand_id: item.id });
    },
    [applyLocationUpdates]
  );

  const showValidationErrors = useCallback(
    (errors: ModelValidationErrors | null, isServerSide: boolean) => {
      setValidationErrors(errors);
      if (isServerSide) {
        formRef.current!.classList.remove("was-validated");
      } else {
        formRef.current!.classList.add("was-validated");
      }
      setValidationVisibile(true);
    },
    [setValidationErrors, setValidationVisibile]
  );

  const confirmChargeChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setChargeConfirmed(event.target.checked);
      if (event.target.checked) {
        const updatedValidationErrors = { ...validationErrors };
        delete updatedValidationErrors[event.target.name];
        setValidationErrors(updatedValidationErrors);
      }
    },
    [setChargeConfirmed, setValidationErrors, validationErrors]
  );

  const blurHandler = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
      if (!validationVisibile) return;
      const newValidationError = getElementValidationErrors(event.target);
      const updatedValidationErrors = mergeModelValidationErrors(
        validationErrors,
        event.target.name,
        newValidationError
      );
      showValidationErrors(updatedValidationErrors, false);
    },
    [validationErrors, showValidationErrors, validationVisibile]
  );

  const [placeAutocomplete, setPlaceAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const places = useMapsLibrary("places");

  useEffect(() => {
    if (!places || !streetAddressInputRef.current) return;

    const _placeAutocomplete = new places.Autocomplete(streetAddressInputRef.current, {
      componentRestrictions: { country: ["us"] },
      fields: ["address_components", "geometry"],
      types: ["address"],
    });
    setPlaceAutocomplete(_placeAutocomplete);
    enterKeyPressPatch(streetAddressInputRef.current);
  }, [places]);

  useEffect(() => {
    if (onChange) {
      onChange({
        valid: validationErrors == null || Object.keys(validationErrors).length === 0,
        attributes: location,
      });
    }
  }, [location, validationErrors, onChange]);

  const onCopyAddressChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCopyAddressChecked(event.target.checked);
      const geocoder = new google.maps.Geocoder();
      const fullAddress = `${copyAddress!.address_1} ${copyAddress!.address_2}, ${copyAddress!.address_city}, ${
        copyAddress!.address_state
      } ${copyAddress!.address_zip}`;
      geocoder.geocode({ address: fullAddress }, (results: google.maps.GeocoderResult[] | null) => {
        if (!isNullOrEmpty(results)) {
          const bestResult = results?.find((result) =>
            ["street_address", "premise"].some((type) => result.types.includes(type))
          );
          if (bestResult && bestResult.geometry && bestResult.address_components) {
            applyLocationUpdates({
              ...addressComponentsToAddress(bestResult.address_components),
              location: {
                latitude: bestResult.geometry.location.lat(),
                longitude: bestResult.geometry.location.lng(),
              },
            });
            return;
          }
        }
        setCopyAddressChecked(false);
        AppToast.show({ type: "error", text: "Unable to use billing address as a Juvo location" });
      });
    },
    [copyAddress, applyLocationUpdates]
  );

  const updateAddress = useCallback(
    (place: google.maps.places.PlaceResult) => {
      if (place.address_components && place.geometry?.location) {
        applyLocationUpdates({
          ...addressComponentsToAddress(place.address_components),
          location: {
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
          },
        });
      }
    },
    [applyLocationUpdates]
  );

  const submitHandler = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      if (confirmCharge) {
        if (chargeConfirmed) {
          confirmChargeRef.current?.setCustomValidity("");
        } else {
          confirmChargeRef.current?.setCustomValidity("You must confirm the charges");
        }
      }
      const isValid = formRef.current!.checkValidity();
      if (isValid) {
        const completedLocation = location as CompanyLocationBasic;
        onSubmit(completedLocation, initialChargeAmount);
      } else {
        const formValidationErrors = collectFormValidationErrors(formRef.current!);
        showValidationErrors(formValidationErrors, false);
      }
    },
    [location, onSubmit, showValidationErrors, initialChargeAmount, confirmCharge, chargeConfirmed]
  );

  useEffect(() => {
    if (!placeAutocomplete) return;
    placeAutocomplete.addListener("place_changed", () => {
      const place = placeAutocomplete.getPlace();
      updateAddress(place);
    });
  }, [placeAutocomplete, updateAddress]);

  return (
    <Form id={id} onSubmit={submitHandler} ref={formRef} noValidate>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <TextField
              value={location?.name ?? ""}
              name="name"
              label="Location Name/Number"
              autoComplete="false"
              maxLength={100}
              minLength={3}
              validationErrors={validationErrors?.name}
              required={true}
              onChange={handleChange}
              onBlur={blurHandler}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Field>
              <Form.Label>
                <>
                  <span>DBA</span>&nbsp;
                  <OverlayTrigger overlay={<Tooltip>{DBA_TOOLTIP_TEXT}</Tooltip>}>
                    <FontAwesomeIcon icon={faCircleInfo} />
                  </OverlayTrigger>
                </>
              </Form.Label>
              <AutoComplete<Brand>
                suggestions={brands}
                selectedItem={brands.find((brand) => brand.id === location.brand_id)}
                onChange={brandChanged}
              />
            </Field>
          </Col>
        </Row>
        {copyAddress && (
          <Row className="mb-2">
            <Col>
              <CheckInput
                id="copy-from-address"
                label={`Same as ${copyAddressLabel}`}
                checked={copyAddressChecked}
                onChange={onCopyAddressChanged}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col sm={12}>
            <TextField
              value={location?.address_1 ?? ""}
              name="address_1"
              label="Street Address"
              autoComplete="false"
              maxLength={100}
              minLength={5}
              validationErrors={validationErrors?.address_1}
              required={true}
              onChange={handleChange}
              onBlur={blurHandler}
              ref={streetAddressInputRef}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6} lg={6} xl={6}>
            <TextField
              value={location?.address_city ?? ""}
              name="address_city"
              label="City"
              maxLength={100}
              minLength={2}
              validationErrors={validationErrors?.address_city}
              required={true}
              onChange={handleChange}
              onBlur={blurHandler}
            />
          </Col>
          <Col sm={3} md={3} lg={3} xl={3}>
            <TextField
              value={location?.address_state ?? ""}
              name="address_state"
              label="State"
              maxLength={100}
              minLength={2}
              validationErrors={validationErrors?.address_state}
              required={true}
              onChange={handleChange}
              onBlur={blurHandler}
            />
          </Col>
          <Col sm={3} md={3} lg={3} xl={3}>
            <TextField
              value={location?.address_zip ?? ""}
              name="address_zip"
              label="Zipcode"
              maxLength={5}
              minLength={5}
              pattern="^\d{5}$"
              validationErrors={validationErrors?.address_zip}
              required={true}
              onChange={handleChange}
              onBlur={blurHandler}
            />
          </Col>
        </Row>
        {confirmCharge && (
          <Row>
            <Col>
              <Form.Group>
                <CheckInput
                  id="charge-confirmation"
                  name="charge_confirmation"
                  label={`Confirm charge(s)`}
                  validationErrors={validationErrors?.charge_confirmation}
                  checked={chargeConfirmed}
                  onChange={confirmChargeChanged}
                  ref={confirmChargeRef}
                />
              </Form.Group>
              <div className="d-flex flex-row gap-3 align-items-center">
                {shortChargeDescription}
                <OverlayTrigger overlay={<Tooltip>{detailedChargeDescription}</Tooltip>}>
                  <FontAwesomeIcon icon={faCircleInfo} />
                </OverlayTrigger>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </Form>
  );
}

const mapStateToProps = (state: RootState) => ({
  brands: state.brands.brands ?? [],
});

const connector = connect(mapStateToProps);

type LocationFormConnectedProps = ConnectedProps<typeof connector>;

export default connector(LocationForm);
