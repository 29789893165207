import { CompanyLocation, CompanyLocationBasic } from "../data/companyLocation";
import * as LocationReducers from "../reducers/locations";
import * as PaymentsReducers from "../reducers/payments";
import * as Api from "../rest-client/api";
import { AppAction, AppAsyncAction } from "./types";

export function loadLocations() : AppAsyncAction<CompanyLocation[]> {
  return (dispatch) => {
    return Api.loadLocations().then(({ responseJson }) => {
      dispatch(LocationReducers.setLocations(responseJson));
      return responseJson;
    });
  };
}

export function loadLocationActivity() : AppAsyncAction {
  return (dispatch) => {
    return Api.fetchLocationActivityStats().then(({ responseJson }) => {
      dispatch(LocationReducers.setLocationActivity(responseJson));
      return responseJson;
    });
  };
}

export function getLocationById(locationId: number) : AppAction<CompanyLocation | undefined> {
  return (_dispatch, getState) => {
    const { locations: { locations } } = getState();
    return locations?.find(l => l.id === locationId);
  };
}

export function getLocationByJobId(jobId: number) : AppAction<CompanyLocation | undefined> {
  return (_dispatch, getState) => {
    const { locations: { locations } } = getState();
    return locations?.find(location => location.jobs.some(job => job.id === jobId));
  };
}

export function updateLocation(locationId: number, location: CompanyLocationBasic) : AppAsyncAction<CompanyLocation> {
  return (dispatch) => {
    return Api.updateLocation(locationId, location).then(({ responseJson }) => {
      dispatch(LocationReducers.updateLocation(responseJson));
      return responseJson;
    });
  };
}

export function createLocation(location: CompanyLocationBasic, confirmedCostAmount?: number) : AppAsyncAction<CompanyLocation> {
  return (dispatch) => {
    return Api.createLocation(location, confirmedCostAmount).then(({ responseJson }) => {
      const location = responseJson as CompanyLocation;
      dispatch(LocationReducers.addLocation(location));
      dispatch(PaymentsReducers.decrementRemainingLocations(location.payment_package_id!));
      return responseJson;
    });
  };
}
