import { ConnectionStatus } from "../../constants";
import { Connection } from "../../data/connection";
import { ConnectionFilters } from "../../data/miscellaneous";

const ACTIVE_CONNECTION_STATUSES = [ConnectionStatus.Pending, ConnectionStatus.Accepted];

export const ConnectionCategories: {
  category: string;
  search: ConnectionFilters;
  matcher: (connection: Connection) => boolean;
}[] = [
  {
    category: "all",
    search: { status: ACTIVE_CONNECTION_STATUSES },
    matcher: () => true,
  },
  {
    category: "viewed",
    search: { status: ACTIVE_CONNECTION_STATUSES, reviewed: true },
    matcher: (connection: Connection) => connection.reviewed,
  },
  {
    category: "favorites",
    search: { status: ACTIVE_CONNECTION_STATUSES, interest_level: 1 },
    matcher: (connection: Connection) => connection.interest_level > 0,
  },
];

export function connectionFiltersForCategory(category: string) {
  return ConnectionCategories.find((f) => f.category === category);
}

export const ConnectionFilters_All = connectionFiltersForCategory("all")!;
export const ConnectionFilters_Viewed = connectionFiltersForCategory("viewed")!;
export const ConnectionFilters_Favorites = connectionFiltersForCategory("favorites")!;
