import { CustomActionResult } from "../data/customActions";
import * as Api from "../rest-client/api";
import { AppAsyncAction } from "./types";

export function fetchCustomActionResults({
  actionId,
  seekerId,
}: {
  actionId?: number;
  seekerId?: number;
}): AppAsyncAction<CustomActionResult[]> {
  return () => {
    return Api.fetchCustomActionResults(actionId, seekerId).then(({ responseJson }) => {
      return responseJson as CustomActionResult[];
    });
  };
}

export function executeCustomAction(actionId: number, objectId: number): AppAsyncAction<CustomActionResult> {
  return () => {
    return Api.executeCustomAction(actionId, objectId).then(({ responseJson }) => {
      return responseJson as CustomActionResult;
    });
  };
}
