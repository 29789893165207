import { useCallback, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { updateProfile } from "../../actions/user";
import { AppDispatch, RootState } from "../../app/store";
import { UserProfile } from "../../data/userProfile";
import ContactInformationForm from "./ContactInformationForm";
import "./ProfilePage.scss";

type ContactInformationModalProps = ContactInformationModalConnectedProps & {
  visible?: boolean;
  onClosed: () => void;
};
function ContactInformationModal({ visible = true, user, onClosed, updateProfile }: ContactInformationModalProps) {
  const [contactInfo, setContactInfo] = useState<Partial<UserProfile>>({
    first_name: user.profile?.first_name ?? "",
    last_name: user.profile?.last_name ?? "",
    address_1: user.profile?.address_1 ?? "",
    address_2: user.profile?.address_2 ?? "",
    address_city: user.profile?.address_city ?? "",
    address_state: user.profile?.address_state ?? "",
    address_zip: user.profile?.address_zip ?? "",
    phone: user.profile?.phone ?? "",
  });
  const [updating, setUpdating] = useState(false);
  const [validationErrors, setValidationErrors] = useState();

  const contactInfoUpdated = useCallback(
    (updates: Partial<UserProfile>) => {
      setContactInfo({ ...contactInfo, ...updates });
    },
    [contactInfo]
  );

  const handleSave = useCallback(() => {
    setUpdating(true);
    updateProfile(contactInfo)
      .then(() => {
        onClosed();
      })
      .catch(({ responseJson }) => {
        console.error("contact update failed", responseJson);
        setValidationErrors(responseJson);
      })
      .finally(() => setUpdating(false));
  }, [contactInfo, onClosed, setUpdating, updateProfile, setValidationErrors]);

  return (
    <Modal show={visible} onHide={onClosed}>
      <Modal.Header closeButton>
        <Modal.Title>Contact Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ContactInformationForm
          contactInfo={contactInfo}
          validationErrors={validationErrors}
          onUpdate={contactInfoUpdated}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClosed}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={updating}>
          {updating ? "Saving changes..." : "Save Changes"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    updateProfile: (updatedAttributes: Partial<UserProfile>) => dispatch(updateProfile(updatedAttributes)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ContactInformationModalConnectedProps = ConnectedProps<typeof connector>;

export default connector(ContactInformationModal);
