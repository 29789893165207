import React from 'react'
import ReactDOM from 'react-dom/client'
import store from './app/store';
import { Provider } from "react-redux";
import App from './App.tsx'
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';
import './index.css'
import AppToast from './components/AppToast.tsx';

const googleAnalyticsTrackingId = import.meta.env.VITE_GOOGLE_ANALYTICS_TRACKING_ID;
if (googleAnalyticsTrackingId) {
  ReactGA.initialize(googleAnalyticsTrackingId);
}
if (import.meta.env.MODE !== 'development') {
  Sentry.init({
    dsn: "https://0d12b9c341d3451e9c43d34bc567d685@o252244.ingest.sentry.io/4504967304052736",
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
    environment: import.meta.env.VITE_ENVIRONMENT,
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <AppToast />
    </Provider>
  </React.StrictMode>,
)
