import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import CertificatesList from "./CertificatesList";
import EducationList from "./EducationList";
import "./ProfileItemList.scss";
import { UserEducation } from "../../data/userEducation";
import { UserCertification } from "../../data/userCertification";

const EXPANDABLE_INITIAL_ITEM_COUNT = 2;

type EducationCardProps = {
  educations: UserEducation[];
  certificates: UserCertification[];
};
function EducationCard({ educations, certificates }: EducationCardProps) {
  const [expanded, setExpanded] = useState(false);

  const educationsCount = educations?.length ?? 0;
  const certificatesCount = certificates?.length ?? 0;
  const totalItems = educationsCount + certificatesCount;

  const showMoreItems = totalItems > EXPANDABLE_INITIAL_ITEM_COUNT;
  const maxCertificatesToShow = expanded
    ? certificatesCount
    : Math.max(EXPANDABLE_INITIAL_ITEM_COUNT - educationsCount, 0);

  return (
    <Card className="border-primary">
      <Card.Header className="bg-primary text-white">Education</Card.Header>
      <Card.Body>
        <EducationList educations={educations} maxItems={expanded ? 1000 : EXPANDABLE_INITIAL_ITEM_COUNT} />
        {certificatesCount > 0 && maxCertificatesToShow > 0 && (
          <React.Fragment>
            <div className="text-decoration-underline text-center mt-3">Certifications</div>
            <CertificatesList certificates={certificates} maxItems={maxCertificatesToShow} />
          </React.Fragment>
        )}
      </Card.Body>
      {showMoreItems && (
        <Card.Footer className="bg-white align-items-center d-flex flex-column p-1">
          {expanded ? (
            <Button variant="link" onClick={() => setExpanded(false)}>
              <FontAwesomeIcon icon={faCaretUp} className="me-2" />
              Most relevant
            </Button>
          ) : (
            <Button variant="link" onClick={() => setExpanded(true)}>
              <FontAwesomeIcon icon={faCaretDown} className="me-2" />
              {totalItems - EXPANDABLE_INITIAL_ITEM_COUNT} more
            </Button>
          )}
        </Card.Footer>
      )}
    </Card>
  );
}

export default EducationCard;
