import { Brand } from "../data/brand";
import BaseRestClient from "./baseClient";

export function loadBrands() {
  return BaseRestClient.fetch("api/v3/employer/brands");
}

export function updateBrand(brandId: number, attributes: Omit<Brand, "id">) {
  return BaseRestClient.patch(`api/v3/employer/brands/${brandId}`, {
    location: attributes,
  });
}

export function createBrand(attributes: Omit<Brand, "id">) {
  return BaseRestClient.post("api/v3/employer/brands", {
    location: attributes,
  });
}
